import { ApiGet, ApiPost } from "../helper";
import { IApiResponse, ContactType, ContactFilterType } from "../type-interface";
import { ILabelValueModel } from "mony-component";

export const FetchAllWithoutPaging = async (keyword: string, filter: ContactFilterType = ""): Promise<ILabelValueModel[]> => {
    let contacts: ILabelValueModel[] = [];
    const response = await ApiGet(`/contact/data/get-all?filter=${filter}&keyword=${keyword || ""}`);
    if (response.success) {
        contacts = response.data.map((x) => {
            return { label: x.contact_name, value: x.contact_id.toString() };
        });
    }
    return contacts;
}

export const FetchAll = async (queryString: string): Promise<IApiResponse> => {
    const response = await ApiGet(`/contact/data/get-all${queryString}`);
    return response;
}

export const CreateContactOtg = async (name: string, type: ContactType): Promise<IApiResponse> => {
    const response = await ApiPost(`/contact/data`, { type: [type], contact_name: name, contact_code: "", contact_details: [] });
    return response;
}

export const GetContactName = async (contactId: number) => {
    const response = await ApiGet(`/contact/name-only/${contactId}`);
    let contactName = "";
    if (response.success) {
        contactName = response.data.contact_name;
    }
    return contactName;
}