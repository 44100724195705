import React from "react";
import { FlooterProps } from "../interfaces/FlooterProps";
import Pagination from "antd/es/pagination";
import { Selected } from ".";
import { GetLanguage } from "../helper/Language";
import CommonEnume from "../enum/CommonEnume";

import ConfigProvider from "antd/es/config-provider";
import enUS from "antd/lib/locale-provider/en_US";
import { ILanguage } from "..";

const Flooter: React.FC<FlooterProps> = (props) => {

  props = { ...defaultProps, ...props };

  const perpageData = React.useRef(Object.keys(CommonEnume.PERPAGE).map((x) => ({ label: CommonEnume.PERPAGE[x], value: CommonEnume.PERPAGE[x].toString() }))).current;

  const [language, setLanguage] = React.useState<ILanguage>(!props.translate ? GetLanguage(props.language || "id") : null);
  React.useEffect(() => {
      if (!props.translate) {
          setLanguage(GetLanguage(props.language || "id"));
      }
  }, [props.language, props.translate]);

  const OnChangedPerPage = React.useCallback((val) => {
    // $(".-reactjs-scrollbar-thumb").css("top", "0%");
    // $(".-reactjs-scrollbar-area").css("margin-top", 0);
    return props.updateFilter("perpage", Number(val.value));
  }, []);

  const OnChangedpage = React.useCallback((page) => {
    // $(".-reactjs-scrollbar-thumb").css("top", "0%");
    // $(".-reactjs-scrollbar-area").css("margin-top", 0);
    return props.updateFilter("current", page);
  }, []);

  return (
    <ConfigProvider locale={enUS} >
      <div className={"table--nav clearfix pb--10 " + props.className} style={{ minWidth: props.minWidth }}>
        <div className="flooter-inline">
          <span className="pt--5 font--size--14">{props.translate ? props.translate("show", "label") : language.Label.show}</span>
          <div className="flooter-selected mt-min-1">
            <Selected
              className="totalRow--number wd--60"
              key="totalRow"
              data={perpageData}
              value={props.data.perpage}
              OnSelected={OnChangedPerPage}
              placeholder="" />
          </div>
          <span className="pt--5 font--size--14">&nbsp;{props.translate ? props.translate("data_per_page", "label") : language.Label.data_per_page} ({props.data.totalRow} {props.translate ? props.translate("total_item", "label") : language.Label.total_item})</span>
        </div>

        {
          props.data.totalRow > 0 ?
            <div className="right flooter-inline text-right">
              <Pagination
                className="flooter--pagination ant-pagination-new"
                onChange={OnChangedpage}
                total={Number(props.data.totalRow)}
                current={Number(props.data.current)}
                defaultCurrent={1}
                pageSize={Number(props.data.perpage)}
                defaultPageSize={10} />
            </div>
            : null
        }
      </div>
    </ConfigProvider>
  );
}

const defaultProps: FlooterProps = {
  className: "",
  minWidth: 990,
  language: "id",
  data: {
    totalRow: 0,
    totalPage: 0,
    perpage: 0,
    current: 0
  }
}

export default React.memo(Flooter);
