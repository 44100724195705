import React from "react";

const FormTitle: React.FC<{ title: string, className?: string }> = (props) => {
    return (
        <div className={`header-full-form text-left title--desc-form ${props.className || ""}`}>
            <div className="float-left">{props.title || ""}</div>
            <div className="float-right pr--20"></div>
            <div className="clear"></div>
        </div>
    );
}

export default FormTitle;