import { IUserModel, IRegisterModel } from '../types';

export const InitialStateContainer = (): IUserModel => {
    return {
        username: "",
        password: "",
        oldpassword: "",
        newpassword: "",
        confirmnewpassword: "",
        confirm_password: "",
        user_fullname: "",
        user_email: "",
        user_email_tmp: "",
        user_email_old: "",
        user_phone: "",
        has_agreed: false,
        company_name: "",
        files: {
            filename: "",
            file: "",
            id: 0,
            url: "",
            uploaded: true,
        },
        // is_show: true,
        user_permission: [],
        // coalang: "",
        // currency: "",
        // proref_code: "",
        // timezone: "",
        // show_referral: false
    };
};

export const InitialStateRegister = (): IRegisterModel => {
    return {
        coalang: "",
        company_name: "",
        confirm_password: "",
        currency: "",
        has_agreed: false,
        password: "",
        proref_code: "",
        timezone: "",
        user_email: "",
        user_fullname: "",
        user_phone: "",
        year_end: "",
        year_end_day: "",
        year_end_month: "",
        show_referral: false
    };
};