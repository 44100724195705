import React from "react";
import { IErrorModel, ITagingModel } from "../type-interface";
import { ILabelValueModel } from "mony-component";
import { TaggingApi } from "../api";

export type TypeTaggingState = {
    state: ILabelValueModel[],
    actions: {
        Load: (keyword: string) => void;
        CreateOtg: (data: ITagingModel, onSuccess: (contactId) => void, onError: (error: IErrorModel[]) => void) => void;
    }
}

export const useTaggingState = (taggingModule: string, taggingGroup: string): TypeTaggingState => {

    const [state, setState] = React.useState([]);

    const Load = React.useCallback(async (keyword: string) => {
        const data = await TaggingApi.FetchHeaderWithoutPaging(keyword, taggingModule, taggingGroup);
        setState(data);
    }, [state]);

    const CreateOtg = React.useCallback(async (data: ITagingModel, onSuccess: (taggingId) => void, onError: (error: IErrorModel[]) => void) => {
        const response = await TaggingApi.CreateOtg(data);
        if (response.success) {
            await Load(data.tagging_title);
            onSuccess(response.data.contact_id);
        } else {
            onError(response.messages);
        }
    }, []);

    return {
        state,
        actions: {
            Load,
            CreateOtg
        }
    };
}
