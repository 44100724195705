import { createStore, applyMiddleware, Store } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";

// const middlewares = [thunkMiddleware];
// const middlewareEnhancer = applyMiddleware(...middlewares);
// const enhancers = [middlewareEnhancer];
// const composedEnhancers = composeWithDevTools(...enhancers);

const containerStore: Store = createStore(
    rootReducer,
    // composeWithDevTools(
        applyMiddleware(thunkMiddleware)
    // )
);

export type ContainerState = ReturnType<typeof rootReducer>;
export { containerStore };