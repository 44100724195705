import * as React from "react";
import * as ReactDOM from "react-dom";

export const Hotspot: React.FC<React.PropsWithChildren<HotspotProps>> = (props) => {
    let style: React.CSSProperties = {
        animationDelay: `${props.delay}s`
    };

    if (props.target) {
        let { left, top, right, bottom, height, width } = props.target;
        left = left + (width / 2);
        top = top + (height / 2);
        if (left && top) {
            style = { ...style, position: "fixed", left, top };
        }
    }

    return (
        <div className={`hotspot hotspot--active ${props.color}`} style={style}>
            {props.children}
        </div>
    );
}

interface HotspotProps {
    active: boolean;
    border: boolean;
    delay: string | number;
    color?: "" | "danger";
    target?: DOMRect;
}

interface RenderProps {
    targetId?: string;
    hostpot: HotspotProps
};

export const RenderHotspot = (options: RenderProps) => {
    if (options.targetId) {
        const target = document.getElementById(options.targetId);
        if (target) {
            options = {
                ...options,
                hostpot: {
                    ...options.hostpot, target: target.getBoundingClientRect()
                }
            };
        }
    }

    const container = document.body.appendChild(document.createElement("DIV"));
    ReactDOM.render(<Hotspot {...options.hostpot} />, container);
}