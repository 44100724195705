import { ApiGet, ApiPost } from "../../libs/helper";
import { IApiResponse } from "../../libs/type-interface";

export const FetchCompanyType = async (): Promise<IApiResponse> => {
    const response = await ApiGet("/user/companytype");
    return response;
}

export const FetchCurrency = async (): Promise<IApiResponse> => {
    const response = await ApiGet("/masterdata/currency");
    return response;
}

export const FetchTimeZone = async (): Promise<IApiResponse> => {
    const response = await ApiGet("/masterdata/timezone");
    return response;
}

export const RegisterUser = async (data: any): Promise<IApiResponse> => {
    const response = await ApiPost("/user/register", data);
    return response;
}

export const FetchProfile = async (): Promise<IApiResponse> => {
    const response = await ApiGet("/user/profile");
    return response;
}

export const CheckValidasiReferral = async (kode: string): Promise<IApiResponse> => {
    const response = await ApiGet(`/saas/check-valid-referral/${kode}`);
    return response;
}
