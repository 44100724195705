import React, { Key, PropsWithChildren } from "react";
import Loader from "./Loader";
import { Common } from "../helper";
import { FormTitle } from ".";

export type TypeHeaders = Array<Array<ITableColHeaderProps>> | Array<ITableColHeaderProps> | React.ReactElement;

interface TableProps {
    id: string;
    style: any;
    className: string;
}

interface TableHeadProps {
    className: string;
    children: React.ReactElement | Array<React.ReactElement>;
}

interface ThTdProps {
    // key?: Key;
    className?: string;
    colSpan?: number;
    rowSpan?: number;
    vAlign?: "top" | "middle" | "bottom";
    content?: React.ReactElement | Array<React.ReactElement> | string | null;
    children?: React.ReactElement;
    heading?: HTMLHeadingElement;
    ownClickEvent?: boolean;
    border?: boolean;
}

export interface ITableColHeaderProps {
    key?: Key;
    content: React.ReactElement | string | null; //string | React.ReactNode;
    className?: string;
    classLabel?: string;
    isRequired?: boolean;
    options?: {
        rowSpan?: number;
        colSpan?: number;
        heading?: React.ReactElement;
    }
}

export interface TableRowProps {
    key?: Key;
    className?: string;
    rowSpan?: number;
    colSpan?: number;
    columns?: TypeColumn;
    content?: React.ReactElement | string | null;
    rowHeading?: boolean;
    heading?: React.ReactElement;
    border?: boolean;
    rowIndex?: number;
    columnHasOwnClick?: Array<number>;
    onRowClick?: (e: any) => void;
}

interface TableBodyProps {
    tableId: string;
    className: string;
    rowClassName?: string;
    heightBody?: number;
    rows?: Array<TableRowProps>;
    headerColCount?: number;
    columnHasOwnClick?: Array<number>;
    onRowClick?: (rowIndex: number | null) => void;
    border?: boolean;
}

export type TypeRows = Array<Array<TableRowProps>>;
export type TypeColumn = Array<TableRowProps> | React.ReactElement;

interface DynamicTableProps {
    id: string;
    ajax?: boolean;
    title?: string;
    titleClassName?: string;
    style?: any;
    className?: string;
    tableHover?: boolean;
    rowClassName?: string;
    emptyRowClassName?: string;
    headClassname?: string;
    bodyClassname?: string;
    emptyRow?: string | null;
    classDivTable?: string;
    heightBody?: number;
    // headers?: ITableColHeaderProps[] | React.ReactElement;
    headers?: TypeHeaders;
    rows?: TypeRows;
    footers?: TypeRows | TypeColumn;
    footerLineTop?: boolean;
    addButton?: React.ReactElement;
    addButtonAfterFooter?: boolean;
    columnHasOwnClick?: Array<number>;
    onRowClick?: (e: any) => void;
}

interface TableFootProps {
    className?: string;
    addButton?: React.ReactElement;
    addButtonAfterFooter?: boolean;
    lineColSpan: number;
    hasFooter: boolean;
    footerLineTop: boolean;
}

class Table extends React.Component<TableProps> {
    render() {
        return (
            <table id={this.props.id} className={this.props.className} style={this.props.style}>{this.props.children}</table>
        );
    }
}

class TableHead extends React.Component<TableHeadProps> {
    render() {
        return (
            <thead className={this.props.className}>{this.props.children}</thead>
        );
    }
}

class TH extends React.Component<ThTdProps> {
    render() {
        const border = this.props.rowSpan > 0 || this.props.border ? "1px solid #ddd" : "none";
        return (
            <th
                className={this.props.className}
                style={{
                    verticalAlign: this.props.rowSpan > 0 ? (this.props.vAlign || "middle") : (this.props.vAlign || "none"),
                    borderRight: border,
                    borderLeft: border
                }}
                rowSpan={this.props.rowSpan}
                colSpan={this.props.colSpan} >
                {this.props.children || this.props.content}
            </th>
        );
    }
}

class TD extends React.Component<ThTdProps> {
    ref: any;
    componentDidMount() {
        this.ref.setAttribute("own-click", this.props.ownClickEvent);
    }
    render() {
        return (
            <td
                ref={(ref) => this.ref = ref}
                className={this.props.className}
                style={{
                    borderRight: this.props.border ? "1px solid #ddd" : "none",
                    borderLeft: this.props.border ? "1px solid #ddd" : "none"
                }}
                rowSpan={this.props.rowSpan}
                colSpan={this.props.colSpan}>
                {this.props.children || this.props.content}
            </td>
        );
    }
};

class TableRow extends React.Component<PropsWithChildren<TableRowProps>> {

    ref: any;

    constructor(props) {
        super(props);
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidUpdate(props) {
        if (props.rowIndex !== this.props.rowIndex) {
            this.setAttrRowIndex(props);
        }
    }

    componentDidMount() {
        this.setAttrRowIndex(this.props);
    }

    setAttrRowIndex(props) {
        if (props.rowIndex !== null && !isNaN(props.rowIndex)) {
            this.ref.setAttribute("row-index", props.rowIndex);
        }
    }

    onRowClick(e) {
        if (this.props.onRowClick) {
            this.props.onRowClick(e);
        }
    }

    render() {
        let columns = this.props.children;
        if (Array.isArray(this.props.columns) && this.props.columns.length > 0) {
            columns = this.props.columns.map((column, index) => {
                const ownClickEvent = this.props.columnHasOwnClick && this.props.columnHasOwnClick.find((i) => i === index) !== undefined;
                const tdKey = column.key || index;
                if (typeof (column) === "string") {
                    return (
                        <TD
                            key={tdKey}
                            content={column}
                            ownClickEvent={ownClickEvent} />
                    );
                } else if (column.heading || this.props.rowHeading) {
                    return (
                        <TH
                            key={tdKey}
                            className={column.className}
                            content={column.content}
                            colSpan={column.colSpan}
                            rowSpan={column.rowSpan}
                            border={column.border || this.props.border} />
                    );
                } else {
                    return (
                        <TD
                            key={tdKey}
                            className={column.className}
                            content={column.content}
                            colSpan={column.colSpan}
                            ownClickEvent={ownClickEvent}
                            border={column.border || this.props.border} />
                    );
                }
            });
        }

        if (!columns) {
            return null;
        }

        return (
            <tr
                // key={this.props.key}
                ref={(ref) => this.ref = ref}
                style={{ cursor: this.props.onRowClick ? "pointer" : "inherit" }}
                onClick={this.onRowClick}
                className={this.props.className}>
                {columns}
            </tr>
        );
    }
}

class TableBody extends React.Component<PropsWithChildren<TableBodyProps>> {

    constructor(props) {
        super(props);
        this.onRowClick = this.onRowClick.bind(this);
    }

    onRowClick(e) {

        if (!this.props.onRowClick) {
            return;
        }

        let cellNode = e.target;
        const elType = e.target["tagName"];
        if (elType !== "TD") {
            while (cellNode["tagName"] !== "TD") {
                cellNode = cellNode["parentNode"];
            }
        }

        const selfClick = cellNode["attributes"]["own-click"] && cellNode["attributes"]["own-click"].value === "true";
        if (selfClick) {
            e.stopPropagation();
            return;
        } else {
            const trNode = cellNode["parentNode"];
            const rowIndex = trNode["attributes"]["row-index"] ? trNode["attributes"]["row-index"].value : undefined;
            this.props.onRowClick(rowIndex ? Number(rowIndex) : null);
        }
    }

    render() {

        const isDynamicBody = Array.isArray(this.props.rows);
        const heading = this.props.className === "tbody-dynamic" ? { height: this.props.heightBody } : {};

        if (isDynamicBody) {
            const TableRows = this.props.rows.map((row, index) => {
                const columns = row.columns || row;
                if (Array.isArray(columns)) {
                    // const emptyCellCount = (this.props.headerColCount || 0) - columns.length;
                    // if (emptyCellCount > 0) {
                    //     columns.push({
                    //         content: "",
                    //         className: "",
                    //         colSpan: emptyCellCount,
                    //         rowHeading: true
                    //     });
                    // }
                }

                if (Array.isArray(columns)) {
                    return (
                        <TableRow
                            key={`${this.props.tableId}-row-table-${index}`}
                            columns={columns}
                            className={this.props.rowClassName}
                            onRowClick={this.props.onRowClick ? this.onRowClick : undefined}
                            columnHasOwnClick={this.props.columnHasOwnClick}
                            rowIndex={index}
                            border={this.props.border} />
                    );
                } else if (typeof (columns) === "object") {
                    // return <TableRow key={keyRow} className={this.props.rowClassName}>{columns}</TableRow>
                    return columns;
                } else {
                    return null;
                }
            });
            return (
                <tbody style={heading} className={this.props.className}>{TableRows}</tbody>
            );
        } else {
            return (
                <tbody style={heading} className={this.props.className}>{this.props.children}</tbody>
            );
        }
    }
}

class TableFoot extends React.Component<PropsWithChildren<TableFootProps>> {
    render() {
        return (
            <tfoot className={this.props.className}>

                {/* Show Add Button Element If addButton is Element and addButtonAfterFooter=false */}
                {this.props.addButton && !this.props.addButtonAfterFooter ?
                    <>
                        <tr>
                            <td colSpan={this.props.lineColSpan}>
                                {this.props.addButton}
                            </td>
                        </tr>
                        {
                            this.props.hasFooter ? (
                                <tr>
                                    <td colSpan={this.props.lineColSpan} className="p--0 none-border">
                                        <hr className="line--top" />
                                    </td>
                                </tr>
                            ) : null
                        }
                    </>
                    : null
                }

                {/* Show Line If addButton is not Element and addButtonAfterFooter=true */}
                {(!this.props.addButton || this.props.addButtonAfterFooter) && (this.props.footerLineTop === undefined ? true : this.props.footerLineTop) ?
                    <tr>
                        <td colSpan={this.props.lineColSpan} className="p--0 none-border">
                            <hr className="line--top" />
                        </td>
                    </tr>
                    : null
                }

                {
                    this.props.children
                }

                {/* Show Add Button Element If addButton is Element and addButtonAfterFooter=true */}
                {this.props.addButton && this.props.addButtonAfterFooter ?
                    <tr>
                        <td colSpan={this.props.lineColSpan}>
                            {this.props.addButton}
                        </td>
                    </tr>
                    : null
                }
            </tfoot>);
    }
}

class MonyTable extends React.Component<PropsWithChildren<DynamicTableProps>> {

    static defaultProps: DynamicTableProps = {
        id: "",
        tableHover: true
    };

    constructor(props) {
        super(props);
        this.generateColHeaders = this.generateColHeaders.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
    }

    onRowClick(e) {
        if (this.props.onRowClick) {
            this.props.onRowClick(e);
        }
    }

    generateRowHeader(header: Array<ITableColHeaderProps>, border: boolean = false): Array<TableRowProps> {
        return header.map((col) => {
            let content: TableRowProps = {
                content: col.classLabel ? <TableColHeader key={col.key} isRequired={col.isRequired} content={col.content} className={col.classLabel} /> : col.content,
                className: col.className
            };

            content.border = border;

            if (col.options) {
                content = {
                    ...content,
                    colSpan: col.options.colSpan,
                    rowSpan: col.options.rowSpan,
                    heading: col.options.heading
                }
            }
            return content;
        });
    }

    /**
     * headerWithRowSpan untuk flagging head lebih dari 1 row dan dianggap ada rowspan
     */
    generateColHeaders(headers: TypeHeaders, headerWithRowSpan: boolean = false): TypeRows {
        let headerContent: TypeRows = null;
        if (!headerWithRowSpan) {
            headerContent = [this.generateRowHeader(headers as Array<ITableColHeaderProps>)];
        } else if (headerWithRowSpan) {
            headerContent = (headers as Array<Array<ITableColHeaderProps>>).map((header: Array<ITableColHeaderProps>) => this.generateRowHeader(header, headerWithRowSpan));
        }
        return headerContent;
    }

    render() {
        const headerColCount = Array.isArray(this.props.headers) ? this.props.headers.length : 0;
        const isDynamicBody = Array.isArray(this.props.rows);
        const bodyRowsCount = Array.isArray(this.props.rows) ? this.props.rows.length : 0;

        let rows = this.props.children || this.props.rows;
        let rowClassName = this.props.rowClassName;
        let bodyClassname = this.props.bodyClassname;

        if (isDynamicBody && bodyRowsCount <= 0) {
            if (this.props.emptyRowClassName) {
                rowClassName = this.props.emptyRowClassName;
            }
            bodyClassname = "";
            if (typeof (this.props.emptyRow) === "string" && this.props.ajax) {
                rows = [{ columns: [{ colSpan: headerColCount, content: this.props.emptyRow, className: "text-center" }] }];
            }
        }

        const headerWithRowSpan = Array.isArray(this.props.headers[0]);
        let header = null;
        if (Array.isArray(this.props.headers) && headerColCount > 0) {
            header = (
                <TableHead className={"dynamic-header-table " + this.props.headClassname}>
                    {
                        this.generateColHeaders(this.props.headers, headerWithRowSpan).map((rowHeader, index) => <TableRow key={`${this.props.id}-header-table-${index}`} columns={rowHeader} rowHeading={true} />)
                    }
                    {/* <TableRow key={`${this.props.id}-header-table`} columns={this.generateColHeaders(this.props.headers)} rowHeading={true} /> */}
                </TableHead>
            );
        } else {
            header = this.props.headers;
        }

        let body = null;
        if (this.props.ajax) { /* && Array.isArray(rows) && rows.length === 0 */
            body = (
                <tbody style={this.props.bodyClassname === "tbody-dynamic" ? { height: this.props.heightBody } : {}} className={this.props.bodyClassname}>
                    <tr>
                        <td colSpan={headerColCount}>
                            <Loader paddingTop={"50px"} paddingBottom={"50px"} className="wd---100" color="#419aff" size={16} showText={false} />
                        </td>
                    </tr>
                </tbody>
            );
        } else if (Array.isArray(rows) && isDynamicBody) {
            body = (
                <TableBody
                    tableId={this.props.id}
                    headerColCount={headerColCount}
                    rows={rows}
                    rowClassName={rowClassName}
                    className={bodyClassname}
                    heightBody={this.props.heightBody}
                    onRowClick={this.props.onRowClick}
                    columnHasOwnClick={this.props.columnHasOwnClick}
                    border={headerWithRowSpan} />
            );
        } else {
            body = (
                <TableBody
                    tableId={this.props.id}
                    headerColCount={headerColCount}
                    rowClassName={rowClassName}
                    className={bodyClassname}
                    heightBody={this.props.heightBody}>
                    {
                        this.props.children
                    }
                </TableBody>
            );
        }

        let footer = null;
        if (this.props.footers || this.props.addButton) {
            const hasFooter = this.props.footers && Array.isArray(this.props.footers) && this.props.footers.length > 0;
            const manyFooter = hasFooter ? Array.isArray(this.props.footers[0]) : false;
            let contentFooter = null;
            if (hasFooter && manyFooter) {
                contentFooter = (this.props.footers as TypeRows).map((rowFooter) => {
                    return (
                        <TableRow
                            key={Common.guid()}
                            columns={rowFooter as Array<TableRowProps>}
                            rowHeading={false}
                            columnHasOwnClick={this.props.columnHasOwnClick} />
                    );
                });
            } else if (hasFooter && !manyFooter) {
                contentFooter = (
                    <TableRow
                        key={Common.guid()}
                        columns={this.props.footers as Array<TableRowProps>}
                        rowHeading={false}
                        columnHasOwnClick={this.props.columnHasOwnClick}
                    />
                );
            }
            footer = (
                <TableFoot
                    addButton={this.props.addButton}
                    addButtonAfterFooter={this.props.addButtonAfterFooter}
                    lineColSpan={headerColCount}
                    hasFooter={hasFooter}
                    footerLineTop={this.props.footerLineTop}>
                    {contentFooter}
                </TableFoot>
            );
        }

        return (
            <>
                {
                    this.props.title ? <FormTitle className={this.props.titleClassName} title={this.props.title} /> : null
                }
                <div className={this.props.classDivTable}>
                    <Table
                        id={this.props.id}
                        className={"table content--table " + (this.props.tableHover ? "table-hover " : "") + this.props.className}
                        style={this.props.style}>
                        {header}
                        {body}
                        {footer}
                    </Table>
                </div>
            </>
        );
    }
}

const TableColHeader: React.FC<ITableColHeaderProps> = (props) => {

    let colHeaderContent = null;

    if (typeof props.content === "string") {
        colHeaderContent = (
            <label className={props.className}>
                {props.content}
                {props.isRequired ? <span className={"required"}> *</span> : null}
            </label>
        );
    } else if (typeof props.content === "object") {
        colHeaderContent = props.content;
    }

    return colHeaderContent;
}

export { Table, TableHead, TableBody, TableRow, TH, TD, MonyTable, TableColHeader };