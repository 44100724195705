export const TYPEREQUEST_SALES_DETAIL = {
    UPDATE_FIELD: "SALES_DETAIL_UPDATE_FIELD",
    UPDATE_BULK_FIELD: "SALES_DETAIL_UPDATE_BULK_FIELD",
    RESET: "SALES_DETAIL_RESET",
    SET_ERROR: "SALES_DETAIL_SET_ERROR"
}

export const TYPEREQUEST_TAGGING = {
    SET: "TAGGING_SET"
}

export const TYPEREQUEST_CONTACT = {
    SET_LIST: "CONTACT_SET_LIST"
}

export const TYPEREQUEST_BILLING = {
    ADD: "BILLING_ADD",
    UPDATE_FIELD_ROW: "BILLING_UPDATE_FIELD_ROW",
    UPDATE_BULK_FIELD_ROW: "BILLING_UPDATE_BULK_FIELD_ROW",
    UPDATE_ROW: "BILLING_UPDATE_ROW",
    DELETE: "BILLING_DELETE"
}

export const TYPEREQUEST_DISBURSEMENT = {
    ADD_FIELD_MAP: "DISBURSEMENT_ADD_FIELD_MAP",
    UPDATE_FIELD_MAP: "DISBURSEMENT_UPDATE_FIELD_MAP",
    DELETE_FIELD_MAP: "DISBURSEMENT_DELETE_FIELD_MAP",
    ADD_ROW_DATA: "DISBURSEMENT_ADD_ROW_DATA",
    DELETE_ROW_DATA: "DISBURSEMENT_DELETE_ROW_DATA",
    UPDATE_FIELD_ROW_DATA: "DISBURSEMENT_UPDATE_FIELD_ROW_DATA",
}
