import React from "react";
import { TableRowSpaceProps } from "../interfaces";

const TableRowSpace: React.FC<TableRowSpaceProps> = (props) => {

  props = { ...defaultProps(props), ...props };

  return (
    <tr>
      <td colSpan={props.colSpan}>
        <hr className={`${props.line ? "line--top" : ""} ${props.className}`} />
      </td>
    </tr>
  );

}

const defaultProps = (props: TableRowSpaceProps) => {
  return {
    line: props.line || true,
    className: props.className || "mt--20 mb--20"
  };
}

export default TableRowSpace;