import React from "react";
import * as H from "history";
// import { keys } from "ts-transformer-keys";

import {
    IModel,
    IFilter,
    IErrorModel,
    IPagingModel,
    TypeApiFetchAll,
    IGeneralFetchAllState,
    TypeQueryStringObjectMapper
} from "../type-interface";

import {
    GeneralFetchAllReducer,
    TypeGeneralFetchAllReducerActions
} from "../reducer/GeneralFetchAllReducer";

import { queryParamsToObject } from "../helper";

type TypeFetchReducer<T extends IModel, F extends IFilter> = {
    state: IGeneralFetchAllState<T, F>;
    actions: {
        LoadInit: (queryString: string) => Promise<void>;
        ReloadData: () => void;
        SetError: (message: Array<IErrorModel>) => void;
        ChangeBulkFieldPaging: (paging: any) => void;
        ChangeFieldPaging: (key: string, value: any) => void;
        ChangeFieldFilter: (key: string, value: any) => void;
        ChangeBulkFieldFilter: (filter: F) => void;
        ChangeFieldFilterTemp: (key: string, value: any) => void;
        ChangeBulkFieldFilterTemp: (filter: F) => void;
        QueryStringToFilter: (queryString: string) => F;
        FilterToQueryString: (filter?: F) => string;
        GetQueryString: () => string;
    }
}

const defaultPaging: IPagingModel = {
    row: 0,
    page: 1,
    perpage: 10,
    current: 0,
    totalRow: 0,
    totalPage: 0
}

interface GeneralFetchAllStateProps<T, F> {
    history?: H.History<any>;
    apiFetch: TypeApiFetchAll;
    initData?: Array<T>;
    initFilter?: F;
    queryStringMapper?: TypeQueryStringObjectMapper;
    reloadAfterChangedFilter?: boolean;
}

// const genDefaultFilter = <F extends IFilter>(filter: F): F => {
//     let newFilter = {};
//     const keysOfProps = keys<IPagingModel>();
//     keysOfProps.forEach((key) => {
//         const keyType = typeof filter[key];
//         const keyDefaultVal = !filter[key] || keyType === "string" ? "" : keyType === "number" ? 0 : null;
//         newFilter = filter[key] || keyDefaultVal;
//     });
//     return newFilter as F;
// }

export const useGeneralFetchAllState = <T extends IModel, F extends IFilter>(props: GeneralFetchAllStateProps<T, F>): TypeFetchReducer<T, F> => {

    const initState = React.useRef<IGeneralFetchAllState<T, F>>({
        isLoading: false,
        data: props.initData || [],
        error: [],
        paging: defaultPaging,
        filter: props.initFilter, // genDefaultFilter<F>(props.initFilter)
    });

    const queryStringRef = React.useRef("");
    const changedFilterRef = React.useRef(false);
    const [state, setState] = React.useState<IGeneralFetchAllState<T, F>>(initState.current);
    const filterTemp = React.useRef({});
    const reducer = GeneralFetchAllReducer<IGeneralFetchAllState<T, F>, T, F>(props.initData, props.initFilter);

    const dispatch = (action: TypeGeneralFetchAllReducerActions<T, F>) => {
        const nextState = reducer(state, action);
        setState(nextState);
    }

    React.useEffect(() => {
        const queryString = FilterToQueryString({
            ...state.filter,
            ...{ page: state.paging.current || 1, perpage: state.paging.perpage || 10 },
            ...filterTemp.current
        });
        filterTemp.current = {};
        if (changedFilterRef.current && props.reloadAfterChangedFilter === false) {
            changedFilterRef.current = false;
            queryStringRef.current = queryString;
        } else {
            if (changedFilterRef.current && queryStringRef.current !== queryString) {
                changedFilterRef.current = false;
                LoadData(queryString);
            }
        }
    }, [state.filter, state.paging]);

    const FilterToQueryString = React.useCallback((filter?: any): string => {
        if (filter !== null) {
            const toConvert = { ...state.filter, ...{ page: state.paging.page || 1, perpage: state.paging.perpage || 10 }, ...filterTemp.current, ...filter };
            filterTemp.current = {};
            if (props.queryStringMapper) {
                const queryStringObject: Object = {};
                Object.keys(toConvert).forEach((keyFrom) => {
                    const keyType = typeof toConvert[keyFrom];
                    const mapper = props.queryStringMapper.find((m) => m.from === keyFrom);
                    const keyDefaultVal = keyType === "string" ? "" : keyType === "number" ? 0 : null;
                    if (mapper) {
                        queryStringObject[mapper.to] = toConvert[mapper.from] || mapper.default || keyDefaultVal;
                    } else {
                        const mapperTo = props.queryStringMapper.find((m) => m.to === keyFrom);
                        queryStringObject[keyFrom] = toConvert[keyFrom] || (mapperTo ? mapperTo.default : undefined) || keyDefaultVal;
                    }
                });
                return Object({ ...queryStringObject }).queryStringBuilder;
            }
            // else if (filter) {
            return Object({ ...toConvert }).queryStringBuilder;
            // }
        } else {
            return "";
        }
    }, [state, filterTemp]);

    const QueryStringToFilter = React.useCallback((queryString: string): F => {

        let newFilter = {};
        const paramsObject = queryParamsToObject(queryString);
        if (queryString && props.queryStringMapper) {
            const queryStringObject: Object = {};
            Object.keys(paramsObject).forEach((keyTo) => {
                const mapper = props.queryStringMapper.find((m) => m.to === keyTo);
                if (mapper) {
                    const value = paramsObject[mapper.to] || mapper.default;
                    queryStringObject[mapper.from] = mapper.default !== undefined ? (typeof mapper.default === "number" ? Number(value) : value) : value;
                } else {
                    queryStringObject[keyTo] = paramsObject[keyTo];
                }
            });
            return queryStringObject as F;
        } else if (queryString) {
            newFilter = paramsObject;
        } else {
            newFilter = { ...state.filter, ...{ current: state.paging.page || 1, perpage: state.paging.perpage | 10 } };
        }

        return newFilter as F;
    }, [state]);

    const onLoading = React.useCallback(() => {
        dispatch({
            type: "FETCH_PENDING"
        });
    }, [state]);

    const onError = React.useCallback((message: Array<IErrorModel>) => {
        dispatch({
            type: "FETCH_ERROR",
            error: message
        });
    }, [state]);

    const onSuccess = React.useCallback((data: any) => {

        let model = data;
        if (data.hasOwnProperty("data")) {
            model = {
                data: data.data,
                paging: {
                    ...state.paging,
                    ...{
                        current: data.page,
                        page: data.page,
                        perpage: data.perpage,
                        row: data.row,
                        totalPage: data.totalPage || data.total_page,
                        totalRow: data.totalRow || data.total_row
                    }
                },
                filter: data.filter
            }
        }

        dispatch({
            type: "FETCH_SUCCESS",
            payload: model
        });

    }, [state]);

    const ReloadData = React.useCallback(() => {
        const currentFilter = FilterToQueryString();
        LoadData(currentFilter);
    }, [state]);

    const LoadInit = React.useCallback(async (queryString?: string) => {
        let filter = null;
        if (queryString.split("&")[0] !== "") {
            filter = QueryStringToFilter(queryString);
        }
        LoadData(FilterToQueryString(filter))
    }, []);

    const LoadData = React.useCallback(async (queryString?: string) => {

        queryStringRef.current = queryString;

        onLoading();
        const response = await props.apiFetch(queryString);
        if (response.success) {
            const filter = QueryStringToFilter(queryString);
            const payload = { ...response.data, filter: filter };
            onSuccess(payload);
            if (props.history && queryString.replace("?", "") !== "" && queryString !== location.search) {
                props.history.push(queryString);
            }
        } else {
            onError(response.messages)
        }

    }, [state]);

    const ChangeFieldFilter = React.useCallback((key: string, value: any) => {
        changedFilterRef.current = true;
        dispatch({
            type: "CHANGE_FIELD_FILTER",
            payload: { key, value }
        });
    }, [state.filter]);

    const ChangeBulkFieldFilter = React.useCallback((value: object) => {
        changedFilterRef.current = true;
        dispatch({
            type: "CHANGE_BULK_FIELD_FILTER",
            payload: value
        });
    }, [state.filter]);

    const ChangeFieldFilterTemp = React.useCallback((key: string, value: any) => {
        filterTemp.current = { ...filterTemp.current, [key]: value };
    }, [filterTemp]);

    const ChangeBulkFieldFilterTemp = React.useCallback((value: object) => {
        filterTemp.current = { ...filterTemp.current, value };
    }, [filterTemp.current]);

    const ChangeFieldPaging = React.useCallback((key: string, value: any) => {
        changedFilterRef.current = true;
        dispatch({
            type: "CHANGE_FIELD_PAGING",
            payload: { key, value }
        });
    }, [state.paging]);

    const ChangeBulkFieldPaging = React.useCallback((value: object) => {
        changedFilterRef.current = true;
        dispatch({
            type: "CHANGE_BULK_FIELD_PAGING",
            payload: value
        });
    }, [state.paging]);

    const SetError = React.useCallback((message: Array<IErrorModel>) => {
        dispatch({
            type: "SET_ERROR",
            error: message
        });
    }, [state]);

    const GetQueryString = React.useCallback(() => {
        // return FilterToQueryString(state.filter);
        return queryStringRef.current;
    }, [state]);

    return {
        state,
        actions: {
            LoadInit,
            SetError, 
            ReloadData,
            ChangeFieldPaging,
            QueryStringToFilter,
            FilterToQueryString,
            ChangeBulkFieldPaging,
            ChangeFieldFilter,
            ChangeBulkFieldFilter,
            ChangeFieldFilterTemp,
            ChangeBulkFieldFilterTemp,
            GetQueryString
        }
    };

}
