import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Icons, MonyFooter, MonyButton, Common } from "mony-component";
import { IPageProps } from "../../../libs/type-interface";

// const Logo = require("../../../assets/images/logomark.svg");
// const Logo2 = require("../../../assets/images/illustration.svg");
const Logo = "logomark.svg";
const Logo2 = "illustration.svg";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const ForgotSuccessPage: React.FC<IPageProps> = (props) => {

    const query = useQuery();

    React.useEffect(() => Common.setTitle(props.pageTitle), []);

    return (
        <div className="login--container">
            <div className="main-content-login ">
                <div className="activate--container content-login">
                    <div className="pt--10 text-left pl--15">
                        <Icons glyph={Logo} className="icon-logo-activate" />
                    </div>
                    <div className="pt--40 pl--30 pr--30 text-center pb--40">
                        <Icons glyph={Logo2} className="icon-logo-activate-content" />
                        <label className=" lbl-title-activate pt--15">{props.translate('sent_email_address', 'label')}</label><br />
                        <label style={{ padding: 10, color: "#0e77ca" }}>
                            {query.get("email")}
                        </label>
                        <hr className="line-activate" />
                        <p className="lbl-title-content pt--15">
                            {props.translate('email_instructions_correct', 'information')}
                        </p>
                        <br />
                        <Link to="/login">{props.translate('back_to_login', 'label')}</Link>
                        <br />
                        <label className="lbl-title-content pt--20 font-weight-normal pb--10">{props.translate('still_can’t_find_email', 'label')}?</label><br />
                        <MonyButton className="wd--250 btn-contac-us" value={props.translate('contact_us', 'button')} onClick={() => {
                            window.location.href = 'mailto:support@harmony.co.id,?subject=Sign up Success&body=';
                        }} />
                    </div>
                </div>

                <MonyFooter className="footer-activate" />
            </div>
        </div>
    );
}