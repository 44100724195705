import React from "react";
import Menu from "antd/es/menu";
import { MoreOutlined, CaretDownOutlined } from "@ant-design/icons"
import { GetMonyColor } from "../helper";
// import Icons from "./Icons";

export interface IPopoverDataMenu {
    key: string;
    label: string;
    classMenu?: string;
    classLabel?: string;
    disabled?: boolean;
}

export interface IPopoverButtonProps {
    key?: string;
    show: boolean;
    placement?: "left" | "right";
    trigger: string;
    title: string;
    classDiv?: string;
    classMenu?: string;
    data?: IPopoverDataMenu[];
    content?: React.ReactNode;
    rowIndex?: number;
    fontSize?: number;
    iconType?: "v-dot3" | "h-dot3" | "caret-down";
    onSelected?: (key: string, rowIndex?: number) => void;
}

// const Logo = require("../assets/images/more-ico-24.svg");
const IconStyle = { color: GetMonyColor("oslo-gray"), verticalAlign: "middle" };

class PopoverButton extends React.Component<IPopoverButtonProps> {

    static defaultProps: IPopoverButtonProps = {
        show: true,
        placement: "left",
        trigger: "click",
        title: "",
        classDiv: "", //popover-cashbank
        classMenu: "",
        iconType: "v-dot3",
        fontSize: 14
    };

    state = {
        show: false
    }

    constructor(props: Readonly<IPopoverButtonProps>) {
        super(props);
        this.showMenuItems = this.showMenuItems.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.renderIcon = this.renderIcon.bind(this);
    }

    onSelected(e: any) {
        if (this.props.onSelected) {
            const key = e.target.getAttribute("data-key");
            this.props.onSelected(key, this.props.rowIndex);
        }
    }

    showMenuItems(_element: EventTarget, show: boolean) {
        this.setState({
            show
        });
    }

    renderIcon() {
        if (this.props.iconType === "caret-down") {
            return <CaretDownOutlined style={{ ...IconStyle, fontSize: this.props.fontSize }} />
        } else {
            return <MoreOutlined style={{ ...IconStyle, fontSize: 25 }} rotate={this.props.iconType === "h-dot3" ? 90 : 0} />
        }
    }

    render() {
        return (
            <div className={"pointer " + this.props.classDiv}
                key={this.props.key}
                onClick={(e) => this.showMenuItems(e.target, !this.state.show)}
                onMouseLeave={(e) => this.showMenuItems(e.target, false)}>
                {/* <Icons glyph={Logo} className="icon-dropdown2" /> */}
                {
                    this.renderIcon()
                }
                <div className={this.state.show ? "popover-relative" : "hidden"}>
                    {
                        this.props.data && Array.isArray(this.props.data) ?
                            <Menu className={this.props.classMenu || "popover-absolute"} mode="vertical">
                                {
                                    this.props.data.map((menu) => <Menu.Item key={menu.key} className={`text-left ${menu.classMenu || ""}`} disabled={menu.disabled || false}><a data-key={menu.key} onClick={this.onSelected} className={`font-14 ${menu.classLabel || ""}`}>{menu.label}</a></Menu.Item>)
                                }
                            </Menu> : this.props.content || null
                    }
                </div>
            </div>
        );
    }
}

export default PopoverButton;
