import React from "react";
import Radio from "antd/es/radio";
import { StateProps } from "../interfaces/StateProps";

interface IMonyRadioButtonProps extends StateProps {
    id?: string;
    key?: string;
    value: boolean;
    displayType?: "vertical" | "horizontal",
    className?: string;
    onClick?: (val: boolean, stateRowIndex?: number) => void;
}

const MonyRadioButton: React.FC<IMonyRadioButtonProps> = (props) => {

    props = { ...defaultProps, ...props };

    const [value, setValue] = React.useState<boolean>(props.value);

    React.useEffect(() => {
        if (props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);

    const onClick = React.useCallback((e) => {
        let val = e;
        if (val.hasOwnProperty("target")) {
            e.preventDefault();
        }

        let newVal = !value;
        if (props.onStateChanged) {
            props.onStateChanged(props.stateKey, newVal, props.stateRowIndex);
        } else if (props.onClick) {
            props.onClick(newVal, props.stateRowIndex);
        }
    }, [value, props]);

    return (
        <Radio className={props.className} id={props.id} key={props.key} style={{ height: 30 }} checked={value} onClick={onClick} />
    );
}

const defaultProps: IMonyRadioButtonProps = {
    key: "",
    value: false,
    displayType: "vertical",
    className: "",
    onClick: () => { }
}

export default React.memo(MonyRadioButton);