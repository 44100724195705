import React from "react";
import ReactDOM from "react-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { MonyInput } from "mony-component";
import { elementFinder } from "../../dom-finder";

interface HelpGuideItem {
    title: string;
    pathModule?: Array<string>;
    subItems: Array<{ title: string; href: string }>;
}

interface IHelpGuideProps {
    display?: boolean;
};

export const HelpGuide: React.FC<IHelpGuideProps> = (props) => {

    const [display, setDisplay] = React.useState<boolean>(props.display);
    const [searchVal, setSearchVal] = React.useState("");
    const [data, setData] = React.useState<Array<HelpGuideItem>>(HelpGuideItems);

    React.useEffect(() => {

        if (!window.MonyHelper) {
            window.MonyHelper = {};
        }

        window.MonyHelper.HelpGuide = {
            show: () => {
                if (!document.getElementById("mony-helpguide").innerHTML) {
                    RenderHelpGuide(true);
                } else {
                    setDisplay(true);
                }
            },
            hide: () => setDisplay(false)
        };

    }, []);

    React.useEffect(() => {

        (() => {
            const pathModule = location.pathname.split("/").filter((o) => o !== "")[0];
            const filterData = HelpGuideItems.filter((o) => (o.pathModule ?? []).includes(pathModule) || pathModule === "dashboard")
                .concat(
                    pathModule === "dashboard" ? [] : HelpGuideItems.filter((o) => (o.pathModule ?? []).length === 0)
                )
                .filter((o) =>
                    (o.title.toLowerCase().includes(searchVal.toLowerCase()) || searchVal === "") ||
                    o.subItems.filter((sub) => sub.title.toLowerCase().includes(searchVal.toLowerCase()) || searchVal === "").length > 0
                )
                // .map((o) => {
                //     o.subItems = o.subItems.filter((sub) => sub.title.toLowerCase().includes(searchVal.toLowerCase()) || searchVal === "");
                //     return o;
                // })
                .filter((o) => o.subItems.length > 0);
            setData(filterData);
        })();

        return () => {
        };

    }, [searchVal, location.pathname]);

    return (
        // <div style={{ display: display ? "flex" : "none", flexDirection: "column", alignItems: "center" }}>
        //     <div className="boarding-overlay" style={{ inset: 0, position: "fixed", cursor: "pointer", background: "rgba(33, 33, 33, 0.8)" }} onClick={() => {
        //         setDisplay(false);
        //         setSearchVal("");
        //     }}></div>
        //     <div
        //         className="boarding-tooltipReferenceLayer"
        //         style={{ minWidth: 370, width: "70%", top: 18 }}>
        //         <div style={{ visibility: "visible" }}>
        //             <MonyInput placeholder={translate('find_guide', 'label') as string} divClassName="h-60 dis-flex wd---100" style={{ fontSize: 24, height: "100%", width: "100%", flex: 1 }} value={searchVal} onChange={(val) => setSearchVal(val)} />
        //             <div className="help-guide-list" style={{ marginTop: 12, height: "100%", width: "100%", background: "#FFFFFF", fontSize: 18, borderRadius: 3 }}>
        //                 {
        //                     HelpGuideItems.filter((o) => o.title.toLowerCase().includes(searchVal.toLowerCase()) || (o.subItems.filter((sub) => sub.title.toLowerCase().includes(searchVal.toLowerCase())).length > 0))
        //                         .map((o, i) => {
        //                             return (
        //                                 <div id={`guide-${i}`} className="item-list" style={{ padding: 12, display: "flex", flexDirection: "column" }}>
        //                                     <strong style={{ padding: 12, background: "lightgrey", borderRadius: 8 }}>{o.title}</strong>
        //                                     {
        //                                         o.subItems.map((sub, idx) => <a id={`sub-guide-${idx}`} href={sub.href} target="_blank" referrerPolicy="no-referrer" style={{ padding: 12, borderBottom: "1px solid lightgray", marginLeft: 12, marginRight: 12 }}>{sub.title}</a>)
        //                                     }
        //                                 </div>
        //                             );

        //                         })
        //                 }
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <>
            {display && <span className="overlay" onClick={() => {
                setDisplay(false);
                setSearchVal("");
            }}></span>}
            <div className={`notif-dropdown wd--400 ${display ? "active-notif" : ""}`} >
                <div className="nd-head">
                    {/* <span className="title">Help & Guide</span> */}
                    {/* <div className="nd-options">
                        <div className="option-icon">
                            <img src="/assets/images/more-white-ico.svg" className="icon-20x20" />
                        </div>
                    </div> */}
                    <MonyInput placeholder={window.MonyHelper.MicroFE.translate("find_guide", "label")} divClassName="h-36 dis-flex wd---100" style={{ height: "100%", width: "100%", flex: 1 }} value={searchVal} onChange={(val) => setSearchVal(val)} />
                </div>
                <div className="nd-body">
                    <ul>
                        {
                            data.length === 0 ?
                                <div className="empty-box"><span className="text">{window.MonyHelper.MicroFE.translate("search_doesnt_match", "message")}</span></div> :
                                <Scrollbars
                                    autoHide={true}
                                    renderTrackHorizontal={(props) => <div {...props} style={{ display: "none" }} />}
                                // renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style, backgroundColor: "rgb(65, 154, 255)" }} />}
                                // renderView={({ style, ...props }) => (<div {...props} style={{ ...style, backgroundColor: "rgb(65, 154, 255)", overflowX: "hidden" }} />)}
                                >
                                    {
                                        data.map((o, i) => {
                                            return (
                                                <li id={`guide-${i}`} className="item-list no-hover" style={{ padding: 0, paddingBottom: 22, display: "flex", flexDirection: "column", height: "fit-content", cursor: "default" }}>
                                                    <strong style={{ textAlign: "left", padding: 12, letterSpacing: 0.80, width: "100%", color: "#7e8893" /*, borderRadius: 8*/ }}>{o.title.toUpperCase()}</strong>
                                                    {
                                                        o.subItems
                                                        .filter((sub) => sub.title.toLowerCase().includes(searchVal.toLowerCase()) || searchVal === "")
                                                        .map((sub, idx) => <a id={`sub-guide-${idx}`} href={sub.href} target="_blank" referrerPolicy="no-referrer" className="hover-color" style={{ width: "100%", textAlign: "left", padding: "3px 12px 3px 12px", color: "white" }}>
                                                            <span className="text">{sub.title}</span>
                                                        </a>)
                                                    }
                                                </li>
                                            );
                                        })
                                    }
                                </Scrollbars>
                        }
                    </ul>
                </div>
            </div>
        </>
    );
}

export const RenderHelpGuide = (defaultDisplay?: boolean) => {
    elementFinder((elRoot) => {
        if (elRoot) {
            ReactDOM.render(<HelpGuide display={defaultDisplay} />, elRoot);
        }
    }, "id", "mony-helpguide");
}

export const HelpGuideItems: Array<HelpGuideItem> = [
    {
        title: "Bantuan Akun",
        pathModule: ["dashboard"],
        subItems: [
            {
                title: "Mendaftar ke Harmony",
                href: "https://www.harmony.co.id/bantuan/bantuan-akun/mendaftar-ke-harmony"
            },
            {
                title: "Login Ke Harmony",
                href: "https://www.harmony.co.id/bantuan/bantuan-akun/login-harmony-2"
            },
            {
                title: "Mengubah Kata Sandi",
                href: "https://www.harmony.co.id/bantuan/bantuan-akun/mengubah-kata-sandi"
            },
            {
                title: "Mengubah Email Login",
                href: "https://www.harmony.co.id/bantuan/bantuan-akun/mengubah-email-login"
            }
        ]
    },
    {
        title: "Mulai Menggunakan Harmony",
        pathModule: ["dashboard"],
        subItems: [
            {
                title: "Mulai Menggunakan Harmony",
                href: "https://www.harmony.co.id/bantuan/mulai-menggunakan-harmony/mulai-menggunakan-harmony"
            },
            {
                title: "Persiapan Onboarding",
                href: "https://www.harmony.co.id/bantuan/mulai-menggunakan-harmony/persiapan-onboarding"
            }
        ]
    },
    {
        title: "Mengelola Perusahaan",
        pathModule: ["company", "settings", "subscription"],
        subItems: [
            {
                title: "Menambah Perusahaan Baru",
                href: "https://www.harmony.co.id/bantuan/mengelola-perusahaan/menambah-perusahaan"
            },
            {
                title: "Berlangganan dengan Harmony",
                href: "https://www.harmony.co.id/bantuan/mengelola-perusahaan/berlangganan"
            },
            {
                title: "Undang Pengguna Baru",
                href: "https://www.harmony.co.id/bantuan/mengelola-perusahaan/undang-pengguna"
            },
            {
                title: "Pengaturan Pengguna dan Role",
                href: "https://www.harmony.co.id/bantuan/mengelola-perusahaan/pengaturan-role"
            },
            {
                title: "Memindahkan Kepemilikan (Ownership)",
                href: "https://www.harmony.co.id/bantuan/mengelola-perusahaan/pindah-kepemilikan"
            },
            {
                title: "Pengaturan Perusahaan",
                href: "https://www.harmony.co.id/bantuan/mengelola-perusahaan/pengaturan-akun"
            }
        ]
    },
    {
        title: "Kas dan Bank",
        pathModule: ["cash-bank"],
        subItems: [
            {
                title: "Daftar Kas dan Bank",
                href: "https://www.harmony.co.id/bantuan/kas-dan-bank/daftar-kas-dan-bank"
            },
            {
                title: "Akun Kas dan Bank Baru",
                href: "https://www.harmony.co.id/bantuan/kas-dan-bank/akun-kas-dan-bank-baru"
            },
            {
                title: "Ubah, Hapus atau Non-aktifkan Akun Kas & Bank",
                href: "https://www.harmony.co.id/bantuan/kas-dan-bank/ubah-hapus-atau-non-aktifkan-akun-kas-bank"
            },
            {
                title: "Membuat Transaksi Akun",
                href: "https://www.harmony.co.id/bantuan/kas-dan-bank/membuat-transaksi-akun"
            },
            {
                title: "Ubah atau Hapus Transaksi Akun",
                href: "https://www.harmony.co.id/bantuan/kas-dan-bank/ubah-atau-hapus-transaksi-akun"
            },
            {
                title: "Rekening Koran",
                href: "https://www.harmony.co.id/bantuan/kas-dan-bank/rekening-koran"
            },
            {
                title: "Melakukan Rekonsiliasi",
                href: "https://www.harmony.co.id/bantuan/kas-dan-bank/melakukan-rekonsiliasi"
            },
            {
                title: "Cash Coding",
                href: "https://www.harmony.co.id/bantuan/kas-dan-bank/cash-coding"
            },
            {
                title: "Aturan Rekonsiliasi",
                href: "https://www.harmony.co.id/bantuan/kas-dan-bank/aturan-rekonsiliasi"
            }
        ]
    },
    {
        title: "Invoice",
        pathModule: ["invoice", "quote", "cn-sales"],
        subItems: [
            {
                title: "Daftar Invoice",
                href: "https://www.harmony.co.id/bantuan/invoice/daftar-invoice"
            },
            {
                title: "Membuat Invoice Baru",
                href: "https://www.harmony.co.id/bantuan/invoice/membuat-invoice-baru"
            },
            {
                title: "Mencatat Pembayaran atas Invoice",
                href: "https://www.harmony.co.id/bantuan/invoice/mencatat-pembayaran-atas-invoice"
            },
            {
                title: "Mencatat Nota Kredit atas Invoice",
                href: "https://www.harmony.co.id/bantuan/invoice/mencatat-nota-kredit-atas-invoice"
            },
            {
                title: "Membatalkan Pembayaran atau Nota Kredit",
                href: "https://www.harmony.co.id/bantuan/invoice/membatalkan-pembayaran-atau-nota-kredit"
            },
            {
                title: "Ubah atau Batalkan Invoice",
                href: "https://www.harmony.co.id/bantuan/invoice/ubah-atau-batalkan-invoice"
            },
            {
                title: "Impor Invoice",
                href: "https://www.harmony.co.id/bantuan/invoice/impor-invoice"
            },
            {
                title: "Ubah Rincian Pembayaran Invoice",
                href: "https://www.harmony.co.id/bantuan/invoice/ubah-rincian-pembayaran-invoice"
            }
        ]
    },
    {
        title: "Bill",
        pathModule: ["bill", "purchase-order", "cn-purchase"],
        subItems: [
            {
                title: "Daftar Bill",
                href: "https://www.harmony.co.id/bantuan/bill/daftar-bill"
            },
            {
                title: "Membuat Bill Baru",
                href: "https://www.harmony.co.id/bantuan/bill/membuat-bill-baru"
            },
            {
                title: "Mencatat Pembayaran atas Bill",
                href: "https://www.harmony.co.id/bantuan/bill/mencatat-pembayaran-atas-bill"
            },
            {
                title: "Mencatat Nota Kredit atas Bill",
                href: "https://www.harmony.co.id/bantuan/bill/mencatat-nota-kredit-atas-bill"
            },
            {
                title: "Membatalkan Pembayaran atau Nota Kredit",
                href: "https://www.harmony.co.id/bantuan/bill/membatalkan-pembayaran-atau-nota-kredit-2"
            },
            {
                title: "Ubah atau Batalkan Bill",
                href: "https://www.harmony.co.id/bantuan/bill/ubah-atau-batalkan-bill"
            }
        ]
    },
    {
        title: "Persediaan",
        pathModule: ["inventory"],
        subItems: [
            {
                title: "Daftar Persediaan",
                href: "https://www.harmony.co.id/bantuan/persediaan/daftar-persediaan"
            },
            {
                title: "Saldo Awal Persediaan Barang",
                href: "https://www.harmony.co.id/bantuan/panduan-lain/saldo-awal-persediaan-barang"
            },
            {
                title: "Tambah Barang Persediaan",
                href: "https://www.harmony.co.id/bantuan/persediaan/tambah-barang-persediaan"
            },
            {
                title: "Ubah, Hapus atau Non-Aktifkan Barang Persediaan",
                href: "https://www.harmony.co.id/bantuan/persediaan/ubah-hapus-atau-non-aktifkan-barang-persediaan"
            },
            {
                title: "Impor Barang Persediaan",
                href: "https://www.harmony.co.id/bantuan/persediaan/impor-barang-persediaan"
            },
            {
                title: "Penyesuaian Stok Barang",
                href: "https://www.harmony.co.id/bantuan/persediaan/penyesuaian-stok-barang"
            },
            {
                title: "Penyesuaian Multi Stok",
                href: "https://www.harmony.co.id/bantuan/persediaan/penyesuaian-multi-stok"
            },
            {
                title: "Persediaan Barang Kelompok",
                href: "https://www.harmony.co.id/bantuan/persediaan/persediaan-barang-kelompok"
            },
            {
                title: "Barang Manufaktur dan Konversi Stok",
                href: "https://www.harmony.co.id/bantuan/persediaan/barang-manufaktur-dan-konversi-stok"
            }
        ]
    },
    {
        title: "Aset Tetap",
        pathModule: ["asset", "asset-type"],
        subItems: [
            {
                title: "Tentang Aset Tetap",
                href: "https://www.harmony.co.id/bantuan/aset-tetap/tentang-aset-tetap"
            },
            {
                title: "Pengaturan Akun dan Tanggal Mulai Aset",
                href: "https://www.harmony.co.id/bantuan/aset-tetap/pengaturan-akun-dan-tanggal-mulai-aset"
            },
            {
                title: "Membuat Tipe Aset",
                href: "https://www.harmony.co.id/bantuan/aset-tetap/membuat-tipe-aset"
            },
            {
                title: "Tambah Aset Tetap Baru",
                href: "https://www.harmony.co.id/bantuan/aset-tetap/tambah-aset-tetap-baru"
            },
            {
                title: "Penyusutan Aset Tetap",
                href: "https://www.harmony.co.id/bantuan/aset-tetap/penyusutan-aset-tetap"
            }
        ]
    },
    {
        title: "Daftar Akun",
        pathModule: ["coa", "conversion-balance"],
        subItems: [
            {
                title: "Sekilas Tentang Daftar Akun",
                href: "https://www.harmony.co.id/bantuan/daftar-akun/sekilas-tentang-daftar-akun"
            },
            {
                title: "Buat Akun / COA Baru",
                href: "https://www.harmony.co.id/bantuan/daftar-akun/buat-akun-coa-baru"
            },
            {
                title: "Ubah, Hapus atau Non-aktifkan Akun COA",
                href: "https://www.harmony.co.id/bantuan/daftar-akun/ubah-hapus-atau-non-aktifkan-akun-coa"
            },
            {
                title: "Mengisi Saldo Awal – Kas & Bank",
                href: "https://www.harmony.co.id/bantuan/panduan-lain/mengisi-saldo-awal-kas-bank"
            }
        ]
    },
    {
        title: "Kontak",
        pathModule: ["contacts"],
        subItems: [
            {
                title: "Daftar Kontak",
                href: "https://www.harmony.co.id/bantuan/kontak/daftar-kontak"
            },
            {
                title: "Tambah Kontak Baru",
                href: "https://www.harmony.co.id/bantuan/kontak/tambah-kontak-baru"
            },
            {
                title: "Impor Kontak",
                href: "https://www.harmony.co.id/bantuan/kontak/impor-kontak"
            }
        ]
    },
    {
        title: "Jurnal Umum",
        pathModule: ["journal"],
        subItems: [
            {
                title: "Buat Jurnal Umum",
                href: "https://www.harmony.co.id/bantuan/jurnal-umum/buat-jurnal-umum"
            },
            {
                title: "Ubah atau Hapus Jurnal Umum",
                href: "https://www.harmony.co.id/bantuan/jurnal-umum/ubah-atau-hapus-jurnal-umum"
            },
            {
                title: "Jurnal Umum Berulang",
                href: "https://www.harmony.co.id/bantuan/jurnal-umum/jurnal-umum-berulang"
            },
            {
                title: "Ubah atau Hapus Jurnal Berulang",
                href: "https://www.harmony.co.id/bantuan/jurnal-umum/ubah-atau-hapus-jurnal-berulang"
            }
        ]
    },
    {
        title: "Penyelesaian Masalah",
        pathModule: ["invoice"],
        subItems: [
            {
                title: "Barang Tidak Memiliki Stok",
                href: "https://www.harmony.co.id/bantuan/penyelesaian-masalah/barang-tidak-memiliki-stok"
            }
        ]
    },
    {
        title: "Pengaturan",
        pathModule: ["inventory", "settings", "coa"],
        subItems: [
            {
                title: "Saldo Awal",
                href: "https://www.harmony.co.id/bantuan/pengaturan/saldo-awal"
            },
            {
                title: "Daftar Satuan Unit",
                href: "https://www.harmony.co.id/bantuan/pengaturan/daftar-satuan-unit"
            },
            {
                title: "Daftar Pajak",
                href: "https://www.harmony.co.id/bantuan/pengaturan/daftar-pajak"
            }
        ]
    },
    {
        title: "Panduan Lain",
        subItems: [
            {
                title: "Mencatat Uang Muka dari Pembeli",
                href: "https://www.harmony.co.id/bantuan/panduan-lain/mencatat-uang-muka-dari-pembeli"
            },
            {
                title: "Penjualan dengan Barang Gratis",
                href: "https://www.harmony.co.id/bantuan/panduan-lain/penjualan-dengan-barang-gratis"
            },
            {
                title: "Mencatat Kasbon atau Pinjaman Karyawan",
                href: "https://www.harmony.co.id/bantuan/panduan-lain/mencatat-kasbon-atau-pinjaman-karyawan"
            },
            {
                title: "Mencatat Pengenaan Lebih Dari Satu Pajak",
                href: "https://www.harmony.co.id/bantuan/panduan-lain/mencatat-pengenaan-lebih-dari-satu-pajak"
            },
            {
                title: "Mencatat Transaksi Penjualan dengan Pemungut PPN",
                href: "https://www.harmony.co.id/bantuan/panduan-lain/mencatat-transaksi-penjualan-dengan-pemungut-ppn"
            },
            {
                title: "Mencatat Pembelian Aset secara Kredit / Cicilan",
                href: "https://www.harmony.co.id/bantuan/panduan-lain/mencatat-pembelian-aset-secara-kredit-cicilan"
            },
            {
                title: "Melakukan Pembayaran atas Saldo Awal Piutang/Hutang Usaha",
                href: "https://www.harmony.co.id/bantuan/panduan-lain/melakukan-pembayaran-atas-saldo-awal-piutang-hutang-usaha"
            }
        ]
    },
    {
        title: "Sekilas Tentang Harmony",
        pathModule: ["dashboard"],
        subItems: [
            {
                title: "Sekilas Tentang Harmony",
                href: "https://www.harmony.co.id/bantuan/sekilas-tentang-harmony/tentang-harmony"
            }
        ]
    }
];
