import moment from 'moment';
import { Component } from 'react';
import { CommonEnume } from '../enum';
import { TypeTranslate } from "mony-component";

class Validator extends Component {

    constructor(props?) {
        super(props);
        this.state = {}
        this.checkUrlAttachment = this.checkUrlAttachment.bind(this);
    }

    form(data, rule = [], translate?: TypeTranslate): { message: string, path: string }[] {
        let message = "";
        return rule.map((x) => {
            let obj = {
                message: "",
                path: ""
            }
            switch (x.rule) {
                case CommonEnume.Rule.STRING:
                    message = this.string(data[x.filed]).format(x.display);
                    break;
                case CommonEnume.Rule.MATCHES:
                    message = this.matches(data[x.filed], data[x.match], translate).format(x.display, x.matchDisplay);
                    break;
                case CommonEnume.Rule.MAX_LENGTH:
                    message = this.max_length(data[x.filed], x.lengthString).format(x.display);
                    break;
                case CommonEnume.Rule.MIN_LENGTH:
                    message = this.min_length(data[x.filed], x.lengthString).format(x.display);
                    break;
                case CommonEnume.Rule.MIN_CHAR:
                    message = this.min_char(data[x.filed], x.lengthString).format(x.display);
                    break;
                case CommonEnume.Rule.EXACT_LENGTH:
                    message = this.exact_length(data[x.filed], x.lengthString).format(x.display);
                    break;
                case CommonEnume.Rule.PASSWORD:
                    message = this.password(data[x.filed], translate).format(x.display);
                    break;
                case CommonEnume.Rule.NUMERIC:
                    message = this.numeric(data[x.filed]).format(x.display);
                    break;
                case CommonEnume.Rule.GTE_ZERO:
                    message = this.gte_zero(data[x.filed], x.msg).format(x.display);
                    break;
                case CommonEnume.Rule.GT_ZERO:
                    message = this.gt_zero(data[x.filed], x.display).format(x.display);
                    break;
                case CommonEnume.Rule.REQUIRED:
                    message = this.required(data[x.filed], x.msg, translate).format(x.display);
                    break;
                case CommonEnume.Rule.EMAIL:
                    message = this.email(data[x.filed], translate).format(x.display);
                    break;
                case CommonEnume.Rule.DATECOMPARE:
                    message = this.dateCompare(data[x.filed], data[x.dateCompare], x.msg).format(x.display, x.dateDisplay);
                    if (x.reverse) {
                        x.filed = x.dateCompare
                    }
                    break;
                case CommonEnume.Rule.DATE_VALID:
                    message = this.dateValid(data[x.date], data[x.month]).format(x.display);
                    break;
                case CommonEnume.Rule.AGREEMENT:
                    message = this.has_agreed(data[x.filed]).format(x.display);
                    break;
                case CommonEnume.Rule.CUSTOM:
                    message = x.customrule(data[x.filed], x.filed, data)
                    break;
                default:
                    message = "Can't find your rule";
                    break;
            }
            obj.message = message;
            obj.path = x.filed;
            return obj;
        });
    }
    password(data, translate) {
        let message = ""
        // let regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/g
        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
        if (!regex.test(data)) {
            message = `{0} ${translate ? translate("password", "information") : "must contain at least one uppercase letter, one number, one special character, and at min 8 characters"}`
        }
        return message;
    }
    email(data, translate) {
        if (!data) {
            return ""
        }
        let regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        let message = "";
        if (!regex.test(data)) {
            message = `{0} ${translate ? translate("invalid", "label") : "is invalid"}`
        }
        return message
    }
    has_agreed(data) {

        if (data !== true) {
            return "{0}"
        } else {
            return ""
        }
    }

    gte_zero(data, msg) {
        data = data || 0
        if (Number(data) < 0) {
            return msg == undefined ? "{0} cannot be less than zero" : msg
        }

        return ""
    }

    gt_zero(data, msg="") {
        data = data || 0
        if (Number(data) > 0) {
            return ""
        }
        if (!msg) {
            return "Value cannot be zero";
        } else {
            return msg;
        }
    }

    dateValid(date, idx_month) {
        if (!date || idx_month === undefined) {
            return ""
        }
        let message = ""
        let months = CommonEnume.MONTHS
        let month = months[idx_month]

        if (date < 1 || date > month.days) {
            message = "{0} is not a valid date"
        }

        return message
    }

    dateCompare(date1, date2, msg) {
        let message = msg == undefined ? "{0}  bigger than {1}" : msg;
        let datediff = moment(new Date(date1)).diff(new Date(date2), 'days');

        if (datediff <= 0) {
            message = "";
        }
        if (msg == undefined) {
            if (date1.length <= 0 && date2.length <= 0) { message = ""; }
        }
        return message;
    }
    required(data, msg, translate) {
        // language
        let message = msg == undefined ? `{0} ${translate? translate("required", "label"): "is required"}` : msg
        // data = data.toString().trim()
        if (data !== undefined && data !== null && data.toString().length > 0) {
            message = "";
        }
        return message;
    }
    numeric(data) {
        let message = "{0} is not number";
        if (!isNaN(parseInt(data))) {
            message = "";
        }
        return message;
    }
    min_length(data, length) {
        let message = '{0} minimum length ' + length;
        if (data.length >= length) {
            message = "";
        }
        return message;
    }
    min_char(data, length) {
        let message = '{0} has to be minimum ' + length + ' characters';
        if (data.length >= length) {
            message = "";
        }
        return message;
    }
    max_length(data, length) {
        let message = '{0} maximum length' + length;
        if (data.length <= length) {
            message = "";
        }
        return message;
    }
    exact_length(data, length) {
        let message = '{0}';
        if (data.length == length) {
            message = "";
        }
        return message;
    }
    matches(data1, data2, translate) {
        let message = `{0} ${translate ? translate("not_match", "label") : "does not match"} {1}`;
        if (data1 === data2) {
            message = "";
        }
        if (data1.length <= 0 || data2 <= 0) { message = ""; }
        return message
    }

    string(data) {
        let message = '{0} is not number!';
        if (isNaN(parseInt(data))) {
            message = "";
        }
        return message;
    }
    CleanPhone(val) {
        let newvalue = val.replace(/[^0-9]+/g, "")
        if (newvalue.slice(0, 1) == '0') {
            return '+62' + newvalue.slice(1, newvalue.length)
        } else if (newvalue == "") {
            return newvalue
        }
        else {
            return '+' + newvalue
        }
    }

    number(val) {
        let newvalue = val.replace(/[^0-9]+/g, "")
        return newvalue
    }

    checkUrlAttachment(fileName, fileUrl) {
        let re = /(?:\.([^.]+))?$/;
        if (re.exec(fileName)[1] === "txt") { return "/assets/images/notepad.png" }
        else if (re.exec(fileName)[1] === "xls" || re.exec(fileName)[1] === "xlsx") { return "/assets/images/excel.png" }
        else if (re.exec(fileName)[1] === "pdf") { return "/assets/images/pdf.png" }
        else if (re.exec(fileName)[1] === "doc" || re.exec(fileName)[1] === "docx") { return "/assets/images/msWord.png" }
        else { return fileUrl; }
    }

}

const validator = new Validator();

export default validator;
