import React from "react";
import { MonyButtonType } from "mony-component/dist/component/MonyButton";

export interface IModalPdfViewerState {
    title?: string;
    show?: boolean;
    showCancel?: boolean;
    src?:string
};

export const PdfInitial = (defaultState?: IModalPdfViewerState): IModalPdfViewerState => {
    const initState: IModalPdfViewerState = {
        title: "",
        show: true,
        showCancel: false,
        src:""
    };
    if (defaultState) {
        return {
            ...initState,
            ...defaultState
        };
    }
    return initState;
}

export type TypePdfStateActions = {
    Show: (data: IModalPdfViewerState) => void;
    Hide: () => void
}

export const usePdfState = (props: { defaultState?: IModalPdfViewerState } = {}): { state: IModalPdfViewerState, actions: TypePdfStateActions } => {

    const [state, setState] = React.useState<IModalPdfViewerState>({ ...PdfInitial, ...props.defaultState });

    const Show = React.useCallback((data: IModalPdfViewerState) => {
        setState({ ...state, ...data, show: true });
    }, [state]);

    const Hide = React.useCallback(() => {
        setState({ ...state, show: false });
    }, [state]);

    const actions = { Show, Hide };
    return { state, actions };
}