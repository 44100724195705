import { ApiGet, ApiPost } from "../helper";
import { IApiResponse, ITagingModel, ITagingDetailModel } from "../type-interface";
import { ILabelValueModel } from "mony-component";

export const FetchHeaderWithoutPaging = async (keyword: string, taggingModule: string, taggingGroup: string): Promise<ILabelValueModel[]> => {
    const response = await ApiGet(`/accounting/list-tagging?keyword=${keyword || ""}&module=${taggingModule}&group=${taggingGroup}`);
    const tagging: ILabelValueModel[] = [];
    if (response.success) {
        await (response.data as ITagingModel[]).forEach((data: ITagingModel) => {
            tagging.push({ label: data.tagging_title, value: String(data.tagging_id) });
        });
    }
    return tagging;
}

export const FetchAllHeader = async (queryString: string): Promise<IApiResponse> => {
    const response = await ApiGet(`/accounting/list-tagging${queryString}`);
    return response;
}

export const FetchWithoutPaging = async (keyword: string, taggingGroup: string = ""): Promise<ILabelValueModel[]> => {
    const response = await ApiGet(`/accounting/list?keyword=${keyword || ""}&module=comtrack&group=${taggingGroup}`);
    const tagging: ILabelValueModel[] = [];
    if (response.success) {
        await response.data.forEach((data) => {
            tagging.push({ label: data.tagging_display, value: String(data.taggingdet_id) });
        });
    }
    return tagging;
}

export const FetchByID = async (taggingId: number, setErrorCallback?: (messages) => void): Promise<ITagingModel> => {
    const response = await ApiGet(`/accounting/get-tagging/${taggingId}`);
    if (!response.success && setErrorCallback) {
        setErrorCallback(response.messages);
    }
    return response.data as ITagingModel;
}

export const CreateOtg = async (data: ITagingModel): Promise<IApiResponse> => {
    const response = await ApiPost("/accounting/tagging", data);
    return response;
}

export const CreateDetailOtg = async (data: ITagingDetailModel): Promise<IApiResponse> => {
    const response = await ApiPost("/accounting/tagging-detail", data);
    return response;
}
