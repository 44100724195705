import React from "react";
import { ICurrencyPickerProps } from "./";
import { UserProfile, GetAllUserCurrency } from "../api/UserApi";
const moment = require("moment")
// import { useDatasourceState } from "../../libs/custom-state";

export interface ICurrencyPickerState {
    data: Array<any>;
    currSelected: ICurrencyValue;
    isRateVisible: boolean;
    rateText: string;
    isCustomRateBoxVisible: boolean;
    isCustomRate: number;
    currInfo: {baseCurrCode: string, currCode: string, currDateFormatted: string, currSourName: string, originalRate: number},
    customRate: number,
    actions: {
        onSelected: (val: ILabelValueModel) => void;
        onClickRateLink: () => void;
        onChangeRateOption: (val: number) => void;
        hideCustomRateBox: () => void;
        onInputCustomRate: (val: number) => void;
        onSaveCustomRate: () => void;
        onClickCustomRateInput: () => void;
    }
}

interface ILabelValueModel {
    value: number, label: string
}

export interface ICurrencyValue {
    currId: number, currDate: string, currRates: number
}

export const useCurrencyPickerPresenter = (props: ICurrencyPickerProps): ICurrencyPickerState => {

    const [currencyList, setCurrencyList] = React.useState([]);
    const [selectOptions, setSelectOptions] = React.useState([]);
    const [baseCurrId, setBaseCurrId] = React.useState(0);
    const [selectedCurr, setSelectedCurr] = React.useState<ICurrencyValue>({ currId: undefined, currDate: "", currRates: 0 });
    const [isRateVisible, setIsRateVisible] = React.useState(false);
    const [rateText, setRateText] = React.useState("");
    const [isCustomRate, setIsCustomRate] = React.useState(0);
    const [isCustomRateBoxVisible, setIsCustomRateBoxVisible] = React.useState(false);
    const [currInfo, setCurrInfo] = React.useState( {baseCurrCode: "", currCode: "", currDateFormatted: "", currSourName: "", originalRate: 0} );
    const [customRate, setCustomRate] = React.useState(0);
    const [companyName, setCompanyName] = React.useState("");

    // const mounted = React.useRef(false);

    React.useEffect(() => {
        (async () => {
            // if (!mounted.current) {
            //     mounted.current = true;
            //     await onLoadData();
            // }
            // if (!globalThis.window["loaded"]) {
            //     globalThis.window["loaded"] = true;
                await onLoadData();
            // }
        })();

        return () => {
            // mounted.current = false;
        }
    }, []);

    React.useEffect(() => {
        setIsRateVisible(baseCurrId != props.value)
        const selectedCurrency = currencyList.find((row) => { return row.curr_id == props.value });
        if (selectedCurrency) {
            setSelectedCurr({ currId: selectedCurrency.curr_id, currDate: selectedCurrency.comp_currdet_date_from, currRates: selectedCurrency.rate_to_base });
            const baseCurrData = currencyList.find((row) => { return row.curr_id == baseCurrId});
            setRateText("1 " + selectedCurrency.curr_code + " = " + selectedCurrency.rate_to_base + " " + baseCurrData.curr_code + " (" + moment(new Date(selectedCurrency.comp_currdet_date_from)).format("DD MMM YYYY") + " by " + selectedCurrency.currsour_name + ")");
            setCurrInfo({baseCurrCode: baseCurrData.curr_code, currCode: selectedCurrency.curr_code, currSourName: selectedCurrency.currsour_name, currDateFormatted: moment(new Date(selectedCurrency.comp_currdet_date_from)).format("DD MMM YYYY"), originalRate: selectedCurrency.rate_to_base})
            if (props.rate && selectedCurrency.rate_to_base != props.rate) {
                setCustomRate(props.rate);
                setRateText("1 " + selectedCurrency.curr_code + " = " + props.rate + " " + baseCurrData.curr_code + " (" + moment(new Date(props.date)).format("DD MMM YYYY") + " by " + companyName + ")");
                setIsCustomRate(1);
            }
        }
    }, [baseCurrId]);

    const onLoadData = React.useCallback(async () => {

        const UserApiResponse = await UserProfile();
        const UserCurrencyApiResponse = await GetAllUserCurrency();
        if (UserCurrencyApiResponse.success) {
            const selectData = UserCurrencyApiResponse.data.map((item) => ({
                value: item.curr_id,
                label: `${item.curr_code} - ${item.curr_name}`
            }));
            
            setBaseCurrId(UserApiResponse.data.current_company.company_configuration.curr_id);
            setCompanyName(UserApiResponse.data.current_company.company_name);
            setCurrencyList(UserCurrencyApiResponse.data);
            setSelectOptions(selectData);
        }

    }, [baseCurrId, currencyList, selectOptions, companyName])

    const onSelected = React.useCallback((val: ILabelValueModel) => {
        const selectedCurrency = currencyList.find((row) => { return row.curr_id == val.value });
        setIsRateVisible(baseCurrId != val.value);
        if (selectedCurrency) {
            setSelectedCurr({ currId: val.value, currDate: selectedCurrency.comp_currdet_date_from, currRates: selectedCurrency.rate_to_base });
            props.onSelected({ currId: val.value, currDate: selectedCurrency.comp_currdet_date_from, currRates: selectedCurrency.rate_to_base });
            const baseCurrData = currencyList.find((row) => { return row.curr_id == baseCurrId});
            setRateText("1 " + selectedCurrency.curr_code + " = " + selectedCurrency.rate_to_base + " " + baseCurrData.curr_code + " (" + moment(new Date(selectedCurrency.comp_currdet_date_from)).format("DD MMM YYYY") + " by " + selectedCurrency.currsour_name + ")")
            setCurrInfo({baseCurrCode: baseCurrData.curr_code, currCode: selectedCurrency.curr_code, currSourName: selectedCurrency.currsour_name, currDateFormatted: moment(new Date(selectedCurrency.comp_currdet_date_from)).format("DD MMM YYYY"), originalRate:selectedCurrency.rate_to_base})
        }
        setCustomRate(0);
    }, [currencyList, baseCurrId, isRateVisible, rateText, currInfo]);

    const showCustomRateBox = React.useCallback(() => {
        setIsCustomRateBoxVisible(true)
    }, [isCustomRateBoxVisible]);

    const hideCustomRateBox = React.useCallback(() => {
        setIsCustomRateBoxVisible(false)
    }, [isCustomRateBoxVisible]);

    const onInputCustomRate = React.useCallback((val: number) => {
        setCustomRate(val);
    }, [customRate]);

    const onClickCustomRateInput = React.useCallback(() => {
        onChangeRateOption(1);
    }, [isCustomRate]);

    const onSaveCustomRate = React.useCallback(() => {
        if (isCustomRate == 1) {
            setSelectedCurr({ currId: selectedCurr.currId, currDate: moment().format("YYYY-MM-DD"), currRates: customRate });
            props.onSelected({ currId: selectedCurr.currId, currDate: moment().format("YYYY-MM-DD"), currRates: customRate });
            setRateText("1 " + currInfo.currCode + " = " + customRate + " " + currInfo.baseCurrCode + " (" + moment().format("DD MMM YYYY") + " by " + companyName + ")")
        } else {
            const selectedCurrency = currencyList.find((row) => { return row.curr_id == selectedCurr.currId });
            setSelectedCurr({ currId: selectedCurr.currId, currDate: selectedCurrency.comp_currdet_date_from, currRates: selectedCurrency.rate_to_base });
            props.onSelected({ currId: selectedCurr.currId, currDate: selectedCurrency.comp_currdet_date_from, currRates: selectedCurrency.rate_to_base });
            setRateText("1 " + currInfo.currCode + " = " + selectedCurrency.rate_to_base + " " + currInfo.baseCurrCode + " (" + moment(new Date(selectedCurrency.comp_currdet_date_from)).format("DD MMM YYYY") + " by " + selectedCurrency.currsour_name + ")");
            setCustomRate(0);
        }
        setIsCustomRateBoxVisible(false);
    }, [selectedCurr, customRate, isCustomRate, currInfo, currencyList]);

    const onChangeRateOption = React.useCallback((val: number) => {
        setIsCustomRate(val)
    }, [isCustomRate])

    return {
        data: selectOptions,
        currSelected: selectedCurr,
        isRateVisible: isRateVisible,
        rateText: rateText,
        isCustomRateBoxVisible: isCustomRateBoxVisible,
        isCustomRate: isCustomRate,
        currInfo: currInfo,
        customRate: customRate,
        actions: {
            onSelected: onSelected,
            onClickRateLink: showCustomRateBox,
            onChangeRateOption: onChangeRateOption,
            hideCustomRateBox: hideCustomRateBox,
            onInputCustomRate: onInputCustomRate,
            onSaveCustomRate: onSaveCustomRate,
            onClickCustomRateInput: onClickCustomRateInput
        }
    }
}
