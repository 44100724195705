import React from "react";
import { IconsProps } from "../interfaces";
import { Default } from "../const/Default";

const Icons: React.FC<IconsProps> = (props) => {

    props = { ...defaultProps(props), ...props };

    const [state, setState] = React.useState({ disabled: false });

    const enable = () => {
        setState({ disabled: false })
    }

    const onClick = (event) => {
        if (!props.ajax) {
            props.onClick(event)
        } else if (props.ajax && !state.disabled) {
            setState({ disabled: true });
            props.onClick(enable);
        }
    }

    return (
        <div onClick={onClick} className={props.extraClass}>
            {/* <svg aria-hidden={props.hidden} className={props.className} dangerouslySetInnerHTML={{ __html: '<use xlink:href="' + props.glyph + '"></use>' }} /> */}
            {/* <img hidden={props.hidden} className={props.className} src={props.glyph} /> */}
            {/* <img hidden={props.hidden} className={props.className} src={`${Default.BASE_URL}/assets/images/${props.glyph}`} /> */}
            <img hidden={props.hidden} className={props.className} src={props.glyph.indexOf("http") != -1 || props.glyph.indexOf("https") != -1 ? `${props.glyph}` : `${Default.BASE_URL}/assets/images/${props.glyph}`} />
        </div>
    );
}

const defaultProps = (props: IconsProps) => {
    return {
        className: props.className || "",
        glyph: props.glyph || "",
        extraClass: props.extraClass || "",
        ajax: props.ajax || false,
        hidden: props.hidden || false,
        onClick: props.onClick || function () { return true; }
    };
}

export default React.memo(Icons);
