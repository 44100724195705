import React, { PropsWithChildren } from "react";
import { PulseLoader as Loader } from "react-spinners";

export type MonyButtonType = "default" | "secondary" | "submit" | "save" | "update" | "cancel" | "delete" | "approve" | "back" | "row-delete";

export interface MonyButtonProps {
    ref?: React.Ref<HTMLButtonElement>;
    id?: string;
    buttonType?: MonyButtonType;
    htmlFor?: string;
    className?: string;
    hidden?: boolean;
    disabled?: boolean;
    ajax?: boolean;
    value?: string | React.ReactElement;
    // classNameLoadingButton?: string | "button-loading";
    // classNameLoadingIcon?: string | "button-loading-icon";
    border?: boolean;
    style?: any,
    icon?: string;
    classIcon?: string;
    labelBack?: string;
    onClick?: (callback: Function) => void;
};

const MonyButton: React.FC<PropsWithChildren<MonyButtonProps>> = (props) => {

    props = { ...defaultProps, ...props };
    const [busy, setBusy] = React.useState(false);

    const setDefaultProps = () => {
        const className = `default ${props.className}`;
        if (props.buttonType === "cancel") {
            props = { ...props, className: `${className} button-cancel${props.border? "-border" : ""}`};
        } else if (props.buttonType == "back") {
            props = { ...props, className: `${className} button-cancel${props.border? "-border" : ""}` };
        } else if (props.buttonType === "delete") {
            props = { ...props, className: `${className} button--delete` };
        } else if (props.buttonType === "save") {
            props = { ...props, className: `${className} button--save` };
        } else if (props.buttonType === "default") {
            props = { ...props, className: `${className} button--primary` };
        } else if (props.buttonType === "secondary") {
            props = { ...props, className: `${className} button--secondary` };
        } else if (props.buttonType === "row-delete") {
            const deleteImg = (
                <img
                    className={props.disabled ? "icon-18x18-disable" : "icon-24x24"}
                    style={{ "margin": "auto" }}
                    src={props.disabled ? "/assets/images/delete-disable.svg" : "/assets/images/delete_ico_24.svg"} />
            );
            props = { ...props, className: `${className} button--row-delete`, value: deleteImg };
        }
    };
    setDefaultProps();

    const enable = () => {
        if (document.getElementById("col-warning") !== null) {
            if (document.getElementById("main-content") !== null) {
                document.getElementById("main-content").scrollTop = 0;
            } else {
                location.href = "#col-warning";
            }
        }
        setBusy(false);
    }

    const onClick = (event) => {
        if (props.ajax) {
            setBusy(true);
            props.onClick(enable);
        } else {
            props.onClick(event)
        }
    }

    return (
        <button
            ref={props.ref}
            id={props.id}
            className={`${props.className}`}
            style={props.style}
            hidden={props.hidden}
            onClick={onClick}
            disabled={props.disabled || busy}>
            {props.ajax && busy ? <Loader color="#419aff" size="6px" /> : null}
            {props.icon ? !busy ? <img src={props.icon} className={`mr--10 ${props.classIcon}`} /> : null : null}
            {props.ajax && busy ? null : props.children || props.value}
            {props.buttonType === "back" && !props.ajax && !busy
                ?
                <div className="wd---100 dis-flex">
                    <img className="pr--10" src="/assets/images/keyboard_arrow_left_blue_24.svg" />
                    <span className="pt-2">{props.labelBack}</span>
                </div>
                : null
            }
        </button>
    );
}

const defaultProps: MonyButtonProps = {
    className: "",
    ref: null,
    buttonType: "default",
    ajax: false,
    value: "",
    disabled: false,
    border: false,
    onClick: () => { },
    hidden: false,
    labelBack: "Back"
}

export default React.memo(MonyButton);