import React from "react";
import ReactDOM from "react-dom";
import { ElementTragetType, elementFinder } from "../../dom-finder";
import { MonyButton } from "mony-component";

interface OnBoardingStage {
    code: string;
    name: string;
    elTragetType: ElementTragetType;
    elTargetKey: string;
    boarding: {
        type: "callout" | "callout-with-hole" | "popup-page",
        title: string;
        contentType: "text" | "html" | "url";
        content: string;
        size?: {
            height: number;
            width: number;
        },
        closable: boolean
    }
}

interface IStateProps { autoDisplay?: boolean, data: Array<OnBoardingStage> };

export const OnBoarding: React.FC<IStateProps> = (props) => {

    const [display, setDisplay] = React.useState<boolean>(props.autoDisplay);
    const [stageIndex, setStageIndex] = React.useState<number>(0);
    const [currElTarget, setCurrElTarget] = React.useState<HTMLElement>(null);

    React.useEffect(() => {

        if (!window.MonyHelper) {
            window.MonyHelper = {};
        }

        window.MonyHelper.OnBoarding = {
            show: () => setDisplay(true),
            hide: () => setDisplay(false)
        };

    }, []);

    React.useEffect(() => {
        elementFinder((elTarget: HTMLElement) => setCurrElTarget(elTarget), props.data[stageIndex].elTragetType, props.data[stageIndex].elTargetKey);
        window.onresize = () => {
            let elTooltip = document.getElementsByClassName("boarding-tooltipReferenceLayer");
            if (elTooltip.length > 0) {
                elementFinder((el: HTMLElement) => rePosition(elTooltip[0] as HTMLDivElement, el), props.data[stageIndex].elTragetType, props.data[stageIndex].elTargetKey);
            }
        };
    }, [stageIndex]);

    const renderHelperHole = React.useCallback(() => {
        if (currElTarget && props.data[stageIndex].boarding.type === "callout-with-hole") {
            const boundClRect = currElTarget.getBoundingClientRect();
            return (
                <div className="boarding-helperLayer" style={{
                    boxShadow: "rgba(33, 33, 33, 0.8) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px",
                    opacity: 1,
                    width: boundClRect.width + (6 * 2),
                    height: boundClRect.height + (6 * 2),
                    top: boundClRect.y - 6,
                    left: boundClRect.x - 6
                }}></div>
            );
        }
        return null;
    }, [currElTarget]);

    const rePosition = React.useCallback((elTooltipWrapper: HTMLDivElement, elTarget?: HTMLElement) => {

        elTarget = elTarget ?? currElTarget;
        const stage = props.data[stageIndex];

        if (!elTooltipWrapper || !elTarget) {
            return;
        }

        let currX = 0;
        let currY = 0;

        const elTooltip: HTMLDivElement = elTooltipWrapper.firstChild as HTMLDivElement;
        const elTail = elTooltipWrapper.querySelector(".boarding-arrow");
        const elContent: HTMLDivElement = elTooltipWrapper.querySelector(".boarding-content");

        if (elContent) {
            elContent.innerHTML = stage.boarding.contentType === "url"
                ? `<iframe action="embedview" sandbox="allow-same-origin" crossorigin="true" allowFullScreen frameborder="0" src="${stage.boarding.content}" height="100%" width="100%" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>`
                : stage.boarding.content;
        }

        if (stage.boarding.type === "popup-page") {

            elTooltip.style.display = "flex";
            elTooltip.style.flexDirection = "column";
            elTooltip.style.width = window.innerWidth + "px";
            elTooltip.style.height = window.innerHeight + "px";

            if (elTail) {
                elTail.classList.add("hidden");
            }

            if (elContent) {
                elContent.style.flex = "1";
            }

            const elFooter: HTMLDivElement = elTooltipWrapper.querySelector(".boarding-tooltipbuttons");
            if (elFooter) {
                elFooter.style.display = "flex";
                elFooter.style.gap = "12px";
                elFooter.style.flexDirection = "row";
                elFooter.style.justifyContent = "center";
                elFooter.style.paddingLeft = "16px";
            }

        } else {

            elTooltip.style.setProperty("width", "fit-content");
            elTooltip.style.setProperty("height", "fit-content");
            elTail.classList.remove("hidden");

            const boundClRect = elTarget.getBoundingClientRect();

            type TailLeftType = "left-top" | "left-middle" | "left-bottom";
            type TailTopType = "top-left" | "top-middle" | "top-right";
            type TailBottomType = "bottom-left" | "bottom-middle" | "bottom-right";
            type TailRightType = "right-top" | "right-middle" | "right-bottom";

            let tailPosition: TailLeftType | TailTopType | TailBottomType | TailRightType = "top-middle";

            const middleX = boundClRect.x + (boundClRect.width / 2);
            currX = middleX - (elTooltip.clientWidth / 2);
            currY = boundClRect.y + (boundClRect.height + (6 * 3));

            if ((middleX + (elTooltip.clientWidth / 2)) > window.innerWidth) {
                currX = (middleX - elTooltip.clientWidth) + 17.5;
                tailPosition = "top-right";
            }
            console.log("currY-1", currY);
            if ((currY + (elTooltip.clientHeight / 2)) > window.innerHeight) {
                tailPosition = "right-bottom";
                currY = (boundClRect.y - elTooltip.clientHeight) + (boundClRect.height / 2) + 17.5;
                currX = boundClRect.x - (elTooltip.clientWidth + (6 * 3));
            } else if ((currY + elTooltip.clientHeight) > window.innerHeight) {
                tailPosition = "right-middle";
                currY = (boundClRect.y - (elTooltip.clientHeight / 2)) + (boundClRect.height / 2);
                currX = boundClRect.x - (elTooltip.clientWidth + (6 * 3));
            } else if ((currY + elTooltip.clientHeight) > window.innerHeight) {
                currY = boundClRect.y - (elTooltip.clientHeight + (6 * 3));
                if (tailPosition === "top-right") {
                    tailPosition = "bottom-right";
                } else {
                    tailPosition = "bottom-middle";
                }
            }
            console.log("currY-2", currY);
            if (currX < 0) {
                if (tailPosition === "top-right") {
                    tailPosition = "bottom-right";
                } else {
                    tailPosition = "left-top";
                }
                currX = boundClRect.x + boundClRect.width + (6 * 3);
                currY = boundClRect.y + (boundClRect.height / 2) - 17.5;

                if ((currY + (elTooltip.clientHeight / 2)) > window.innerHeight) {
                    tailPosition = "left-bottom";
                    currY = (boundClRect.y - elTooltip.clientHeight) + (boundClRect.height / 2) + 17.5;
                } else if ((currY + elTooltip.clientHeight) > window.innerHeight) {
                    tailPosition = "left-middle";
                    currY = (boundClRect.y - (elTooltip.clientHeight / 2)) + (boundClRect.height / 2);
                }
            }

            if (elTail) {
                elTail.classList.replace(elTail.classList[1], tailPosition);
            }
        }

        elTooltipWrapper.style.left = currX + "px";
        elTooltipWrapper.style.top = currY + "px";

    }, [currElTarget, stageIndex]);

    const renderCallout = React.useCallback(() => {
        if (currElTarget) {
            return (
                <div
                    ref={(el: HTMLDivElement) => {
                        // if (props.data[stageIndex].boarding.type !== "popup-page") {
                        rePosition(el);
                        // }
                    }}
                    className="boarding-tooltipReferenceLayer"
                    style={{ top: 0, left: 0 }} >
                    <div className="boarding-tooltip boarding-floating" role="dialog" style={{ opacity: 1, display: "block" }}>
                        <div className="boarding-tooltip-header">
                            <h1 className="boarding-tooltip-title"></h1>
                            <a className="boarding-skipbutton" onClick={() => setDisplay(false)} role="button" tabIndex={0}>×</a>
                        </div>
                        <div className="boarding-content"></div>
                        {/* {props.data[stageIndex].boarding.content}</div> */}
                        {/* <div className="boarding-bullets">
                            <ul role="tablist">
                                {
                                    props.data.map((o, i) => (
                                        <li key={o.code} role="presentation" onClick={() => setStageIndex(i)}>
                                            <a role="button" className={stageIndex === i ? "active" : ""} tabIndex={i} data-step-number={i + 1}>&nbsp;</a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div> */}
                        {/* <div className="ant-progress-outer pl--12 pr--12" style={{ display: "inherit" }}>
                            <div className="ant-progress-inner">
                                <div className="ant-progress-bg" style={{ width: "20%", height: 8 }}></div>
                            </div>
                        </div> */}
                        <div className="boarding-arrow left-middle" style={{ display: "inherit" }}></div>
                        <div className="boarding-tooltipbuttons">
                            <MonyButton className="float-left wd-auto" disabled={stageIndex === 0} onClick={() => setStageIndex(stageIndex - 1)}>Back</MonyButton>
                            <MonyButton className="float-right wd-auto" onClick={() => {
                                if (stageIndex < props.data.length - 1) {
                                    setStageIndex(stageIndex + 1);
                                } else {
                                    setDisplay(false);
                                }
                            }}>{stageIndex < props.data.length - 1 ? "Next" : "Finish"}</MonyButton>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }, [currElTarget, stageIndex]);

    return (
        <div style={{ display: display ? "block" : "none" }}>
            <div className="boardingFloatingElement"></div>
            <div className="boarding-overlay" style={{ inset: 0, position: "fixed", cursor: "pointer" }}></div>
            {renderHelperHole()}
            {renderCallout()}
        </div>
    );
}

export const RenderOnBoarding = () => {
    if (!document.getElementById("mony-onboarding")) {
        const element = document.createElement("DIV");
        element.id = "mony-onboarding";
        ReactDOM.render(<OnBoarding autoDisplay={false} data={onBoardingStages} />, document.body.appendChild(element));
    }
}

export const onBoardingStages: Array<OnBoardingStage> = [
    {
        code: "input-1",
        name: "Inputan 1",
        elTargetKey: "inputan1",
        elTragetType: "id",
        boarding: {
            type: "callout-with-hole",
            title: "Inputan 1",
            contentType: "text",
            content: "Masukan nilai untuk inputan 1...",
            size: { height: 200, width: 400 },
            closable: true
        }
    },
    {
        code: "input-2",
        name: "Inputan 2",
        elTargetKey: "inputan2",
        elTragetType: "id",
        boarding: {
            type: "callout-with-hole",
            title: "Inputan 2",
            contentType: "text",
            content: "Masukan nilai untuk inputan 2...",
            closable: true
        }
    },
    {
        code: "input-3",
        name: "Inputan 3",
        elTargetKey: "inputan3",
        elTragetType: "id",
        boarding: {
            type: "callout-with-hole",
            title: "Inputan 3",
            contentType: "text",
            content: "Masukan nilai untuk inputan 3...",
            closable: true
        }
    },
    {
        code: "input-4",
        name: "Inputan 4",
        elTargetKey: "inputan4",
        elTragetType: "id",
        boarding: {
            type: "callout-with-hole",
            title: "Inputan 4",
            contentType: "text",
            content: "Masukan nilai untuk inputan 4...",
            closable: true
        }
    },
    {
        code: "input-5",
        name: "Inputan 5",
        elTargetKey: "inputan5",
        elTragetType: "id",
        boarding: {
            type: "callout-with-hole",
            title: "Inputan 5",
            contentType: "text",
            content: "Masukan nilai untuk inputan 5...",
            closable: true
        }
    },
    {
        code: "input-6",
        name: "Inputan 6",
        elTargetKey: "inputan6",
        elTragetType: "id",
        boarding: {
            type: "callout-with-hole",
            title: "Inputan 6",
            contentType: "text",
            content: "Masukan nilai untuk inputan 6...",
            closable: true
        }
    },
    {
        code: "input-7",
        name: "Inputan 7",
        elTargetKey: "inputan7",
        elTragetType: "id",
        boarding: {
            type: "callout-with-hole",
            title: "Inputan 7",
            contentType: "text",
            content: "Masukan nilai untuk inputan 7...",
            closable: true
        }
    },
    {
        code: "input-8",
        name: "Inputan 8",
        elTargetKey: "inputan8",
        elTragetType: "id",
        boarding: {
            type: "popup-page",
            title: "Inputan 8",
            contentType: "url",
            content: "https://www.harmony.co.id/bantuan/invoice/daftar-invoice",
            // content: "https://www.malasngoding.com",
            closable: true
        }
    },
    {
        code: "input-9",
        name: "Inputan 9",
        elTargetKey: "inputan9",
        elTragetType: "id",
        boarding: {
            type: "callout-with-hole",
            title: "Inputan 9",
            contentType: "text",
            content: "Masukan nilai untuk inputan 9...",
            closable: true
        }
    },
    {
        code: "input-10",
        name: "Inputan 10",
        elTargetKey: "inputan10",
        elTragetType: "id",
        boarding: {
            type: "callout-with-hole",
            title: "Inputan 10",
            contentType: "text",
            content: "Masukan nilai untuk inputan 10...",
            closable: true
        }
    },
    {
        code: "label-1",
        name: "Label 1",
        elTargetKey: "ant-tag mr--0 pointer bg-transparent no-border",
        elTragetType: "class",
        boarding: {
            type: "callout-with-hole",
            title: "Label 1",
            contentType: "text",
            content: "Ini adalah label 1...",
            closable: true
        }
    }
];