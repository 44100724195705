import React, { PropsWithChildren } from "react";
import { MonyFooter, Icons } from ".";
import { Common } from "../helper";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
// import $ from "jquery";

// const Logo = require("../assets/images/blue-24.svg");
// const Tonggel = require("../assets/images/hamburger-iconsvg.svg");
// const QuickfindMenuLogo = require("../assets/images/quickfind-ico-24.svg");
// const CheveronDownLogo = require("../assets/images/cheveron_down_24.svg");
// const DropupLogo = require("../assets/images/dropup_white.svg");
// const IconCheck = require("../assets/images/check.svg");
const Logo = "blue-24.svg";
const Tonggel = "hamburger-iconsvg.svg";
const QuickfindMenuLogo = "quickfind-ico-24.svg";
const CheveronDownLogo = "cheveron_down_24.svg";
const DropupLogo = "dropup_white.svg";
const IconCheck = "check.svg";
const BellActive = "bell-active.svg";

interface MonyLayoutProps {
    IsUrlNoSession?: () => boolean;
    sideMenu?: Array<{
        key: string;
        name: string;
        link: string;
    }>;
}

const MonyLayout: React.FC<PropsWithChildren<MonyLayoutProps>> = (props) => {

    const [state, setState] = React.useState({
        showLanguage: false,
        showprofile: false,
        showProfileSetting: false,
        language: "en",
        showMenu: true
    });

    const menuProfile = () => {
        return (
            <Menu className="profile-div">
                {/* <Menu.Item>
                    <div>
                        <Link id="account-settings" className="profile" onClick={() => setState({ ...state, showProfileSetting: true })} to={"#"}>
                            {language.Menu.account_settings}
                        </Link>
                    </div>
                </Menu.Item> */}
                <Menu.Item>
                    <Link id="logout" to="/logout"><div className="color-red"> Logout</div></Link>
                </Menu.Item>
            </Menu>
        );
    }

    const menuLanguage = () => {
        return (
            <Menu className="profile-div">
                <Menu.Item>
                    <div onClick={() => setState({ ...state, language: "en" })}>
                        <Link id="account-settings" className="profile dis-flex" to="#">
                            <label className="pointer">English</label>
                            {state.language == "en" ? <Icons glyph={IconCheck} className="icon-menu-language-en" extraClass="pt--1" /> : null}
                            {/* {state.language == "en" ? <img src="assets/images/blue-24.svg" className="icon-menu-language-en pt--1" /> : null} */}
                        </Link>
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <div onClick={() => setState({ ...state, language: "id" })}>
                        <Link id="account-settings" className="profile dis-flex" to="#">
                            <label className="pointer">Bahasa Indonesia</label>
                            {state.language == "id" ? <Icons glyph={IconCheck} className="icon-menu-language-id" extraClass="pt--1" /> : null}
                            {/* {state.language == "id" ? <img src="assets/images/blue-24.svg" className="icon-menu-language-id pt--1" /> : null} */}
                        </Link>
                    </div>
                </Menu.Item>
            </Menu>
        );
    }

    React.useEffect(() => {
        if (props.IsUrlNoSession && !props.IsUrlNoSession() && !Common.getCookie()) {
            if (window.location.pathname !== "/login") {
                window.location.href = "/login";
            }
        }
    }, []);

    const onClickToggle = React.useCallback(() => {
        // this.props.dispatch(showMenu(!this.props.menu.showMenu));
        // this.props.dispatch(showCompany(false));
        // this.props.dispatch(showCompanyMini(false));
        // setState({ ...state, showMenu: !state.showMenu });
        // $(".chose-company-sidebar-mini").removeClass("display-none");
        // $(".chose-company-sidebar").removeClass("display-none");
        // if (state.showMenu) {
        //     $(".content-container").addClass("content-full");
        // }
        // else {
        //     $(".content-container").removeClass("content-full");
        // }
    }, [state]);

    return (
        <>
            {
                props.IsUrlNoSession && props.IsUrlNoSession() ? props.children : <>
                    <div className="wrapper">
                        <div className="side-warpper">
                            <Link onClick={() => { }} to={""}>
                                <div className="company-warpper">
                                    <label>Bank Harmoni</label>
                                    <Icons glyph={Logo} className="icon-dropdown" />
                                    {/* <img src="assets/images/blue-24.svg" className="icon-dropdown" /> */}
                                    <hr className="line-border" />
                                </div>
                            </Link>
                            <div className="quickfind-warpper">
                                <Icons glyph={QuickfindMenuLogo} className="icon-quickfind-menu" />
                                {/* <img src="assets/images/quickfind-ico-24.svg" className="icon-quickfind-menu" /> */}
                                <input type="text" autoComplete="off" className="quickfind-field" placeholder="QUICK FIND..." onChange={() => { }} />
                                {/* <div></div><input type="text" autoComplete="off" className="quickfind-field" placeholder="QUICK FIND..." /> */}
                            </div>
                            <div style={{ position: "relative", overflow: "hidden", width: "200px", height: "1000px" }}>
                                <div className="scroll-content react-scrollbar-default">
                                    <div>
                                        <div>
                                            <h1 className="title-menu">QUICK ACCESS</h1>
                                            <ul className="side-menu">
                                                <Link key={1} id={"new-invoice"} to={""}>
                                                    <li>
                                                        <label className="lbl-menu dis-flex">Create New Invoice</label>
                                                    </li>
                                                </Link>
                                                <Link key={2} id={"new-bill"} to={""}>
                                                    <li>
                                                        <label className="lbl-menu dis-flex">Create New Bill</label>
                                                    </li>
                                                </Link>
                                                <Link key={3} id={"new-expense"} to={""}>
                                                    <li>
                                                        <label className="lbl-menu dis-flex">Create New Expense</label>
                                                    </li>
                                                </Link>
                                                <Link key={4} id={"new-manual-journal"} to={""}>
                                                    <li>
                                                        <label className="lbl-menu dis-flex">Create Manual Journal</label>
                                                    </li>
                                                </Link>
                                            </ul>
                                        </div>
                                        <div>
                                            <h1 className="title-menu">MENU</h1>
                                            <ul className="side-menu btm-menu">
                                                {
                                                    !props.sideMenu ? "" : props.sideMenu.map((menu, index) => {
                                                        return (
                                                            <Link key={index} id={menu.key} to={menu.link}>
                                                                <li>
                                                                    <label className="lbl-menu dis-flex">{menu.name}</label>
                                                                </li>
                                                            </Link>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="topbar" className="topbar">
                        <div className="row content-topbar">
                            <div className="subscrip">
                                <div className="col-topbar pointer" onClick={onClickToggle}>
                                    <Icons glyph={Tonggel} className="toggel-icon" />
                                    {/* <img src="assets/images/hamburger-iconsvg.svg" className="toggel-icon" /> */}
                                </div>
                            </div>
                            <div className="div-profile-bar">
                                <div className="col-profile">
                                    <div className="col-notiff">
                                        <div className="notification-wrap">
                                            <div className="notif-icon">
                                                <Icons glyph={BellActive} />
                                            </div>
                                        </div>
                                    </div>
                                    <Dropdown onVisibleChange={() => setState({ ...state, showprofile: true })} trigger={["click"]} placement="bottomLeft" visible={state.showprofile} overlay={menuProfile()}>
                                        <Link className="ant-dropdown-link" to="#">
                                            <label className="lblusername pointer">Rovi Riyadi</label>
                                            <Icons glyph={CheveronDownLogo} className="icon-dropdown-profile" />
                                            {/* <img src="assets/images/cheveron_down_24.svg" className="icon-dropdown-profile" /> */}
                                        </Link>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className={state.showLanguage ? "div-language-bar2" : "div-language-bar"}>
                                <div className="col-profile">
                                    <Dropdown onVisibleChange={() => setState({ ...state, showLanguage: true })} trigger={["click"]} placement="bottomLeft" visible={state.showLanguage} overlay={menuLanguage()}>
                                        <Link to="#">
                                            <label className={state.showLanguage ? "lblusername pointer color-white" : "lblusername pointer"}>{state.language == "en" ? "ENG" : "IND"}</label>
                                            <Icons glyph={state.showLanguage ? DropupLogo : CheveronDownLogo} className="icon-dropdown-language" extraClass="div-icon-language" />
                                            {/* <img src={state.showLanguage ? "assets/images/dropup_white.svg" : "assets/images/cheveron_down_24.svg"} className="icon-dropdown-language div-icon-language" /> */}
                                        </Link>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div id="main-content" className="content-container">
                            {props.children}
                        </div>
                        <MonyFooter />
                    </div>
                </>
            }
        </>
    );
}

export default React.memo(MonyLayout);
