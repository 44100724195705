import React from "react";
import ReactDOM from "react-dom";
import { ModalForm, MonyButton, MonyTable, MonyInput, Icons } from "mony-component";
import { PiletApi } from "piral";
import { useDispatch, useSelector } from "react-redux";
import { ContainerState } from "../../redux/store";
import { FetchReferralReward, SetSnackBar } from "../../redux/actions/ContainerAction";
import { containerStore } from "../../redux/store";
import { Provider } from "react-redux";
import { SnackBar } from "./"


export interface IModalShareInvoiceProps {
    visible: boolean;
    piral?: PiletApi;
    title?: string;
    className?: string;
    onCancel: () => void;
    onChange: () => void;
    ajax?:boolean;
    width?: any;
    copylink: boolean;
    link: any;
    company_id: number;
    type: string
    token: any;
}

export const ShareModalPreviewPage: React.FC<IModalShareInvoiceProps> = (props) => {
    props = { ...defaultProps, ...props };
    const dispatch = useDispatch();
    const [copied, setCopied] = React.useState(false);
    let hostname = window.location.hostname
    const [generateLink, setGenerateLink] = React.useState("");

    const shareLink = React.useCallback(async () => {
        setGenerateLink(hostname + "/share-" + props.type + "/" + props.company_id + "/" + props.link)
    }, [generateLink]);

    React.useEffect(() => {
        if (props.visible) {
            shareLink();
        }
    }, [props.visible]);

    const onCopyLink = React.useCallback((e: any) => {
        navigator.clipboard.writeText(generateLink);
        setCopied(true);
        dispatch(SetSnackBar({
            message: "URL Copied",
            duration: 3000,
            show: true
        }));

    }, [copied, generateLink]);

    return (

        <ModalForm 
            width={615}
            style={{minWidth: 615}}
            classBodyContent="mt--15"
            visible={props.visible}
            title={[<div className="title-modal"><label className="lbl-title">{props.title}</label></div>]}
            onCancel={() => props.onCancel()}>
            <div className="content-share-online" key={"share"}>
                <div className="row-field" key={"share2"}>
                    <label className="label-share">Anyone with the link can view this {props.type.capitalizeFirstLetter()}.</label>
                    <div className="dis-flex wd---100">
                    <MonyInput
                        divClassName="wd---100"
                        className="input-share wd---100"
                        readonly={true}
                        value={generateLink}
                    />
                    <MonyButton
                        id="btn-share"
                        className="button--save btn-copy-link wd--100"
                        style={{ padding: 0 }}
                        onClick={onCopyLink}
                        value={"Copy Link"} />
                </div>

                <div className="wd---100">
                    <div className="wd--153 pull-right ">
                        {copied === true ? <label className="label-url"><img src="/assets/images/green-check.svg" /><span className="url-copied">URL Copied</span></label> : null}
                    </div>
                </div>
                    <div className="clear"/>
                </div>
            </div>
        </ModalForm>
    );
}

const defaultProps: IModalShareInvoiceProps = {
    visible: false,
    title: null,
    className:"",
    onCancel: () => { },
    onChange: () => { },
    ajax:false,
    copylink: false,
    link: null,
    company_id: null,
    type: "",
    token: null
}


