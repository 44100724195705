import React from "react";
import moment from "moment";
import CommonEnume from "../enum/CommonEnume";
import { DateRangeProps } from "../interfaces/DateRangeProps"
import { MonyDatePicker } from ".";

class DateRangeBoxCls extends React.Component<DateRangeProps> {

    static defaultProps: { placeholderStart: string; placeholderEnd: string; disabled: boolean; showTime: boolean; autoFixOverlaping: boolean; autoFillEndDate: boolean; autoFillIncrement: number; dateFormat: string; valueDateFormat: string; onChange: () => void; inputPrefixCls: string; divClassName: string; dateStart: any; dateEnd: any; };

    constructor(props) {
        super(props);
        this.onStateChanged = this.onStateChanged.bind(this);
    }

    onStateChanged(key: string, val: any) {
        if (this.props.onStateChanged) {
            const stateKey = key === "dateStart" ? this.props.stateKeyStart : this.props.stateKeyEnd;
            this.props.onStateChanged(stateKey, val, this.props.stateRowIndex);
        } else {
            if (key === "dateStart") {
                let endDate = this.props.dateEnd;
                const isValidStartDate = moment(val).isValid();
                const isValidEndDate = moment(endDate).isValid();
                if (isValidEndDate && this.props.autoFixOverlaping) {
                    endDate = moment(moment(endDate).isBefore(val) ? val : endDate).format("YYYY-MM-DD");
                }
                if (isValidStartDate && !isValidEndDate && this.props.autoFillEndDate) {
                    endDate = moment(this.props.autoFillIncrement > 0 ? moment(val).add(this.props.autoFillIncrement, "day") : val).format("YYYY-MM-DD");
                }
                this.props.onChange(val, endDate);
            } else if (key === "dateEnd") {
                this.props.onChange(!this.props.dateStart ? val : moment(this.props.dateStart).format("YYYY-MM-DD"), val);
            }
        }
    }

    render() {
        return (
            <div className={"dis-flex mt--2 " + this.props.divClassName}>
                <MonyDatePicker
                    value={this.props.dateStart}
                    selectsStart={true}
                    className="wd--125 margin--top--min-4"
                    placeholder={this.props.placeholderStart}
                    startDate={this.props.dateStart}
                    endDate={this.props.dateEnd}
                    stateKey="dateStart"
                    onStateChanged={this.onStateChanged}
                    defaultDate={false}
                    disabled={this.props.disabled}
                />
                <div><label className="p--5 color-text-grey">~</label></div>
                <MonyDatePicker
                    value={this.props.dateEnd}
                    selectsEnd={true}
                    className="wd--125 margin--top--min-4"
                    placeholder={this.props.placeholderEnd}
                    startDate={this.props.dateStart}
                    endDate={this.props.dateEnd}
                    error={moment(this.props.dateEnd).isBefore(this.props.dateStart)}
                    stateKey="dateEnd"
                    onStateChanged={this.onStateChanged}
                    defaultDate={false}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

DateRangeBoxCls.defaultProps = {
    placeholderStart: "Select Start",
    placeholderEnd: "Select End",
    disabled: false,
    showTime: false,
    autoFixOverlaping: true,
    autoFillEndDate: false,
    autoFillIncrement: 0,
    dateFormat: CommonEnume.DATE_FORMAT.SHORT_DATE,
    valueDateFormat: CommonEnume.DATE_FORMAT.API_DATE,
    onChange: function () { },
    inputPrefixCls: "datepickerant",
    divClassName: "",
    dateStart: null,
    dateEnd: null
}

export default DateRangeBoxCls;
// export default React.memo(DateRangeBox);