import React from "react";
import Select from "antd/es/select";
// import Tag from "antd/es/tag";
import { GetLanguage } from "../helper/Language";
import { StateProps } from "../interfaces/StateProps";
import { ILanguage, TypeTranslate } from "../language";

import ConfigProvider from "antd/lib/config-provider";
import enUS from "antd/lib/locale-provider/en_US";
import { SelectData } from "../interfaces";

export interface ISelectMultipleProps extends StateProps {
    className?: string;
    style?: any;
    value?: string | number | string[];
    language?: string;
    allable?: boolean;
    allowMultiple?: boolean;
    show?: boolean;
    error?: boolean;
    data?: Array<SelectData>;
    datasource?: Array<SelectData>;
    placeholder?: string;
    dropdownMatchSelectWidth?: boolean | number;
    onClick?: Function;
    OnSelected?: (value: number | string[]) => void;
    minLength?: number;
    disabled?: boolean;
    defaultValue?: string;
    showAddBox?: boolean;
    flatBox?: boolean;
    border?: boolean;
    onAdd?: (value: string) => void;
    notFound?: React.ReactNode;
    translate?: TypeTranslate;
}

class SelectMultiple extends React.Component<ISelectMultipleProps> {

    static defaultProps = {
        show: true,
        data: [],
        allable: true,
        allowMultiple: true,
        defaultValue: "",
        value: [],
        datasource: [],
        error: false,
        placeholder: "Select Item",
        disabled: false,
        className: "",
        notFound: "Not Found",
        showAddBox: false,
        minLength: 3,
        flatBox: false,
        border: true
    };

    state = {
        search: "",
        openAddBox: false
    }

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onClickAdd = this.onClickAdd.bind(this);
        this.isStringNotEmpty = this.isStringNotEmpty.bind(this);
        this.isNumberNotNol = this.isNumberNotNol.bind(this);
        // this.tagRender = this.tagRender.bind(this);
    }

    getClassName() {
        let classname = this.props.className;
        if (this.props.error) {
            classname = classname + " error-selected";
        }
        if (this.props.flatBox) {
            classname = `${classname} ant-select-flat-box flat-box`;
        }
        if (!this.props.border) {
            classname = `${classname} ant-select-no-border no-border`;
        }
        return classname;
    }

    isNumberNotNol(val: any) {
        return Number(val) > 0 && !isNaN(Number(val));
    }

    isStringNotEmpty(val: any) {
        return val !== "" && isNaN(Number(val));
    }

    getValue(value: any) {
        if (value !== undefined && !Array.isArray(value)) {
            value = value.toString();
        }
        return value;

        // if (value !== undefined) {
        // if (!Array.isArray(value) && (!this.isNumberNotNol(value) || !this.isStringNotEmpty(value)) ) { //Number(value) === 0) {
        //     value = undefined;
        // } else if (Array.isArray(value) && value.length !== 0 && !this.props.allowMultiple) {
        //     value = value[0].toString();
        // }

        // else if (prefix === "label" && !Array.isArray(value) && (this.isNumberNotNol(value) || this.isStringNotEmpty(value))) {
        //     const find = this.props.data.find((opt) => {
        //         if (this.isNumberNotNol(value)) {
        //             return Number(opt.value) === Number(value);
        //         } else if (this.isStringNotEmpty(value)) {
        //             return String(opt.value) === String(value);
        //         } else {
        //             return opt.value === value;
        //         }
        //     });
        //     return find ? find.label : undefined;
        // }
        // }
        // return value;
    }

    filterOption(value, option) {
        let label = option.props.children || "";
        return label.toLowerCase().indexOf(value.toLowerCase()) != -1;
    }

    onClickAdd() {
        if (this.props.onAdd) {
            this.setState({ openAddBox: true }, () => this.props.onAdd(this.state.search));
        }
    }

    onSearch(val: any) {
        this.setState({ search: val });
    }

    onChange(data: any) {

        let value = data;

        if (this.props.disabled) {
            return;
        }

        // if (data.length > 0 && !this.props.allowMultiple) {
        //     if (Array.isArray(this.props.value) && this.props.value.length !== 0) {
        //         return;
        //     } else if (!Array.isArray(this.props.value) && (this.isNumberNotNol(this.props.value) && this.isStringNotEmpty(this.props.value))) {
        //         return;
        //     }
        // }

        if (this.props.allowMultiple) {
            if (data[data.length - 1] === "all") {
                value = ["all"];
            } else if (data[0] === "all") {
                value.shift();
            }
        } else if (!this.props.allowMultiple && value.length > 1) {
            value = [data[data.length - 1].toString()];
        }

        this.setState({ search: "" });
        if (this.props.onStateChanged) {
            this.props.onStateChanged(this.props.stateKey || "", value || [], this.props.stateRowIndex);
        } else {
            this.props.OnSelected(value);
        }
    }

    renderAddBox(language: ILanguage) {
        if (this.state.search.length >= this.props.minLength) {
            return <a className="long-text wd---100" onClick={this.onClickAdd}>{"+ " + this.props.translate ? this.props.translate("add", "button") : language.Button.add + " " + this.state.search}</a>
        } else {
            return null;
        }
    }

    // tagRender(props) {
    //     return (
    //         <Tag closable={!this.props.disabled} style={{ marginRight: 3, marginBottom: 1 }}>
    //             {props.label}
    //         </Tag>
    //     );
    // }

    render() {

        let language = !this.props.translate ? GetLanguage(this.props.language) : undefined;
        let data: React.ReactNode[] = this.props.datasource.length > 0 ? this.props.datasource : this.props.data.map((x, i) => <Select.Option key={i} disabled={x.disabled} value={this.getValue(x.value)}>{x.label}</Select.Option>);
        let allable = data.length > 0 ? this.props.allable : false;
        if (allable) {
            data.unshift(<Select.Option key="all" value="all">{this.props.translate ? this.props.translate("all", "placeholder") : language.Placeholder.all}</Select.Option>);
        }

        return (
            <ConfigProvider locale={enUS} >
                <Select
                    mode="multiple"
                    // disabled={this.props.disabled}
                    // tagRender={this.tagRender}
                    value={this.getValue(this.props.value)}
                    onChange={this.onChange}
                    onSearch={this.onSearch}
                    className={this.getClassName()}
                    style={this.props.style}
                    size="middle"
                    filterOption={this.filterOption}
                    placeholder={this.props.placeholder}
                    dropdownMatchSelectWidth={this.props.dropdownMatchSelectWidth}
                    notFoundContent={!this.props.showAddBox ? this.props.notFound : this.renderAddBox(language)}>
                    {data}
                </Select>
            </ConfigProvider>
        );
    }
}

export default SelectMultiple;
