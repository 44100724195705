import React from "react";
import PlayCircleOutlined from "@ant-design/icons/es/icons/PlayCircleOutlined";


interface IMonyVideoProps {
    source: string;
    autoPlay?: boolean;
}

const MonyVideo: React.FC<IMonyVideoProps> = (props) => {

    const [played, setPlayed] = React.useState(false);
    const vidRef = React.createRef<HTMLVideoElement>();

    React.useEffect(() => {
        vidRef.current.onpause = () => setPlayed(false);
        if (props.autoPlay) {
            vidRef.current.play().then(() => setPlayed(true));
        }
    }, []);

    const onPlay = React.useCallback(() => {
        if (!played) {
            vidRef.current.play().then(() => setPlayed(true));
        } else {
            vidRef.current.pause();
            setPlayed(false);
        }
    }, [played]);

    return (
        <div className="mony-video" style={{ height: "auto", width: "auto" }}>
            <video ref={vidRef} style={{ height: "auto", width: "100%" }} controls={played}>
                <source type="video/mp4" src={props.source} />
                <source type="video/ogg" src={props.source} />
                Your browser does not support the video tag.
            </video>
            <PlayCircleOutlined className={`play-button ${played ? 'hidden' : ''}`} onClick={onPlay} />
        </div>
    );
}

export default React.memo(MonyVideo);