import React from "react";
import { PiletApi } from "piral";
import { SelectProps } from "mony-component/dist/interfaces/SelectProps";

import {
    DashboardPeriodSelect,
    InvoiceNumberPicker,
    InvoiceNumberDisplay,
    InvoiceNumberAutoComplete,
    CoaFullAutoComplete,
    ContactAutoComplete,
    ContactEmployeeAutoComplete,
    BankAutoComplete,
    IInvoiceNumberPickerProps,
    IInvoiceNumberDisplayProps,
    ICoaFullAutoCompleteProps,
    IContactAutoCompleteProps,
    IContactEmployeeAutoCompleteProps,
    IBankAutoCompleteProps,
    SystemCounterAutoComplete,
    ISystemCounterAutoCompleteProps,
    CurrencyPicker,
    ICurrencyPickerProps
} from ".";

export enum ExtensionNameEnum {
    DashboardPeriodeSelect = "dashboard-period-select",
    InvoiceNumberPicker = "invoice-number-picker",
    InvoiceNumberAutocomplete = "invoice-number-autocomplete",
    InvoiceNumberDisplay = "invoice-number-display",
    CoaAutocomplete = "coa-autocomplete",
    ContactAutoComplete = "contact-autocomplete",
    BankAutoComplete= "bank-autocomplete",
    ContactEmployeeAutoComplete= "contact-employee-autocomplete",
    SystemCounterAutoComplete = "systemcounter-autocomplete",
    CurrencyPicker = "currency-picker",
}

export const RegisterPiralExtension = (app: PiletApi) => {
    app.registerExtension<SelectProps>(ExtensionNameEnum.DashboardPeriodeSelect, ({ params }) => <DashboardPeriodSelect {...params} />);
    app.registerExtension<IInvoiceNumberPickerProps>(ExtensionNameEnum.InvoiceNumberPicker, ({ params }) => <InvoiceNumberPicker {...params} />);
    app.registerExtension(ExtensionNameEnum.InvoiceNumberAutocomplete, ({ params }) => <InvoiceNumberAutoComplete {...params} />);
    app.registerExtension<IInvoiceNumberDisplayProps>(ExtensionNameEnum.InvoiceNumberDisplay, ({ params }) => <InvoiceNumberDisplay {...params} />);
    app.registerExtension<ICoaFullAutoCompleteProps>(ExtensionNameEnum.CoaAutocomplete, ({ params }) => <CoaFullAutoComplete {...params} />);
    app.registerExtension<IContactAutoCompleteProps>(ExtensionNameEnum.ContactAutoComplete, ({ params }) => <ContactAutoComplete {...params} />);
    app.registerExtension<IBankAutoCompleteProps>(ExtensionNameEnum.BankAutoComplete, ({ params }) => <BankAutoComplete {...params} />);
    app.registerExtension<IContactEmployeeAutoCompleteProps>(ExtensionNameEnum.ContactEmployeeAutoComplete, ({ params }) => <ContactEmployeeAutoComplete {...params} />);
    app.registerExtension<ISystemCounterAutoCompleteProps>(ExtensionNameEnum.SystemCounterAutoComplete, ({ params }) => <SystemCounterAutoComplete {...params} />);
    app.registerExtension<ICurrencyPickerProps>(ExtensionNameEnum.CurrencyPicker, ({ params }) => <CurrencyPicker {...params} />);
}