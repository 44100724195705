import { ILanguage } from ".";

const LanguageID: ILanguage = {
    Menu: {
        create_invoice: "Invoice Baru",
        create_bill: "Bill Baru",
        create_expense: "Klaim Biaya Baru",
        create_journal: "Jurnal Umum Baru",
        dashboard: "Dashboard",
        cash_bank: "Kas & Bank",
        projects: "Sales",
        salescommission: "Sales Commission",
        reports: "Laporan",
        sales: "Penjualan",
        quote: "Penawaran",
        invoice: "Invoice",
        creditnote: "Nota Kredit",
        cn_sales: "Nota Kredit Penjualan",
        cnsales: "Nota Kredit Penjualan",
        purchase: "Pembelian",
        purchase_order: "Pemesanan",
        po: "Pemesanan",
        bill: "Bill",
        cn_purchase: "Nota Kredit Pembelian",
        cnpurchase: "Nota Kredit Pembelian",
        expense: "Klaim Biaya",
        inventory: "Persediaan",
        fixed_assets: "Aset Tetap",
        fixed_asset: "Aset Tetap",
        asset: "Aset",
        asset_type: "Tipe Aset",
        onegate_tax: "OneGate Tax",
        coa: "Daftar Akun",
        coa_delete:"Hapus Akun",
        contact: "Kontak",
        journal: "Jurnal Umum",
        settings: "Pengaturan",
        file_library: "Kumpulan Berkas",
        history_business: "Riwayat Aktifitas",
        my_harmony: "My Harmony",
        account_settings: "Pengaturan Akun",
        help_center: "Bantuan",
        back_to_app: "Kembali ke app",
        quick_access: "AKSES CEPAT",
        quick_find: "PENCARIAN",
        choose_comp: "Pilih Perusahaan",
        choose_contact: "Choose from Computer",
        menu: "MENU",
        no_result: "Tidak ditemukan",
        subscription: "Berlangganan & Tagihan",
        repeating_invoice: "Invoice Berulang",
        repeating_bill: "Pembelian Berulang",
        repeating_journal: "Journal Berulang",
        account_transaction: "Transaksi Akun",
        transfer_money_detail: "Rincian Pindah Buku",
        spend_receive:"Transaksi Bank",
        customer_statement:"Pernyataan Pelanggan",
        mandiri_account_list: "Daftar Rekening Mandiri",
        mandiri_register: "Tambah Rekening Baru",
        mandiri_transfer_list: "Daftar Transfer",
        mandiri_create_transfer_payment_hth: "Buat Transfer Host to Host",
        mandiri_create_transfer_payment_mft: "Buat Transfer MFT",
        mandiri_create_transfer_bulk: "Buat Transfer Gabungan",
        mandiri_beneficiary_account: "Daftar Rekening Tujuan",
        mandiri: "Mandiri",
        view_statement: "Lihat Laporan Hutang/Piutang",
    },
    Services: {
        assets: "Assets",
        cashbank: "Cash Bank",
        cnpurchase: "CN Purchase",
        cnsales: "CN Sales",
        cn_purchase: "CN Purchase",
        cn_sales: "CN Sales",
        expense: "Expense",
        inventory: "Inventory",
        invoice: "Invoice",
        invoice_repeating: "Invoice Repeating",
        journal: "Journal",
        journal_repeating: "Journal Repeating",
        po: "Purchase Order",
        purchase: "Bill",
        purchase_order: "Purchase Order",
        purchase_repeating: "Bill Repeating",
        quote: "Quote",
        starting_balance: "Conversion Balance",
        contact: "Contact",
        tagging_detail: "Tagging",
        tagging: "Tagging",
        conversion_balance: "Conversion Balance",
    },
    Validasi: {
        required: "is required",
        invalid: "is invalid",
        matches: "matches",
        email: "email",
        password: "password",
        min_legth: "min_length",
        max_length: "max_length",
        exact_length: "exact_length",
        numeric: "numeric",
        string: "string",
        valid_email: "valid_email",
        datecompare: "datecompare",
        date_valid: "date_valid",
        agreement: "agreement",
        custom: "custom",
        gte_zero: "cannot be equal to or less than zero",
        gt_zero: "gt_zero",
        bigger_than: "bigger than",
        minimum_char: "has to be minimum",
        char: "characters",
    },
    Title: {
        project_list: "Sales List",
        invoice_list: "Invoice List",
        new_invoice: "New Invoice",
        edit_invoice: "Edit Invoice",
        view_invoice_credit_allocation: "View Invoice Credit Allocation",
        new_repeating_invoice: "New Repeating Invoice",
        view_repeating_invoice: "View Repeating Invoice",
        edit_repeating_invoice: "Edit Repeating Invoice",
        view_invoice_detail: "View Invoice Details",
        import_invoice: "Import Invoice",
        import_bill: "Import Bill",
        product_last_sales: "10 Last Sales",
        bill_list: "Bill List",
        new_bill: "New Bill",
        edit_bill: "Edit Bill",
        view_bill_details: "View Bill Details",
        view_bill_credit_allocation: "View Bill Credit Allocation",
        view_journal_payment_details: "View Journal Payment Details",
        new_repeating_bill: "New Repeating Bill",
        view_repeating_bill: "View Repeating Bill",
        edit_repeating_bill: "Edit Repeating Bill",

        cn_purchase_list: "Credit Note Purchase List",
        new_cn_purchase: "New Purchase Credit Note",
        edit_cn_purchase: "Edit Purchase Credit Note",
        view_cn_purchase_details: "View Purchase Credit Note Details",

        new_cn_sales: "New Sales Credit Note",
        edit_cn_sales: "Edit Sales Credit Note",
        view_cn_sales_details: "View Sales Credit Note Details",
        cn_sales_list: "Credit Note Sales List",

        expense_claim_list: "Expense Claims List",
        new_expense_claim: "New Expense Claims",
        edit_expense_claim: "Edit Expense Claim",
        view_expense_claim_detail: "View Expense Claim Details",

        po_list: "Purchase Order List",
        view_po_detail: "View Purchase Order Details",
        new_po: "New Purchase Order",
        edit_po: "Edit Purchase Order",
        share_po: "Share Purchase Order",

        new_quote: "New Quote",
        edit_quote: "Edit Quote",
        view_quote_detail: "View Quote Details",
        quote_list: "Quote List",
        share_quote: "Share Quote",

        user_management_list: "User Management List",

        fixed_asset_setting: "Fixed Assets Settings",

        import_fixed_asset: "Import Fixed Assets",
        asset_type_list: "Asset Type List",

        asset_list: "Asset List",
        new_asset: "New Asset",
        edit_asset: "Edit Asset",
        view_asset_detail: "View Asset Details",

        run_depreciation: "Run Depreciation",

        new_receive_money: "New Receive Money",
        new_spend_money: "New Spend Money",
        edit_receive_money: "Edit Receive Money",
        edit_spend_money: "Edit Spend Money",
        new_transfer_money: "New Transfer Money",
        edit_transfer_money: "Edit Transfer Money",
        view_transaction_detail: "View Transaction Details",
        view_bank_statement_detail: "View Bank Statement Details",

        cash_coding_list: "Cash Coding List",
        bank_statement_list: "Bank Statement List",
        account_transaction_list: "Account Transaction List",
        bank_reconcile_list: "Bank Reconcile List",

        new_bank_account: "New Bank Account",
        edit_bank_account: "Edit Bank Account",
        bank_account_detail: "Bank Account Details",
        cash_bank_list: "Cash & Bank List",
        import_bank_statement: "Import Bank Statement",
        link_to_onefeed: "Link to OneFeed",
        smart_rule: "Smart Rule List",
        create_smart_rules: "Create Smart Rules",
        edit_smart_rules: "Edit Smart Rules",

        contact: "Contacts",
        new_contact: "New Contact",
        edit_contact: "Edit Contact",
        import_contact: "Import Contact",
        view_contact_detail: "View Contact Details",
        contact_list: "Contact List",

        customer_statement: "Customer Statement",
        conversion_balance: "Conversion Balance",

        email_setup: "Email Setup",

        inventory_list_title: "Inventory List",
        edit_inventory: "Edit Inventory",
        new_inventory: "New Inventory",
        view_inventory_detail: "View Inventory Details",
        view_inventory_adj: "View Inventory Adjustment Details",
        import_inventory: "Import Inventory",

        manual_journal_list: "Manual Journal List",
        new_manual_journal: "New Manual Journal",
        edit_manual_journal: "Edit Manual Journal",
        view_manual_journal: "View Manual Journal",
        edit_repeating_journal: "Edit Repeating Journal",
        new_repeating_journal: "New Repeating Journal",
        view_journal_detail: "View Journal Details",

        account_mapping_setting: "Account Mapping Settings",
        coa: "Chart of Accounts",

        company_setting: "Company Setting",

        transfer_ownership: "Transfer Ownership",
        transfer_ownership_approval: "Transfer Ownership Approval",
        tax_list: "Tax List",

        product_unit_list: "Product Unit List",

        account_summary_report: "Account Summary Report",
        aged_payable_report: "Aged Payable Report",
        aged_receivable_report: "Aged Receivable Report",
        fixed_asset_recon: "Fixed Assets Reconciliation Report",
        balance_sheet_report: "Balance Sheet Report",
        account_trans_report: "Account Transactions Report",
        movement_equity_report: "Movements in Equity Report",
        general_ledger_report: "General Ledger Report",
        profit_loss_report: "Profit & Loss Report",
        trial_balance_report: "Trial Balance Report",
        cash_flow_report: "Cash Flow Statement",
        bank_summary_report: "Bank Summary Report",
        item_valuation_report: "Item Valuation Report",
        profit_loss_comp_report: "Profit & Loss Report (Comparation)",
        purchase_item_report: "Purchase By Item Report",
        fixed_asset_report: "Fixed Assets Report",
        inventory_list: "Inventory List Report",
        journal_report: "Journal Report",
        sales_list_report: "Sales List Report",
        purchase_list_report: "Purchase List Report",
        report_list: "Reports",
        sales_item: "Sales By Item Report",

        subs_and_bill: "Subscriptions & Billing",

        history_business: "History Business Activity",
        file_library: "File Library",
        setting: "Settings",
        transaction_number_setting: "Transaction Number Settings",

        ppn_list: "PPN List",
        pph_list: "PPh Pasal 23/26 List",

        notification_list: "Notification List",
        notification_setting: "Notification Settings",
        find_and_match: "Find and Match",

        tag_list: "Tag List",
        transactions_list: "Transactions List",
        multi_items_adjustment: "Multi Items Adjustment",
        review_items_adjustment: "Review Items Adjustment",
        review_stock_conversion: "Review Stock Conversion",
        stock_conversion: "Stock Conversion",
        aging_expense_report: "Aging Expense Report"
    },
    Label: {
        information: "Information",
        change: "Change",
        year: "Year",
        for: "for",
        draft: "Draft",
        dasboard_draft: "Draft",
        quoted: "Quoted",
        declined: "Declined",
        accepted: "Accepted",
        invoiced: "Invoiced",
        approved: "Approved",
        voided: "Voided",
        repeating: "Repeating",
        none: "None",
        deleted: "Deleted",
        user: "User",

        find: "Find",

        number: "Number",
        contact: "Contact",
        exp_date: "Expiry Date",

        to_date: "To",
        from_date: "From",

        uploaded: "Uploaded",
        data: "Data",

        to: "To",
        name_repeating: "Name",
        name: "Name",
        from: "From",
        date: "Date",
        paid: "Paid",
        due: "Due",
        payee: "Payee",
        ytd: "YTD",
        spent: "Spent",
        received: "Received",
        paid_date: "Paid Date",
        due_date: "Due Date",
        reference: "Reference",
        amounts_are: "Amounts are",
        currency: "Currency",
        currency_rate: "Currency Rate",
        reporting_date: "Reporting Date",
        note: "Note",
        action: "Action",
        add_note: "Add Note",
        any_text_field: "Any Text Field",
        max_20_char: "Max. 20 characters",
        max_30_char: "Max. 30 characters",
        max_50_char: "Max. 50 characters",
        max_100_char: "Max. 100 characters",
        max_file: "Max. file size 2mb, up to 5 attachments",
        attachments: "Attachments",
        file_attachments: "file attached",
        subtotal: "Sub Total",
        discount: "Discount",
        withholding: "Withholding",
        withholding_acc: "Withholding Account",
        select_template: "Select Template",
        share: "Share",
        share_now_label: "Anyone with the link can view this",
        remain: "Remain",
        remaining: "Remaining",
        status: "Status",
        start_date: "Start Date",
        start_date_repeat: "Start Date",
        next_date: "Next Date",
        end_date: "End Date",
        end_date_repeat: "End Date",
        frequency: "Frequency",
        balance: "Balance",
        awaiting_for_approve: "Awaiting for approval",
        billed: "Billed",
        debit: "Debit",
        credit: "Credit",
        difference: "Difference",

        subject: "Subject",
        subject_email: "Subject Email",
        email_content: "Email Content",
        message: "Message",
        send_me_email: "Send a copy to my email",
        use_semicolon: "Use semicolon (;) to separate multiple email addresses",
        include: "Includes",
        cannot_undone: "This action cannot be undone.",

        credit_note: "Credit Note",
        terms: "Terms",

        // Account Setting
        change_profile: "Change Profile",
        upload_picture: "Upload Picture",
        cancel_this_change: "Cancel this change",
        resend_email_conf: "Resend email confirmation",
        check_your_email: "Check your email",
        confirm_new_address: "to confirm your new address. The change won't take effect until you confirm.",
        old_password: "Old Password",
        new_password: "New Password",
        confirm_new_password: "Confirm New Password",
        min_6_char: "Min 6 characters, including at least one number.",

        // Invoice
        new_invoice: "New Invoice",
        invoice_detail: "Invoice Details",
        invoice_number: "Invoice Number",
        invoice_terms: "Invoice Terms",
        invoice_include_pdf: "Include Invoice PDF",
        invoice_template: "Invoice Template",
        invoice_no_pdf: "No Invoice Attached",
        invoice_email_confirm: "Looks like your email doesn't include Quote PDF. Do you still want to send it?",
        invoice_void: "Do you want to void this invoice?",
        invoice_void_text: "When you void a Invoice, Harmony creates a reverse journal for this transaction. The Invoice will still be accessible under the Voided tab.",
        invoice_current_balance: "Current Invoice Balance",
        invoice_view_repeating: "View Repeating Invoice",
        invoice_edit_repeating: "Edit Repeating Invoice",
        invoice_amount_allocate: "Amount due of Invoice",
        new_sales: "New Sales",

        // Repeating
        setup: "Setup",
        scheduler: "Scheduler",
        edit_scheduler: "Edit Scheduler",
        set_scheduler: "Set Scheduler",
        no_schedule_found: "No schedule found",
        repeating_title: "Repeating Title",
        repeat: "Repeat",
        every: "Every",
        repeat_every: "Repeat Every",
        repeat_at_date: "Repeat At Date",
        at_date: "At Date",
        ends: "Ends",
        invoice_create_as: "Invoice Created As",
        bill_create_as: "Bill Created As",
        month_s: "Month(s)",
        by_the_end: "By the end of month",
        transaction: "Transaction(s)",
        warn_start_date: "Transaction Date is less than today. Please note that Harmony will create transactions for the dates that has passed.",
        repeating_expired_currency: "This transaction is set to inactive because the currency is not the same as base currency.",

        // Bill
        new_bill: "New Bill",
        bill_detail: "Bill Details",
        bill_number: "Bill Number",
        bill_terms: "Bill Terms",
        bill_include_pdf: "Include Bill PDF",
        bill_template: "Bill Template",
        bill_no_pdf: "No Bill Attached",
        bill_email_confirm: "Looks like your email doesn't include Quote PDF. Do you still want to send it?",
        bill_void: "Do you want to void this bill?",
        bill_void_text: "When you void a Bill, Harmony creates a reverse journal for this transaction. The Bill will still be accessible under the Voided tab.",
        bill_current_balance: "Current Bill Balance",
        bill_view_repeating: "View Repeating Bill",
        bill_edit_repeating: "Edit Repeating Bill",
        bill_amount_allocate: "Amount due of Bill",

        // Quote
        new_quote: "New Quote",
        quote_edit: "Edit Quote",
        quote_detail: "Quote Details",
        quote_number: "Quote Number",
        quote_terms: "Quote Terms",
        quote_include_pdf: "Include Quote PDF",
        quote_template: "Quote Template",
        quote_no_pdf: "No Quote Attached",
        quote_email_confirm: "Looks like your email doesn't include Quote PDF. Do you still want to send it?",
        quote_void: "Do you want to void this quote?",
        quote_void_text: "When you void a Quote, Harmony creates a reverse journal for this transaction. The Quote will still be accessible under the Voided tab.",

        // Purchase Order
        new_po: "New Purchase Order",
        new_address: "New Address",
        po_edit: "Edit Purchase Order",
        po_detail: "Purchase Order Details",
        po_number: "Purchase Order Number",
        po_terms: "Purchase Order Terms",
        delivery_address: "Delivery Address",
        delivery_date: "Delivery Date",
        no_address_found: "No Address Found",
        po_click_button: "Click button below to add address",
        po_include_pdf: "Include Purchase Order PDF",
        po_template: "Purchase Order Template",
        po_no_pdf: "No Purchase Order Attached",
        po_email_confirm: "Looks like your email doesn't include Quote PDF. Do you still want to send it?",
        po_void: "Do you want to void this Purchase Order?",
        po_void_text: "When you void a Purchase Order, Harmony creates a reverse journal for this transaction. The Purchase Order will still be accessible under the Voided tab.",

        // CN Sales
        new_cnsales: "New Sales Credit Note",
        cnsales_detail: "Sales Credit Note Details",
        cnsales_number: "Credit Note Number ",
        cnsales_void: "Do you want to void this Credit Note?",
        cnsales_void_text: "When you void a Credit Note, Harmony creates a reverse journal for this transaction. The Credit Note will still be accessible under the Voided tab.",

        // CN Purchase
        new_cnpurchase: "New Purchase Credit Note",
        cnpurchase_detail: "Purchase Credit Note Details",
        cnpurchase_number: "Credit Note Number ",
        cnpurchase_void: "Do you want to void this Credit Note?",
        cnpurchase_void_text: "When you void a Credit Note, Harmony creates a reverse journal for this transaction. The Credit Note will still be accessible under the Voided tab.",
        cnpurchase_edit: "Edit Purchase Credit Note",

        // Expense
        new_expense: "New Expense",
        new_claim: "New Claims",
        new_expense_claim: "New Expense Claim",
        edit_expense: "Edit Expense Claim",
        expense_detail: "Expense Claim Detail",
        claim_number: "Claim Number",
        awaiting_for_revise: "Awaiting for Revise",
        awaiting_for_approval: "Awaiting for Approval",
        awaiting_for_payment_expense: "Awaiting for Payment",
        waiting_for_approval: "Waiting for Approval",
        expense_void: "Do you want to void this Expense?",
        expense_void_text: "When you void a Claim, Harmony creates a reverse journal for this transaction. The Expense Claim will still be accessible under the Voided tab.",
        expense_confirm_void: "Are you sure you want to void this claim? This action cannot be undone.",
        payment_due_date: "Payment Due Date",
        no_expense_found: "No Expenses found",

        // Journal
        new_journal: "New Journal",
        new_journal_manual: "New Manual Journal",
        new_journal_repeat: "New Repeating Journal",
        edit_journal_manual: "Edit Manual Journal",
        edit_journal_repeat: "Edit Repeating Journal",
        view_journal: "View Journal",
        view_journal_repeat: "View Repeating Journal",
        journal_detail: "Journal Details",
        journal: "Journal",
        journal_name: "Journal Name",
        journal_manual: "Manual Journal",
        journal_repeating: "Repeating Journal",
        journal_name_text: "A short title for this transaction, up to 100 characters",
        journal_delete: "Do you want to delete this Journal?",

        // Inventory
        item: "Item",
        desc: "Description",
        description: "Description",
        qty: "QTY",
        quantity: "Quantity",
        unit: "Unit",
        unit_price: "Unit Price",
        disc: "Disc",
        account: "Account",
        tax_rate: "Tax Rate",
        amount: "Amount",
        amount_by: "Amount By",
        amount_item: "Amount",
        amount_to: "Amount to",
        amount_from: "Amount from",
        tracked: "Tracked",
        untracked: "Untracked",
        item_name: "Item Name",
        item_unit: "Item Unit",
        item_type: "Item Type",
        item_code: "Item Code",
        unit_type: "Unit Type",
        set_status: "Set Status",
        track_this_item: "Track this item",
        track_text: "By turning on 'Track Inventory' Harmony will monitor the quantity and value of your item, and prevent sales when the quantity is zero.",
        track_note: "Note : This option can not be changed once you have recorded transactions with this item.",
        untrack_text: "This item can't be untracked because it is associated with tracked transactions.",
        purchase_this_item: "I purchase this item",
        sell_this_item: "I sell this item",
        inventory_account: "Inventory Account",
        buy_unit_price: "Buy Unit Price",
        purchase_desc: "Purchase Description (for suppliers)",
        sales_unit_price: "Sales Unit Price",
        sales_desc: "Sales Description (for customers)",
        code: "Code",
        click: "Click",
        here: "here",
        track_item: "to track this item",
        create_new_inven: "Create New Inventory",
        inventory_details: "Inventory Details",
        inventory_purchase_details: "Purchase Details",
        inventory_sell_details: "Sell Details",
        purchase_account: "Purchase Account",
        cost_of_sales_account: "Cost of Sales Account",
        sales_account: "Sales Account",
        inventory_recent_transaction: "Inventory Recent Transactions",
        showing_20: "Showing only the latest 20 transactions",
        step_1_of_two: "Step 1 of 2 (Preparations)",
        step_2_of_two: "Step 2 of 2 (Review Data)",
        new_item_inven: "New Item",
        duplicate: "Duplicate",
        invalid: "Invalid",
        notes: "Notes",
        buy_tax_rate: "Buy tax rate",
        buy_account: "Buy Account",
        sell_price: "Sell price",
        sell_tax_rate: "Sell tax rate",
        sell_account: "Sell account",
        show_error_only: "Show errors only",
        inventory_avg_cost: "Average Cost",
        item_s: "item (s)",
        adjustment_type: "Adjustment Type",
        adjustment_date: "Adjustment Date",
        adjustment_details: "Adjustment Details",
        qty_today: "Qty Today",
        new_cost_perunit: "New Cost per Unit",
        total_value: "Total Value",
        value_of_adj: "Value of adjustment",
        adjustment_account: "Adjustment Account",
        new_qty_onhand: "New Qty on Hand",
        cost_price: "Cost Price",
        adjustment: "Adjustment",
        view_inventory_adj: "View Inventory Adjustment",
        transaction_date: "Transaction Date",
        inventory_adj_details: "Inventory Adjustment Details",
        adj_revaluation: "Adj Revaluation",
        adj_decrease: "Adj Decrease Qty",
        adj_increase: "Adj Increase Qty",
        manufactured_goods: "Manufactured Goods",
        inven_tracking_account: "Inventory Tracking Account",
        cogs_account: "COGS Account",
        select_cost_account: "Select Cost Account",
        group_item_name: "Group Item Name",
        qty_available_for_sale: "Qty Available for Sale",
        conversion_details: "Conversion Details",
        additional_cost: "Additional Cost",
        unit_sale_price: "Unit Sale Price",
        current_cost: "Current Cost",
        actual_amount: "Actual Amount",
        actual_qty: "Actual Qty",
        multiplier: "Multiplier",

        // Inventory header table
        inventory_name: "Name",
        inventory_code: "Code",
        inventory_type: "Type",
        inventory_qty_on_hand: "Qty On Hand",
        inventory_buy_price: "Buy price",
        inventory_sale_price: "Sale price",
        inventory_status: "Status",

        // Inventory tab item
        all_item: "All items",
        active_item: "Active",
        inactive_item: "Inactive",
        inventory_confirm_delete: "Are you sure want to delete this item?",
        contact_confirm_delete: "Are you sure want to delete this contact?",

        // Asset
        new_asset: "New Asset",
        regis_asset: "Register Asset",
        disposed: "Disposed",
        disposed_asset: "Disposed",
        registered: "Registered",
        type: "Type",
        purchase_date: "Purchase Date",
        purchase_price: "Purchase Price",
        book_value: "Book Value",
        disposal_date: "Disposal Date",
        disposal_price: "Disposal Price",
        gain_loss: "Gains/Losses",
        last_depre: "Last Depreciation",
        asset_name: "Asset Name",
        asset_type: "Asset Type",
        asset_code: "Asset Code",
        warranty_exp: "Warranty Expired",
        warranty_exp_date: "Warranty Expired Date",
        serial_number: "Serial Number",
        asset_account: "Asset Account",
        asset_status: "Asset Status",
        accumulated_depre: "Accumulated Depreciation Account",
        depre_expense: "Depreciation Expense Account",
        depre_expenses: "Depreciation Expense",
        depre_start_date: "Depreciation Start Date",
        show_more_opt: "Show more options",
        cost_limit: "Cost Limit",
        residual_value: "Residual Value",
        depreciation_method: "Depreciation Method",
        rate_effective: "Rate / Effective Life (Years)",
        rate_effective_value: "Value Rate / Effective Life (Years)",
        depreciation_text: "The Depreciation Start Date for this asset is before the Fixed Assets Start Date:",
        accumulated_depre_text: "Accumulated Depreciation as at",
        book_value_text: "Book Value as at",
        asset_view_text: "Note : Editing Book Value details will affect Depreciation that has already been made. If you wish to continue, all depreciation for this asset will be reversed and recalculated with the new details.",
        notif_create_asset: "Before starting, please create an Asset Type.",
        asset_please_set: "Before starting, please set your",
        fix_asset_start_date: "Fixed Assets Start Date",
        asset_delete: "Do you want to delete this Asset?",
        rollback_depre: "Rollback Depreciation",
        review_depre: "Review Depreciation",
        rollback: "Rollback",
        rollback_to: "Rollback To",
        view_book_value: "View Book Value",
        asset_regis_success: "{0} Asset(s) registered successfully",
        asset_regis_failed: "{0} Asset(s) register failed",

        // Fixed Asset
        new_asset_type: "New Asset Type",
        fixed_asset_detail: "Fixed Assets Details",
        import_fix_asset: "Import Fixed Assets",
        fixed_asset_setting: "Fixed Assets Settings",
        change_start_date: "Change start date",
        disposal_acc_setting: "Disposal Accounts Settings",
        acc_setting_text1: "Fixed Assets Start Date is the first day of the financial year when Harmony start running depreciation.",
        acc_setting_text2: "Set your default accounts for Disposals. All future Disposal transactions will have these options pre-selected.",
        gain_disposal: "Gain on Disposal",
        loss_disposal: "Loss on Disposal",
        change_date_text1: "If you click Save, the following will happen",
        change_date_text2: "All depreciation and disposal journals will be reversed.",
        change_date_text3: "Start Date will change {0} to the date you select below.",
        change_date_text4: "You will need to re-enter the accumulated depreciation for any assets purchased before the new start date.",
        change_date_text5: "If you've set a lock date in Financial Settings, some start dates may be unavailable.",
        rollback_text1: "All depreciation in the Rollback period will be reversed.",
        rollback_text2: "Any registered assets will remain registered.",
        rollback_text3: "Any disposed assets will remain disposed.",
        dispose_asset: "Dispose Asset",
        date_dispose: "Date Disposed",
        sale_proceed: "Sale Proceeds",
        sale_proceed_acc: "Sale Proceeds Account",
        depre_this_year: "Depreciation for this financial year",
        cost: "Cost",
        current_accumulated_depre: "Current Accumulated Depreciation",
        depre_post: "Depreciation to be posted",
        accumulated_summary: "Acummulated summary",
        capital_gain: "Capital gain",
        revers_to_post: "Reversal of depreciation",
        disposal_summary: "Disposal Summary",
        book: "Book",
        book_depre_default: "Book Depreciation Default",
        disposal_journal: "Disposal Journal",
        review_disposal_journal: "Review Disposal Journal",

        undo_disposal_text1: "If you wish to undo disposal, the following will happen",
        undo_disposal_text2: "Asset {0} will be restored to Registered.",
        undo_disposal_text3: "All disposal journals created for this asset will be reversed.",
        undo_disposal_text4: "Depreciation will be run up to current depreciation date.",

        // General
        view_detail: "Lihat Rincian",
        detail: "Rincian",
        show: "Tampilkan",
        data_per_page: "data per halaman",
        total_item: "total data",
        void_draft: "Apakah anda yakin ingin menghapus draft ini? Tindakan ini tidak dapat dibatalkan.",
        tax: "Pajak",
        delete_trans: "Hapus Transaksi",
        cant_undo: "Tindakan ini tidak dapat dibatalkan.",
        confirm_delete_trans: "Apakah anda ingin menghapus transaksi ini?",
        confirm_delete_recur: "Apakah anda yakin ingin menghapus ini?",
        no_file_selected: "Tidak ada file",

        // Dashboard
        go_to_dashboard: "Go to Dashboard",
        cash_flow: "Cash Flow",
        cash_in: "Cash In",
        cash_out: "Cash Out",
        invoices: "Invoices",
        bills: "Bills",
        no_cash_flow_found: "No Cash Flow found",
        no_acc_watchlist_found: "No Account Watchlist found",
        awaiting_for_payment: "Awaiting for payment",
        overdue: "Overdue",
        paid_in: "Paid in last 30 days",
        expenses: "Expenses",
        total_expenses: "Total Expenses",
        others: "Others",
        account_watchlist: "Account Watchlist",
        profit_loss: "Profit & Loss",
        income: "Income",
        net_income: "Net Income",
        last_updated: "Last updated",
        bank_balance: "Bank Balance",
        balance_harmony: "Balance in Harmony",
        net_income_for: "Net Income for",
        click_new_acc: "Click New Account Button",
        start_track: "to start tracking the balance of your bank",
        few_seconds: "a few seconds ago",
        a_minute: "a minute ago",
        an_hour: "an hour ago",
        minute_ago: "minutes ago",
        hour_ago: "hours ago",

        // Notes
        history_note: "History & Notes",

        // Contact
        new_contacts: "New Contacts",
        new_contact: "New Contact",
        edit_contact: "Edit Contact",
        view_contact: "View Contact",
        contact_has_allocate: "This contact has credit to allocate",
        allocate_to: "Credit allocation to",
        allocate_detail: "Allocation details",
        remaining_credit: "Remaining credit",
        amount_allocate: "Amount to allocate",
        amount_due: "Amount due of",
        remaining_due: "Remaining",
        allocate_credit: "Allocate credit",
        allocate_more_remain: "Amount to allocate cannot be more than remaining credit",
        total_amount_allocate: "Total Amount to allocate",

        email: "Email",
        phone: "Phone",
        you_owe_them: "You Owe Them",
        they_owe_you: "They Owe You",

        contact_info: "Contact Information",
        all_contact: "All contacts",
        customers: "Customers",
        suppliers: "Suppliers",
        employee: "Employee",
        archieved: "Archived",

        customer: "Customer",
        supplier: "Supplier",

        customer_type: "Customer",
        supplier_type: "Supplier",
        employee_type: "Employee",


        contact_detail: "Contact Detail",
        contact_details: "Contact Details",
        contact_name: "Contact Name",
        contact_code: "Contact Code",
        contact_code_new: "Contact Code (optional)",
        contact_type: "Contact Type",
        contact_max_30: "This field may contain up to 30 characters",
        website: "Website",
        website_text: "Add http:// or https:// before the url of the webpage",
        contact_status_text: "Set the status for this contact",
        custom_code: "Custom Code",

        contact_person: "Contact person (you can add up to 5 contact people)",
        first_name: "First Name",
        last_name: "Last Name",
        mobile: "Mobile",
        fax: "Fax",
        phone_number: "Phone Number",

        address: "Address",
        billing_address: "Billing Address",
        shipping_address: "Shipping Address",
        attention: "Attention",
        country: "Country",
        city: "City",
        state: "State",
        postal_code: "Postal Code",

        bank_account: "Bank Account",
        bank: "Bank",
        bank_account_number: "Bank Account Number",
        bank_account_name: "Bank Account Name",
        bank_name_account: "Bank Account Name",

        custom_default: "Custom Defaults",
        account_receivable: "Account Receivable",
        account_payable: "Account Payable",
        bill_due_date: "Bill Due Date",
        invoice_due_date: "Invoice Due Date",

        add_5_contact: "You can add up to 5 contact people",
        copy_warn: "Are you sure want to copy this contact as",

        copy_to_x: "Copy to {0}",
        x_x_x: "{0}, {1}, {2}",
        x_and_x: "{0}, {1}",
        x_only: "{0}",

        contact_recent_trans: "Contact Recent Transaction",
        transaction_number: "Transaction Number",

        transactions: "Transactions",
        statement_type: "Statement Type",
        transaction_type: "Transaction Type",
        date_range: "Date Range",
        balance_in: "Balance in",
        details: "Details",
        payments: "Payments",
        opening_balance: "Opening Balance",
        total_balance: "Total Balance",
        from_range: "From Date",
        to_range: "To Range",
        activity: "Activity",
        outstanding: "Outstanding",
        customer_statement: "Customer Statement",

        attention_billing: "Attention Billing",
        address_billing: "Address Billing",
        country_billing: "Country Billing",
        city_billing: "City Billing",
        state_billing: "State Billing",
        postal_code_billing: "Postal Code Billing",
        attention_shipping: "Attention Shipping",
        address_shipping: "Address Shipping",
        country_shipping: "Country Shipping",
        city_shipping: "City Shipping",
        state_shipping: "State Shipping",
        postal_code_shipping: "Postal Code Shipping",

        account_receivable_code: "Account Receivable Code",
        account_payable_code: "Account Payable Code",
        bill_due_date_term: "Bill Due Date Term",
        invoice_due_date_term: "Invoice Due Date Term",

        learn_more: "Learn More",
        import_text_1: "Please refer to the table below for more information about how to insert data into the template Harmony provided.",
        import_text_2: "If you are still having trouble with importing your data, please contact",
        our_support: "our support",

        column: "Column",
        format_entry_detail: "Format Entry Details",

        desc_contact_type: "Contact's type",
        desc_contact_email: "Contact's email",
        desc_contact_phone: "Contact's phone number",
        desc_contact_receivable: "Default account for Sales transactions",
        desc_contact_payable: "Default account for Purchase transactions",
        desc_contact_bill_duedate: "Default bill payment term (due date or days after)",
        desc_contact_bill_term: "Default Bill payment term",
        desc_contact_invoice_duedate: "Default invoice payment term (due date or days after)",
        desc_contact_invoice_term: "Default invoice payment term",

        format_contact_type: "Enter the type with the following format: customer only, supplier only, employee only, customer & supplier.",
        format_contact_email: "Enter with email address format e.g.: contact@email.com",
        format_contact_phone: "Enter with numbers and symbols only. Letter input will result in Invalid Data. E.g.: 02182403713 will become +622182403713",
        format_contact_receivable: "Use the Account Code from your list of COA in Harmony to enter into the appropriate column.",
        format_contact_bill_duedate: "Enter the days number, and make sure to also enter for the Bill DueDate Term column.",
        format_contact_bill_term: "Enter the number corresponding each term:",
        format_contact_bill_term1: "for Date of the following month",
        format_contact_bill_term2: "for Days after the transaction date",
        format_contact_bill_term3: "for Days after the transaction month",
        format_contact_bill_term4: "for Date of the current month",
        format_contact_invoice_duedate: "Enter the days number, and make sure to also enter for the Invoice DueDate Term column.",

        msg_bill_duedate: "Please fill in Bill Due Date with number format.",
        msg_invoice_duedate: "Please fill in Invoice Due Date with number format.",
        msg_bill_term: "Please choose the appropriate Terms for Bill Due Date.",
        msg_invoice_term: "Please choose the appropriate Terms for Invoice Due Date.",

        delete_person: "Cannot delete row, you must have at least 1 person",
        confirm_delete_person: "Do you want to delete this person?",
        no_contact_people: "You don't have contact people",
        no_contact_address: "You don't have contact address",
        no_contact_bank: "You don't have contact bank",

        view_statement: "View Statement",
        as_at: "As at ",
        contact_s: "contact (s)",
        invoice_s: "invoice (s)",

        // Payment
        gain: "Gain",
        loss: "Loss",
        rate: "Rate",
        amount_paid: "Amount Paid",
        date_paid: "Date Paid",
        transaction_info: "Transaction Info",
        select_method: "Select Method",
        exchange_rate: "Exchange Rate",
        set_exchange: "Set Exchange",
        set_exchange_rate: "Set exchange rate",
        exchange_rate_text: "The rate only applies to this transaction. If you want to change the rate for all transactions,",
        goto_currency: "go to currency settings.",
        apply_from_setting: "Rate Applied from Settings",
        custom_rate: "Custom Rate",
        paid_to: "Paid To",
        paid_from: "Paid From",
        payment_invoice: "Payment Invoice",
        payment_bill: "Payment Bill",
        payment_expense: "Payment Expense",
        refund_cnsales: "Refund Credit Note Sales",
        refund_cnpurchase: "Refund Credit Note Purchase",
        discount_include: "Discount Include",
        original_rate: "Original Rate",
        rate_from: "Rate From",
        text_exchange_repeat: "Exchange rate (when available) will be set on {0} creation date.",
        updated_by: "Updated by",
        on: "on",

        choose_file_text: "Choose files from your library related to this transaction as supporting documents. You may choose up to 5 files.",
        no_file_yet: "There’s no files yet.",
        goto: "Go to",
        library: "Library",
        add_files: "to add files",
        file_name: "File Name",
        payee_import: "payee",
        data_s: "data (s)",
        new_data: "New Data",

        // Cash & Bank
        from_account: "From Account",
        new_cash_bank: "New Cash & Bank",
        filter_by: "Filter By",
        click_new_cash_bank: "Click New Cash & Bank Button",
        to_start_tracking: "to start tracking the balance of your bank",

        account_name: "Account Name",
        bank_statement: "Bank Statement",
        account_transaction: "Account Transactions",
        auto_feed: "Auto Feeds",
        transfer_money: "Transfer Money",
        transfer_money_detail: "Transfer Money Details",
        smart_rule: "Smart Rule",
        smart_rules: "Smart Rules",
        link_one_feed: "Link OneFeed",

        new_item: "new items",
        linked_to: "Linked to",

        view_bank_account: "View Bank Account",
        reconcilation: "Reconcilation",
        cash_coding: "Cash Coding",
        spend_money: "Spend Money",
        receive_money: "Receive Money",

        last_sync: "Last sync",

        reconcile: "Reconcile",
        source: "Source",

        unreconciled: "Unreconciled",
        unreconciled_status: "Unreconciled",
        reconciled: "Reconciled",
        system: "System",
        import: "Imported",
        onefeed: "OneFeed",
        spend: "Spend",
        receive: "Receive",
        new_transaction: "New Transaction",
        manage_bank: "Manage Bank",
        import_bank_statement: "Import Bank Statement",
        view_cash_bank: "View Cash & Bank",

        from_bank_statement: "From your Bank Statements",
        from_harmony_transaction: "From your Harmony Transactions",
        new_receive_money: "New Receive Money",
        receive_money_detail: "Receive Money Detail",
        receive_number: "Receive Number",
        new_spend_money: "New Spend Money",
        edit_receive_money: "Edit Receive Money",
        edit_spend_money: "Edit Spend Money",
        spend_money_detail: "Spend Money Details",
        spent_money_detail: "Spent Money Details",
        spend_number: "Spend Number",
        spent_number: "Spent Number",

        enter_transfer_detail: "Enter Transfer Details",
        from_bank: "From Bank",
        to_bank: "To Bank",
        transfer_date: "Transfer Date",

        error_same_account: "Cannot transfer to the same account as the source",

        void_trans_confirmation: "Do you want to delete this transaction",
        unreconcile_confirmation: "Are you sure you want to unreconcile this transaction?",
        unreconcile_note: "Note: both the statement line and the bank transaction will remain in your record.",
        reconcile_confirmation: "Are you sure you want to reconcile this transaction?",
        reconcile_note: "Note: It is recommended that you only set as reconciled when you cannot import the bank statement data.",

        set_reconcile: "Set as reconciled",
        set_unreconcile: "Set as unreconciled",

        transfer_from: "Transfer From",
        transfer_to: "Transfer To",

        unreconcile_bank_statement: "Are you sure you want to unreconcile this Bank Statement",
        delete_bank_statement: "Do you want to delete Bank statement?",
        delete_statement_line: "Are you sure you want to Delete this statement line(s)?",
        delete_statement_line_advise: "We advise you only do this to remove duplicate bank statement line(s).",
        delete_statement_line_group_advise: "Deleting this line(s) will completely remove it from your list of Statement Lines",

        cannot_find_payee: "Cannot find that Payee name in Contact. Please create new Contact first.",
        reconcile_confirm: "Are you sure want to reconcile?",
        item_selected: "items selected",
        items_selected: "Item(s) Selected",

        account_detail: "Account Details",
        view_account_detail: "View Account Details",
        edit_account_detail: "Edit Account Details",
        new_account_detail: "New Account Details",
        link_bank_to: "Linked Bank to",
        account_code: "Account Code",
        openning_balance_info: "Opening Balance Info",
        setup_conversion_warn: "You don`t appear to have set up conversion balance yet",
        click_here_to_setup: "Click here to set up",
        total_inbase_currency: "Total In Base Currency",

        account_information_detail: "Account Details Information",
        account_information: "Account Information",
        bank_desc: "Bank Description",
        unique_50_char: "A unique code/number for this account, up to 50 characters",
        delete_account_confirm: "Are you sure want to delete this account?",
        remove_account_code_text1: "This action will also remove the Account Code",
        remove_account_code_text2: "which is connected to this Bank Account.",

        restore: "Restore",
        restore_delete: "Restore Deleted Data?",

        has_been_transferred: "has been transferred successfully",
        reconciliation_adjustment: "Reconciliation Adjustment",
        trans_name: "Transaction Name",
        statement_date: "Statement Date",
        statement_lines: "Statement Lines",
        statement_group: "Statement Group",
        imported_date: "Imported Date",
        beginning_date: "Beginning Date",
        ending_date: "Ending Date",
        total_received: "Total Received",
        total_spending: "Total Spending",

        // Find and Match
        adjustment_required: "Adjustment Account is required",
        select_transaction: "Please select transaction",
        payment_mt_balance: "Payment cannot be more than balance",
        find_select_match: "Find & Select Matching Transactions",
        find_select_transaction: "Find & Select Your Transactions",
        selected_transaction: "Selected Transactions",
        review_reconc: "Review Your Reconciliation",
        match_money: "Must Match : Money",
        matched_with_rule: "Matched with rule",
        set_contact: "Set Contact",
        set_reference: "Set Reference",
        no_possible_match: "No possible match for this statement",
        click_here_for: "Click here for",
        new_spending_rule: "new spending rule",
        new_receive_rule: "new receive rule",
        or: "or",
        create_partial_payment: "Create partial payment for this transaction.",
        partial_payment: "Partial Payment",
        remaining_balance: "Remaining Balance",

        // Reconcile
        chart_account: "Chart of Account",
        delete_payment: "Delete Payment",
        delete_statement: "Delete Statement Line",
        match: "Match",
        all_bank_account: "All Bank Accounts",
        rule_title: "Rule Title",
        set_status_recon: "Set Status",
        existing_new_contact: "Existing or New Contact",
        set_during_rec: "Set During Reconciliation",
        copy_from_ref: "Copy from Reference",
        copy_from_payee: "Copy from Payee",
        money_spent_statement: "When money spent on the bank statement matches",
        money_type_statement: "When money {0} on the bank statement matches",
        of_the_following: "of the following conditions",
        set_contact_recon: "Set contact",
        set_reference_recon: "Set the reference",
        percentage: "Percentage",
        auto_allocate: "Automatically allocate the money and account to",
        fixed_value: "Fixed Value",
        value: "Value",
        auto_remaining: "Allocate remaining value with the following percentage",
        apply_cond: "Please apply a condition for this rule",
        total_precentage_must: "Total percentage must be 100%",
        add_condition: "Add Condition",
        add_new_line: "Add New Line",

        // Smart Rules
        new_spend_rule: "New Spend Rule",
        new_receiv_rule: "New Receive Rule",
        delete_bank_rule: "Delete Bank Rule",
        delete_rule: "Delete Rule",
        delete_rule_confirmation: "Are you sure you want to delete this rule?",
        change_status_to: "Change status to",
        order: "Order",
        new_rule: "New Rule",
        equals: "Equals",
        contains: "Contains",
        starts_with: "Starts With",
        is_blank: "Is Blank",
        greater_than: "More Than",
        greater_than_or_equals: "More Than Or Equals",
        less_than: "Less Than",
        less_than_or_equals: "Less Than Or Equals",

        // OneFeed
        onefeed_setting: "OneFeed Settings",
        pause_onefeed: "Pause OneFeed",
        resume_onefeed: "Resume OneFeed",
        is_running: " is running",
        is_paused: " is paused",
        unlink_onefeed: "Unlink OneFeed",
        keep_onefeed: "No, keep OneFeed",
        continue_unlink: "Continue unlink",
        unlink_onefeed_confirmation: "Are you sure you want to stop linking with OneFeed?",
        unlink_note: "Once you unlink, you will not be able to receive your bank account transaction feeds anymore. You will need to re-link and provide your credentials again if you want to link to OneFeed in the future.",
        connect_and_manage: "Connect and manage your bank account seamlessly by ",
        link_to_onefeed: "Link to OneFeed",
        pause_onefeed_text1: "Pause OneFeed to stop the auto feeds link at midnight (You can still sync your feeds manually anytime you want).",
        pause_onefeed_text2: "Unlink OneFeed to completely stop using OneFeed",
        link_onefeed: "Link to OneFeed",
        sync_success: "Syncing Successful",
        sync_fail: "Sync Failed",
        sync_now: "Sync Now",
        authentication_failed: "Authentication Failed",

        message_301: "Unable to connect with OneFeed. Please update your credentials.",
        message_302: "OneFeed is under maintenance, please try again later or contact our support.",

        new_trans_sync: "new transactions synced",
        get_transaction_bank: "Hold on while we are getting your bank transactions",

        step_1: "Step 1 of 2 (Connect Bank Account)",
        bank_name: "Bank Name",
        important_notes: "Important Notes",
        step_1_text1: "Before you continue, it is advisable to make sure that",
        the_bank_statement: "the Bank Statement balance",
        and: "and",
        account_trans_balance: "Account Transaction balance",
        are_equal: "are equal.",
        step_1_text2: "OneFeed will only import new transactions coming in after connection with OneFeed succeeded.",

        step_1_text3: "Harmony will connect to your bank account for automatic bank feeds with your permission. You can pause or stop getting automatic bank feeds anytime you want.",
        step_1_text4: "The synchronization occurs once a day",
        at: "at",
        midnight: "midnight.",

        searching_bank_acc: "Searching your bank account",

        acc_number: "Acc. Number",
        bank_provider: "Bank Provider",
        connect_to: "Connect To",
        cash_bank_acc: "Your Cash & Bank Account in Harmony",
        onefeed_balance: "OneFeed Balance",
        onefeed_bank_statement: "Bank Statement",
        acc_transaction: "Account Transaction",

        user_id: "User ID",
        password: "Password",
        confirm_password: "Confirm Password",
        account_number: "Account Number",
        va_number: "Virtual Account Number",

        step_2: "Step 2 of 2 (Connect Bank Account)",
        found_bank_acc: "We found your Bank Account!",
        connected_account: "Connect to your preferred account, and click Continue to begin linking your Bank Account to Harmony",
        proceed_link: "Proceed Linking",
        found_error: "Found {0} error(s).",
        here_some: "Here is some",

        summary: "Summary",
        success_connect: "Succesfully connected to OneFeed!",
        summary_text: "Here is the summary of your OneFeed",
        go_cash_bank: "Go to Cash & Bank",
        select_account_link: "Please select the account you wish to link",
        cancel_linking: "Cancel Linking",
        keep_my_data: "No, Keep My Data",
        cancel_linking_bank: "Are you sure want to cancel linking your bank account?",
        relinking_bank: "You will need relinking your bank account if you cancel this step. This action cannot be undone",

        sync_with_onefeed: "Syncing with OneFeed",

        onefeed_unlinked: "OneFeed has been unlinked.",

        problem: "Problem",
        solution: "Solution",
        onefeed_solution_title: "Here are some solutions for you",
        onefeed_solution_text1: "The discrepancies you are seeing may result from the following conditions",
        onefeed_solution_text2: "Your Account Transactions and Bank Statement balances are not equal.",
        onefeed_solution_text3: "Manually Import your Bank Statement CSV and proceed with reconciliation to match the balances.",
        onefeed_solution_text4: "Your Account Transactions and Bank Statement balances are equal, but not equal to the OneFeed balance.",
        onefeed_solution_text5: "Manually Import your Bank Statement CSV up to the current balance in OneFeed.",
        onefeed_solution_text6: "There are no balances yet on your Account Transactions.",
        onefeed_solution_text7: "Create transactions in the Cash & Bank account to record the current OneFeed balance as opening balance.",
        onefeed_solution_text8: "If you are still having trouble with your Cash & Bank balance, please contact our support.",

        uploading: "Uploading",
        file: "Files",

        // Chart of Account
        assets: "Assets",
        liabilities: "Liabilities",
        equity: "Equity",
        revenue: "Revenue",
        coa_expenses: "Expenses",

        account_type: "Account Type",
        create_cash_bank: "Create Cash & Bank Accounts",
        coa_set_status: "Set the status for this account",
        coa_desc_acc: "A description of how this account should be used",
        acc_max_char_50: "A short title for this account, up to 50 characters",
        parent_account: "Parent Account",
        coa_cant_delete: "This account cannot be deleted because it is used in transactions or related to settings.",
        what_does_it_mean: "What does it means?",
        coa_cant_unactive: "The selected account already had a transaction and you are unable to deactivate.",
        change_not_permitted: "Change status not permitted",
        balance_ytd: "Balance YTD",
        no_amount: "No Amount",
        delete_this_account: "Delete this account?",
        unable_to_recover: "You will lose all and unable to recover the data after this action",

        not_connected_cashbank: "This account is not connected to Cash & Bank",
        connect_cash_bank: "Connect to Cash & Bank",
        coa_connect_text1: "It is important to connect your account, to ease your process by using our Reconciliation and OneFeed synchronization feature.",
        coa_connect_text2: "If this account has existing balance, it will automatically matched with the Cash & Bank’s balance.",

        this_is_parent_account: "This account is a Parent Account",

        // Company Setting
        company_name: "Company Name",
        company_info: "Company Info",
        company_display_name: "Company Display Name",
        company_legal_name: "Company Legal Name",
        organization_type: "Organization Type",
        industry_type: "Industry Type",
        timezone_setting: "Timezone Settings",
        timezone: "Timezone",
        upload_logo: "Upload Logo",
        financial_setting: "Financial Settings",
        inventory_method: "Inventory Method",
        tax_regis_number: "Tax Registration Number",
        basic_information: "Basic Information",
        addresses: "Addresses",
        delete_address_confirm: "Do you want to delete this Address?",
        set_as_default: "Set As Default",
        address_name: "Address Name",
        region_state: "Region/State",
        town_city: "Town/City",
        street_address: "Street Address",
        zip_code: "Zip Code",

        new_bank: "New Bank",
        bank_account_detail: "Bank Account Details",
        delete_bank_confirm: "Do you want to delete this Bank Details?",
        swift_code: "Swift Code",
        bank_branch: "Bank Branch",
        bank_address: "Bank Address",

        no_bank_found: "No Bank Account Details Found",
        bank_click_button: "Click button below to add Bank Account Details",

        // History Business
        select_types: "Select Menu",
        activity_from: "Activity From",
        items: "Items",
        business_menu: "Menu",
        date_and_time: "Date & Time",
        check_date_filter: "Please check your Date filter.",

        // Tax
        tax_name: "Tax Name",
        set_as_withholding: "Set as Withholding",
        set_status_tax: "Set the status for this tax",

        // Unit List
        unit_code: "Unit Code",
        unit_name: "Unit Name",
        unique_code: "A unique code/number for this unit, up to 50 characters",
        short_title_unit: "A short title for this unit, up to 50 characters",
        set_status_unit: "Set the status for this unit",

        // Lock Date
        lock_date_desc: "Lock dates stop data from being changed for a specific period. You can change these at any time",

        // Conversion Balance
        conversion_date: "Conversion Date",
        conversion_date_conv: "Conversion Date",
        please_set_conversion: "Please set conversion date",
        save_data_confirm: "Do you want to save this data?",
        search_result_for: "Search result for",
        total_debit_credit: "Total Debit and Credit must be balance",
        adjustment_needed: "adjustment needed",
        conversion_date_change_confirm: "Conversion Date has been changed. Please confirm the balances below and click the Save button to continue.",
        choose_conversion_date: "Please choose Conversion Date before you continue.",
        conversion_date_text1: "Please enter the migration date, or the date you start creating transactions using Harmony. It's easiest when you set your conversion date at the beginning of the month.",
        conversion_date_text2: "For this conversion date, you need to enter the conversion balances (also known as Opening Balances) on",

        // COA Mapping
        choose_default_account: "Choose the default account for each of the transaction below",
        invoice_account: "Invoice Account",
        bill_account: "Bill Account",
        expense_claim_account: "Expense Claims Account",
        sales_discount_account: "Sales Discount Account",
        purchase_discount_account: "Purchase Discount Account",
        gain_loss_account: "Gain/Loss on Foreign Exchange Account",
        gain_loss_acc: "Gain/Loss Account",
        net_profit_acc: "Retained Earnings Account",
        net_profit_source: "Retained Earnings",

        // Transaction Number
        transactio_number_desc: "Enter your own transaction numbering format to be applied on your organization's documents. You can enter either a Prefix, a Suffix or use both for your transactions numbering. Harmony will auto increment the transaction number with every new transactions.",
        prefix: "Prefix",
        suffix: "Suffix",
        starting_number: "Starting Number",
        sales_credit_note: "Sales Credit Note",
        purchase_credit_note: "Purchase Credit Note",
        invoice_starting_number: "Invoice Starting Number",
        bill_starting_number: "Bill Starting Number",
        quote_starting_number: "Quote Starting Number",
        po_starting_number: "Purchase Order Starting Number",
        sales_starting_number: "Sales Credit Note Starting Number",
        purchase_starting_number: "Purchase Credit Note Starting Number",
        expense_starting_number: "Expense Starting Number",
        spend_starting_number: "Spend Money Starting Number",
        receive_starting_number: "Receive Money Starting Number",
        recommended_size: "Recommended size 2mb.",
        file_type: "File type must be .png or .jpg",

        create_and_new: "Create & New",

        csv_only: "csv only",

        empty_card_info: "Harmony will charge your Credit Card for your current billing and your subsequent recurring billing.",

        //pop-up login
        hey: "Hey",
        your_session_has_expired: "your session has expired.",
        enter_password_to_resume: "Enter password to resume using Harmony",
        back_to_login_page: "Back to login page",

        //inventory grouping item

        single_item: "Single Item",
        group_item: "Group Item",
        new_group_item: "New Group Item",
        group_details: "Group Details",
        group_name: "Group Name",
        group_code: "Group Code",
        items_price: "Items Price",
        set_price: "Set Price",
        inventory_unit: "Inventory Unit",
        total_cost: "Total Cost",
        select_tracked_item: "Select Tracked Items",

        //notification
        select_all: "Select All",
        end_of_month: "By the end of month",

        tag_list: "Tag List",
        tag_name: "Tag Name",
        category: "Category",
        category_optional: "Category (optional)",
        transaction_count: "Transaction Count",
        view_tag: "Tag",
        new_tag: "New Tag",
        delete_tag: "Delete Tag",
        show_spent_trx: "Show Spent Transaction Only",
        show_receive_trx: "Show Receive Transaction Only",

        delete_tag_title: "Are you sure you want to delete this tag?",
        delete_tag_desc: "Deleting this tag will also remove the tag from your transactions, but will not delete the transaction itself.",
        delete_tag_desc2: "This action cannot be undone.",

        //tagging detail list
        inventory_adjustment: "Inventory Adjustment",
        asset_depreciation: "Asset Depreciation",
        manual_journal: "Manual Journal",
        asset_disposal: "Asset Disposal",
        payment: "Payment",

        creditnote: "Credit Note",
        quote: "Quote",
        invoice: "Invoice",
        po: "Purchase Order",
        bill: "Bill",
        expense: "Expense",
        tags: "Tags",

        //inventory adjustment
        items_adj: "Items Adjustment",
        multi_item_adj: "Multi Items Adjustment",
        cost_per_unit: "Cost per Unit",
        adjustment_value: "Adjustment Value",
        total_value_adj: "Total Value of Adjustment",
        new_cost_unit: "New Cost / Unit",
        conversion_date_stock: "Conversion Date",

        //background processing
        import_bs_notes: "It seems import process will take longer than expected. Harmony will continue running in the background and you may continue your activities.",
        alert: "Alert",
        filter_by_tags: "Filter by Tags",
        notes_disable_contact_expense: "Contact is inactive and cannot be edited. Change Contact's status ",
        notes_disable_contact_expense2: " to edit Expense Claim's Contact",

        // Asset
        delete_regis_asset: "Delete Registered Assets",
        confirm_delete_text1: "If you click Delete, please note of the following:",
        confirm_delete_text2: "{0} will be deleted from list of Registered Assets.",
        confirm_delete_text3: "All depreciation journals will be reversed for this asset.",
        confirm_delete_text4: "If this asset was added from another transaction, this action will not affect the original transaction.",

        //report
        variance: "Variance"
    },
    CashbankStatus: {
        rec: "Reconciled",
        unrec: "Unreconciled",
        void: "Void"
    },
    Contact: {
        contact_detail_desc: "Fill these fields with company or personal details.",
        adv_information: "Advanced Information",
        adv_information_desc: "Contact's info that will be inserted into supporting document.",
        contact_person: "Contact Person",
        contact_person_desc: "Information regarding contact person. Max 5 persons.",
        others: "Others",
        others_desc: "Other information regarding contact.",
        copy_to_delivery: "Copy to Shipping Address",

        recent_trans: "Recent Transactions",
        recent_trans_desc: "List of recent transaction for this contact.",

        set_status_contact: "Set the status for this contact",

        contact_person_name: "Contact Person Name",
    },
    Saas: {
        subscribe: "Subscribe",
        renew_subscription: "Renew Subscription",
        package: "Package",
        owner: "Owner",
        your_role: "Your Role",
        expired_date: "Expired Date",
        see_subscription: "See Subscriptions",
        transfer_ownership: "Transfer Ownership",
        start_free_trial: "Start Free Trial",
        create_order: "Create Order",

        plan_details: "Plan Details",
        current_package: "Current Package",
        payment_method: "Payment Method",
        prev_payment_method: "Previous Payment Method",
        cancel_subscription: "Cancel Subscription",
        bank_transfer: "Bank Transfer",
        virtual_account: "Virtual Account",
        credit_card: "Credit Card",
        add_credit_card: "Add Credit Card",
        billing_history: "Billing History",
        name_credit_card: "Name on Credit Card",
        edit_credit_card: "Edit Credit Card",
        remove_credit_card: "Remove Credit Card",
        yes_remove_it: "Yes, remove it",
        keep_my_card: "No, keep my card",

        dashboard_not_available: "Default dashboard is not available. Please update your subscription.",
        dashboard_not_available_2: "Default dashboard is not available. Please choose another company from the list.",

        company_expired: "Company Expired",
        trial_expired: "Trial Expired",
        trial_expired_access: "Access to",
        trial_expired_desc: "is disabled because the company's trial session has ended. Please upgrade to a paid subscription to continue using Harmony.",

        subs_expired: "Access to",
        subs_expired_desc: "dashboard is disabled because the company’s subscription has expired. Please renew your subscription to continue.",

        i_agree_to: "I agree to",
        harmony_agreement: "Harmony's Agreement",
        privacy_policy: "Privacy Policy",

        current_trans_info: "Current Transfer Information",
        expires_in: "Expires in",
        history: "History",

        expired: "Expired",
        expires: "Expires",
        expires_at: "Expires at",
        trial_expires_at: "Trial Expires at",
        hour: " hour ",
        days_left: "Days Left",

        // Transfer Ownership
        transfer_ownership_desc: "Please note that when you transfer the company's ownership, you will no longer be able to access Subscription and Billing Page and therefore, if you have Credit Card details saved you will no longer be able to remove it. Please make sure to check your Subscription details before continuing.",
        transfer_ownership_desc_2: "Ownership may only be transferred to a registered email in Harmony only. New Owner will need to confirm it before the change take effect.",
        enter_target_email: "Please enter your target's email",
        email_target: "Enter new owner's email",
        password_trans_ownership: "Enter your password to continue",
        confirm_regis_email: "Please make sure to enter registered email only.",
        enter_password: "Please enter your password to execute this action",
        transfer_ownership_success: "Transfer ownership has been succesfully requested",

        credit_card_name: "Credit Card Name",
        credit_card_number: "Credit Card Number",
        expiry_month: "Expiry Month",
        expiry_year: "Expiry Year",
        invalid_expiry_month: "Invalid expiry month",
        card_has_expired: "Card has expired",
        name_on_cc: "Name on Credit Cards",

        confirm_remove_payment: "Are you sure want to remove this payment option?",
        switch_method: "Your payment method will automatically switch to Bank Transfer",

        cancel_billing: "Cancel Billing",
        yes_cancel_billing: "Yes, cancel billing",
        keep_my_plan: "No, keep my plan",

        confirm_cancel_billing: "Are you sure want to cancel billing?",
        new_billing_switch: "Your new billing will be removed and automatically switch back to last running plan.",

        upgrade_plan: "Upgrade Plan",
        change_plan: "Change Plan",
        yes_change_plan: "Yes, change plan",

        confirm_change_plan: "Are you sure you want to change your plan?",
        new_plan_switch: "You currently have pending transactions due. If you continue, your previous Invoice will be cancelled automatically.",

        cancel_subs_teks1: "We're sorry to see you go.",
        cancel_subs_teks2: "When you cancel your subscription, you will not be billed for your next billing. However, you will still be able to use Harmony until your current plan expires.",
        cancel_subs_teks3: "Remember, you can continue using Harmony in the future by logging in to Harmony and update your subscription.",

        enter_password_verification: "Please enter your password for verification",

        new_billing_with: "New Billing with",
        occurs_on: "occurs on",
        invoice_next_billing: "Invoice for next billing period will be available on",

        tooltip_switch: "This package will automatically switch when the current package expires",

        next_package: "Next Package",

        start_at: "Start at",

        failed_charge_1: "We failed to charge your Credit Card. Make sure you have input your card details correctly or",
        failed_charge_2: "contact our support.",

        subs_cancel: "Subscription cancelled",

        promo_code_required: "Promo Code is required.",
        promo_code_found: "Promo Code found. Please apply Promo Code to use discount.",
        compare_package: "Compare Package",
        choose_package: "Choose Package",
        coming_soon: "Coming Soon",
        select_package: "Select Package",
        choose_payment_method: "Choose Payment Method",
        continue_payment: "Continue to Payment",
        purchase_plan: "Purchase Plan",
        package_selection: "Package Selection",
        review_payment: "Review Payment",

        date_order: "Date Order",
        billing_period: "Billing Period",

        plan: "Plan",
        order_summary: "Order Summary",
        review_order: "Review Order",
        total_amount_payable: "Total Amount Payable",

        have_promo_code: "Have promo code?",
        promo_code: "Promo Code",
        code_applied: "Code Applied",
        discount_applied: "Discount Applied",
        credit_balance: "Credit Balance",
        credit_balance_tooltip: "The remaining balance from previous prorated calculation",

        agree_to: "I agree to the",
        subscription_agreement: "Subscription Agreement",
        privacy_policy_subs: "Privacy Policy",
        and_start_today: "and understand my subscription will start today",
        pay_now: "Pay Now",

        yes_downgrade_plan: "Yes, downgrade plan",
        confirm_current_plan: "You are going to downgrade from your current package. Are you sure you want to continue?",
        downgrade_text1: "If you choose to downgrade your package, you will lose your access to some features that are not available in the new package.",
        downgrade_text2: "For more info on these features you may wish to",
        downgrade_text3: "beforehand.",

        thank_you_for_order: "Thank you for your order",

        your_bank_name: "Your Bank Name",
        your_account_name: "Your Account Name",
        your_invoice_no: "Your Invoice No.",
        transfer_amount: "Transfer Amount",
        destination_bank: "Destination bank",

        payment_confirmation: "Payment Confirmation",
        submitting_success: "Thank you for submitting your payment confirmation. Please give our admin some time to check for it.",
        please_make_payment: "Please make payment immediately",
        amount_to_be_paid: "Amount to be paid",
        transfer_amount_alert: "If transferred amount doesn’t match",
        transfer_amount_alert2: "your payment verification process will be inhibited",
        bank_destination_info: "Bank Destination Information",
        completed_payment: "Completed Payment?",
        click_button_below: "Click the button below fill the payment confirmation form and upload the proof of payment.",
        back_to_billing: "Back to your billing status",
        upload_payment_proof: "Upload Proof of Payment",
        sender_bank: "Sender Bank",
        sender_name: "Sender Name",
        bank_destination: "Bank Destination",
        proof_of_payment: "Proof of Payment",
        waiting_for_payment: "Waiting for Payment",

        card_info: "Card Information",
        remove_cc_title: "Are you sure want to remove this card?",

        cancel_subs_info: "When your subscriptions is canceled, all data in your harmony will be erased",

        // Saas V2
        paid: "Paid",
        unpaid: "Unpaid",
        list_billing: "List of your paid and unpaid billings",
        see_my_billing: "See my billing",
        select_provided_bank: "Select Provided Bank",
        invoice_date: "Invoice Date",
        currently_no_billing: "You currently have no billing.",
        subscribe_now: "Subscribe now",
    },
    COA: {
        cash_bank_account: "Cash & Bank Account",
        account_receivables: "Account Receivables",
        current_assets: "Current Assets",
        inventory: "Inventory",
        fixed_assets: "Fixed Assets",
        accumulated_depreciation: "Accumulated Depreciation",
        other_assets: "Other Assets",

        account_payables: "Account Payables",
        current_liability: "Current Liability",
        current_liabilities: "Current Liabilities",
        non_current_liability: "Non-Current Liability",
        non_current_liabilities: "Non-Current Liabilities",

        equity: "Equity",

        revenue: "Revenue",
        other_income: "Other Income",

        cost_of_sales: "Cost of Sales",
        expenses: "Expenses",
        other_expenses: "Other Expenses",

        bank: "Bank",
        asset_account: "Asset Account",
        accumulated_depre_account: "Accumulated Depre Account"
    },
    Reports: {
        financial: "Financial",
        sales: "Sales",
        purchase: "Purchase",
        inventory: "Inventory",
        assets: "Assets",
        expense: "Expense",

        balance_sheet: "Balance Sheet",
        balance_sheet_desc: "The Balance Sheet report summarizes your company’s assets, liabilities and equity at a specific time.",
        profit_loss: "Profit & Loss",
        profit_loss_desc: "The Profit & Loss report shows your company’s net income, by way of listing all your revenue and expenses.",
        movement_equity: "Movements in Equity",
        movement_equity_desc: "This report details the change in owner’s equity during a specific period.",
        profit_loss_comp: "Profit & Loss Report (Comparation)",
        profit_loss_comp_desc: "The Profit & Loss report shows your company’s net income, by way of listing all your revenue and expenses.",
        journal_report: "Journal Report",
        journal_report_desc: "This report lists all transaction entry that occured within a chosen date range.",
        account_trans: "Account Transactions",
        account_trans_desc: "This report lists all the transactions entry for a chosen account within a date range.",
        general_ledger: "General Ledger",
        general_ledger_desc: "The General Ledger report shows the movements of all accounts listed in your Chart Of Accounts for a chosen date range.",
        trial_balance: "Trial Balance",
        trial_balance_desc: "This report shows the balance of all your accounts at a specific period. All accounts are grouped based on their categories.",
        bank_summary: "Bank Summary Report",
        bank_summary_desc: "The Bank Summary report shows money spent and received from your bank accounts for a chosen date range.",
        account_summary: "Account Summary",
        account_summary_desc: "The Account Summary report shows a monthly summary for a chosen account.",
        cash_flow_indirect: "Cash Flow Statement (Indirect Method)",
        cash_flow_indirect_desc: "This report shows the company's cash flow, particularly from Operating, Financial and Investing activities during a certain period of time.",
        tax_detail: "Tax Detail Report",
        tax_detail_desc: "This report lists all transactions entry for each Tax Rate within a chosen date range.",
        sales_list: "Sales List Report",
        sales_list_desc: "This report lists all the sale transactions that happened during a chosen period.",
        aged_receivable: "Aged Receivable Report",
        aged_receivable_desc: "This report displays information for Invoices and Sales Credit Notes, based on how long they’ve been unpaid.",
        sales_item: "Sales By Item Report",
        sales_item_desc: "This report lists the total quantity sold for each salescommission for a period of a chosen time, as well as the total value sold.",
        purchase_list: "Purchase List Report",
        purchase_list_desc: "This report lists all the purchase transactions that happened during a chosen period.",
        aged_payable: "Aged Payable Report",
        aged_payable_desc: "This report displays information for Bills and Purchase Credit Notes, based on how long they’ve been unpaid.",
        purchase_item: "Purchase By Item Report",
        purchase_item_desc: "This report lists the total quantity purchased for each product for a period of a chosen time, as well as the total value purchased.",
        inventory_list: "Inventory List Report",
        inventory_list_desc: "The Inventory report lists all the Inventory items quantity and cost as of a specific date.",
        fixed_asset: "Fixed Assets Report",
        fixed_asset_desc: "The Fixed Assets report lists all the Fixed Assets’ value and depreciation as of a specific date.",
        item_valuation: "Item Valuation Report",
        item_valuation_desc: "This report details the transaction and valuation for a chosen item over a period of time.",
        fixed_asset_recon: "Fixed Assets Reconciliation Report",
        fixed_asset_recon_desc: "This report shows the balance of fixed assets from the Fixed Assets Register and Balance Sheet.",
        executive_summary: "Executive Summary Report",
        executive_summary_desc: "This report shows a summary of your company's performance and financial reports.",
        expense_report: "Expense Claim List Report",
        expense_report_desc: "This report lists all the expense claim transactions that happened during a chosen period",
        expense_aging_report: "Aging Expense Report",
        expense_aging_report_desc: "Aging Expense Report Description",

        as_at: "As At ",
        as_of: "As of ",
        balance_date: "Balance Date",
        balance_sheet_report: "Balance Sheet Report",
        account_summary_report: "Account Summary Report",
        account_trans_report: "Account Transactions Report",
        movement_equity_report: "Movements in Equity Report",
        general_ledger_report: "General Ledger Report",
        profit_loss_report: "Profit & Loss Report",
        trial_balance_report: "Trial Balance Report",
        cash_flow_report: "Cash Flow Statement",
        executive_summary_report: "Executive Summary Report",

        select_account: "Select Account",
        select_period: "Select Period",

        // Label
        month: "Month",
        year: "Year",
        monthly_balance: "Monthly Balance",
        from: "From",
        to: "To",
        current: "Current",
        aging_by: "Aging By",
        date_range: "Date Range",
        select_date_range: "Select Date Range",
        source: "Source",
        balance_sheet_beginning: "Balance Sheet Beginning",
        register_beginning: "Register Beginning",
        balance_sheet_ending: "Balance Sheet Ending",
        register_ending: "Register Ending",
        cash_received: "Cash Received",
        cash_spent: "Cash Spent",
        ending_balance: "Ending Balance",

        beginning_cash_balance: "Beginning Cash Balance",
        cash_flow_op: "Cash Flow from Operating Activities",
        cash_flow_op_desc: "Net Cash provided by Operating Activities",
        cash_flow_inv: "Cash Flow from Investing Activities",
        cash_flow_inv_desc: "Net Cash provided by Investing Activities",
        cash_flow_fin: "Cash Flow from Financing Activities",
        cash_flow_fin_desc: "Net Cash provided by Financing Activities",
        net_change: "NET CHANGE IN CASH (A + B + C)",
        ending_cash_balance: "ENDING CASH BALANCE",

        closing_balance: "Closing Balance",
        beginning_balance: "Beginning Balance",
        title: "Title",
        monthly: "Monthly",
        yearly: "Yearly",
        equity_date: "For the {0} ended {1}",

        transaction_detail: "Transaction Details",
        quantity_movement: "Quantity Movement",
        unit_cost: "Unit Cost",
        quantity_on_hand: "Quantity on Hand",
        inventory_value: "Inventory Value",

        cost_of_sales: "Cost of Sales",
        gross_profit: "Gross Profit",
        operating_expenses: "Operating Expenses",
        net_operating_income: "Net Operating Income",
        other_income_expense: "Other Income & Expenses",
        net_profit: "Net Profit (Loss)",

        qty_purchased: "QTY Purchased",
        value_purchased: "Value Purchased",
        avg_purchase_price: "AVG Purchase Price",

        period: "Period",
        acquisition_date: "Acquisition Date",
        accumulated_depreciation: "Accumulated Depreciation",

        select_source: "Select Source",
        balance_due: "Balance Due",

        qty_sold: "QTY Sold",
        total_value_sold: "Total Value Sold",
        avg_sale_price: "AVG Sale Price",

        tax_account: "Tax Account",
        item_account: "Item Account",
        taxable_amount: "Taxable Amount",
        tax_amount: "Tax Amount",
        total_amount: "Total Amount",

        debit_mtd: "Debit MTD",
        credit_mtd: "Credit MTD",
        debit_ytd: "Debit YTD",
        credit_ytd: "Credit YTD",

        liabilities_equity: "Liabilities & Equity",

        //notes bank summary, #165555131
        bank_summary_notes: "*This report includes Transfer Money transactions. The graph on the Dashboard excludes Transfer Money transactions.",

        //EXECUTIVE SUMMARY REPORT
        exec_cash: "Cash",
        exec_cash_receive: "Cash Received",
        exec_cash_spent: "Cash Spent",
        exec_cash_surplus_deficit: "Cash Surplus (Deficit)",
        exec_closing_bank_balance: "Closing Bank Balance",
        exec_profitability: "Profitability",
        exec_income: "Income",
        exec_cost_of_sales: "Cost of Sales",
        exec_gross_profit_loss: "Gross Profit (Loss)",
        exec_other_income: "Other Income",
        exec_expenses: "Expenses",
        exec_net_profit_loss: "Net Profit (Loss)",
        exec_balance_sheet: "Balance Sheet",
        exec_debtors: "Debtors",
        exec_creditors: "Creditors",
        exec_net_assets: "Net Assets",
        exec_number_of_invoices_issued: "Number of Invoices Issued",
        exec_average_value_of_invoices: "Average Value of Invoices",
        exec_performance: "Performance",
        exec_gross_profit_margin: "Gross Profit Margin",
        exec_net_profit_margin: "Net Profit Margin",
        exec_return_on_assets: "Return on Assets",
        exec_return_on_equity: "Return on Equity",
        exec_position: "Position",
        exec_debt_to_equity_ratio: "Debt to Equity Ratio",
        exec_current_ratio: "Current Ratio",
        exec_quick_ratio: "Quick Ratio",
        exec_account_receivable_days: "Account Receivable Days",
        exec_account_payable_days: "Account Payable Days",
        exec_inventory_days: "Inventory Days",
        exec_cash_gap_analysis: "Cash Gap Analysis",
    },
    Settings: {
        setting: "Settings",
        general: "General",
        financial: "Financial",

        company_setting: "Company Settings",
        company_setting_desc: "Edit your company information details.",
        user_management: "User Management",
        user_management_desc: "Invite new user and manage user's access in your company.",
        email_setting: "Email Settings and Templates",
        email_setting_desc: "Set-up email address, display name and email content templates to be used when sending Invoices to customers.",
        history_business: "History Business Activity",
        history_business_desc: "This list shows all the History & Notes logged for all transactions created in Harmony.",
        file_library: "File Library",
        file_library_desc: "List of all attachments uploaded from transaction.",

        tax_list: "Tax List",
        tax_list_desc: "View, add and edit list of taxes that is used in transactions.",
        product_unit: "Product Unit List",
        product_unit_desc: "View, add and edit list of units that is used for items in transactions.",
        lock_date: "Lock Date",
        lock_date_desc: "Set up lock date for your transactions to prevent changes made to a certain period.",
        conversion_balance: "Conversion Balance",
        conversion_balance_desc: "Enter opening balances from previous accounting records.",
        account_mapping: "Account Mapping Settings",
        account_mapping_desc: "Select default accounts for your transactions.",
        transaction_number: "Transaction Number Settings",
        transaction_number_desc: "Define the transaction number format to be used when creating Invoices, Bills and Credit Notes. The number will automatically increment with every new transactions.",
        currency_list: "Currency List",
        currency_list_desc: "View, add, edit, and delete list of currency that is used for items in transactions.",

        notification: "Notifications",
        notification_desc: "This menu allows you to see and manage your notifications.",
        tag_list: "Tag List",
        tag_list_desc: "List of tags created and added into transactions in Harmony."
    },
    Currency: {
        currency_list: "Currency List",
        rates_for: "Rates for",
        base_currency: "Base Currency",
        currency_name: "Currency Name",
        currency_code: "Currency Code",
        rate_to_base: "Rate to Base",
        base_to_rate: "Base to Rate",
        notes: "Notes",
        rate_by: "Rate by",
        select_method: "Select Method",
        online_rate_from: "Online Rate from",
        custom_rate: "Custom Rate",
        delete_the_currency: "Delete the {0}`s Currency?",
        rate: "Rate",

        add_currency: "New Currency",
        view_currency: "View Currency",
        edit_currency: "Edit Currency",
        delete_currency: "Delete Currency",
    },
    UserManagement: {
        nick_name: "Nick Name",
        roles: "Roles",
        owner: "Owner",
        super_user: "Super User",
        superuser: "Super User",
        accountant: "Accountant",
        sales: "Sales",
        purchasing: "Purchasing",
        awaiting_for_confirmation: "Awaiting for Confirmation",
        invitation_rejected: "Invitation Rejected",
        invitation_expired: "Invitation Expired",
        view_invite_user: "View Invite User",
        cancel_invitation_confirm: "Cancel invitation for this user?",
        remove_user_confirm: "Are you sure want to remove this user?",
        remove_user_note: "Note: This will remove {0}'s access to your company.",
        transaction_will_remain: "All transactions will remain",
        within_your_company: "within your company.",
        invitation_has_been_sent: "The invitation email has been sent !",
    },
    EmailSetting: {
        email_setup: "Email Setup",
        setup_default_email: "Setup Default Email",
        configure_email: "Configure emails sent by Harmony",
        setup_email_template: "Setup Email Template",
        create_template_harmony: "Create templates for emails sent by Harmony",
        display_name: "Display Name",
        email_address: "Email Address",
        template_name: "Template Name",
        type: "Type",
        email_address_reply: "Email Address (for ‘Reply To’)",
        default_cant_edited: "Default template cannot be deleted or edited",
        request_sent: "Request has been sent to",
        remove_user_info: "Remove {0} from this list?",
        success_change_default: "Success: {0} [{1}] is now the default display name",
        default_changed: "Default successfully changed",
        resend: "Resend",
        pending: "Pending",
        cn_sales: "CN Sales",
        cn_purchase: "CN Purchase",
    },
    FileLibrary: {
        file_library: "File Library",
        unassigned: "Unassigned",

        // File Library
        upload_date: "Upload Date",
        upload_by: "Upload By",
        download_file: "Download File",
        transaction_file: "Transaction",
        by: "By",

        expense: "Expense",
        journal: "Journal",

        max_attach_5: "Can only attach maximum of 5 files.",
        delete_file_confirm: "Are you sure want to delete this file?",
        delete_file_count: "Are you sure want to delete these {0} file(s)?",
    },
    DueDate: {
        at_date: "At date",
        how_many_days: "How many days"
    },
    Notes: {
        // Action
        created: "Created",
        updated: "Updated",
        note: "Note",
        undo: "Undo",
        voided: "Voided",
        approved: "Approved",
        approved_repeat: " approved",
        paid: "Paid",
        applied: "Applied",
        payment: "Payment",
        emailed: "Emailed",
        registered: "Registered",
        submitted: "Submitted",
        billed: "Billed",
        declined: "Declined",
        rejected: "Rejected",
        accepted: "Accepted",
        quoted: "Quoted",
        email: "Email",
        invoiced: "Invoiced",
        update_reconcile: "Update Reconcile",
        create_cashcoding: "Create Cashcoding",
        create_transfer: "Create Transfer",
        rollback: "Rollback",
        depreciation: "Depreciation",
        disposed: "Disposed",
        undo_dispose: "Undo Dispose",
        reverse_depreciation: "Reverse Depreciation",
        start_date_changed: "Start Date Changed",
        less_payment: "Less Payment",
        less_credit: "Less Credit",
        less_refund: "Less Refund",
        credit_to: "Credit to",
        stock_adjustment: "Stock Adjustment",
        conversion_stock: "Conversion Stock",

        // Detail
        to: " to ",
        to_tagging: " to ",
        for: " for ",
        for_repeat: " for ",
        from: " from ",
        by: " by ",
        on: " on ",
        on_journal: " on ",
        sent_to: "sent to",
        note_added_by: "Note added by",
        note_by: "Note by",
        with_total_of: "with total of",
        with_total_of_journal: "with total of",
        created_by: "created by",
        edited_by: "edited by",
        updated_by: "updated by",
        added_by: "added by",
        approved_by: "approved by",
        quoted_by: "quoted by",
        voided_by: "voided by",
        was_declined_by: "was declined by",
        has_been_accepted: "has been accepted by",
        has_been_approved: "has been approved by",
        submitted_for_approval: "submitted for approval by",

        created_for: "created for",
        scheduler_suceed_for: "Scheduler succeed for",
        scheduler_failed_for: "Scheduler failed for",
        scheduler_suceed: "Scheduler succeed. ",
        scheduler_failed: "Scheduler failed.",
        scheduler_created: "scheduler created",
        scheduler_created_by: "scheduler created by",
        sent_to_draft: "sent to Draft",

        credit_applied_to: "Credit applied to",
        credit_applied_from: "Credit applied from",
        credit_remove_for: "Payment removed for",
        payment_made_to: "Payment made to",
        payment_received_to: "Payment received to",
        payment_received_from: "Payment received from",
        payment_made_from: "Payment made from",
        there_is: "There is",
        credit_remaining: "credit remaining on this Credit Note",
        payment_removed_for: "Payment removed for",
        payment_removed: "Payment removed",
        payment_made_for: "Payment made for",
        invoice_fully_paid: "This invoice has been fully paid",
        claim_fully_paid: "This claim has been fully paid",
        bill_fully_paid: "This bill has been fully paid",
        remaining_invoice: "remaining on this Invoice",
        remaining_bill: "remaining on this Bill",
        claim_remaining: "remaining for this claim",
        for_expense_claim: "due for this expense claim",

        reconciled: "Reconciled",
        reconciled_by: "Reconciled by",
        transfer_money: "Transfer Money",
        transfer_money_from: "Transfer Money from",
        manually_reconciled_by: "Manually reconciled by",
        manually_unreconciled_by: "Manually unreconciled by",
        manually_unreconciled_from: "Manually unreconciled from",
        reconciled_statement_line: "Reconciled to Statement Line",
        unreconciled: " Unreconciled ",
        unreconciled_by: " Unreconciled by ",
        statement_line: "Statement Line",

        // Asset
        has_been_updated: "Has been updated",
        has_been_registered: "Has been registered",
        has_been_created: "Has been created",
        depreciation_of: "Depreciation of",
        depreciation_of_reversed: "Depreciation of",
        was_created_for: "was created for",
        was_registered_by: "was registered by",
        was_updated_by: "was updated by",
        date_rolled_back: "Depreciation Date rolled back from",
        disposal_was_undone: "disposal was undone on",
        asset_start_date: "Fixed Asset Start Date was changed from",
        was_deleted_by: "was deleted by",
        disposed_for: "disposed for",
        disposed_notaction: " disposed",
        ran_by: "ran by",
        reversed_on: "reversed on",
        disposed_on: " on ",
        depreciation_on: " on ",
        asset_to: " to ",
        asset_on: " on ",
        created_by_asset: " by ",
        canceled_by: " by ",

        //Inventory
        item: "Item ",
        item_code_for: "Item Code for",
        group_item_code_for: "(Group) Item Code for",
        changed_from: "changed from",
        changed_to: "changed to",
        is_now_tracked: "is now Tracked",
        is_now_untracked: "is now Untracked",

        item_buy: "Item",
        item_sell: "Item",
        buy_price_changed: "buy price changed from",
        sell_price_changed: "sell price changed from",
        to_buy: " to ",

        item_account: "Item",
        inventory_account_changed: "Inventory Account changed from",
        awaiting_for_payment: "Awaiting for Payment",
        group_item: "Group Item",
        group_item_bracket: "(Group) Item",
        details_of_group_item: "Details of Group Item",
        detail_of_group_item: "Detail of (Group) Item",
        none: "None",
        quantity_increased: "Quantity increased",
        quantity_decreased: "Quantity decreased",
        with_value_of: "with value of",
        total_value_adjusted_from: "total value adjusted from",
        by_conversion_stock: "by conversion stock with value of",
        by_conversion: " by ",

        // Contact
        deleted: "Deleted",
        has_been_created_by: "has been created by",
        has_been_updated_to: "has been updated to",
        has_been_updated_by: "has been updated by",
        has_been_deleted_by: "has been deleted by",
        details_for_contact: "Details for Contact",

        // Tag
        tag: "Tag",
        under_category: "(under category",
        updated_from_category: "updated from category",
        updated_to: "updated to",
        deleted_by: "deleted by",
        no_category: "no category",
        updated_from_no_category: "updated from no category",
        category: "category",

        // Journal
        journal: "Journal",

        // Repeating
        repeating_scheduler: "Repeating scheduler",
        set_to_inactive: "is set to inactive",
        set_to_paused: "is set to paused",

        awaiting_for_approval: "Awaiting for Approval",
    },
    Import: {
        download_file: "Download Template File",
        download_text: "Download our template file before importing your data. The template file has the correct column headers required by Harmony to import your data correctly.",
        insert_data: "Insert Your Data",
        insert_text: "Use Microsoft Excel or similar Spreadsheet application to open the previously downloaded template file and insert your data. Make sure the data you inserted corresponds to the correct column header provided.",
        insert_text_bank: "Use Microsoft Excel or similar Spreadsheet application to open the previously downloaded template file and insert your data. Make sure the data you inserted corresponds to the correct column header provided.",
        important: "Important:",
        import_rule1: "Do not change or delete the column headers in the template file.",
        import_rule2: "You can only import a maximum of 100 rows of items.",
        import_rule3: "Do not use separator for thousands. Use Period (.) as decimal separator.",
        import_rule4: "The symbol (*) next to header title means that it is mandatory fields. If you leave it empty, Harmony will consider it to be invalid.",
        import_rule5: "Use the Account Code from your list of COA in Harmony to enter into the appropriate column.",
        import_rule6: "Use the Unit Code from your Unit List and Tax Name from your Tax List to enter into the appropriate column. It is advisable that you have created all the necessary Units and Tax Rates for your items.",
        import_rule7: "Fill the \"Inventory Account, \"Buy Account\" and \"Sell Account\" for Tracked Items. If you leave all three empty, Harmony will consider the item to be Untracked items.",
        import_rule8: "Use this format (dd/mm/yyyy) to enter Date.",
        import_rule9: "Imported assets will be listed under the Draft tab, and will need to be manually Registered.",
        upload_data: "Upload Your Data",
        upload_text: "When you have finished inserting all your data into the template file, upload it here. Your file should end with the extension .xlsx, .xls or .csv",
        upload_text_plus: "When you have finished inserting all your data into the template file, upload it here. Your file should end with the extension .xlsx, .xls or .csv",
        unable_import: "Unable to import any of your data. Please correct it, and try uploading again.",

        cash_bank_rule1: "Do not change the column headings in the Harmony template file.",
        cash_bank_rule2: "Harmony provides only one column for the amount, enter positive number to record Money Received, and enter a negative number to record Money Spent in the same column.",
        cash_bank_rule3: "Please use this date format for your data: 25/12/2017(dd/mm/yyyy).",

        download_file_bs: "Prepare Your Bank Statement",
        download_text_bs: "If your Bank Statement data comes from other sources, you may need to copy the data into Harmony Bank Statement template which you can get by downloading from the link below.",
        download_note_bs: "*Note: If you obtain your Bank Statement directly from Bank BCA in .csv form, then you may proceed directly to Step 3.",
        insert_data_bs: "Insert Your Data into Harmony Template File",
        upload_text_bs: "When you have finished inserting all your data into the template file, upload it here.",
        upload_note_bs: "File must be .csv, .xlsx, or .xls",

        invoice_rule1: "Please use this format for your Date: 25/12/2017 (dd/mm/yyyy).",
        invoice_rule2: "Use the Currency Code and Tax Name from your Currency List and Tax List to enter into the appropriate column. It is advisable that you have created all the necessary Tax Rates for your Invoices.",
        invoice_rule3: "Make sure you have added the appropriate Contact and Items on your Invoice file into Harmony",
        invoice_rule4: "Enter the number corresponding each term for \"Amounts are\" condition:"

    },
    Modal: {
        confirmation: "Confirmation",
        sum_item: " new items will be imported",
        sum_bankstate: " new data will be imported",
        sum_contact: " new contacts will be imported",
        sum_invoice: " new sales will be imported",
        sum_bill: " new bill will be imported",
        share_quote: "Share Quote",
        share_invoice: "Share Invoice",
        share_bill: "Share Bill",
        share_po: "Share Purchase Order",
        url_copied: "URL Copied",
    },
    Tax_Method: {
        inclusive: "Tax Inclusive",
        exclusive: "Tax Exclusive",
        none: "No Tax",
    },
    DepreciationMethod: {
        no_depreciation: "No Depreciation",
        straight_line: "Straight Line",
        declining_balance: "Declining Balance",
        full_depreciation_at_purchase: "Full Depreciation at Purchase",
    },
    Tooltip: {
        tax: "The accounts for this settings has been locked",
        stock: "Stock",
        error_compare_due_date: "Due Date must be after Invoice Date.",
        error_compare_due_date_bill: "Due Date must be after Bill Date.",

        // Dashboard
        cash_flow: "The total amount of money received and spent in a business over a period of time.",
        invoices: "A summary of the Invoices created based on their status for all time. The invoices that are paid in the last 30 days are shown on the bottom of the graph.",
        bills: "A summary of the Bills created based on their status for all time. The Bills that are paid in the last 30 days are shown on the bottom of the graph.",
        expenses: "List of the expenses incurred over a chosen period of time, sorted from the highest value.",
        account_watchlist: "List of 5 accounts with the highest value, based on the chosen period of time.",
        projects: "A list of the projects of products.",
        profit_loss: "A summary of the company’s income and expenses from the profit and loss report, based on the chosen period of time.",

        //import invoice
        invoice_draft: "Total Invoices that are in Draft status",
        invoice_awaiting: "Total Invoices that are unpaid, but are not pass the due date yet.",
        invoice_overdue: "Total Invoices that are unpaid, and are already pass the due date.",
        invoice_paid_in: "Total payment received for invoices in the last 30 days from today.",
        error_invoice_number_only: "Numbers Only.",
        error_invoice_item: "Item not found in your list.",
        error_invoice_amount_by: "Choose between: nominal or percent",
        error_invoice_withholding: "Choose between: yes or no",
        error_invoice_currency: "Use the Currency from your list of Currency in Harmony to enter into the appropriate column.",
        error_invoice_amounts_are: "Choose between: No Tax, Tax Exclusive, Tax Inclusive.",

        //import Bill
        bill_draft: "Total Bills that are in Draft status.",
        bill_awaiting: "Total Bills that are unpaid, but are not pass the due date yet.",
        bill_overdue: "Total Bills that are unpaid, and are already pass the due date.",
        bill_paid_in: "Total payment received for Bills in the last 30 days from today.",

        //import inventory
        tmpinv_item_code: "Alphanumeric. Max 50 characters.",
        tmpinv_item_name: "Alphanumeric. Max 100 characters.",
        tmpinv_unit_name: "Use the Unit Code from your Unit List in Harmony to enter into the appropriate column.",
        inv_coadet_name: "Use the Account Code from your list of COA in Harmony to enter into the appropriate column.",
        buy_price: "Numbers only. Do not use separator for thousands. Use Period (.) as decimal separator.",
        buy_acc_name: "Use the Account Code from your list of COA in Harmony to enter into the appropriate column.",
        buy_tax_name: "Use the Tax Name from your list of Tax in Harmony to enter into the appropriate column.",
        sell_price: "Numbers only. Do not use separator for thousands. Use Period (.) as decimal separator.",
        sell_acc_name: "Use the Account Code from your list of COA in Harmony to enter into the appropriate column.",
        sell_tax_name: "Use the Tax Name from your list of Tax in Harmony to enter into the appropriate column.",

        // Import Asset
        error_asset_40char: "Alphanumeric. Max 40 characters.",
        error_asset_20char: "Alphanumeric. Max 20 characters.",
        error_asset_name: "Alphanumeric. Max 40 characters.",
        error_asset_type: "Asset Type not found in your list.",
        error_asset_number: "Numbers only. Do not use separator for thousands. Use Period (.) as decimal separator.",
        error_asset_purchase_date: "Enter with date format only: dd/mm/yyyy",
        error_asset_purchase_price: "Numbers only. Do not use separator for thousands. Use Period (.) as decimal separator.",
        error_asset_desc: "Alphanumeric. Max 250 characters.",

        // Import Contact
        error_contact_name: "Alphanumeric. Max 30 characters.",
        error_contact_type: "Choose between: customer, supplier, employee.",
        error_contact_website: "Add http:// or https:// before the url of the webpage.",
        error_contact_email: "Must follow the email format: example@mail.co.id.",
        error_contact_phone: "No spaces or letters. Use 0 or + before country/city code.",
        error_contact_fax: "No spaces. Use 0 or + before country/city code.",
        error_contact_coa: "Use the Account Code from your list of COA in Harmony to enter into the appropriate column.",
        error_contact_duedate: "Enter number for days.",
        error_contact_duedate_term: "Enter number for term option.",
        error_invoice_contact: "Contact not found in your list.",

        error_bs_date: "Enter with date format only: dd/mm/yyyy",
        error_bs_invaliddate: "Invalid Date. Make sure Date is not more than today.",
        error_bs_amount: "Numbers only. Do not use separator for thousands. Use Period (.) as decimal separator.",
        error_bs_invalidamount: "Amount has reached maximum limit",
        error_bs_invalidamount2: "Invalid Amount format. Please check your file to make sure Amount is Debit or Credit.",
        error_bs_payee: "Alphanumeric. Max 255 characters.",
        error_bs_description: "Alphanumeric. Max 255 characters.",
        error_bs_reference: "Alphanumeric. Max 255 characters.",

        error_contact_npwp: "Enter with number format, max 16 character.",

        // Find and Match
        adjusment_needed: "Adjustment is needed because the total transactions is not equal to the bank statement amount. Please choose the adjusment account.",

        // OneFeed
        balance_not_equal: "Your balances are not equal, ",
        please_check_again: "please check again",
        already_connected: "Already connected on Harmony",

        coa_cannot_delete: "Account used in transactions cannot be deleted.",
        lock_by_system: "Locked By System.",

        quick_placeholder_1: "Placeholders are markers to display the content from Harmony when inserted into email template.",
        quick_placeholder_2: "E.g. : Inserting [Contact Name] will generate a contact name from Harmony.",

        asset_lock: "The accounts for this asset type has been locked",

        delete_currency: "Action is disabled because the item has been used in settings or transactions.",
    },
    Dropdown: {
        all_account: "All Accounts",
        add_more_row: "Add more rows",
        add_5: "Tambah 5",
        add_10: "Tambah 10",
        add_15: "Tambah 15",
        today: "Today",
        yesterday: "Yesterday",
        this_week: "This Week",
        last_week: "Last Week",
        last_3_month: "Last 3 months",
        last_6_month: "Last 6 months",
        this_month: "This Month",
        last_month: "Last Month",
        this_year: "This Year",
        last_year: "Last Year",
        all_status: "All Status",
        active: "Active",
        expired: "Expired",
        after: "After",
        never: "Never",
        running: "Running",
        paused: "Paused",
        day_after_invoice_date: "day(s) after invoice date",
        day_after_the_end_of_invoice_month: "day(s) after the end of invoice month",
        day_after_bill_date: "day(s) after bill date",
        day_after_the_end_of_bill_month: "day(s) after the end of bill month",
        revaluation: "Revaluation",
        update_quantity: "Update Quantity",
        decrease_qty: "Decrease Quantity",
        increase_qty: "Increase Quantity",
        all_depre: "All depreciation up to and including",
        no_depre: "No Depreciation",

        following_month: "of the following month",
        after_trans_date: "day(s) after the transaction date",
        after_end_trans_month: "day(s) after the end of the transaction month",
        current_month: "of the current month",

        // dropdown coa, jgn asal rubah ya nnti error
        all_accounts: "All Accounts",
        assets: "Assets",
        liabilities: "Liabilities",
        equity: "Equity",
        revenue: "Revenue",
        expenses: "Expenses",

        // dropdown organization type, jgn asal rubah ya nnti error
        private_corporation: "Private Corporation",
        public_corporation: "Public Corporation",
        nonprofit_organization: "Nonprofit Organization",
        sole_proprietor: "Sole Proprietor",
        government: "Government",
        others: "Others",
        service: "Service",
        retail: "Retail",
        home_industry: "Home Industry",
        service_industry: "Service Industry",
        retail_industry: "Retail Industry",
    },

    // LanguageID.Placeholder = {
    //     all: "All",
    //     all_source: "All source",
    //     any: "Any",
    //     select_contact: "Select Contact",
    //     select_item: "Select Item",
    //     type_to_select: "Type to Select",
    //     select_account: "Select An Account",
    //     select_tax: "Select Tax",
    //     select_unit: "Select Unit",
    //     select_date: "Select Date",
    //     select_start: "Select Start",
    //     select_end: "Select End",
    //     select_asset_type: "Select asset type",
    //     select_method: "Select Method",
    //     select_depre_method: "Select Deprecation Method",
    //     select_rate_effective: "Select Rate / Effective Life (Years)",
    //     select_start_date: "Select Start Date",
    //     select_bank: "Select a bank",
    //     select_bank_account: "Select Bank Account",
    //     select_terms: "Select Terms",
    //     select_type: "Select Type",
    //     select_status: "Select Status",
    //     select_your_bank: "Select your bank",
    //     select_currency: "Select Currency",
    //     select_account_type: "Select Account Type",
    //     select_parent_account: "Select Parent Account",
    //     select_inventory_method: "Select Inventory Method",
    //     select_country: "Select Country",
    //     select_role: "Select Roles",
    //     select_transaction_type: "Select Transaction Type",
    //     select_items: "Select Items",
    //     select_menu: "Select Menu",
    //     select_to: "Select To",
    //     select_from: "Select From",
    //     notes: "Add some notes for internal use",
    //     search: "Search",
    //     enter_text_here: "Enter Text Here",
    //     enter_reason: "Enter Reason",
    //     add_description: "Add description",
    //     reason_adjustment: "Reason for Adjustment",
    //     type_to_search: "Type to Search",
    //     add_quick: "Add Quick Placeholder",
    //     select_source: "Select a source",
    //     select_industry: "Select Industry",
    //     select_timezone: "Select Timezone",
    //     select_invoice_number: "Select Invoice Number",
    //     not_found: "Not Found",
    //     select_tags: "Select Tag",
    //     ex_bank_name: "ex : Bank Mandiri",
    //     ex_bank_desc: "ex : 126.0000.978.009",
    //     ex_account_code: "ex : 1102",
    // }

    // LanguageID.Button = {
    //     new: "New",
    //     yes: "Yes",
    //     no: "No",
    //     cancel: "Cancel",
    //     no_cancel: "Cancel",
    //     save: "Save",
    //     submit: "Submit",
    //     create: "Create",
    //     update: "Update",
    //     decline: "Decline",
    //     approve: "Approve",
    //     accept: "Accept",
    //     apply: "Apply",
    //     upload: "Upload",
    //     add_attachment: "Add Attachment",
    //     choose_lib: "Choose from Library",
    //     choose_contact: "Choose from Computer",
    //     add_more_row: "Add New Rows",
    //     save_to_draft: "Save to draft",
    //     remove: "Remove",
    //     edit: "Edit",
    //     delete: "Delete",
    //     delete_recurring: "Delete Recurring",
    //     send: "Send",
    //     send_email: "Send Email",
    //     send_anyway: "Send Anyway",
    //     share_now: "Share Now",
    //     preview: "Preview",
    //     add: "Add",
    //     show_all: "Show All",
    //     hide: "Hide",
    //     save_changes: "Save Changes",
    //     generate: "Generate",
    //     please_wait: "Please Wait",
    //     add_delivery_address: "Add Delivery Address",
    //     new_delivery_address: "New Delivery Address",
    //     back: "Back",
    //     yes_continue: "Yes, continue",
    //     dispose: "Dispose",
    //     register: "Register",
    //     undo_disposal: "Undo Disposal",
    //     undo_now: "Undo Now",
    //     change_date: "Change Date",
    //     preview_pdf: "Preview PDF",

    //     new_quote: "New Quote",
    //     new_account: "New Account",
    //     add_new_note: "Add New Note",
    //     clone: "Clone",
    //     new_repeating_invoice: "New Repeating Invoice",
    //     new_repeating_bill: "New Repeating Bill",
    //     payment: "Payment",
    //     add_cn: "Add Credit Note",
    //     create_payment: "Create Payment",
    //     create_refund: "Create Refund",
    //     create_invoice: "Create Invoice",
    //     create_bill: "Create Bill",
    //     void_invoice: "Yes, Void Invoice",
    //     void_bill: "Yes, Void Bill",
    //     void_cn: "Yes, Void Credit Note",
    //     void_expense: "Yes, Void Expense Claims",
    //     void: "Void",
    //     void_delete: "Delete", //sprint 63, requester noviyanti, #165556313
    //     copy_link: "Copy link",
    //     new_cn: "New Credit Note",
    //     refund: "Refund",
    //     edit_cn: "Edit Credit Note",
    //     edit_bill: "Edit Bill",
    //     edit_expense: "Edit Expense",
    //     edit_journal: "Edit Journal",
    //     edit_asset: "Edit Asset",

    //     create_asset_type: "Create Asset Type",

    //     inventory_import: "Import Inventory",
    //     inventory_new: "New Inventory",
    //     inventory_edit: "Edit Inventory",
    //     inventory_export_xls: "Export as Excel",
    //     inventory_stock_adj: "Stock Adjustment",
    //     delete_item: "Delete Item",

    //     delete_journal: "Delete Journal",
    //     delete_contact: "Delete Contact",

    //     upload_file: "Upload File",
    //     upload_file_from: "Upload File From",
    //     continue: "Continue",
    //     import_goback: "Go back and correct your data",
    //     import_data: "Import Data",
    //     review_adj: "Review Adjustment",
    //     post_adj: "Post Adjustment",
    //     import_asset: "Import Asset",
    //     import_contact: "Import Contact",
    //     import_invoice: "Import Invoice",
    //     import_bill: "Import Bill",
    //     run_depre: "Run Depreciation",
    //     submit_file: "Choose File",
    //     show_summary: "Show Summary",
    //     review_journal: "Review Journal",
    //     post: "Post",

    //     import_cashbank: "Import Bank Statement",
    //     add_person: "Add Person",
    //     show_more: "Show More",
    //     got_it: "Got It",

    //     save_reconcile: "Save & Reconcile",
    //     transfer: "Transfer",
    //     delete_account: "Delete Account",
    //     rule: "Rule",

    //     add_address: "Add Address",
    //     edit_address: "Edit Address",
    //     remove_address: "Remove Address",
    //     delete_address: "Delete Address",
    //     add_bank_detail: "Add Bank Detail",
    //     edit_bank_detail: "Edit Bank Details",
    //     remove_bank_detail: "Remove Bank Details",
    //     delete_bank_detail: "Delete Bank Details",
    //     delete_bank: "Delete Bank",

    //     edit_user: "Edit User",
    //     remove_user: "Remove User",
    //     reinvite_user: "Reinvite User",
    //     cancel_invitation: "Cancel Invitation",
    //     send_invitation: "Send Invitation",
    //     invite_new_user: "Invite New User",
    //     send_verification_email: "Send Verification Email",

    //     new_email: "New Email",
    //     new_template: "New Template",
    //     edit_template: "Edit Template",
    //     delete_template: "Delete Template",
    //     delete_file: "Delete File",
    //     add_file: "Add File",
    //     download: "Download",
    //     new_tax: "New Tax",
    //     new_unit: "New Unit",
    //     set_conversion_balance: "Set Conversion Date",

    //     create_new: "Create New",
    //     browse_file: "Browse File",

    //     export_as: "Export as",
    //     yes_delete_trans: "Yes, Delete Transaction", //sprint 63, requester noviyanti, #165556313
    //     new_company: "New Company",

    //     go_back: "Go Back",
    //     change_password: "Change Password",
    //     clone_to_onegate: "Clone to OneGate",

    //     back_to_billing: "Back to Billing Page",
    //     back_to_package: "Back to Package Selection",

    //     save_card: "Save Card",
    //     update_card: "Update Card",
    //     add_card: "Add Card",
    //     add_new: "Add New",
    //     new_items: "New Items",
    //     remove_card: "Remove Card",
    //     edit_group_item: "Edit Group Item",
    //     edit_single_item: "Edit Single Item",
    //     delete_this_item: "Delete this Item",
    //     stock_conversion: "Stock Conversion",
    //     convert_item: "Convert Item",

    //     select_action: "Select Action",
    //     new_tag: "New Tag",
    //     delete_tag: "Delete Tag",
    //     edit_tag: "Edit Tag",
    //     more_settings: "More Settings",
    //     post_conversion: "Post Conversion",

    //     bulk_payment: "Bulk Payment",
    // }
    Month: {
        Jan: "January",
        Feb: "February",
        Mar: "March",
        Apr: "April",
        May: "May",
        Jun: "June",
        Jul: "July",
        Aug: "August",
        Sep: "September",
        Oct: "October",
        Nov: "November",
        Dec: "December",
    },
    Message: {
        update: "Data has been successfully changed",
        updated: "Data has been successfully updated",
        add: "Data has been successfully added",
        registered: "Data has been successfully registered",
        failed: "",
        email: "Please check your mail",
        delete: "Data has been deleted",
        paid: "Payment Successful",
        transaction: "The transaction has been saved",
        void: "The statement line has been successfully deleted.",
        delete_cashcoding: "Transaction successfully deleted", //sprint 63, requester noviyanti, #165556313 (voided)
        declined: "Data has been declined",
        approve: "Data has been successfully approved",
        asset_registered: "Asset has successfully been registered",
        asset_disposed: "Asset has successfully been disposed",
        value_cant_zero: "Value cannot be zero",
        empty_row: "You don't have any data on this table.",
        empty_asset: "You don't have any asset",
        no_result: "There are no items to display.",
        cannot_zero: "Value cannot be zero.",
        upload: "Upload successful",
        please_wait: "Please Wait...",
        enter_withholding_value: "Please enter withholding value.",
        email_sent: "Email Successfully Sent",
        value_adj_cannot_zero: "Value of Adjustment cannot be 0",
        may_take_while: "This may take a while.",
        dont_close_window: "Please don’t close this window.",
        processing_data_import: "Processing data import...",
        success_stock_adj: "Successful Stock Adjustment",
        proccess_data_import: "Processing data import...",
        rundep_rollback: "Depreciation is now current to ",
        add_account_asset: "Fixed assets settings successfully saved",
        connect_cash_bank: "{0} Succesfully Created",
        duplicate_file: " has already exist. Please try with another file name.",
        max_upload: "Maximum file to upload is ",
        max_download: "Maximum file to download is ",
        apply_filter: "Please apply filter to show data",
        copy_contact_type: "Contact type successfully updated.",
        cash_coding: "Data has been saved & reconciled successfully.",
        rec_success: "The transaction has been successfully reconciled",
        rec_unrec: "The transaction has been unreconciled",
        delete_unrec: "Transaction has been manually unreconciled",
        rec_void: "Transaction successfully deleted", //sprint 63, requester noviyanti, #165556313 (voided)
        transfer: "Successfully transferred",
        search_bank_account: "Searching your bank account…",
        empty_transaction: "There are no transactions to display.",
        empty_statement: "There are no statement lines to display.",
        empty_bank_statement: "There are currently no bank statement lines to reconcile.",
        thank_you_for_waiting: "Thank you for waiting",
        onefeed_proccess: "It seems the process took longer than we expected. We will continue running in the background, and you can come back a while later.",
        no_transaction_selected: "There is  no  transactions selected",
        no_data_display: "There’s no data to display",
        no_trans_imported: "No transactions have been imported yet.",
        no_trans_match: "No transaction match with this statement.",
        lock_date: "Lock dates settings has been updated",
        conversion_date: "Conversion dates settings has been updated.",
        delete_user: "{0} has been removed",
        max_size: "Maximum file size 2mb",
        empty_report: "No data for the selected period",
        item_not_found: "Item is not found",
        ownership_empty: "You don't have any transfer history",
        switch_company: "Company will be switched",
        user_status_update: "User status updated",
        change_password: "Your password has been changed successfully",
        change_profile: "Your data have been updated",
        changing_email_cancel: "Changing email address canceled",
        email_confirmation: "Email confirmation resent to",
        payment_bank_success: "Thank you for submitting your Payment Confirmation.",
        error_customer_bank: "Unable to retrieve data for Company Bank Account. Please check your data or remove it completely from your message.",
        proccessing_order: "Processing your order...",
        thank_you: "Thank You",
        charging_failed: "Charging Failed",
        failed_charge_text: "We have failed to charge your credit card. Contact our support or update your details.",
        success_order: "We have succesfully processed your order",
        error_subtotal_minus: "Transaction subtotal cannot amount to a negative number.",
        error_track_minus: "Items Tracked cannot have a negative Unit Price.",
        order_added: "Order successfully added",
        restore_rec: "The statement line has been successfully restored",
        success_invite: "User successfully reinvited",
    },
    Notification: {
        second: "s",
        minute: "m",
        hour: "h",
        notification: "Notifications",
        no_notif_found: "No notification(s) found",
        no_notif_found_detail: "We’ll notify you when something arrives",
        view_all_notif: "View All Nofifications",
        mark_all_as_read: "Mark All as Read",
        mark_all_as_unread: "Mark All as Unread",
        mark_as_read: "Mark as read",
        mark_as_unread: "Mark as unread",
        notification_settings: "Notification Settings",
        loading: "Loading...",
        new_statement_lines: "ready for reconciliation",
        new_statements: "new statement(s) in",
        failed_onefeed_sync: "OneFeed failed to sync",
        failed_onefeed_connection: "OneFeed failed to connect",
        invoice_cron_check_duedate: "Invoice {0} is overdue",
        is_overdue: "is overdue",
        scheduling_failed_repeating_invoice: "created in Draft. Please check item are in stock or lock date is inactive",
        bill_cron_check_duedate: "Bill {0} is overdue",
        scheduling_failed_repeating_bill: "created in Draft. Please check lock date is inactive",
        expense_cron_check_duedate: "Claims {0} is overdue",
        claims: "Claims",
        item_out_of_stock: "{0} is out of stock",
        item_stock: "",
        out_of_stock: "is out of stock",
        depreciation_for: "Depreciation for",
        has_not_been_done: "has not been done",
        for: "for",
        cron_check_depre: "Depreciation for {0} has not been done",
        failed_charge_cc: "Failed to charge your Credit Card. Billing remains unpaid",
        failed_charge_bank_trf: "Your subscription expire today. Please proceed with your payment",
        failed_charge_cc_day3: "Failed to charge your Credit Card. Billing remains unpaid",
        failed_charge_bank_trf_day3: "Subscription has expired and billing remains unpaid. Please proceed with your payment",

        list_notification: "List Notification",
        notif_settings_label: "Select which of these feature you would like to get notify",
        notif_cashbank: "Show your statement for reconciliation and OneFeed",
        notif_sales: "Show your overdue invoice",
        notif_purchase: "Inform you the overdue of your bill",
        notif_expense: "Will inform you of overdued claims",
        notif_inventory: "Message you the stock your tracked items",
        notif_fixedasset: "Inform you about unfinished depreciation",
        notif_contact: "Inform you about unfinished depreciation",

        // Cash Bank
        cash_bank_statement: "statement(s) ready for reconciliation for",
        cash_bank_onefeed_connected: "OneFeed connected to",
        cash_bank_onefeed_fail: "failed to sync",
        background_process_done: "Import Background Process is done",
        please_review_your_data: "Please review your data before finalizing import process",
        there_are: "There are",
        new_data_imported: "new data imported to Harmony",
    },
    Default: {
        language: "en"
    },
    Error: {
        General_error: "Mohon periksa kembali form.",
        item_or_desc: "Item or Description cannot be empty. Please fill one or the other.",
        compare_date_invoice: "Due Date must be after Invoice Date.",
        compare_date_quote: "Expiry Date must be after Quote Date.",
        compare_date_repeat: "Repeating End Date must be after Transaction Date.",
        error_repeating: "Please set up repeating scheduler.",
        compare_date_po: "Delivery Date must be after Date",
        compare_date_bill: "Due Date must be after Bill Date.",
        journal_detail: "Cannot delete row, you must have at least 2 line items",
        inventory_detail: "Cannot delete row, you must have at least 2 line items",
        residual_gt_cost: "Residual Value can't be greater than the Cost Limit!",
        cost_gt_purchase_price: "Cost Limit can't be greater than the Purchase Price!",
        asset_mandatory: "Please edit and complete mandatory fields in the form.",
        error_clone_onegate: "Unable to clone to OneGate. This feature is only available for IDR currency."
    },
    Button: {
        new: "Baru",
        yes: "Ya",
        no: "Tidak",
        cancel: "Batal",
        no_cancel: "Batal",
        save: "Simpan",
        submit: "Submit",
        create: "Buat",
        update: "Perbarui",
        decline: "Tolak",
        approve: "Setuju",
        accept: "Terima",
        apply: "Apply",
        upload: "Upload",
        add_attachment: "Add Attachment",
        choose_lib: "Choose from Library",
        choose_contact: "Choose from Computer",
        add_more_row: "Tambah Baris",
        save_to_draft: "Save to draft",
        remove: "Remove",
        edit: "Edit",
        delete: "Delete",
        delete_recurring: "Delete Recurring",
        send: "Send",
        send_email: "Send Email",
        send_anyway: "Send Anyway",
        share_now: "Share Now",
        preview: "Preview",
        add: "Add",
        show_all: "Show All",
        hide: "Hide",
        save_changes: "Save Changes",
        generate: "Generate",
        please_wait: "Please Wait",
        add_delivery_address: "Add Delivery Address",
        new_delivery_address: "New Delivery Address",
        back: "Back",
        yes_continue: "Yes, continue",
        dispose: "Dispose",
        register: "Register",
        undo_disposal: "Undo Disposal",
        undo_now: "Undo Now",
        change_date: "Change Date",
        preview_pdf: "Preview PDF",

        new_quote: "New Quote",
        new_account: "New Account",
        add_new_note: "Add New Note",
        clone: "Clone",
        new_repeating_invoice: "New Repeating Invoice",
        new_repeating_bill: "New Repeating Bill",
        payment: "Payment",
        add_cn: "Add Credit Note",
        create_payment: "Create Payment",
        create_refund: "Create Refund",
        create_invoice: "Create Invoice",
        create_bill: "Create Bill",
        void_invoice: "Yes, Void Invoice",
        void_bill: "Yes, Void Bill",
        void_cn: "Yes, Void Credit Note",
        void_expense: "Yes, Void Expense Claims",
        void: "Void",
        void_delete: "Delete", //sprint 63, requester noviyanti, #165556313
        copy_link: "Copy link",
        new_cn: "New Credit Note",
        refund: "Refund",
        edit_cn: "Edit Credit Note",
        edit_bill: "Edit Bill",
        edit_expense: "Edit Expense",
        edit_journal: "Edit Journal",
        edit_asset: "Edit Asset",
        create_asset_type: "Create Asset Type",
        inventory_import: "Import Inventory",
        inventory_new: "New Inventory",
        inventory_edit: "Edit Inventory",
        inventory_export_xls: "Export as Excel",
        inventory_stock_adj: "Stock Adjustment",
        delete_item: "Delete Item",
        delete_journal: "Delete Journal",
        delete_contact: "Delete Contact",
        upload_file: "Upload File",
        upload_file_from: "Upload File From",
        continue: "Continue",
        import_goback: "Go back and correct your data",
        import_data: "Import Data",
        review_adj: "Review Adjustment",
        post_adj: "Post Adjustment",
        import_asset: "Import Asset",
        import_contact: "Import Contact",
        import_invoice: "Import Invoice",
        import_bill: "Import Bill",
        run_depre: "Run Depreciation",
        submit_file: "Choose File",
        show_summary: "Show Summary",
        review_journal: "Review Journal",
        post: "Post",
        import_cashbank: "Import Bank Statement",
        add_person: "Add Person",
        show_more: "Show More",
        got_it: "Got It",
        save_reconcile: "Save & Reconcile",
        transfer: "Transfer",
        delete_account: "Delete Account",
        rule: "Rule",
        add_address: "Add Address",
        edit_address: "Edit Address",
        remove_address: "Remove Address",
        delete_address: "Delete Address",
        add_bank_detail: "Add Bank Detail",
        edit_bank_detail: "Edit Bank Details",
        remove_bank_detail: "Remove Bank Details",
        delete_bank_detail: "Delete Bank Details",
        delete_bank: "Delete Bank",
        edit_user: "Edit User",
        remove_user: "Remove User",
        reinvite_user: "Reinvite User",
        cancel_invitation: "Cancel Invitation",
        send_invitation: "Send Invitation",
        invite_new_user: "Invite New User",
        send_verification_email: "Send Verification Email",
        new_email: "New Email",
        new_template: "New Template",
        edit_template: "Edit Template",
        delete_template: "Delete Template",
        delete_file: "Delete File",
        add_file: "Add File",
        download: "Download",
        new_tax: "New Tax",
        new_unit: "New Unit",
        set_conversion_balance: "Set Conversion Date",
        create_new: "Create New",
        browse_file: "Browse File",
        export_as: "Export as",
        yes_delete_trans: "Yes, Delete Transaction", //sprint 63, requester noviyanti, #165556313
        new_company: "New Company",
        go_back: "Go Back",
        change_password: "Change Password",
        clone_to_onegate: "Clone to OneGate",
        back_to_billing: "Back to Billing Page",
        back_to_package: "Back to Package Selection",
        save_card: "Save Card",
        update_card: "Update Card",
        add_card: "Add Card",
        add_new: "Add New",
        new_items: "New Items",
        remove_card: "Remove Card",
        edit_group_item: "Edit Group Item",
        edit_single_item: "Edit Single Item",
        delete_this_item: "Delete this Item",
        stock_conversion: "Stock Conversion",
        convert_item: "Convert Item",
        select_action: "Select Action",
        new_tag: "New Tag",
        delete_tag: "Delete Tag",
        edit_tag: "Edit Tag",
        more_settings: "More Settings",
        post_conversion: "Post Conversion",
        bulk_payment: "Bulk Payment"
    },
    Placeholder: {
        all: "All",
        all_source: "All source",
        any: "Any",
        select_contact: "Select Contact",
        select_item: "Select Item",
        type_to_select: "Type to Select",
        select_account: "Select An Account",
        select_tax: "Select Tax",
        select_unit: "Select Unit",
        select_date: "Select Date",
        select_start: "Select Start",
        select_end: "Select End",
        select_asset_type: "Select asset type",
        select_method: "Select Method",
        select_depre_method: "Select Deprecation Method",
        select_rate_effective: "Select Rate / Effective Life (Years)",
        select_start_date: "Select Start Date",
        select_bank: "Select a bank",
        select_bank_account: "Select Bank Account",
        select_terms: "Select Terms",
        select_type: "Select Type",
        select_status: "Select Status",
        select_your_bank: "Select your bank",
        select_currency: "Select Currency",
        select_account_type: "Select Account Type",
        select_parent_account: "Select Parent Account",
        select_inventory_method: "Select Inventory Method",
        select_country: "Select Country",
        select_role: "Select Roles",
        select_transaction_type: "Select Transaction Type",
        select_items: "Select Items",
        select_menu: "Select Menu",
        select_to: "Select To",
        select_from: "Select From",
        notes: "Add some notes for internal use",
        search: "Search",
        enter_text_here: "Enter Text Here",
        enter_reason: "Enter Reason",
        add_description: "Add description",
        reason_adjustment: "Reason for Adjustment",
        type_to_search: "Type to Search",
        add_quick: "Add Quick Placeholder",
        select_source: "Select a source",
        select_industry: "Select Industry",
        select_timezone: "Select Timezone",
        select_invoice_number: "Select Invoice Number",
        not_found: "Not Found",
        select_tags: "Select Tag",
        ex_bank_name: "ex : Bank Mandiri",
        ex_bank_desc: "ex : 126.0000.978.009",
        ex_account_code: "ex : 1102"
    }
}

export default LanguageID;