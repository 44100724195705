import React from "react";
import { Warning, MonyInput, MonyButton, Common } from "mony-component";
import { useForgotPasswordginPresenter } from "./ResetPasswordPresenter";
import { MonyFooter } from "mony-component";
import { IPageProps } from "../../../libs/type-interface";

export const ResetPasswordPage: React.FC<IPageProps> = (props) => {

    const { state, onChangedValue, onSubmit, onShowHidePassword } = useForgotPasswordginPresenter();

    React.useEffect(() => {
        Common.setTitle(props.pageTitle);
        document.getElementById("loading-js").style.visibility = "hidden";
        document.body.onkeydown = (event) => {
            if (event.keyCode === 13) {
                document.getElementById("btn-submit").click();
            }
        }

        return () => {
            document.body.onkeydown = undefined;
        }
    }, []);

    return (
        <div className="login--container">
            <div className="main-content-login" >
                <div className="content-login">
                    <div className="pt--20">
                        <label className="lbl-forgot">{props.translate("reset_password", "label")}</label>
                    </div>
                    <div className="pt--40 pl--30 pr--30 text-left">
                        <div className="pb--20">
                            <Warning language="en" message={state.error} />
                        </div>
                        <div>
                            <div>
                                <label>{props.translate("new_password", "label")}</label>
                            </div>
                            <div className="pt--10">
                                <MonyInput
                                    inputType={state.showPassword ? "basic" : "password"}
                                    className="passsword-show"
                                    inputDelay={false}
                                    error={state.error.filter((x) => x.path === "newpassword").length > 0 ? true : false}
                                    icon={state.data.newpassword ? <span className="lbl-hidden-pass">{state.showPassword ? "Hide" : "Show"}</span> : null}
                                    onClickIcon={() => onShowHidePassword("newpassword")}
                                    onStateChanged={onChangedValue}
                                    stateKey="newpassword"
                                    value={state.data.newpassword}
                                />
                                <label className="helper-text-modal">{props.translate("password", "information")}</label>
                            </div>
                        </div>
                        <div className="pt--20">
                            <div>
                                <label>{props.translate("confirm_new_password", "label")}</label><br />
                            </div>
                            <div className="pt--10">
                                <MonyInput
                                    inputType={state.showPasswordConfirm ? "basic" : "password"}
                                    className="passsword-show"
                                    inputDelay={false}
                                    error={state.error.filter((x) => x.path === "confirmnewpassword").length > 0 ? true : false}
                                    icon={state.data.confirmnewpassword ? <span className="lbl-hidden-pass">{state.showPasswordConfirm ? "Hide" : "Show"}</span> : null}
                                    onClickIcon={() => onShowHidePassword("confirmnewpassword")}
                                    onStateChanged={onChangedValue}
                                    stateKey="confirmnewpassword"
                                    value={state.data.confirmnewpassword}
                                />
                                <label className="helper-text-modal">{props.translate("password", "information")}</label>
                            </div>
                        </div>
                        <div className="pt--40 pb--20">
                            <MonyButton id="btn-submit" className="button--save" value="Reset Password" onClick={onSubmit} disabled={state.disableSubmit} />
                        </div>
                    </div>
                </div>
                <label className="lbl-info"></label>
                <MonyFooter className="mt--72 footer-copyright" />
            </div>
        </div>
    );
}