import React from "react";
import Tag from "antd/es/tag";
import { MonyCard, Spacing } from ".";

export interface IMonyExtensionCardProps {
    title?: string;
    link?: string;
    description?: string;
    image?: string;
    logo?: string;
    developer?: string;
    installed?: boolean;
    height?: string | number;
    width?: string | number;
}

const MonyExtensionCard: React.FC<IMonyExtensionCardProps> = (props) => {

    props = { ...defaultProps, ...props };

    return (
        <div className="wd--300 m--20 ant-col ant-col-xs-24 ant-col-sm-8" style={{ "paddingLeft": "20px;", "paddingRight": "20px;", "paddingTop": "20px;", "paddingBottom": "20px;" }}>
            <MonyCard
                cardTitle={props.title}
                cardDescription={props.description}
                rateValue={5}
                titleLink={props.link}
                imgSource={props.image}
                topRight={
                    props.installed ?
                        <div className="right-cell font-bold">
                            <Tag className="wd--80 text-center m--0" color="#f50">Installed</Tag>
                        </div>
                        : null
                }
                others={
                    <>
                        <div className="mt--8 dis-flex">
                            <img height={40} width={40} src={props.logo} />
                            <Spacing spacingType="horizontal" width={10} />
                            <div style={{ position: "relative", width: 300 - 50 - 30 }}>
                                <label style={{ position: "absolute", bottom: 0 }}>{props.developer}</label>
                            </div>
                        </div>
                        {/* <div className="wd---100 dis-table mt--8">
                            <div className="left-cell font-bold">
                                Price
                            </div>
                            <div className="right-cell font-bold">
                                <Tag color="#87d068">FREE</Tag>
                            </div>
                        </div> */}
                        <div className="wd---100 dis-table mt--10">
                            <div className="left-cell">
                            </div>
                            <div className="right-cell font-bold">
                                <Tag className="wd--80 text-center m--0" color={props.installed ? "#87d068" : "#2db7f5"}>
                                    {
                                        props.installed ? "Setting" : "Install"
                                    }
                                </Tag>
                            </div>
                        </div>
                    </>
                } />
        </div>
    );
}

const defaultProps = {
    title: "",
    link: "",
    description: "",
    height: 300,
    width: 300
}

export default React.memo(MonyExtensionCard);