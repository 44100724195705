import React from "react";
import { Steps } from "antd";
import { Link } from "react-router-dom";
import { Icons, Loading, Warning, Common, MonyFooter, MonyTagLabel, MonyButton } from "mony-component";
import { IPageProps } from "../../../libs/type-interface";
import { useSignupPresenter, SignupStep1Page, SignupStep2Page, SignupStep3Page } from "./index";
import { useSelector } from "react-redux";
import { ContainerState } from "../../redux/store";
import {
    injectGtmScript,
    // removeGtmScript
} from "../../../libs/helper";
import { LanguageSwitcher } from "../shared-components";

// const Logo = require("../../../assets/images/logomark.svg");
// const Logoback = require("../../../assets/images/arrow-left.svg");
const Logo = "logomark.svg";
const Logoback = "arrow-left.svg";

const listSteps = [
    {
        title: 'First',
        content: 'First-content'
    }, {
        title: 'Second',
        content: 'Second-content'
    }, {
        title: 'Last',
        content: 'Last-content'
    }
];

export const SignupPage: React.FC<IPageProps> = (props) => {

    const { state, onPrevStep } = useSignupPresenter(props);
    const { signupStep } = useSelector((state: ContainerState) => state.container);

    React.useEffect(() => {
        Common.setTitle(props.pageTitle);
        injectGtmScript();

        return () => {
            document.body.onkeydown = undefined;
            // removeGtmScript();
        }
    }, []);

    const ContentStep = React.useCallback(() => [
        <SignupStep1Page translate={props.translate} />,
        <SignupStep2Page translate={props.translate} />,
        <SignupStep3Page translate={props.translate} />
    ], [signupStep, props.language, props.translate, state.signup, state.companyTypes]);

    return (
        <div className="signup--container">
            <Loading text="Creating your account, please wait" showLoading={state.loading} />
            <div className="div-step">
                <Steps size="small" current={signupStep}>
                    {listSteps.map((item) => <Steps.Step key={item.title} title={item.title} />)}
                </Steps>
            </div>

            <div className="main-content-signup">
                <div className="content-signup">
                    {
                        signupStep > 0 ?
                            <div>
                                <div className="float-left">
                                    <a className="pointer" onClick={onPrevStep}><Icons glyph={Logoback} className="icon-signup-back" /></a>
                                </div>
                                <div className="clear"></div>
                                <label className="lbl-signup">{props.translate('tell_us_about', 'label')} <br /> {props.translate('your_company', 'label')}</label>
                            </div>
                            :
                            <div className="pt--20">
                                <Icons glyph={Logo} className="icon-logo" />
                                <label className="lbl-login font-login">{props.translate('welcome_first', 'label')}</label>
                            </div>
                    }

                    <div className="pt--10 pl--30 pr--30 text-left">
                        <div className="pb--20">
                            {state.error.length > 0 ? <Warning scrollableKey="main-content-signup" scrollableType="class" translate={props.translate} message={state.error} /> : null}
                        </div>
                        {ContentStep()[signupStep]}
                    </div>
                </div>
                <label className="mt--90 lbl-info font-weight-normal">{props.translate('already_account', 'label')} ? <MonyTagLabel onClick={() => window.location.href = "/login"} textColor="dodger-blue" tagClassName="pointer bg-transparent no-border">{props.translate('login_here', 'label')}</MonyTagLabel></label>
                <LanguageSwitcher language={props.language} onClick={(language: string) => window.MonyHelper.MicroFE.changeLanguage(language)} />
                <MonyFooter className={signupStep == 0 ? "mt--20 mb--10" : signupStep == 1 ? "mt--130 mb--5" : state.signup.company_type == 1 ? "mt--43" : "mb--5"} />
            </div>
        </div>
    );
}