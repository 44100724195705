const checkIsSignup = () => ["/sign-up", "sign-up-success"].includes(window.location.pathname);
const getVarDataLayer = () => "dataLayer"; //checkIsSignup() ? "dataLayer" : "dataLayerApp";

export const pushGtm = (event: "signin" | "signup", eventAction: string = "", eventLabel: string = "") => {
    if (window.dataLayer) {
        dataLayer.push({
            "event": event,
            "event_category": event,
            "event_action": eventAction,
            "event_label": eventLabel
        });
    }
}

export const pushGtmPageView = () => {
    const varDataLayer = getVarDataLayer();
    if (window[varDataLayer]) {
        if (window[varDataLayer]) {
            window[varDataLayer].push({ "event": "pageViewApp" });
        }
    }
}

export const pushGtmSignin = (companyId: string, userId: string) => {
    const varDataLayer = getVarDataLayer();
    if (window[varDataLayer]) {
        window[varDataLayer].push({
            "event": "signin",
            "event_category": "signin",
            "event_action": `${companyId}-${userId}`,
            "event_label": ""
        });
    }
}

export const removeGtmScript = () => {
    const removeScript = !checkIsSignup() ? "gtm-script-website" : "gtm-script-app";
    const existRemove = document.getElementById(removeScript);
    if (existRemove) {
        delete window.dataLayer;
        document.head.removeChild(existRemove);
        const existRemoveNoscript = document.getElementById("noscript-" + removeScript);
        if (existRemoveNoscript) {
            document.body.removeChild(existRemoveNoscript);
        }
    }
}

export const injectGtmScript = () => {
    const isSignup = checkIsSignup();
    const scriptId = isSignup ? "gtm-script-website" : "gtm-script-app";

    // removeGtmScript();

    const exist = document.getElementById(scriptId);
    if (!exist) {

        const gtmId = isSignup ? global.GTM_ID_WEBSITE : global.GTM_ID_APP;
        if (!gtmId) {
            return;
        }

        /**
         * Dibedakan nama variable dataLayer untuk di page signup dan login/afterlogin untuk persiapan kalau sudah bisa multiple GTM
         */
        const varDataLayer = getVarDataLayer();
        const gtmAuth = isSignup ? global.GTM_AUTH_WEBSITE : global.GTM_AUTH_APP;
        const gtmPreview = isSignup ? global.GTM_PREVIEW_WEBSITE : global.GTM_PREVIEW_APP;
        const script = document.createElement("script");
        script.id = scriptId;
        script.type = "text/javascript";
        script.text = `(
        function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),
              dl=l!='${varDataLayer}'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x';
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','${varDataLayer}','${gtmId}');`;
        document.head.appendChild(script);

        const noscript = document.createElement("noscript");
        noscript.id = "noscript-" + scriptId;
        const iframe = document.createElement("iframe");
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
        iframe.height = "0";
        iframe.width = "0"
        iframe.className = "hidden";
        noscript.appendChild(iframe)
        document.body.insertBefore(noscript, document.body.firstChild);
    }
}