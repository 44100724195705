import { combineReducers } from 'redux';
import { getAppReducer } from '../../../libs/redux/reducers/AppReducer';
import { StoreType } from '../types';
import { getContainerReducer } from './ContainerReducer';

export type ReducerNameType = "POS_CONFIG" | "POS_USER";

const rootReducer = combineReducers<StoreType>({
    container: getContainerReducer,
    app: getAppReducer
});

export default rootReducer;