import React, { PropsWithChildren } from "react";
import { Loader } from ".";

const Loading: React.FC<PropsWithChildren<{ id?: string, showLoading?: boolean, text?: string }>> = (props) => {
    const wrapper = document.getElementsByClassName("wrapper");
    const wrapperWidth = wrapper.length !== 0 ? wrapper[0].clientWidth : 0;
    const exist = document.getElementsByClassName("clearfix loading--content");

    for (let i = 0; i < exist.length; i++) {
        exist[i]["hidden"] = true;
    }

    return (
        <div id={props.id} className="clearfix loading--content" hidden={!props.showLoading} style={{ width: `calc(100% - ${wrapperWidth}px)` }}>
            <div id={`${props.id}-content`} className="loading-content pt--50">
                <Loader color="#419aff" size={16} margin={4} paddingTop={0} paddingBottom={0} showText={false} />
                <label className="pt--40 mb--20 lbl-info-loading">{props.text || "Please Wait..."}</label>
                {props.children}
            </div>
        </div>
    );
}

export default React.memo(Loading);
