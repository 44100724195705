import React from "react";
import { useDatasourceState } from "../custom-state";
import { MonyAutoComplete } from "mony-component";
import { ContactApi } from "../api";
import {
    IFilter,
    ContactType,
    StateChageEvent,
    ContactFilterType
} from "../type-interface";

export interface IContactEmployeeAutoCompleteProps {
    value: string | number;
    stateKey?: string;
    placeholder?: string;
    contactType?: ContactType;
    contactFilterType?: ContactFilterType;
    stateRowIndex?: number;
    onStateChanged?: StateChageEvent;
    remoteFilter?: boolean;
    error?:boolean
}

interface IContactFilter extends IFilter {
    keyword: string;
    type: ContactType;
    filter: ContactFilterType;
}

const ContactEmployeeAutoComplete: React.FC<IContactEmployeeAutoCompleteProps> = (props) => {

    const defaultFilter: IContactFilter = {
        keyword: "",
        type: props.contactType,
        filter: "employee"
    }

    const { state, actions } = useDatasourceState({
        apiFetch: ContactApi.FetchAll,
        defaultFilter: defaultFilter,
        dataMapper: [
            { from: "contact_name", to: "label" },
            { from: "contact_id", to: "value" }
        ]
    });

    React.useEffect(() => {
        if (state.data.length === 0) {
            actions.LoadData();
        }
    }, []);

    const onReloadData = React.useCallback(async (value: string) => {
        actions.ChangeFieldFilter("keyword", value);
    }, [state]);

    return (
        <MonyAutoComplete
            language={"id"}
            data={state.data}
            value={props.value}
            placeholder={props.placeholder || "Select Contact Employee"}
            stateKey={props.stateKey}
            stateRowIndex={props.stateRowIndex}
            onStateChanged={props.onStateChanged}
            remoteFilter={props.remoteFilter}
            onRemoteFilter={onReloadData}
            allowClear={true}
            error={props.error}
        />
    );
}

export default ContactEmployeeAutoComplete;