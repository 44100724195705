export const Default =
{
    BASE_URL: window.location.origin,
    WAITING_MESSAGE: 2,
    MAXUPLOAD: 5,
    DISTANCE: 5,
    DASHBOARD_URL: "/commission/dashboard",
    DELAY: 200,
    minAvg: 2,
    maxAvg: 10,
    TRIAL: "day(s) left in your trial",
    CLOSETO_EXP_SUBS: "day(s) left until your subscription expire",
    GRACE_PERIOD: "Subscription expired. You are currently in grace period",
    EXP_SUBS: "Your subscription has expired",
    DELAY_TYPING: 350,
    LIMIT_COMPANY: 3,
    TBL_EMPTYDATA: "You don't have any data on this table",
    TBL_EMPTYREPORT: "Please apply filter to show data",
    TBL_EMPTYREPORTDATA: "No data for the selected period",
    TBL_NORESULT: "There are no items to display",
    GENERAL_ERROR: "Please recheck your form",
}