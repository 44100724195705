import { Pilet, PiletApi } from './types';

/**
 * Sets up the given pilet by calling the exported `setup` function
 * on the pilet.
 * @param app The pilet's evaluated content.
 * @param api The generated API for the pilet.
 */
export function setupPilet(app: Pilet, api: PiletApi) {
  try {
    return app.setup(api);
  } catch (e) {
    console.error(`Error while setting up ${app && app.name}.`, e);
  }
}
