import React from "react";
import { MonyInput, MonyButton, TypeTranslate, Common } from "mony-component";
import { useSignupPresenter } from "./SignupPresenter";
import { queryParamsToObject } from "../../../../src/libs/helper";

interface IProps {
    translate: TypeTranslate;
}

export const SignupStep1Page: React.FC<IProps> = (props) => {

    const {
        state,
        onChangedSignup,
        onShowHidePassword,
        onShowHidePasswordConfirm,
        onNextStep
    } = useSignupPresenter(props);

    React.useEffect(() => {
        // const params = queryParamsToObject(location.search);
        // const paramReferralCode = params["referral"] || "";
        // let cookieReferralCode = Common.getCookie("referral") || "";

        // if (paramReferralCode && cookieReferralCode !== paramReferralCode) {
        //     cookieReferralCode = paramReferralCode;
        //     Common.setCookie(cookieReferralCode, "referral");
        // }
        // onChangedSignup("proref_code", cookieReferralCode);
        onChangedSignup("proref_code", state.cookieReferralCode);
    }, []);

    const Referal = React.useCallback(() => {
        const content = (
            <div>
                <div>
                    <label className="font-login">Referral Code (Optional) </label>
                </div>
                <div className="pt--10">
                    <MonyInput
                        className="passsword-show"
                        placeholder="Insert 6-15 characters without space"
                        maxLength={15}
                        error={state.error.filter((x) => x.path === "proref_code").length > 0 ? true : false}
                        showClear={true}
                        value={state.signup.proref_code}
                        stateKey="proref_code"
                        onStateChanged={onChangedSignup} />
                </div>
            </div>
        );

        return (
            <div className="pt--20">
                {content}
            </div>
        );
    }, [state.signup.proref_code, state.error]);

    return (
        <div>
            <div>
                <div>
                    <label className="font-login">{props.translate('full_name', 'label')}<span className="required"> *</span></label>
                </div>
                <div className="pt--10">
                    <MonyInput
                        id="user_fullname"
                        maxLength={50}
                        error={state.error.filter((x) => x.path === "user_fullname").length > 0 ? true : false}
                        value={state.signup.user_fullname}
                        stateKey="user_fullname"
                        onStateChanged={onChangedSignup}
                    />
                </div>
            </div>
            {/* <div className="pt--20">
                <div>
                    <label className="font-login">{props.translate('phone_number', 'label')}<span className="required"> *</span></label>
                </div>
                <div className="pt--10">
                    <MonyInput
                        id="phone_number"
                        placeholder="+62xxxxxxxxxx"
                        error={state.error.filter((x) => x.path === "user_phone").length > 0 ? true : false}
                        onStateChanged={onChangedSignup}
                        stateKey="user_phone"
                        value={state.signup.user_phone}
                    />
                </div>
            </div> */}
            <div className="pt--20">
                <div>
                    <label className="font-login">{props.translate('email_address', 'label')} <span className="required"> *</span></label>
                </div>
                <div className="pt--10">
                    <MonyInput
                        id="user_email"
                        error={state.error.filter((x) => x.path === "user_email").length > 0 ? true : false}
                        placeholder={props.translate('example_mail', 'placeholder')}
                        onStateChanged={onChangedSignup}
                        stateKey="user_email"
                        value={state.signup.user_email}
                    />
                </div>
            </div>
            <div className="pt--20">
                <div>
                    <label className="font-login">{props.translate('password', 'label')} <span className="required"> *</span></label><br />
                    <label className="helper-text-modal">{props.translate('password', 'information')}</label>
                </div>
                <div className="pt--10">
                    <MonyInput
                        id="password"
                        inputType={state.showPassword ? "basic" : "password"}
                        className="passsword-show"
                        inputDelay={false}
                        error={state.error.filter((x) => x.path === "password").length > 0 ? true : false}
                        icon={state.signup.password ? <span className="lbl-hidden-pass">{props.translate(state.showPassword ? "hide" : "show", "label")}</span> : null}
                        onClickIcon={onShowHidePassword}
                        onStateChanged={onChangedSignup}
                        stateKey="password"
                        value={state.signup.password}
                    />
                </div>
            </div>
            <div className="pt--20">
                <div>
                    <label className="font-login">{props.translate('confirm_password', 'label')}<span className="required"> *</span></label><br />
                    <label className="helper-text-modal">{props.translate('password', 'information')}</label>
                </div>
                <div className="pt--10">
                    <MonyInput
                        id="confirm_password"
                        inputType={state.showPasswordConfirm ? "basic" : "password"}
                        className="passsword-show"
                        inputDelay={false}
                        error={state.error.filter((x) => x.path === "confirm_password").length > 0 ? true : false}
                        icon={state.signup.confirm_password ? <span className="lbl-hidden-pass">{props.translate(state.showPasswordConfirm ? "hide" : "show", "label")}</span> : null}
                        onClickIcon={onShowHidePasswordConfirm}
                        onStateChanged={onChangedSignup}
                        stateKey="confirm_password"
                        value={state.signup.confirm_password}
                    />
                </div>
            </div>
            {Referal()}
            <div className="pt--40 pb--20">
                <MonyButton
                    id="btn-step1"
                    className="button--save"
                    value={props.translate('continue', 'button')}
                    onClick={onNextStep}
                />
            </div>
        </div>
    );
}