import { IErrorModel } from "../../../libs/type-interface";

export enum APP_ENUM {
    SET_LOADING = "SET_LOADING",
    SET_ERROR = "SET_ERROR",
    RESET_APP_STATE = "RESET_APP_STATE"
}

export interface ErrorModel extends IErrorModel {
    type: "error" | "warning" | "info";
};

export interface ErrorType {
    [key: string]: Array<ErrorModel>;
}

export interface AppStateType {
    loading: boolean;
    error: ErrorType;
}

export interface SetLoadingActionType {
    type: typeof APP_ENUM.SET_LOADING;
    payload: boolean;
}

export interface SetErrorActionType {
    type: typeof APP_ENUM.SET_ERROR;
    payload: ErrorType;
}

export interface ResetAppStateActionType {
    type: typeof APP_ENUM.RESET_APP_STATE;
}

export type AppActionType = SetLoadingActionType | SetErrorActionType | ResetAppStateActionType;