import React from "react";
import { Icons, MonyButton, MonyInput, Warning, Common, MonyFooter, MonyTagLabel } from "mony-component";
import { useLoginPresenter } from "./LoginPresenter";
import { Link } from "react-router-dom";
import { IPageProps } from "../../../libs/type-interface";
import { LanguageSwitcher } from "../shared-components";
import { injectGtmScript } from "../../../libs/helper";

const Logo = "logomark.svg";

export const LoginPage: React.FC<IPageProps> = (props) => {

    const { state, onChangedLogin, onLogin, onShowHidePassword } = useLoginPresenter();

    React.useEffect(() => {
        Common.setTitle(props.pageTitle);
        injectGtmScript();
        document.getElementById("loading-js").style.visibility = "hidden";
        document.body.onkeydown = (event) => {
            if (event.keyCode === 13) {
                document.getElementById("btn-submit").click();
            }
        }

        return () => {
            document.body.onkeydown = undefined;
        }
    }, []);

    return (
        <div className="login--container">
            <div className="main-content-login">
                <div className="top--70 content-login">
                    <div className="pt--20">
                        <Icons glyph={Logo} className="icon-logo" />
                        <label className="font-login lbl-login">{props.translate('login', 'label')}</label>
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left">
                        <div className="pb--20">
                            <Warning translate={props.translate} message={state.error} />
                        </div>
                        <div>
                            <div>
                                <label className="font-login">{props.translate("email_address", "label")}</label>
                            </div>
                            <div className="pt--10">
                                <MonyInput
                                    id="wmd-input"
                                    key="username"
                                    inputDelay={false}
                                    autoFocus={true}
                                    className={state.error.filter((x) => x.path === "username").length > 0 ? "input-type input-error" : "input-type "}
                                    onStateChanged={onChangedLogin}
                                    stateKey="username"
                                    value={state.login.username} />
                            </div>
                        </div>
                        <div className="pt--20" >
                            <div>
                                <label className="font-login">{props.translate('password', 'label')}</label>
                            </div>
                            <div className="pt--10">
                                <MonyInput
                                    key="password"
                                    inputType={state.showPassword ? "basic" : "password"}
                                    className="pr--40"
                                    inputDelay={false}
                                    icon={state.login.password ? <span className="lbl-hidden-pass">{props.translate(state.showPassword ? "hide" : "show", "label")}</span> : null}
                                    onClickIcon={onShowHidePassword}
                                    error={state.error.filter((x) => x.path === "password").length > 0 ? true : false}
                                    onStateChanged={onChangedLogin}
                                    stateKey="password"
                                    value={state.login.password} />
                            </div>
                            <div className="pt--10 text-right">
                                <Link to="/forgot" className="helper-text-modal ">{props.translate('forgot_your_password', 'label')}?</Link>
                            </div>
                        </div>
                        <div className="pt--20 pb--50">
                            <MonyButton
                                id="btn-submit"
                                // ref={refBtnLogin}
                                ajax={true}
                                classIcon="button-loading-icon-submit"
                                style={{ width: '100%', textAlign: "center" }}
                                // classNameLoadingMonyButton="button-loading-classIcon"
                                disabled={!state.login.username && !state.login.password}
                                className="button--save"
                                value={props.translate('login', 'button')}
                                onClick={onLogin} />
                        </div>
                    </div>
                </div>

                <label className="mt--90 lbl-info font-weight-normal">{props.translate('new_harmony', 'label')}? <MonyTagLabel onClick={() => window.location.href = "/sign-up"} textColor="dodger-blue" tagClassName="pointer bg-transparent no-border">{props.translate('sign_up_free', 'label')}</MonyTagLabel></label>
                <LanguageSwitcher language={props.language} onClick={(language: string) => window.MonyHelper.MicroFE.changeLanguage(language)} />
                <MonyFooter className="mt--72 footer-copyright" />
            </div>
        </div>
    );
}