import React from "react";
import { Chart as GoogleChart } from "react-google-charts";
import { Loader } from ".";

const MonyPieChart: React.FC<{ height?: number, width?: number, data: any, title: string }> = (props) => {

  props = { ...defaultProps, ...props };

  return (
    <GoogleChart
      width={props.width}
      height={props.height}
      chartType="PieChart"
      loader={<Loader minWidth={props.width}/>}
      data={props.data}
      options={{
        title: props.title,
        annotations: {
          // remove annotation stem and push to middle of chart
          stem: {
            color: 'transparent',
            length: 120
          },
          textStyle: {
            color: '#9E9E9E',
            fontSize: 18
          }
        },
        pieHole: 0.7,
        chartArea: {
          height: "80%",
          width: "100%",
          top: 50,
          right: 50,
          bottom: 50,
          left: 50
        },
        // legend: "none",
        pieSliceText: "none", //value
        sliceVisibilityThreshold: 0,
        tooltip: {
          text: "value"
        },
        animation: {
          duration: 2000,
          easing: "out",
          startup: true
        }
      }}
    />
  );
}

const defaultProps = {
  height: 200,
  width: 200,
  data: []
}

export default React.memo(MonyPieChart);