import React from "react";
import { useDatasourceState } from "../custom-state";
import { GrowlMessage, MonyAutoComplete, IMonyAutoCompleteProps, MonyAutoCompletePropsDefaultProps } from "mony-component";
import { ContactApi } from "../api";
import {
    IFilter,
    ContactType,
    ContactFilterType
} from "../type-interface";

export interface IContactAutoCompleteProps extends IMonyAutoCompleteProps {
    // value: string | number;
    // stateKey?: string;
    // placeholder?: string;
    contactType?: ContactType;
    contactFilterType?: ContactFilterType;
    // stateRowIndex?: number;
    // onStateChanged?: StateChageEvent;
    // remoteFilter?: boolean;
}

interface IContactFilter extends IFilter {
    keyword: string;
    type: ContactType;
    filter: ContactFilterType;
}

const ContactAutoComplete: React.FC<IContactAutoCompleteProps> = (props) => {

    props = { ...MonyAutoCompletePropsDefaultProps, ...props };

    const defaultFilter: IContactFilter = {
        keyword: "",
        type: props.contactType,
        filter: props.contactFilterType
    }

    const { state, actions } = useDatasourceState({
        apiFetch: ContactApi.FetchAll,
        defaultFilter: defaultFilter,
        dataMapper: [
            { from: "contact_name", to: "label" },
            { from: "contact_id", to: "value" }
        ]
    });

    React.useEffect(() => {
        if (state.data.length === 0) {
            actions.LoadData();
        }
    }, []);

    const onReloadData = React.useCallback(async (value: string) => {
        actions.ChangeFieldFilter("keyword", value);
    }, [state]);

    const onCreateOtg = React.useCallback(async (name: string, callback?: (errorMessage?: any) => void) => {
        const response = await ContactApi.CreateContactOtg(name, props.contactType);
        if (response.success) {
            GrowlMessage.Success(`Contact ${name} has been created!`);
            await onReloadData(name);
            state.data.push({ label: name, value: response.data.contact_id });
            props.onStateChanged(props.stateKey, response.data.contact_id)
            // await Load("");
            // onSuccess(response.data.contact_id);
        } else {
            // onError(response.messages);
        }
        if (callback) {
            callback(response.messages);
        }
    }, [state]);

    return (
        <MonyAutoComplete
            language={"id"}
            data={state.data}
            // value={props.value}
            placeholder={props.placeholder || "Select Contact"}
            // stateKey={props.stateKey}
            // stateRowIndex={props.stateRowIndex}
            // onStateChanged={props.onStateChanged}
            // remoteFilter={props.remoteFilter}
            onRemoteFilter={onReloadData}
            // allowClear={true}
            remoteFilter={props.remoteFilter}
            allowClear={props.allowClear}
            stateKey={props.stateKey}
            stateRowIndex={props.stateRowIndex}
            onStateChanged={props.onStateChanged}
            value={props.value}
            minLength={props.minLength}
            divClassName={props.divClassName}
            className={props.className}
            classAddNew={props.classAddNew}
            showAdds={props.showAdds}
            disabled={props.disabled}
            readonly={props.readonly}
            notFound={props.notFound}
            error={props.error}
            flatBox={props.flatBox}
            border={props.border}
            dropdownMatchSelectWidth={props.dropdownMatchSelectWidth}
            stateChangedType={props.stateChangedType}
            stateKeySearch={props.stateKeySearch}
            onAdd={onCreateOtg}
            onBlur={props.onBlur}
            onRawInput={props.onRawInput}
        />
    );
}

export default ContactAutoComplete;