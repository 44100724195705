import React from "react";
import { IErrorModel } from "../../../libs/type-interface";
import { UserApi } from "../../../libs/api";
import { pushGtm } from "../../../libs/helper";

export interface ILoginModel {
    username: string;
    password: string;
}

export interface LoginPresenterType {
    state: {
        login: ILoginModel;
        showPassword: boolean;
        loading: boolean;
        error: Array<IErrorModel>
    };
    SetLoading: (loading: boolean) => void;
    SetError: (error: Array<IErrorModel>) => void;
    onChangedLogin: (key: string, value: any) => void;
    onLogin: (ajaxCallback: () => void) => void;
    onShowHidePassword: () => void;
}

export const useLoginPresenter = (): LoginPresenterType => {

    const [login, setLogin] = React.useState<ILoginModel>({ username: "", password: "" });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [error, setError] = React.useState<Array<IErrorModel>>([]);

    const onChangedLogin = React.useCallback((key: string, value: any) => {
        setLogin({ ...login, [key]: value });
    }, [login]);

    const onLogin = React.useCallback(async (ajaxCallback: () => void) => {
        const response = await UserApi.ValidateLogin(login);
        if (response.success) {
            const resProfile = await UserApi.UserProfile(response.data.token);
            if (resProfile.success) {
                const userId = resProfile.data.user_id;
                const companyId = resProfile.data.current_company.company_id;
                if (window.dataLayer && resProfile.success) {
                    pushGtm("signin", `${companyId}-${userId}`);
                }
                window.location.href = "/dashboard";
            } else {
                setError(resProfile.messages);
            }
        } else {
            setError(response.messages);
        }
        if (ajaxCallback) {
            ajaxCallback();
        }
    }, [login]);

    const onShowHidePassword = React.useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);

    const SetLoading = React.useCallback((loading: boolean) => {
        setLoading(loading);
    }, [loading]);

    const SetError = React.useCallback((error: Array<IErrorModel>) => {
        setError(error)
    }, [error]);

    return {
        state: {
            login,
            showPassword,
            loading,
            error
        },
        SetLoading,
        SetError,
        onChangedLogin,
        onLogin,
        onShowHidePassword
    }
}