import { AppStateType, AppActionType, APP_ENUM } from '../types/AppTypes';

const initialState: AppStateType = {
    loading: false,
    error: null
}

export const getAppReducer = (
    state = initialState,
    action: AppActionType
): AppStateType => {
    if (action.type === APP_ENUM.SET_LOADING) {
        return {
            ...state,
            loading: action.payload
        };
    } else if (action.type === APP_ENUM.SET_ERROR) {
        return {
            ...state,
            error: { ...state.error, ...action.payload }
        };
    } else if (action.type === APP_ENUM.RESET_APP_STATE) {
        return initialState;
    } else {
        return state;
    }
};