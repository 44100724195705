import React from "react";
import { useDatasourceState } from "../custom-state";
import { MonyAutoComplete, IMonyAutoCompleteProps, MonyAutoCompletePropsDefaultProps, TypeTranslate } from "mony-component";
import { IFilter } from "../type-interface";
import { SaasApi } from "../api";
// import { PiletApi } from "piral";
// import { useLanguageState } from "../custom-state";

export interface ISystemCounterAutoCompleteProps extends IMonyAutoCompleteProps {
    docTypeCode?: string;
    moduleCode?: string;
    translate?: TypeTranslate;
    // piral?: PiletApi;
}

interface ISystemCounterFilter extends IFilter {
    keyword: string;
    docTypeCode: string;
    moduleCode: string;
}

const SystemCounterAutoComplete: React.FC<ISystemCounterAutoCompleteProps> = (props) => {

    props = { ...MonyAutoCompletePropsDefaultProps, ...props}

    // const { translate } = useLanguageState(props.piral);
    const defaultFilter: ISystemCounterFilter = {
        keyword: "",
        docTypeCode: props.docTypeCode,
        moduleCode: props.moduleCode
    }

    const { state, actions } = useDatasourceState({
        apiFetch: SaasApi.DocumentNumberGroupListAll,
        defaultFilter: defaultFilter,
        queryStringMapper: [
            { from: "keyword", to: "search", default: "" },
            { from: "docTypeCode", to: "doc_type", default: "" },
            { from: "moduleCode", to: "module", default: "" }
        ],
        dataMapper: [
            { from: "description", to: "label" },
            { from: "doc_type_code", to: "value" }
        ]
    });

    const loaded = React.useRef(false);

    React.useEffect(() => {
        if (state.data.length === 0) {
            actions.LoadData(true);
            loaded.current = true;
        }
        return () => {
        }
    }, []);

    const onRemoteFilter = React.useCallback(async (keyword: string, keyState?: string) => {
        actions.ChangeFieldFilter("keyword", keyword);
    }, [state]);

    return (
        <MonyAutoComplete
            key={props.key}
            // language={"id"}
            placeholder={props.translate && props.translate("choose_system_counter_code", "placeholder")}
            remoteFilter={true}
            allowClear={true}
            data={state.data}
            stateKey={props.stateKey}
            stateRowIndex={props.stateRowIndex}
            onStateChanged={props.onStateChanged}
            value={props.value}
            minLength={props.minLength}
            divClassName={props.divClassName}
            className={props.className}
            classAddNew={props.classAddNew}
            showAdds={props.showAdds}
            disabled={props.disabled}
            readonly={props.readonly}
            notFound={props.notFound}
            error={props.error}
            flatBox={props.flatBox}
            border={props.border}
            dropdownMatchSelectWidth={props.dropdownMatchSelectWidth}
            stateChangedType={props.stateChangedType}
            stateKeySearch={props.stateKeySearch}
            onAdd={props.onAdd}
            onBlur={props.onBlur}
            onRemoteFilter={props.onRemoteFilter || onRemoteFilter}
            onRawInput={props.onRawInput}
            translate={props.translate}
        />
    );
}

export default SystemCounterAutoComplete;