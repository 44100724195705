import React from "react";
import { Default } from "../const/Default";
import { TextAreaProps } from "../interfaces/TextAreaProps";
import { useDebounce } from "use-lodash-debounce";

const TextArea: React.FC<TextAreaProps> = (props) => {

    props = { ...defaultProps, ...props };

    const [state, setState] = React.useState({ focus: false, paste: false, keyDown: "", reff: undefined });
    const [value, setValue] = React.useState(props.value);
    const lastValue = React.useRef(value);
    const variable = React.useRef({ count: 0, textInput: undefined });
    const debouncedValue = useDebounce(value, Default.DELAY_TYPING);
    const hasSynced = React.useRef(false);

    React.useEffect(() => {
        if (props.value !== value && (variable.current.count === 0 || hasSynced.current)) {
            lastValue.current = props.value;
            setValue(props.value);
        }
    }, [props]);

    React.useEffect(() => {
        if (lastValue.current !== value) {
            lastValue.current = value;
            if (props.onStateChanged) {
                props.onStateChanged(props.stateKey || "", value || "", props.stateRowIndex);
            } else {
                props.onChange(value);
            }
            hasSynced.current = true;
        }
    }, [debouncedValue, hasSynced]);

    const onChange = React.useCallback((val: string) => {
        hasSynced.current = false;
        setValue(val);
    }, [value, hasSynced]);

    const onRef = React.useCallback((node) => {
        variable.current.textInput = node;
        if (props.reff) {
            props.reff(variable.current.textInput);
        }
    }, [variable]);

    const onFocus = React.useCallback((e) => {
        setState({ ...state, focus: true });
        variable.current.count = variable.current.count + 1;
        props.onFocus(e);
    }, [props, variable, state]);

    const onBlur = React.useCallback((e) => {
        setState({ ...state, focus: false, reff: null });
        props.onBlur(e);
    }, [variable, value, debouncedValue]);
    
    return (
        <textarea
            id={props.id}
            ref={onRef}
            style={props.style}
            className={props.error ? "input-error " + props.className : "textArea " + props.className}
            onClick={props.onClick}
            readOnly={props.readonly}
            disabled={props.disabled}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            maxLength={props.maxlength}
            placeholder={props.placeholder}
            rows={props.rows}
            onBlur={onBlur}
            onFocus={onFocus} />
    );
}

const defaultProps: TextAreaProps = {
    value: "",
    showClear: false,
    useValidate: false,
    regex: "^[a-zA-Z0-9_]*$",
    autoFocus: false,
    className: "input-type",
    placeholder: "",
    style: {},
    hidden: false,
    error: false,
    readonly: false,
    reff: () => { },
    onChange: () => { },
    onFocus: () => { },
    onBlur: () => { }
}

export default React.memo(TextArea);