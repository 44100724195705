import React from "react";
import { useSelector } from "react-redux";
import { ContainerState } from "../../redux/store";

const SnackBar: React.FC = () => {

    const { snackbar } = useSelector((state: ContainerState) => state.container);
    const snackBarRef = React.createRef<HTMLDivElement>();

    React.useEffect(() => {
        if (snackbar.show) {
            ShowSnackBar();
        }
    }, [snackbar]);

    const ShowSnackBar = React.useCallback(() => {
        if (snackBarRef.current) {
            snackBarRef.current.className = "show";
            setTimeout(() => {
                snackBarRef.current.className = snackBarRef.current.className.replace("show", "");
            }, snackbar.duration || 3000);
        }
    }, [snackbar, snackBarRef]);

    return (
        <div ref={snackBarRef} id="snackbar">{snackbar.message}</div>
    );
}

export default SnackBar;