import React from "react";
// import Tag from "antd/es/tag";

export interface IMonyCardProps {
    className?: string;
    cardTitle?: string;
    titleLink?: string;
    cardDescription?: string;
    imgSource?: string;
    rateValue?: number;
    others?: React.ReactNode;
    height?: number;
    width?: number;
    topRight?: React.ReactNode;
    contentHeight?: {
        
    }
}

const MonyCard: React.FC<IMonyCardProps> = (props) => {

    props = { ...defaultProps, ...props };

    return (
        <div className={`ant-card design-mini-panel ant-card-bordered ant-card-shadow ant-card-hoverable ${props.className}`} style={{ width: props.width }}>
            <div className="ant-card-cover">
                <div className="m--15 text-center" style={{
                    height: props.height,
                    width: props.width,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundImage: `url(${props.imgSource})`
                }}>
                    {/* <img alt="AntV" height={props.height - 20} width={props.width - 20} src={props.imgSource} /> */}
                </div>
                <div className="ant-image-mask wd---100 ant-card-body" style={{ height: props.height - 20}}>
                    <div className="wd---100 dis-table">
                        { props.topRight }
                    </div>
                </div>
                <div className="ant-card-body">
                    <div className="ant-card-meta">
                        <div className="ant-card-meta-detail">
                            <a href={props.titleLink} target="_blank" rel="noopener noreferrer" className={!props.titleLink ? "cursor-default" : ""}>
                                <div className="ant-card-meta-title">{props.cardTitle}</div>
                            </a>
                            <div className="ant-card-meta-description">{props.cardDescription}</div>
                        </div>
                    </div>
                    {
                        props.others
                    }
                </div>
            </div>
        </div>
    );
}

const defaultProps = {
    className: "",
    cardTitle: "",
    titleLink: "",
    cardDescription: "",
    height: 300,
    width: 300,
    rateValue: 0
}

export default React.memo(MonyCard);