import React from "react";
import Menu from "antd/es/menu";
import Dropdown from "antd/es/dropdown";
import { MoreOutlined, CaretDownOutlined } from "@ant-design/icons"
import { GetMonyColor } from "../helper";

declare const Placements: ["topLeft", "topCenter", "topRight", "bottomLeft", "bottomCenter", "bottomRight"];
declare type Placement = typeof Placements[number];

export interface IDropdownMenuOptions {
    key: string;
    label: string;
    classMenu?: string;
    classLabel?: string;
    disabled?: boolean;
}

export interface IDropdownMenuProps {
    key?: string;
    placement?: Placement;
    className?: string;
    classMenu?: string;
    menu?: Array<IDropdownMenuOptions>;
    rowIndex?: number;
    iconType?: "v-dot3" | "h-dot3" | "caret-down";
    onSelected?: (key: string, rowIndex?: number) => void;
}

const MonyDropdownMenu: React.FC<IDropdownMenuProps> = (props) => {

    props = { ...defaultProps, ...props };

    const onSelected = React.useCallback((param) => {
        if (props.onSelected) {
            props.onSelected(param.key, props.rowIndex);
        }
    }, [props]);

    const RenderIcon = React.useMemo(() => {
        if (props.iconType === "caret-down") {
            return <CaretDownOutlined style={{ fontSize: 14, color: GetMonyColor("oslo-gray"), verticalAlign: "middle" }} />
        } else {
            return <MoreOutlined style={{ fontSize: 22, color: GetMonyColor("oslo-gray"), verticalAlign: "middle" }} rotate={props.iconType === "h-dot3" ? 90 : 0} />
        }
    }, [props.menu]);

    const RenderMenu = React.useMemo(() => {
        if (Array.isArray(props.menu)) {
            return (
                <Menu className={props.classMenu || "popover-absolute"} mode="vertical">
                    {
                        props.menu.map((menu) => <Menu.Item key={menu.key} className={`text-left pv--8 ${menu.classMenu || ""}`} onClick={onSelected} disabled={menu.disabled || false}><a data-key={menu.key} className={`font-14 ${menu.classLabel || ""}`}>{menu.label}</a></Menu.Item>)
                    }
                </Menu>
            )
        }
        return null;
    }, [props.menu]);

    return (
        <Dropdown className={props.className} overlay={RenderMenu} trigger={["click"]} placement={props.placement}>
            <a onClick={e => e.preventDefault()}>
                {RenderIcon}
            </a>
        </Dropdown>
    );
}

const defaultProps: IDropdownMenuProps = {
    menu: [],
    placement: "bottomRight",
    className: "",
    classMenu: "",
    iconType: "v-dot3"
}

export default React.memo(MonyDropdownMenu);
