import React from "react";

import {
    IModel,
    IFilter,
    IErrorModel,
    IPagingModel,
    IGeneralFetchAllState
} from "../type-interface";

export type TypeGeneralFetchAllReducerActions<T, F> =
    | { type: "FETCH_PENDING" }
    | { payload: T & { data?: T, paging?: IPagingModel, filter?: F }; type: "FETCH_SUCCESS" }
    | { payload: { key: string, value: any }, type: "CHANGE_FIELD_PAGING" }
    | { payload: object, type: "CHANGE_BULK_FIELD_PAGING" }
    | { payload: { key: string, value: any }, type: "CHANGE_FIELD_FILTER" }
    | { payload: object, type: "CHANGE_BULK_FIELD_FILTER" }
    | { error: Array<IErrorModel>; type: "FETCH_ERROR" }
    | { error: Array<IErrorModel>; type: "SET_ERROR" }

export const GeneralFetchAllReducer: <TS extends IGeneralFetchAllState<IModel, IFilter>, T extends IModel, F extends IFilter>(initialModel: Array<T>, initialFilter?: F) => React.Reducer<TS, TypeGeneralFetchAllReducerActions<T, F>> = () => {
    return (state, action) => {
        if (action.type === "SET_ERROR") {
            return {
                ...state,
                error: action.error
            };
        } else if (action.type === "FETCH_PENDING") {
            return {
                ...state,
                error: [],
                isLoading: true
            };
        } else if (action.type === "FETCH_SUCCESS") {

            let data = action.payload;
            let paging = state.paging;

            if (action.payload.hasOwnProperty("data")) {
                data = action.payload.data;
                paging = { ...state.paging, ...action.payload.paging };
            }

            return {
                ...state,
                isLoading: false,
                error: [],
                data: data,
                paging: paging,
                filter: { ...state.filter, ...action.payload.filter }
            };

        } else if (action.type === "FETCH_ERROR") {
            return {
                ...state,
                isLoading: false,
                error: action.error,
                data: []
            };
        } else if (action.type === "CHANGE_FIELD_PAGING") {
            return {
                ...state,
                paging: {
                    ...state.paging,
                    [action.payload.key]: action.payload.value
                }
            };
        } else if (action.type === "CHANGE_BULK_FIELD_PAGING") {
            return {
                ...state,
                paging: {
                    ...state.paging,
                    ...action.payload
                }
            };
        } else if (action.type === "CHANGE_FIELD_FILTER") {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.key]: action.payload.value
                }
            };
        } else if (action.type === "CHANGE_BULK_FIELD_FILTER") {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload
                }
            };
        }
    }
}
