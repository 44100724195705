import moment from "moment";
import LanguageEN from "../language/en";
import { ILanguage } from "../language";

class Converter {

    reverseNumber(text: string) {
        if (!text) {
            return text;
        }
        text = "" + text.split(".")[0];
        const numb = text.match(/\d/g);
        if (numb !== null) {
            return Number(numb.join(""));
        } else {
            return 0;
        }
    }

    // rounding jika decimal lebih dr 3, request pak handoko biar keliatan rapih
    moneyConverter(money: string | number = 0, decimal: number = 2, defaultMoney = null): string {

        money = isNaN(Number(money)) ? 0 : money;

        let moneyNumber = String(this.numberConverter(money, money));
        let convertmoney = "";

        if (moneyNumber !== undefined) {

            const decimalNumber = String(moneyNumber).split(".");

            if (decimalNumber[1] !== undefined) {
                if (decimalNumber[1].length === 1) {
                    decimalNumber[1] = decimalNumber[1] + "0";
                } else if (decimalNumber[1].length === 2 || decimalNumber[1].length === 3 && decimal === 4) {
                    decimalNumber[1] = decimalNumber[1];
                }
                moneyNumber = decimalNumber[0] + "." + decimalNumber[1];

                if (decimalNumber[1].length >= 3 && decimal === 2 || decimalNumber[1].length > 3 && decimal !== 2) {
                    // convert disamakan dengan backend supaya rounding selalu konsisten
                    // moneyNumber = parseFloat(moneyNumber).toFixed(decimal)

                    const absoluteNumber = Math.abs(Number(moneyNumber));
                    const factor = Math.pow(10, decimal);
                    const tempNumber = absoluteNumber * factor;
                    const roundTempNumber = Math.round(tempNumber);
                    moneyNumber = Number(moneyNumber) < 0 ? String((-1 * roundTempNumber / factor)) : String((roundTempNumber / factor));

                    const decimalNumber2 = String(moneyNumber).split(".");

                    if (decimalNumber2.length === 1) {
                        moneyNumber = moneyNumber + ".00";
                    }
                    else if (decimalNumber2[1] !== undefined && decimalNumber2[1].length === 1) {
                        decimalNumber2[1] = decimalNumber2[1] + "0";
                        moneyNumber = decimalNumber2[0] + "." + decimalNumber2[1];
                    }
                }
            } else if (decimal === 0) {
                // convert disamakan dengan backend supaya rounding selalu konsisten
                moneyNumber = Number(moneyNumber).toFixed(0);
            } else {
                // convert disamakan dengan backend supaya rounding selalu konsisten
                moneyNumber = Number(moneyNumber).toFixed(2);
                // let absoluteNumber = Math.abs(moneyNumber)
                // let factor = Math.pow(10, 2)
                // let tempNumber = absoluteNumber * factor
                // let roundTempNumber = Math.round(tempNumber)
                // moneyNumber = (roundTempNumber / factor).toString()
            }

            if (decimal === 0) {
                convertmoney = moneyNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                convertmoney = moneyNumber.replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
            }

            if (Number(moneyNumber) < 0) {
                convertmoney = convertmoney.replace("-", "(") + ")";
            } else if (convertmoney === "0") {
                convertmoney = "-";
            }
        }

        if (defaultMoney !== null && money === 0) {
            convertmoney = defaultMoney || "";
        }

        return convertmoney;
    }

    /* Fungsi formatRupiah */
    formatRupiah(value: string | number, decimal: number = 0, allowStringEmpty: boolean = false, prefix?: string): string {

        if (typeof (value) === "number") {
            value = value.toFixed(decimal);
        } else if (typeof (value) === "string" && value === "") {
            value = allowStringEmpty ? value : Number(0).toFixed(decimal);
        }

        let splitPoint = String(value).replace(/Rp. /g, "").split("."),
            // number_string = value.replace(/Rp. /g, "").replace(/[^.\d]/g, ""),
            number_string = splitPoint[0].replace(/[^\d]/g, ""),
            split = number_string.split(","),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi),
            separator = "",
            comma = "";

        // tambahkan titik jika yang di input sudah menjadi value ribuan
        if (ribuan) {
            separator = sisa ? "," : "";
            rupiah += separator + ribuan.join(",");
        }

        rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
        comma = rupiah && splitPoint.length >= 2 ? `.${splitPoint[1]}` : decimal > 0 ? ".00" : "";
        return (prefix === undefined ? rupiah : rupiah ? `Rp. ${rupiah}` : "") + comma;
    }

    rupiahToNumber(value: string, decimal: number = 0, autofillDecimal: boolean = true): string {
        value = value.replace(/Rp. /g, "");
        const splitPoint = value.split(".");
        let retVal = value.replace(/[^.\d]/g, "");
        if (splitPoint.length > 1 && splitPoint[1].length > 0) {
            retVal = `${splitPoint[0].replace(/[^.\d]/g, "")}.${splitPoint[1].replace(/[^\d]/g, "").substring(0, 2)}`;
        }
        if (decimal === 2 && autofillDecimal) {
            retVal = Number(retVal.replace(/[^.\d]/g, "")).toFixed(decimal);
        }
        return retVal;
    }

    /*
    bigMoneyConverter(money = 0, decimal = 2) {
        money = new BigNumber(isNaN(money) ? 0 : money)
        let convertmoney
        if (money !== undefined) {
            let decimalNumber = money.toString().split(".")

            if (decimalNumber[1] !== undefined) {
                if (decimalNumber[1].length === 1) {
                    decimalNumber[1] = parseInt(decimalNumber[1] + "0")
                } else if (decimalNumber[1].length === 2 || decimalNumber[1].length === 3 && decimal === 4) {
                    decimalNumber[1] = decimalNumber[1]
                }
                money = decimalNumber[0] + "." + decimalNumber[1]

                if (decimalNumber[1].length >= 3 && decimal === 2 || decimalNumber[1].length > 3 && decimal !== 2) {
                    //convert disamakan dengan backend supaya rounding selalu konsisten
                    // money = parseFloat(money).toFixed(decimal)

                    let absoluteNumber = Math.abs(money)
                    let factor = Math.pow(10, decimal)
                    let tempNumber = absoluteNumber * factor
                    let roundTempNumber = Math.round(tempNumber)
                    money = money < 0 ? (-1 * roundTempNumber / factor).toString() : (roundTempNumber / factor).toString()

                    let decimalNumber2 = money.toString().split(".")

                    if (money.toString().split(".").length === 1) {
                        money = money + ".00"
                    }
                    else if (decimalNumber2[1] !== undefined && decimalNumber2[1].length === 1) {
                        decimalNumber2[1] = parseInt(decimalNumber2[1] + "0")
                        money = decimalNumber2[0] + "." + decimalNumber2[1]
                    }
                }
            } else {
                //convert disamakan dengan backend supaya rounding selalu konsisten
                money = parseFloat(money).toFixed(2)
            }

            convertmoney = money.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
            if (money < 0) { convertmoney = convertmoney.replace("-", "(") + ")" }
            else if (money === 0) { convertmoney = "-" }
        }
        return convertmoney
    }
    */

    // function money converter lama (dengan rounding)
    moneyWithRoundConverter(money: number = 0, decimal = 4, defaultMoney = null) {
        money = isNaN(money) ? 0 : money;
        const moneyNumber = Number(this.numberConverter(money, money));
        let convertmoney
        if (moneyNumber !== undefined) {
            const decimalNumber = String(moneyNumber).split(".");

            if (decimalNumber[1] !== undefined) {
                if (decimalNumber[1].length >= 4) {
                    if (Number(decimalNumber[1].substr(2, 2)) === 0) {
                        decimalNumber[1] = decimalNumber[1].substr(0, 2);
                    } else if (Number(decimalNumber[1].substr(3, 1)) === 0) {
                        decimalNumber[1] = decimalNumber[1].substr(0, 3);
                    }
                }
            }

            // console.log(moneyNumber, "money", moneyNumber.replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), parseFloat(moneyNumber).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'))
            if (decimalNumber.length > 1) {
                if (decimalNumber[1].length < 3 || Number(decimalNumber[1]) === 0) {
                    convertmoney = (Math.trunc(moneyNumber * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                } else if (decimalNumber[1].length === 3) {
                    convertmoney = (Math.trunc(moneyNumber * Math.pow(10, 3)) / Math.pow(10, 3)).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                } else {
                    convertmoney = (Math.trunc(moneyNumber * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                }
            } else {
                convertmoney = (Math.trunc(moneyNumber * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
            }
            if (moneyNumber < 0) {
                convertmoney = convertmoney.replace("-", "(") + ")";
            } else if (moneyNumber === 0) {
                convertmoney = "-";
            }
        }
        if (defaultMoney !== null && money === 0) {
            convertmoney = defaultMoney;
        }
        return convertmoney
    }

    // function money converter baru, tanpa rounding
    moneyWithoutRoundConverter(money: number = 0, decimal = 4, defaultMoney = null) {
        decimal = decimal;
        money = isNaN(money) ? 0 : money;
        let moneyNumber = Number(this.numberConverter(money, money));
        let convertmoney;
        if (moneyNumber !== undefined) {
            const decimalNumber = String(moneyNumber).split(".");

            if (decimalNumber[1] !== undefined) {
                if (decimalNumber[1].length >= 4) {
                    if (Number(decimalNumber[1].substr(2, 2)) === 0) {
                        decimalNumber[1] = decimalNumber[1].substr(0, 2);
                    } else if (Number(decimalNumber[1].substr(3, 1)) === 0) {
                        decimalNumber[1] = decimalNumber[1].substr(0, 3);
                    } else {
                        decimalNumber[1] = decimalNumber[1].substr(0, 4);
                    }
                } else if (decimalNumber[1].length === 1) {
                    decimalNumber[1] = decimalNumber[1] + "0";
                }
                moneyNumber = Number(decimalNumber[0] + "." + decimalNumber[1]);
            } else {
                moneyNumber = Number(moneyNumber.toFixed(2));
            }

            convertmoney = String(moneyNumber).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
            if (moneyNumber < 0) {
                convertmoney = convertmoney.replace("-", "(") + ")";
            } else if (moneyNumber === 0) {
                convertmoney = "-";
            }
        }
        if (defaultMoney !== null && money === 0) {
            convertmoney = defaultMoney;
        }
        return convertmoney;
    }

    /*
    bigMoneyWithoutRoundingConverter(money = 0, decimal = 4) {
        money = new BigNumber(isNaN(money) ? 0 : money)
        let convertmoney
        if (money !== undefined) {
            let decimalNumber = money.toString().split(".")

            if (decimalNumber[1] !== undefined) {
                if (decimalNumber[1].length >= 4) {
                    if (Number(decimalNumber[1].substr(2, 2)) === 0) {
                        decimalNumber[1] = decimalNumber[1].substr(0, 2)
                    } else if (Number(decimalNumber[1].substr(3, 1)) === 0) {
                        decimalNumber[1] = decimalNumber[1].substr(0, 3)
                    } else {
                        decimalNumber[1] = decimalNumber[1].substr(0, 4)
                    }
                } else if (decimalNumber[1].length === 1) {
                    decimalNumber[1] = parseInt(decimalNumber[1] + "0")
                }
                money = decimalNumber[0] + "." + decimalNumber[1]
            } else {
                money = parseFloat(money).toFixed(2)
            }

            convertmoney = money.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
            if (money < 0) { convertmoney = convertmoney.replace("-", "(") + ")" }
            else if (money === 0) { convertmoney = "-" }
        }
        return convertmoney
    }
    */

    formatNumber(val: number = 0) {
        val = isNaN(val) ? 0 : val
        const parsedNumber = this.numberConverter(val, val)
        let formattedNumber
        if (parsedNumber !== undefined) {
            formattedNumber = Number(parsedNumber).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            if (parsedNumber < 0) {
                formattedNumber = formattedNumber.replace("-", "(") + ")";
            } else if (parsedNumber === 0) {
                formattedNumber = "-";
            }
        }
        return formattedNumber
    }

    formatNumberWithMinus(money: number = 0, decimal = 4, defaultMoney = null) {
        money = isNaN(money) ? 0 : money;
        const moneyNumber = Number(this.numberConverter(money, money));
        let convertmoney;
        if (moneyNumber !== undefined) {
            const decimalNumber = String(moneyNumber).split(".");

            if (decimalNumber.length > 1) {
                if (decimalNumber[1].length < 3) {
                    convertmoney = (Math.trunc(moneyNumber * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                } else if (decimalNumber[1].length === 3) {
                    convertmoney = (Math.trunc(moneyNumber * Math.pow(10, 3)) / Math.pow(10, 3)).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                } else {
                    convertmoney = (Math.trunc(moneyNumber * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
                }
            } else {
                convertmoney = (Math.trunc(moneyNumber * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
            }
            // convertmoney = (Math.trunc(money * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        }
        if (defaultMoney !== null && money === 0) {
            convertmoney = defaultMoney;
        }
        return convertmoney;
    }

    stringConverter(value = "") {
        let respon = value
        if (value.length <= 0) {
            respon = "-"
        }
        if (value === "0") {
            respon = "-"
        }
        return respon
    }

    statusConverter(value: any, language = LanguageEN) {
        let respon = language.Label.inactive_item
        if (value === 1) {
            respon = language.Label.active_item
        }
        if (value) {
            respon = language.Label.active_item
        }
        return respon
    }

    formatDate(dateString: string, formatDate = "DD MMM YYYY") {
        if (!dateString) { return "" }
        return moment(dateString).format(formatDate)

    }

    replaceSpecialChar(val: string | number, defaultNumber = "") {
        return String(val).replace(/[ -!@#$%^&*()_+\=\[\]{}':"\\|,<>\/?]/g, defaultNumber);
    }

    replaceAlpa(val: string, defaultNumber = "") {
        return String(val).replace(/[a-zA-Z]/g, defaultNumber);
    }

    numberConverter(val: string | number, defaultNumber: string | number = 0, checkMinus = true): string | number {
        val = this.replaceSpecialChar(val);
        val = this.replaceAlpa(val);
        const floatVal = parseFloat(String(val));
        if (isNaN(floatVal)) {
            if (isNaN(parseFloat(String(defaultNumber)))) {
                return defaultNumber;
            } else {
                return Number(defaultNumber);
            }
        } else if (floatVal < 0 && checkMinus) {
            return Number(defaultNumber);
        }
        return Number(floatVal);
    }

    rateConverter(rate: string, limitrate = 100) {
        rate = this.replaceAlpa(rate)
        rate = this.replaceSpecialChar(rate)
        const count = []
        for (let i = 0; i < rate.length; i++) {
            if (rate[i] === ".") { count.push(i) }
        }
        if (count.length <= 1) {
            if (Number(rate) > limitrate) { return limitrate }
        }
        else { rate = rate.slice(0, count[1]) }

        return rate
    }

    symbolsConverter(value: string, symbols: string) {
        //   if(typeof (Enum.SYMBOLS[symbols]) === 'undefined')
        // {throw "chose in enum"}
        return value + " " + symbols;
    }

    getCoaDetailName(coaArray: any[], coadetId: number) {
        return coaArray.map((data) => data.coa_detail.filter((coaDetail: any) => coaDetail.coadet_id === coadetId).map((sales: any) => sales.coadet_name)).map((x) => x);
    }

    capitalize(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    convertNOFA(value: string) {
        return value.substr(0, 3) + "." + value.substr(3, 3) + "-" + value.substr(6, 2) + "." + value.substr(8)
    }

    convertNPWP(value: string) {
        return value.substr(0, 2) + "." + value.substr(2, 3) + "." + value.substr(5, 3) + "." + value.substr(8, 1) + "-" + value.substr(9, 3) + "." + value.substr(12)
    }

    capitalizeFirstLetter(str: string) {
        const splitStr = str.toLowerCase().split(" ");
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }

        return splitStr.join(" ");
    }

    convertMonthLanguage(date: string, language: ILanguage) {
        // Param date formatnya shortDate (12 Jan 18)
        let result = ""
        const splitDate = date.split(" ");
        const month = splitDate.length === 3 ? splitDate[1].substr(0, 3) : splitDate[0].substr(0, 3)
        // Handle kalau balikan date dari belakang belum ada
        if (date.length > 1) {
            result = splitDate.length === 3 ? `${splitDate[0]} ${language.Month[month].substr(0, 3)} ${splitDate[2]}` : `${language.Month[month]} ${splitDate[1]}`
        }

        return result
    }

    convertMonthLanguage2(date: string, language: ILanguage) {
        // Param date formatnya shortDate (12 Jan 18)
        let result = ""
        const splitDate = date.split(" ");
        const month = splitDate.length === 3 ? splitDate[1].substr(0, 3) : splitDate[0].substr(0, 3)
        // Handle kalau balikan date dari belakang belum ada
        if (date.length > 1) {
            result = splitDate.length === 3 ? `${splitDate[0]} ${language.Month[month].substr(0, 3)} ${splitDate[2]}` : `${language.Month[month].substr(0, 3)} ${splitDate[1]}`
        }

        return result
    }

    convertDateLanguage(date: string, language: ILanguage) {
        // Param date formatnya shortDate (12 Jan 18)
        let result = ""
        const splitDate = date.split(" ");
        const month = splitDate.length === 3 ? splitDate[1].substr(0, 3) : splitDate[0].substr(0, 3)
        // Handle kalau balikan date dari belakang belum ada
        if (date.length > 1) {
            result = splitDate.length === 3 ? `${splitDate[0]} ${language.Month[month]} ${splitDate[2]}` : `${language.Month[month]} ${splitDate[1]}`
        }

        return result
    }

    multiLanguageNotification(data: any, language: ILanguage) {
        let result = data
        if (result.length > 0) {
            // if (result.match(/statement(s) ready for reconciliation/g)) {
            //     result = result.replace(/statement(s) ready for reconciliation/, language.Notification.new_statement_lines)
            // }
            if (result.match(/new statement\(([^)]+)\) in/g)) {
                result = result.replace(/new statement\(([^)]+)\) in/, language.Notification.new_statements)
            }
            if (result.match(/statement[(]s[)] ready for reconciliation for/g)) {
                result = result.replace(/statement[(]s[)] ready for reconciliation for/, language.Notification.cash_bank_statement)
            }
            if (result.match(/ready for reconciliation/g)) {
                result = result.replace(/ready for reconciliation/, language.Notification.new_statement_lines)
            }
            if (result.match(/OneFeed failed to sync/g)) {
                result = result.replace(/OneFeed failed to sync/, language.Notification.failed_onefeed_sync)
            }
            if (result.match(/OneFeed failed to connect/g)) {
                result = result.replace(/OneFeed failed to connect/, language.Notification.failed_onefeed_connection)
            }
            if (result.match(/OneFeed failed to connect/g)) {
                result = result.replace(/OneFeed failed to connect/, language.Notification.failed_onefeed_connection)
            }
            if (result.match(/is overdue/g)) {
                result = result.replace(/is overdue/, language.Notification.is_overdue)
            }
            if (result.match(/was deleted by/g)) {
                result = result.replace(/was deleted by/, language.Notes.was_deleted_by)
            }
            if (result.match(/created in Draft. Please check item are in stock or lock date is inactive/g)) {
                result = result.replace(/created in Draft. Please check item are in stock or lock date is inactive/, language.Notification.scheduling_failed_repeating_invoice)
                result = result.replace(/for/, language.Notification.for)
            }
            if (result.match(/created in Draft. Please check lock date is inactive/g)) {
                result = result.replace(/created in Draft. Please check lock date is inactive/, language.Notification.scheduling_failed_repeating_bill)
                result = result.replace(/for/, language.Notification.for)
            }
            if (result.match(/is out of stock/g)) {
                result = language.Notification.item_stock + result.replace(/is out of stock/, language.Notification.out_of_stock)
            }
            if (result.match(/Depreciation for/g)) {
                result = result.replace(/Depreciation for/, language.Notification.depreciation_for)
            }
            if (result.match(/has not been done/g)) {
                result = result.replace(/has not been done/, language.Notification.has_not_been_done)
            }
            if (result.match(/Failed to charge your Credit Card. Billing remains unpaid/g)) {
                result = result.replace(/Failed to charge your Credit Card. Billing remains unpaid/, language.Notification.failed_charge_cc)
            }
            if (result.match(/Your subscription expire today. Please proceed with your payment/g)) {
                result = result.replace(/Your subscription expire today. Please proceed with your payment/, language.Notification.failed_charge_bank_trf)
            }
            if (result.match(/Subscription has expired and billing remains unpaid. Please proceed with your payment/g)) {
                result = result.replace(/Subscription has expired and billing remains unpaid. Please proceed with your payment/, language.Notification.failed_charge_bank_trf_day3)
            }
            if (result.match(/Claims/g)) {
                result = result.replace(/Claims/, language.Notification.claims)
            }
            if (result.match(/OneFeed connected to/g)) {
                result = result.replace(/OneFeed connected to/, language.Notification.cash_bank_onefeed_connected)
            }
            if (result.match(/failed to sync/g)) {
                result = result.replace(/failed to sync/, language.Notification.cash_bank_onefeed_fail)
            }

            if (result.match(/Scheduler failed./g)) {
                result = result.replace(/Scheduler failed./, language.Notes.scheduler_failed)
            }
            if (result.match(/is set to inactive/g)) {
                result = result.replace(/is set to inactive/, language.Notes.set_to_inactive)
            }
            if (result.match(/is set to paused/g)) {
                result = result.replace(/is set to paused/, language.Notes.set_to_paused)
            }
            if (result.match(/ for /g)) {
                result = result.replace(/ for /, language.Notes.for_repeat)
            }
            if (result.match(/ for /g)) {
                result = result.replace(/ for /, language.Notes.for)
            }
            if (result.match(/Import Background Process is done/g)) {
                result = result.replace(/Import Background Process is done/, language.Notification.background_process_done)
            }
            if (result.match(/Please review your data before finalizing import process/g)) {
                result = result.replace(/Please review your data before finalizing import process/, language.Notification.please_review_your_data)
            }
            if (result.match(/There are/g)) {
                result = result.replace(/There are/, language.Notification.there_are)
            }
            if (result.match(/new data imported to Harmony/g)) {
                result = result.replace(/new data imported to Harmony/, language.Notification.new_data_imported)
            }
        }
        return result
    }

    multiLanguageNote(data: any, language: ILanguage, type: string, action: string) {
        let result = data
        // Karna balikkan dari belakang cuma string dan ga bisa dipecah makanya dibuat gini, klo ada ide lain silahkan dirubah

        if (result.length > 0) {
            if (type === "ASSET") {
                if (action === "reverse-depreciation" || action === "reverse_depreciation") {
                    if (result.match(/Depreciation of/g)) {
                        result = result.replace(/Depreciation of/, language.Notes.depreciation_of_reversed)
                    }
                    if (result.match(/reversed on/g)) {
                        result = result.replace(/reversed on/, language.Notes.reversed_on)
                    }
                } else if (action === "created") {
                    if (result.match(/Has Been Created/g)) {
                        result = result.replace(/Has Been Created/, language.Notes.has_been_created)
                    }
                    if (result.match(/was created for/g)) {
                        result = result.replace(/was created for/, language.Notes.was_created_for)
                    }
                    if (result.match(/ by /g)) {
                        result = result.replace(/ by /, language.Notes.created_by_asset)
                    }
                } else if (action === "undo-dispose" || action === "undo_dispose") {
                    if (result.match(/disposal was undone on/g)) {
                        result = result.replace(/disposal was undone on/, language.Notes.disposal_was_undone)
                    }
                    if (result.match(/disposal was undone  on/g)) {
                        result = result.replace(/disposal was undone  on/, language.Notes.disposal_was_undone)
                    }
                    if (result.match(/ by /g)) {
                        result = result.replace(/ by /, language.Notes.by)
                    }

                    if (language.Default.language === "id") {
                        result = "Penghapusan " + result
                    } else {
                        result = result
                    }
                } else {
                    if (result.match(/Has been updated/g)) {
                        result = result.replace(/Has been updated/, language.Notes.has_been_updated)
                    }
                    if (result.match(/Has been registered/g)) {
                        result = result.replace(/Has been registered/, language.Notes.has_been_registered)
                    }
                    if (result.match(/Depreciation of/g)) {
                        result = result.replace(/Depreciation of/, language.Notes.depreciation_of)
                    }
                    if (result.match(/was registered by/g)) {
                        result = result.replace(/was registered by/, language.Notes.was_registered_by)
                    }
                    if (result.match(/was updated by/g)) {
                        result = result.replace(/was updated by/, language.Notes.was_updated_by)
                    }
                    if (result.match(/Depreciation Date rolled back from/g)) {
                        result = result.replace(/Depreciation Date rolled back from/, language.Notes.date_rolled_back)
                    }
                    if (result.match(/Fixed Asset Start Date was changed from/g)) {
                        result = result.replace(/Fixed Asset Start Date was changed from/, language.Notes.asset_start_date)
                    }
                    if (result.match(/was deleted by/g)) {
                        result = result.replace(/was deleted by/, language.Notes.was_deleted_by)
                    }
                    if (result.match(/disposed for/g)) {
                        result = result.replace(/disposed for/, language.Notes.disposed_for)
                    }
                    if (result.match(/ disposed/g)) {
                        result = result.replace(/ disposed/, language.Notes.disposed_notaction)
                    }
                    if (result.match(/ran by/g)) {
                        result = result.replace(/ran by/, language.Notes.ran_by)
                    }
                    if (result.match(/ to /g)) {
                        result = result.replace(/ to /, language.Notes.asset_to)
                    }
                    if (result.match(/ on /g)) {
                        if (action === "depreciation") {
                            result = result.replace(/ on /, language.Notes.depreciation_on)
                        } else if (action === "disposed") {
                            result = result.replace(/ on /, language.Notes.disposed_on)
                        } else {
                            result = result.replace(/ on /, language.Notes.asset_on)
                        }
                    }
                    if (result.match(/ by /g)) {
                        result = result.replace(/ by /, language.Notes.by)
                    }
                }
            } else if (type === "INVENTORY") {
                if (result.match(/buy price changed from/g)) {
                    result = result.replace(/Item/, language.Notes.item_buy)
                    result = result.replace(/buy price changed from/, language.Notes.buy_price_changed)
                    result = result.replace(/ to /, language.Notes.to_buy)
                }

                if (result.match(/sell price changed from/g)) {
                    result = result.replace(/Item/, language.Notes.item_sell)
                    result = result.replace(/sell price changed from/, language.Notes.sell_price_changed)
                    result = result.replace(/ to /, language.Notes.to_buy)
                }

                if (result.match(/Inventory Account changed from/g)) {
                    result = result.replace(/Inventory Account changed from/, language.Notes.inventory_account_changed)
                }

                if (result.match(/(Group) Item Code for/g)) {
                    result = result.replace(/(Group) Item Code for/, language.Notes.group_item_code_for)
                }

                if (result.match(/Item Code for/g)) {
                    result = result.replace(/Item Code for/, language.Notes.item_code_for)
                }

                if (result.match(/is now Tracked/g)) {
                    result = result.replace(/is now Tracked/, language.Notes.is_now_tracked)
                }

                if (result.match(/Quantity increased/g)) {
                    result = result.replace(/Quantity increased/, language.Notes.quantity_increased)
                }

                if (result.match(/Quantity decreased/g)) {
                    result = result.replace(/Quantity decreased/, language.Notes.quantity_decreased)
                }

                if (result.match(/total value adjusted from/g)) {
                    result = result.replace(/total value adjusted from/, language.Notes.total_value_adjusted_from)
                    result = result.replace(/total value adjusted from/, language.Notes.total_value_adjusted_from)
                }

                if (result.match(/by conversion stock with value of/g)) {
                    result = result.replace(/by conversion stock with value of/, language.Notes.by_conversion_stock)
                    result = result.replace(/ by /, language.Notes.by_conversion)
                }

                if (result.match(/with value of/g)) {
                    result = result.replace(/with value of/, language.Notes.with_value_of)
                }

                if (result.match(/Created by/g) || result.match(/created by/g)) {
                    result = result.replace(/Created by/, language.Notes.created_by)
                    result = result.replace(/created by/, language.Notes.created_by)
                }

                if (result.match(/Inventory Account changed from/g)) {
                    result = result.replace(/Inventory Account changed from/, language.Notes.inventory_account_changed)
                }

                if (result.match(/changed from/g)) {
                    result = result.replace(/changed from/, language.Notes.changed_from)
                }

                if (result.match(/changed to/g)) {
                    result = result.replace(/changed to/, language.Notes.changed_to)
                }

                if (result.match(/updated by/g)) {
                    result = result.replace(/updated by/, language.Notes.updated_by)
                }
                if (result.match(/deleted by/g)) {
                    result = result.replace(/deleted by/, language.Notes.deleted_by)
                }

                if (result.match(/ by /g)) {
                    result = result.replace(/ by /, language.Notes.by)
                }

                if (result.match(/ to /g)) {
                    result = result.replace(/ to /, language.Notes.to_buy)
                }
                if (result.match(/Details of Group Item/g)) {
                    result = result.replace(/Details of Group Item/, language.Notes.details_of_group_item)
                }
                if (result.match(/Detail of (Group) Item/g)) {
                    result = result.replace(/Detail of (Group) Item/, language.Notes.detail_of_group_item)
                }
                if (result.match(/(Group) Item/g)) {
                    result = result.replace(/(Group) Item/, language.Notes.group_item_bracket)
                }
                if (result.match(/Group Item/g)) {
                    result = result.replace(/Group Item/, language.Notes.group_item)
                }
                if (result.match(/Item /g)) {
                    result = result.replace(/Item /, language.Notes.item)
                }

                if (result.match(/None/g)) {
                    result = result.replace(/None/, language.Notes.none)
                }
                if (result.match(/ for /g)) {
                    result = result.replace(/ for /, language.Notes.for_repeat)
                }
            } else if (type === "CONTACT") {
                if (result.match(/has been created by/g)) {
                    result = result.replace(/has been created by/, language.Notes.has_been_created_by)
                }
                if (result.match(/has been updated to/g)) {
                    result = result.replace(/has been updated to/, language.Notes.has_been_updated_to)
                }
                if (result.match(/Details for Contact/g)) {
                    result = result.replace(/Details for Contact/, language.Notes.details_for_contact)
                }
                if (result.match(/has been updated by/g)) {
                    result = result.replace(/has been updated by/, language.Notes.has_been_updated_by)
                }
                if (result.match(/has been deleted by/g)) {
                    result = result.replace(/has been deleted by/, language.Notes.has_been_deleted_by)
                }
                if (result.match(/Contact/g)) {
                    result = result.replace(/Contact/, language.Menu.contact)
                }
                if (result.match(/ by /g)) {
                    result = result.replace(/ by /, language.Notes.by)
                }
            } else if (type === "TAGGING") {
                if (result.match(/Tag/g)) {
                    result = result.replace(/Tag/, language.Notes.tag)
                }
                if (result.match(/\(under category/g)) {
                    result = result.replace(/\(under category/, language.Notes.under_category)
                }
                if (result.match(/created by/g)) {
                    result = result.replace(/created by/, language.Notes.created_by)
                }
                if (result.match(/updated from category/g)) {
                    result = result.replace(/updated from category/, language.Notes.updated_from_category)
                }
                if (result.match(/updated by/g)) {
                    result = result.replace(/updated by/, language.Notes.updated_by)
                }
                if (result.match(/updated to/g)) {
                    result = result.replace(/updated to/, language.Notes.updated_to)
                }
                if (result.match(/deleted by/g)) {
                    result = result.replace(/deleted by/, language.Notes.deleted_by)
                }
                if (result.match(/ by /g)) {
                    result = result.replace(/ by /, language.Notes.by)
                }
                if (result.match(/ to /g)) {
                    result = result.replace(/ to /, language.Notes.to_tagging)
                }
                if (result.match(/updated from no category/g)) {
                    result = result.replace(/updated from no category/, language.Notes.updated_from_no_category)
                }
                if (result.match(/no category/g)) {
                    result = result.replace(/no category/, language.Notes.no_category)
                }
                if (result.match(/category/g)) {
                    result = result.replace(/category/, language.Notes.category)
                }
            } else if (type === "JOURNAL") {
                if (result.match(/Note added by/g)) {
                    result = result.replace(/Note added by/, language.Notes.note_added_by)
                }
                if (result.match(/Repeating scheduler/g)) {
                    result = result.replace(/Repeating scheduler/, language.Notes.repeating_scheduler)
                }
                if (result.match(/Scheduler succeed for/g)) {
                    result = result.replace(/Scheduler succeed for/, language.Notes.scheduler_suceed_for)
                }
                if (result.match(/scheduler succeed for/g)) {
                    result = result.replace(/scheduler succeed for/, language.Notes.scheduler_suceed_for)
                }
                if (result.match(/Scheduler failed for/g)) {
                    result = result.replace(/Scheduler failed for/, language.Notes.scheduler_failed_for)
                }
                if (result.match(/with total of/g)) {
                    result = result.replace(/with total of/, language.Notes.with_total_of_journal)
                }
                if (result.match(/created by/g)) {
                    result = result.replace(/created by/, language.Notes.created_by)
                }
                if (result.match(/updated by/g)) {
                    result = result.replace(/updated by/, language.Notes.updated_by)
                }
                if (result.match(/deleted by/g)) {
                    result = result.replace(/deleted by/, language.Notes.deleted_by)
                }
                if (result.match(/edited by/g)) {
                    result = result.replace(/edited by/, language.Notes.edited_by)
                }
                if (result.match(/ on /g)) {
                    result = result.replace(/ on /, language.Notes.on_journal)
                }
                // } else if(type === "SELL_REPEATING") {

            } else {
                if (result.match(/Note added by/g)) {
                    result = result.replace(/Note added by/, language.Notes.note_added_by)
                }

                if (result.match(/Note by/g)) {
                    result = result.replace(/Note by/, language.Notes.note_by)
                }

                if (result.match(/was declined by/g)) {
                    result = result.replace(/was declined by/, language.Notes.was_declined_by)
                }

                if (result.match(/has been accepted by/g)) {
                    result = result.replace(/has been accepted by/, language.Notes.has_been_accepted)
                }

                if (result.match(/submitted for approval by/g)) {
                    result = result.replace(/submitted for approval by/, language.Notes.submitted_for_approval)
                }

                if (result.match(/with total of/g)) {
                    result = result.replace(/with total of/, language.Notes.with_total_of)
                }

                // Invoice
                if (result.match(/Credit applied from/g)) {
                    result = result.replace(/Credit applied from/, language.Notes.credit_applied_from)
                }

                if (result.match(/Payment received from/g)) {
                    result = result.replace(/Payment received from/, language.Notes.payment_received_from)
                }

                if (result.match(/This invoice has been fully paid/g)) {
                    result = result.replace(/This invoice has been fully paid/, language.Notes.invoice_fully_paid)
                }

                if (result.match(/remaining on this Invoice/g)) {
                    result = result.replace(/remaining on this Invoice/, language.Notes.remaining_invoice)
                }

                // Bill
                if (result.match(/Payment made from/g)) {
                    result = result.replace(/Payment made from/, language.Notes.payment_made_from)
                }

                if (result.match(/This bill has been fully paid/g)) {
                    result = result.replace(/"This bill has been fully paid/, language.Notes.bill_fully_paid)
                }

                if (result.match(/remaining on this bill/g)) {
                    result = result.replace(/remaining on this bill/, language.Notes.remaining_bill)
                }

                // CN Sales
                if (result.match(/Credit applied to/g)) {
                    result = result.replace(/Credit applied to/, language.Notes.credit_applied_to)
                }

                if (result.match(/Payment removed for/g) && result.match(/ by /g)) {
                    result = result.replace(/Payment removed for/, language.Notes.credit_remove_for)
                    if (result.match(/ by /g)) {
                        result = result.replace(/ by /, language.Notes.canceled_by)
                    }
                }

                // handle message credit removed for untuk data lama tidak bisa berubah jadi "payment removed for"
                if (result.match(/Credit removed for/g) && result.match(/ by /g)) {
                    result = result.replace(/Credit removed for/, language.Notes.credit_remove_for)
                    if (result.match(/ by /g)) {
                        result = result.replace(/ by /, language.Notes.canceled_by)
                    }
                }

                if (result.match(/Payment made to/g)) {
                    result = result.replace(/Payment made to/, language.Notes.payment_made_to)
                }

                if (result.match(/There is/g)) {
                    result = result.replace(/There is/, language.Notes.there_is)
                }

                if (result.match(/credit remaining on this Credit Note/g)) {
                    result = result.replace(/credit remaining on this Credit Note/, language.Notes.credit_remaining)
                }

                if (result.match(/Payment removed for/g)) {
                    result = result.replace(/Payment removed for/, language.Notes.payment_removed_for)
                }

                // CN Purchase
                if (result.match(/Payment received to/g)) {
                    result = result.replace(/Payment received to/, language.Notes.payment_received_to)
                }

                // Repeating
                if (result.match(/Repeating scheduler/g)) {
                    result = result.replace(/Repeating scheduler/, language.Notes.repeating_scheduler)
                }
                if (result.match(/Scheduler succeed for/g)) {
                    result = result.replace(/Scheduler succeed for/, language.Notes.scheduler_suceed_for)
                }
                if (result.match(/scheduler succeed for/g)) {
                    result = result.replace(/scheduler succeed for/, language.Notes.scheduler_suceed_for)
                }

                if (result.match(/scheduler succeed. /g)) {
                    result = result.replace(/scheduler succeed. /, language.Notes.scheduler_suceed)
                }

                if (result.match(/Scheduler succeed. /g)) {
                    result = result.replace(/Scheduler succeed. /, language.Notes.scheduler_suceed)
                }

                if (result.match(/Scheduler failed for/g)) {
                    result = result.replace(/Scheduler failed for/, language.Notes.scheduler_failed_for)
                }

                if (result.match(/scheduler failed./g)) {
                    result = result.replace(/scheduler failed./, language.Notes.scheduler_failed)
                }

                if (result.match(/Scheduler failed./g)) {
                    result = result.replace(/Scheduler failed./, language.Notes.scheduler_failed)
                }

                if (result.match(/scheduler created by/g)) {
                    result = result.replace(/scheduler created by/, language.Notes.scheduler_created_by)
                }

                if (result.match(/scheduler created/g)) {
                    result = result.replace(/scheduler created/, language.Notes.scheduler_created)
                }

                if (result.match(/screduler created/g)) {
                    result = result.replace(/screduler created/, language.Notes.scheduler_created)
                }

                if (result.match(/has been created/g)) {
                    result = result.replace(/has been created/, language.Notes.has_been_created)
                }

                if (result.match(/sent to Draft/g)) {
                    result = result.replace(/sent to Draft/, language.Notes.sent_to_draft)
                }

                if (result.match(/is set to inactive/g)) {
                    result = result.replace(/is set to inactive/, language.Notes.set_to_inactive)
                }

                if (result.match(/is set to paused/g)) {
                    result = result.replace(/is set to paused/, language.Notes.set_to_paused)
                }

                // Expense
                if (result.match(/has been approved by/g)) {
                    result = result.replace(/has been approved by/, language.Notes.has_been_approved)
                }

                if (result.match(/This claim has been fully paid/g)) {
                    result = result.replace(/This claim has been fully paid/, language.Notes.claim_fully_paid)
                }

                if (result.match(/remaining for this claim/g)) {
                    result = result.replace(/remaining for this claim/, language.Notes.claim_remaining)
                }

                if (result.match(/Payment made for/g)) {
                    result = result.replace(/Payment made for/, language.Notes.payment_made_for)
                }

                if (result.match(/due for this expense claim/g)) {
                    result = result.replace(/due for this expense claim/, language.Notes.for_expense_claim)
                }

                // Cash Bank
                if (result.match(/Manually Reconciled by/g)) {
                    result = result.replace(/Manually Reconciled by/, language.Notes.manually_reconciled_by)
                }

                if (result.match(/Manually Unreconciled by/g)) {
                    result = result.replace(/Manually Unreconciled by/, language.Notes.manually_unreconciled_by)
                }

                if (result.match(/Manually unreconciled from/g)) {
                    result = result.replace(/Manually unreconciled from/, language.Notes.manually_unreconciled_from)
                }

                if (result.match(/Manually reconciled by/g)) {
                    result = result.replace(/Manually reconciled by/, language.Notes.manually_reconciled_by)
                }

                if (result.match(/Manually unreconciled by/g)) {
                    result = result.replace(/Manually unreconciled by/, language.Notes.manually_unreconciled_by)
                }

                if (result.match(/Reconciled to Statement Line/g)) {
                    result = result.replace(/Reconciled to Statement Line/, language.Notes.reconciled_statement_line)
                }

                if (result.match(/ Unreconciled by /g)) {
                    result = result.replace(/ Unreconciled by /, language.Notes.unreconciled_by)
                }

                if (result.match(/Unreconciled./g)) {
                    result = result.replace(/Unreconciled./, language.Notes.unreconciled)
                }

                if (result.match(/Unreconciled/g)) {
                    result = result.replace(/Unreconciled/, language.Notes.unreconciled);
                }

                if (result.match(/Reconciled by/g)) {
                    result = result.replace(/Reconciled by/, language.Notes.reconciled_by);
                }

                if (result.match(/Reconciled/g)) {
                    result = result.replace(/Reconciled/, language.Notes.reconciled);
                }

                if (result.match(/Transfer Money from/g)) {
                    result = result.replace(/Transfer Money from/, language.Notes.transfer_money_from);
                }

                if (result.match(/Transfer money from/g)) {
                    result = result.replace(/Transfer money from/, language.Notes.transfer_money_from);
                }

                if (result.match(/Transfer Money/g)) {
                    result = result.replace(/Transfer Money/, language.Notes.transfer_money);
                }

                if (result.match(/Transfer money/g)) {
                    result = result.replace(/Transfer money/, language.Notes.transfer_money);
                }

                if (result.match(/Statement Line/g)) {
                    result = result.replace(/Statement Line/, language.Notes.statement_line);
                }

                // Journal
                if (result.match(/Journal/g)) {
                    result = result.replace(/Journal/, language.Notes.journal);
                }

                // Standalone (bair ga ngaco translatenya ini selalu taro pling bawah)
                if (result.match(/sent to/g)) {
                    result = result.replace(/sent to/, language.Notes.sent_to);
                }

                if (result.match(/created by/g)) {
                    result = result.replace(/created by/, language.Notes.created_by);
                }

                if (result.match(/Created by/g)) {
                    result = result.replace(/Created by/, language.Notes.created_by);
                }

                if (result.match(/created for/g)) {
                    result = result.replace(/created for/, language.Notes.created_for);
                }

                if (result.match(/edited by/g)) {
                    result = result.replace(/edited by/, language.Notes.edited_by);
                }

                if (result.match(/Edited by/g)) {
                    result = result.replace(/Edited by/, language.Notes.edited_by);
                }

                if (result.match(/updated by/g)) {
                    result = result.replace(/updated by/, language.Notes.updated_by);
                }

                if (result.match(/approved by/g)) {
                    result = result.replace(/approved by/, language.Notes.approved_by);
                }

                if (result.match(/Approved by/g)) {
                    result = result.replace(/Approved by/, language.Notes.approved_by);
                }

                if (result.match(/quoted by/g)) {
                    result = result.replace(/quoted by/, language.Notes.quoted_by);
                }

                if (result.match(/Voided by/g)) {
                    result = result.replace(/Voided by/, language.Notes.voided_by);
                }

                if (result.match(/voided by/g)) {
                    result = result.replace(/voided by/, language.Notes.voided_by);
                }

                if (result.match(/ approved/g)) {
                    result = result.replace(/ approved/, language.Notes.approved_repeat);
                }

                if (result.match(/deleted by/g)) {
                    result = result.replace(/deleted by/, language.Notes.deleted_by);
                }

                if (result.match(/ to /g)) {
                    result = result.replace(/ to /, language.Notes.to);
                }

                if (result.match(/ from /g)) {
                    result = result.replace(/ from /, language.Notes.from);
                }

                if (result.match(/ for /g)) {
                    result = result.replace(/ for /, language.Notes.for);
                }

                if (result.match(/ for /g)) {
                    result = result.replace(/ for /, language.Notes.for_repeat);
                }

                if (result.match(/ on /g)) {
                    result = result.replace(/ on /, language.Notes.on);
                }

                if (result.match(/ by /g)) {
                    result = result.replace(/ by /, language.Notes.by);
                }
            }


        }
        return result;
    };

    formatNum(num, digits): string {
        const units = ["k", "M", "G", "T", "P", "E", "Z", "Y"];
        const floor = Math.floor(Math.abs(num).toString().length / 3);
        const value = +(num / Math.pow(1000, floor))
        return value.toFixed(value > 1 ? digits : 2) + units[floor - 1];
    };
};


const converter = new Converter();
export default converter;
