import React from "react";
import { PiletApi } from "piral";

export type TypeLanguageState = {
    currentLanguage: string,
    translate: (key: string, keyGroup?: string) => string
}

export const useLanguageState = (piletApi: PiletApi): TypeLanguageState => {

    const [language, setLanguage] = React.useState<string>(window.MonyHelper.MicroFE.CurrentLanguage || "");

    React.useEffect(() => {
        piletApi.on("store-data", ({ name, value }) => {
            if (name === "language") {
                setLanguage(value);
            }
        });
    }, [])

    return {
        currentLanguage: language,
        translate: (key: string, keyGroup?: string) => window.MonyHelper.MicroFE.translate(key, keyGroup)
    };
}