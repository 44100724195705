import React, { PropsWithChildren } from "react";
import Carousel from "antd/es/carousel";
import { MonyVideo } from "."

export interface IMonyCardDetailProps {
    titleContent?: React.ReactNode | string;
    descriptionContent?: React.ReactNode | string;
    topRight?: React.ReactNode;
    height?: number;
    width?: number;
    dataMedia: Array<{
        sourceType: "image" | "video";
        source: string;
        autoPlay?: boolean;
    }>;
}

const MonyCardDetail: React.FC<PropsWithChildren<IMonyCardDetailProps>> = (props) => {

    props = { ...defaultProps, ...props };

    return (
        <div className="ant-card design-mini-panel ant-card-bordered ant-card-shadow ant-card-hoverable margin-auto" style={{ width: props.width }}>
            <div className="ant-card-cover">
                <Carousel autoplay={false}>
                    {
                        props.dataMedia.map((media) => {
                            return (
                                media.sourceType === "image" ?
                                    <img src={media.source} style={{ height: "auto", width: "auto" }} /> :
                                    <MonyVideo source={media.source} autoPlay={media.autoPlay} />
                            );
                        })
                    }
                </Carousel>
                <div className="wd---100 pos-absolute top-0 pt--10">
                    <div className="pull-right mr--10">{props.topRight}</div>
                </div>
                <div className="ant-card-body">
                    <div className="ant-card-meta">
                        <div className="ant-card-meta-detail">
                            {props.children}
                            {/* {
                                typeof props.titleContent === "string" ?
                                    <div className="ant-card-meta-title">{props.titleContent}</div> :
                                    props.titleContent
                            }
                            {
                                typeof props.descriptionContent === "string" ?
                                    <div className="ant-card-meta-description">{props.descriptionContent}</div> :
                                    props.descriptionContent
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const defaultProps = {
    titleContent: "",
    titleLink: "",
    cardDescription: "",
    height: 500,
    width: 700,
    rateValue: 0
}

export default React.memo(MonyCardDetail);
