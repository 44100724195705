import React from "react";
import { IErrorModel } from "../../../libs/type-interface";
import { UserApi } from "../../../libs/api";
import { Common } from "mony-component";
import { CommonEnume } from "../../../libs/enum";
import { Validator } from "../../../libs/helper";
import { useHistory, useParams } from "react-router";

export interface ResetPasswordModel {
    newpassword: string;
    confirmnewpassword: string;
    token: string;
}

export interface ResetPasswordPresenterType {
    state: {
        disableSubmit: boolean;
        showPassword: boolean;
        showPasswordConfirm: boolean;
        data: ResetPasswordModel;
        loading: boolean;
        error: Array<IErrorModel>
    };
    SetLoading: (loading: boolean) => void;
    SetError: (error: Array<IErrorModel>) => void;
    onChangedValue: (key: string, value: any) => void;
    onSubmit: (ajaxCallback: () => void) => void;
    onShowHidePassword: (stateKey: string) => void;
}

export const useForgotPasswordginPresenter = (): ResetPasswordPresenterType => {

    const [data, setData] = React.useState<ResetPasswordModel>({ newpassword: "", confirmnewpassword: "", token: "" });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<Array<IErrorModel>>([]);
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

    const history = useHistory();
    const params = useParams();

    React.useEffect(() => {
        setData({ ...data, token: params["token"] });
        CheckToken();
    }, []);

    const CheckToken = React.useCallback(async () => {
        const response = await UserApi.CheckResetToken(params["token"]);
        if (!response.success) {
            history.push("/reset-error");
        }
    }, []);

    React.useEffect(() => {
        setDisableSubmit((data.newpassword + data.confirmnewpassword).length === 0);
    }, [data]);

    const onChangedValue = React.useCallback((key: string, value: any) => {
        setData({ ...data, [key]: value });
        setError([]);
    }, [data, error]);

    const onShowHidePassword = React.useCallback((stateKey: string) => {
        if (stateKey === "newpassword") {
            setShowPassword(!showPassword);
        } else if (stateKey === "confirmnewpassword") {
            setShowPasswordConfirm(!showPasswordConfirm);
        }
    }, [showPassword, showPasswordConfirm])

    const ValidateInput = React.useCallback(() => {

        const rule = [
            { filed: "newpassword", display: "New Password", rule: CommonEnume.Rule.REQUIRED },
            { filed: "confirmnewpassword", display: "Confirm New Password", rule: CommonEnume.Rule.REQUIRED },
            // { filed: "newpassword", display: "New Password", rule: CommonEnume.Rule.MIN_LENGTH, lengthString: "8" },
            { filed: "newpassword", display: "New Password", rule: CommonEnume.Rule.PASSWORD },
            { filed: "newpassword", display: "New Password", match: "confirmnewpassword", matchDisplay: "Confirm New Password", rule: CommonEnume.Rule.MATCHES }
        ]

        const message = Validator.form(data, rule).filter((x) => x.message.length > 0);

        if (message.length > 0) {
            message.forEach((o) => o["type"] = "error");
            SetError(message);
            return false;
        }

        return true;
    }, [data, error]);

    const onSubmit = React.useCallback(async (ajaxCallback: () => void) => {

        if (!ValidateInput()) {
            return true;
        }

        const response = await UserApi.ResetPassword(data);
        if (response.success) {
            Common.setCookie(response.data.token);
            history.push("/reset-success");
        } else {
            setError(response.messages);
        }

        if (ajaxCallback) {
            ajaxCallback();
        }
    }, [data]);

    const SetLoading = React.useCallback((loading: boolean) => {
        setLoading(loading);
    }, [loading]);

    const SetError = React.useCallback((error: Array<IErrorModel>) => {
        setError(error)
    }, [error]);

    return {
        state: {
            showPassword,
            showPasswordConfirm,
            disableSubmit,
            data,
            loading,
            error
        },
        onShowHidePassword,
        SetLoading,
        SetError,
        onChangedValue,
        onSubmit
    }
}