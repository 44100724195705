import React from "react";
import { SubFormProps } from "../interfaces";

const SubForm: React.FC<SubFormProps> = (props) => {

  props = { ...defaultProps(props), ...props };

  if (props.defaultMargin) {
    props.style.margin = "24px 24px 10px 24px";
  }

  return (
    <div className={`${props.className}`} style={props.style}>
      <div className="header-template-form">
        {props.header}
      </div>
      <div className="footer-template-form">
        {props.children}
      </div>
    </div>
  );
}

const defaultProps = (props: SubFormProps) => {
  return {
    defaultMargin: props.defaultMargin || true,
    className: props.className || "",
    header: props.header || null
  };
}

export default SubForm;