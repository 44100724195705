import React from "react";
import { MonyTagLabel } from "mony-component";

export const LanguageSwitcher: React.FC<{ language: string; onClick: (language: string) => void }> = (props) => {
    const currentLanguage: string = props.language.toLowerCase();
    const RenderLabel = React.useCallback((language: "id" | "en") => {
        return (
            <MonyTagLabel onClick={() => props.onClick(language)} tagColor="catskill-white" textColor={language === currentLanguage ? "oslo-gray" : "dodger-blue"} textClassName={language === currentLanguage ? "font-14-bold" : "font-14-bold pointer text-underline"}>{language.toUpperCase()}</MonyTagLabel>
        );
    }, [props.language]);

    return (
        <div className="mt--20 mb--20 text-center">
            {RenderLabel("id")}
            <span className="ml--5 mr--5">|</span>
            {RenderLabel("en")}
        </div>
    );
}