export function isCallExtension() {
    const callExt = window.location.pathname.split("/")[1] == "apps" && window.location.pathname.length > 5;
    return callExt
}

// export function extensionRenderer(callback: Function, selector: string, try_count: number = 500) {
//     let mainContent: HTMLDivElement = document.querySelector(selector);
//     const reactRendered = document.querySelector("#app").children[0].attributes["data-reactroot"] ? true : false;
//     const extension = isCallExtension();

//     console.log("XXX-reactRendered", reactRendered);
//     console.log("XXX-mainContent", mainContent);

//     if ((!mainContent || !reactRendered) && try_count > 0 && extension) {
//         alert(try_count);
//         setTimeout(function () {
//             try_count--;
//             extensionRenderer(callback, selector, try_count);
//         }, 250)
//     } else if (mainContent && reactRendered) {
//         callback(mainContent);
//     } else {
//         throw new Error('Main Container Element Not Found!');
//     }
// }

export function extensionRenderer(callback: Function, selector: string, try_count: number = 500) {
    let coate: HTMLDivElement = document.querySelector("#coate");
    let wrapper: HTMLDivElement = document.querySelector("#content-wrapper");
    let mainContent: HTMLDivElement = document.querySelector("#main-content");

    const extension = isCallExtension();
    if ((!coate || !wrapper || !mainContent) && try_count > 0 && extension) {
        setTimeout(function () {
            try_count--;
            extensionRenderer(callback, selector, try_count);
        }, 250)
    } else if (coate && wrapper && mainContent) {
        callback(mainContent);
    } else {
        throw new Error('Main Container Element Not Found!');
    }
}


export type ElementTragetType = "id" | "name" | "class" | "tag";

export function elementFinder(callback: (elTarget: HTMLElement) => void, elTragetType: ElementTragetType, elTargetKey: string, try_count: number = 20) {
    // return new Promise((resolve, _reject) => {
        let elTarget: HTMLElement = null;

        if (elTragetType === "id") {
            elTarget = document.getElementById(elTargetKey);
        } else if (elTragetType === "class") {
            const el: HTMLCollectionOf<Element> = document.getElementsByClassName(elTargetKey);
            if (el.length > 0) {
                elTarget = el[0] as HTMLElement;
            }
        } else if (elTragetType === "name") {
            const el: NodeListOf<HTMLElement> = document.getElementsByName(elTargetKey);
            if (el.length > 0) {
                elTarget = el[0];
            }
        } else if (elTragetType === "tag") {
            const el: HTMLCollectionOf<Element> = document.getElementsByTagName(elTargetKey);
            if (el.length > 0) {
                elTarget = el[0] as HTMLElement;
            }
        }

        if (!elTarget && try_count > 0) {
            setTimeout(() => {
                // console.log("XXXXX---try_count", try_count);
                try_count--;
                elementFinder(callback, elTragetType, elTargetKey, try_count);
            }, 250)
        } else {
            // return elTarget;
            // resolve(elTarget);
            callback(elTarget);
        }
    // });
}