import React from "react";
import { Icons, Common, Loader } from "mony-component";
import { MonyFooter } from "mony-component";
import { IPageProps } from "../../../libs/type-interface";
import { NavLink } from "react-router-dom";
import { UserApi } from "../../../libs/api";
import { useHistory, useParams, useLocation } from "react-router";
import { queryParamsToObject } from "../../../libs/helper";
import { useLanguageState } from "../../../libs/custom-state";

// const LogoCompany = require("../../../assets/images/logomark.svg");
// const LogoSuccess = require("../../../assets/images/check-ico-24.svg");
// const LogoError = require("../../../assets/images/error-outline-ico-24.svg");
const LogoCompany = "logomark.svg";
const LogoSuccess = "check-ico-24.svg";
const LogoError = "error-outline-ico-24.svg";

interface PageProps extends IPageProps {
    pageType: "process" | "success" | "failed";
}

const AllActivatePage: React.FC<PageProps> = (props) => {

    const params = useParams();
    const { search } = useLocation();
    const history = useHistory();
    const language = useLanguageState(props.piral);

    React.useEffect(() => {
        Common.setTitle(props.pageTitle);
        if (props.pageType === "process") {
            CheckToken();
        }
    }, []);

    const CheckToken = React.useCallback(async () => {
        const query = queryParamsToObject<{ isadmin: number }>(search);
        const response = await UserApi.Activation(params["token"], query.isadmin);
        if (response.success) {
            history.push("/activate-success");
        } else {
            history.push("/activate-error");
        }
    }, []);

    const RenderLogo = React.useMemo(() => {
        let Logo = LogoCompany;
        if (props.pageType === "success") {
            Logo = LogoSuccess;
        } else if (props.pageType === "failed") {
            Logo = LogoError;
        }
        return (
            <Icons glyph={Logo} className="icon-logo-activate-content" />
        );
    }, [props.pageType]);

    const RenderContent = React.useMemo(() => {
        if (props.pageType === "failed") {
            return (
                <div className="pt--40 pl--30 pr--30 text-center">
                    {RenderLogo}
                    <label className=" lbl-title-activate pt--15">{language.translate("sorry_invalid_token", "label")}</label>
                    <p className="lbl-title-content pt--15">
                        {language.translate("verify_account_invalid", "information")}
                    </p>
                    <hr className="line-activate" />
                    <label className="lbl-title-content pt--60 font-weight-normal"><strong>{language.translate("have_any_issues", "label")}?</strong></label><br />
                    <a href="mailto:support@harmony.co.id,?subject=Activate Error&body=" className="lbl-title-content font-weight-normal pb--80">{language.translate("contact_us_at", "label")}</a>
                </div>
            );
        } else if (props.pageType === "process") {
            return (
                <div className="pt--40 pl--30 pr--30 text-center">
                    <Loader size={50} />
                </div>
            )
        } else if (props.pageType === "success") {
            return (
                <div className="pt--40 pl--30 pr--30 text-center">
                    {RenderLogo}
                    <label className=" lbl-title-activate pt--15">{language.translate("congratulations_your_account", "label")} <br /> {language.translate("successfully_activated", "label")}!</label>
                    <hr className="line-activate" />
                    <label className="lbl-title-content pt--60 font-weight-normal pb--80">{language.translate("please_click", "label")}  <NavLink to="/login">{language.translate("here", "label")}</NavLink> {language.translate("diverted_few_seconds", "label")} </label>
                </div>
            )
        }
    }, [props.pageType]);

    return (
        <div className="login--container">
            <div className="main-content-login ">
                <div className="activate--container content-login">
                    <div className="pt--10 text-left pl--15">
                        <Icons glyph={LogoCompany} className="icon-logo-activate" />
                    </div>
                    <div className="pt--40 pl--30 pr--30 text-center">
                        {RenderContent}
                    </div>
                </div>

                <MonyFooter className="footer-activate" />
            </div>
        </div>
    );
}

export const ActivatePage: React.FC<IPageProps> = (props) => {
    return <AllActivatePage pageType="process" {...props} />
}

export const ActivateFailedPage: React.FC<IPageProps> = (props) => {
    return <AllActivatePage pageType="failed" {...props} />
}

export const ActivateSuccessPage: React.FC<IPageProps> = (props) => {
    return <AllActivatePage pageType="success" {...props} />
}