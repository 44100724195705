import React from "react";
import { MonyTagLabel } from "mony-component";
import { ArapApi } from "../api";
import { MonyNavigate } from "../helper";

export interface IInvoiceNumberDisplayProps {
    invoiceRefId: number;
}

const InvoiceNumberDisplay: React.FC<IInvoiceNumberDisplayProps> = (props) => {

    const [invoiceNumber, setInvoiceNumber] = React.useState("");

    React.useEffect(() => {
        const load = async () => {
            if (!isNaN(props.invoiceRefId) && props.invoiceRefId > 0) {
                const invoiceNumber = await ArapApi.GetInvoiceNumber(props.invoiceRefId);
                setInvoiceNumber(invoiceNumber);
            } else {
                setInvoiceNumber("");
            }
        }
        load();
    }, [props.invoiceRefId]);

    const getLink = React.useCallback(() => {
        return `/invoice/view/${props.invoiceRefId}`;
    }, [props.invoiceRefId]);

    const onClick = React.useCallback(() => {
        MonyNavigate(getLink());
    }, [props.invoiceRefId]);

    return (
        <MonyTagLabel
            tagColor="malibu"
            textColor="catskill-white"
            width="100%"
            tagClassName={invoiceNumber ? "" : "hidden"}
            textClassName="font-14-bold pointer"
            onClick={onClick}>
            {invoiceNumber}
        </MonyTagLabel>
    );
}

export default InvoiceNumberDisplay;