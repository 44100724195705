import React from "react";
import Alert from "antd/es/alert";
import { GetLanguage } from "../helper/Language";
import { WarningProps } from "../interfaces/WarningProps";

const Warning: React.FC<WarningProps> = (props) => {

    props = { ...defaultProps, ...props };
    const [show, setShow] = React.useState(false);

    const Message = React.useMemo(() => {
        let message = null;
        if (props.message) {
            const userLang = props.language || "id";
            message = props.message.map((x, index) => {
                return <li key={index}>{x.lang === undefined ? x.message : userLang === "id" ? x.lang[1].id : x.lang[0].en} </li>
            });
            let language = !props.translate ? GetLanguage(userLang) : undefined;
            if (!props.repeat && props.message.length > 1) {
                message = ([
                    <li key="message-error">
                        {props.messageText || (props.translate ? props.translate("general_error", "error") : language && language.Error.General_error)}
                    </li>
                ]);
            }
        }
        return message;
    }, [props]);

    React.useEffect(() => {
        const visible = props.message && props.message.length > 0;
        setShow(visible);
        if (visible) {
            if (location.hash.indexOf("col-warning") < 0) {
                const elements = props.scrollableType === "id" ? document.getElementById(props.scrollableKey || "main-content") : document.getElementsByClassName(props.scrollableKey || "main-content");
                const container: HTMLElement = (elements instanceof HTMLCollection && elements.length > 0 ? elements[0] : elements || document.body) as HTMLElement;
                container.scrollTop = 0;
            }
        }
    }, [props.message, props.messageText]);

    return (
        <div id="col-warning" style={props.style} className={`row-field ${props.classDiv} ${!show ? "hidden" : ""}`}>
            <Alert
                showIcon={true}
                className={props.className}
                message={[<ul key="message-eror" className="font-weight-normal m--0 p--0">{Message}</ul>]}
                type={props.type || "error"}
            />
        </div>
    );
}

const defaultProps: WarningProps = {
    scrollableType: "id",
    scrollableKey: "main-content",
    language: "id"
}

export default Warning;
// export default React.memo(Warning);