import React from "react";
import { Icons, MonyButton } from "./";

// const Logo = require("../assets/images/logomark.svg");
// const Logo404 = require("../assets/images/404.svg");
const Logo = "logomark.svg";
const Logo404 = "404.svg";

const NotFound: React.FC<{ onGoHomeClick: () => void }> = (props) => {

    props = { ...defaultProps, ...props };

    return (
        <div className="login--container">
            <div className="main-content-login ">
                <div className="activate--container content-login">
                    <div className="pt--10 text-left pl--15">
                        <Icons glyph={Logo} className="icon-logo-activate" />
                    </div>
                    <div className="pt--40 pl--30 pr--30 text-center">
                        <Icons glyph={Logo404} className="icon-logo-activate-content" />
                        <label className=" lbl-title-activate pt--15">Oops..</label>
                        <p>"The page you were looking for doesn’t exist</p>
                        <hr className="line-activate" />
                        <MonyButton className="wd--170 mt--50 mb--50" value="Go to Dashboard" onClick={() => props.onGoHomeClick()} />
                    </div>
                </div>
                <label className="lbl-info"></label>
                {/* <Footer className="footer-activate-email" /> */}
            </div>
        </div>
    );
}

const defaultProps = {
    onGoHomeClick: () => {}
}

export default NotFound;