import {
    useDispatch,
    useSelector
} from "react-redux";
import { SetLoading, SetError, ResetAppState } from "../../libs/redux/actions/AppAction";
import { ContainerState } from "../redux/store";
import { AppStateType, ErrorModel, ErrorType } from "../../libs/redux/types/AppTypes";

export interface AppPresenterType {
    appState: AppStateType;
    SetLoading: (loading: boolean) => void;
    SetError: (error: ErrorType) => void;
    ResetAppState: () => void;
    GetError: (key?: string) => Array<ErrorModel>;
    IsLoading: () => boolean;
}

export const useAppPresenter = (options: { keyError?: string } = {}): AppPresenterType => {
    const dispatch = useDispatch();
    const { error, loading } = useSelector((state: ContainerState) => state.app);
    return {
        appState: useSelector((state: ContainerState) => state.app),
        SetLoading: (loading: boolean) => dispatch(SetLoading(loading)),
        SetError: (error: ErrorType) => dispatch(SetError(error)),
        ResetAppState: () => dispatch(ResetAppState()),
        GetError: (key?: string): Array<ErrorModel> => {
            const filterKeyError = key ?? options.keyError ?? "";
            if (error && error.hasOwnProperty(filterKeyError)) {
                return error[filterKeyError];
            }
            return [];
        },
        IsLoading: () => loading
    }
}