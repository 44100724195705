import React from "react";
import { IErrorModel } from "../type-interface";

export type TypeAsyncState = {
    loading: boolean,
    error: IErrorModel[],
    actions: {
        SetLoading: (loading: boolean) => void,
        SetError: (error: IErrorModel[]) => void,
        ClearError: () => void
    }
}

export const useAsyncState = (): TypeAsyncState => {

    const [error, setError] = React.useState<IErrorModel[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const SetError = React.useCallback((error: IErrorModel[]) => {
        setError(error);
    }, [error]);

    const ClearError = React.useCallback(() => {
        setError([]);
    }, [error]);

    const SetLoading = React.useCallback((loading: boolean) => {
        setLoading(loading);
    }, [error]);

    return {
        error,
        loading,
        actions: {
            SetError,
            ClearError,
            SetLoading
        }
    };
}