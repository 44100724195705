import React from "react";

const HorizontalSpace: React.FC<{ width?: string | number }> = (props) => {
  props = { ...defaultProps(props), ...props };
  return (
    <div style={{ "width": typeof (props.width) === "number" ? `${props.width}px` : props.width }}></div>
  );
}

const defaultProps = (props) => {
  return {
    width: props.width || 12
  };
}

export default React.memo(HorizontalSpace);