import React from "react";
import { MonyButtonType } from "mony-component/dist/component/MonyButton";

export interface IConfirmationState {
    code?: string;
    title?: string;
    show?: boolean;
    showCancel?: boolean;
    okButtonType?: MonyButtonType;
    okText?: string;
    ajax?: boolean;
    textBody?: string;
    onClickOk?: (callback: Function) => void;
    onCancel?: (code: string) => void;
};

export const confirmationInitial = (defaultState?: IConfirmationState): IConfirmationState => {
    const initState: IConfirmationState = {
        code: "",
        title: "",
        show: false,
        showCancel: false,
        ajax: false,
        okButtonType: "default",
        okText: "",
        textBody: ""
    };
    if (defaultState) {
        return {
            ...initState,
            ...defaultState
        };
    }
    return initState;
}

export type TypeConfirmationStateActions = {
    Show: (data: IConfirmationState) => void;
    Hide: () => void
}

export const useConfirmationState = (props: { defaultState?: IConfirmationState } = {}): { state: IConfirmationState, actions: TypeConfirmationStateActions } => {

    const [state, setState] = React.useState<IConfirmationState>({ ...confirmationInitial, ...props.defaultState });
    const showRef = React.useRef(state.show);

    const Show = React.useCallback((data: IConfirmationState) => {
        setState({ ...state, ...data, show: true });
    }, [state]);

    const Hide = React.useCallback(() => {
        setState({ ...state, show: false });
    }, [state]);

    React.useEffect(() => {
        if (showRef.current !== state.show) {
            if (state.onCancel && !state.show) {
                state.onCancel(state.code);
            }
            showRef.current = state.show;
        }
    }, [state.show]);

    const actions = { Show, Hide };
    return { state, actions };
}