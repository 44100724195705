import React from "react";
import { Icons, MonyButton, MonyFooter, MonyInput, MonyTagLabel } from "mony-component";
import { IPageProps } from "../../libs/type-interface";
import { ICurrencyPickerProps } from "../../libs/component-extension";
import { ICurrencyValue } from "../../libs/component-extension/CurrencyPickerPresenter";

// import { VariableSizeList } from 'react-window';

import { OnBoarding, onBoardingStages } from "./OnBoarding";
import { Hotspot, RenderHotspot } from "./Hotspot";

const Logo = "logomark.svg";

interface ITestPageProps {
    CurrencyPicker: React.ComponentType<ICurrencyPickerProps>;
}

const rowHeight = 100;
const rowSize = 7;
const rowAdjust = 2;

const GetListDummy = (size=0) => {
    const data = [];
    for (let i = 0; i < size; i++) {
        data.push({ name: `Image ${i+1}`, url: "https://www.harmony.co.id/wp-content/uploads/2020/10/hero@3x.png" })
    }
    return data;
}

export const TestPage: React.FC<IPageProps & ITestPageProps> = (props) => {

    const [state, setState] = React.useState<ICurrencyValue>({ currId: 2, currDate: "", currRates: 0 });
    const [list, setList] = React.useState<Array<any>>([]);
    const [paging, setPaging] = React.useState<{ size: number, page: number }>({ size: rowSize + rowAdjust, page: 1 });

    const prevScroll = React.useRef<number>(0);

    React.useEffect(() => {
        // setList(GetListDummy(10000));
        RenderHotspot({ targetId: "button-x1", hostpot: { delay: 0.5, active: true, border: true, color: "danger" } });
    }, []);

    const RenderList = React.useCallback(() => {
        
        const startSlice = paging.page === 1 ? 0 : (paging.page - 1) * paging.size;
        const endSlice = list.length > (paging.page * paging.size) ? (paging.page * paging.size) : list.length;
        const toShow = list.slice( startSlice, endSlice);

        console.log("SLICE " + startSlice + " to " + endSlice );
        console.log("XXX-data", toShow);
        return toShow.map((o) => (
            <div style={{ height: rowHeight }}>
                <label>{o.name}</label>
                <img src={o.url} height={80} width={150} />
            </div>
        ));
    }, [list, paging]);

    const onHandleScroll = React.useCallback((e) => {

        const containerHeight = Number(e.target["clientHeight"]);
        const scrollTop = Number(e.target["scrollTop"]);

        let goto: "top" | "bottom" = scrollTop < prevScroll.current ? "top" : "bottom";
        console.log("XXX-goto", { scrollTop: scrollTop, containerHeight: containerHeight-rowHeight, goto });

        prevScroll.current = scrollTop;

        if (scrollTop === ((rowSize + rowAdjust) * 100) - (rowSize * 100) && goto === "bottom") {
            console.log("PAGE-BOT", paging);
            setPaging({ ...paging, page: paging.page + 1 });
            e.target.scrollTo(0, ((rowSize + rowAdjust) * 100) - ((rowSize + 1) * 100));
        }

        if (scrollTop === 0 && goto === "top" && paging.page > 1) {
            console.log("PAGE-TOP", paging);
            setPaging({ ...paging, page: paging.page - 1 });
            
            e.target.scrollTo(0, ((rowSize + rowAdjust) * 100) - ((rowSize + 1) * 100));
        }

    }, [paging, prevScroll.current]);

    const Row = ({ index, style }) => (
        <RowComponent image={list[index].url} name={list[index].name} style={style} />
    );

    return (
        <div className="login--container">
            <div className="main-content-login" style={{ height: rowSize * rowHeight, scrollBehavior: "revert" }} onScroll={onHandleScroll} >
                <div className="top--70 content-login">
                    <div className="pt--20">
                        <Icons glyph={Logo} className="icon-logo" />
                        <label className="font-login lbl-login">{props.translate('login', 'label')}</label>
                    </div>

                    <MonyButton value="Show On Boarding" onClick={() => window.MonyHelper.OnBoarding.show()} style={{ width: "50%" }}/>

                    <MonyButton id="button-x1" value="Help!" onClick={() => window.MonyHelper.OnBoarding.show()} style={{ width: 100, marginLeft: 12 }}/>

                    <div className="pt--10 pl--30 pr--30 text-left">
                        <MonyInput id="inputan1" />
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left" style={{ position: "fixed", bottom: 100, left: 10 }}>
                        <MonyInput id="inputan2" style={{ width: 50}}/>
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left" style={{ position: "fixed", bottom: 50, left: 10 }}>
                        <MonyInput id="inputan3" style={{ width: 50}}/>
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left" style={{ position: "fixed", top: 450, left: 10 }}>
                        <MonyInput id="inputan4" style={{ width: 100}}/>
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left" style={{ position: "fixed", top: 450, right: 10 }}>
                        <MonyInput id="inputan5" style={{ width: 50}}/>
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left" style={{ position: "fixed", bottom: 150, right: 10 }}>
                        <MonyInput id="inputan6" style={{ width: 50}}/>
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left" style={{ position: "fixed", bottom: 50, right: 10 }}>
                        <MonyInput id="inputan7" style={{ width: 50}}/>
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left">
                        <MonyInput id="inputan8" />
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left">
                        <MonyInput id="inputan9" />
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left">
                        <MonyInput id="inputan10" />
                    </div>
                </div>

                <label className="mt--90 lbl-info font-weight-normal">{props.translate('new_harmony', 'label')}? <MonyTagLabel onClick={() => window.location.href = "/sign-up"} textColor="dodger-blue" tagClassName="pointer bg-transparent no-border">{props.translate('sign_up_free', 'label')}</MonyTagLabel></label>
                <MonyFooter className="mt--72 footer-copyright" />
                
                {/* {RenderList()} */}
            </div>
            <OnBoarding autoDisplay={false} data={onBoardingStages}/>
        </div>
        // <FixedList height={700} rowComponent={Row} data={data} rowHeight={rowHeight} />
        // <div className="login--container">
        //     <ReactWindowExample />
        // </div>
    );
}

// const ReactWindowExample = () => {

//     const [list, setList] = React.useState<Array<any>>([]);
//     const [winHeight, seWinHeight] = React.useState(window.innerHeight);

//     React.useEffect(() => {
//         setList(GetListDummy(100));
//         window.onresize = () => {
//             seWinHeight(window.innerHeight);
//         }
//     }, []);

//     const Row = ({ index, style }) => {
//         const isHeader = (index + 1) % 10 === 0;
//         return (
//             <div style={style}>
//                 {
//                     isHeader
//                     ? <h1>{list[index].name}</h1>
//                     : <><label>{list[index].name}</label><img src={list[index].url} height={80} width={150} /></>
//                 }
//             </div>
//         );
//     }

//     return (
//         <VariableSizeList
//             height={winHeight}
//             width="100%"
//             itemCount={list.length}
//             itemSize={(index) => {
//                 if (index%2 === 0) {
//                     return 50;
//                 }
//                 return 100;
//             }}
//             className="list-container">
//             {Row}
//         </VariableSizeList>

//     );
// }

const Row = React.memo((props: any) => {
    // the data here is the same data that is passed into the FixedList
    const { data, dataIndex, top, height } = props;

    const o = data[dataIndex];
    // Important!, make sure you inline-style your component with the the provided top, height. Also make sure to set your container element to position absolute
    return (
        // <div style={{ top, height }} className="react-recycled-row">
        //     <label>{o.name}</label>
        //     <img src={o.url} height={80} width={150} />
        // </div>
        <div style={{top, height}} className="react-recycled-row">{data[dataIndex]}</div>
    );
})

const RowComponent = ({ image, name, style }) => (
    <div style={style} className={name % 2 ? "list-group-item-odd" : "list-group-item-even"}>
        <div className="avatar">
            <img alt="avatar" src={image} height={80} width={150} />
        </div>

        <div className="details">
            <div className="info">
                <p className="number">{name}</p>
            </div>

        </div>
    </div>
);