import React, { PropsWithChildren } from "react";
import { ModalForm } from "./";
import { IModalFormProps } from "./ModalForm";

export interface IModalPdfViewerProps extends IModalFormProps {
    src?: string | Blob;
}

const ModalPdfViewer: React.FC<PropsWithChildren<IModalPdfViewerProps>> = (props) => {

    props = {
        ...defaultProps,
        ...props,
        classBodyContent: "",
        // styleBodyContent: { padding: "0px 0px 0px 8px" }
    };

    const [state, setState] = React.useState({ loading: true });
    const [objectURL, setObjectURL] = React.useState(null);
    React.useEffect(() => {
        return () => {
            URL.revokeObjectURL(objectURL);
        }
    }, []);

    React.useEffect(() => {
        setState({ ...state, loading: true });
        setObjectURL(props.src instanceof Blob ? window.URL.createObjectURL(props.src) : props.src);
        URL.revokeObjectURL(objectURL);
    }, [props.src]);

    const onLoadedFrame = React.useCallback(() => {
        setState({ ...state, loading: false });
    }, [state]);

    return (
        <ModalForm {...props}>
            {/* <embed src={`${props.src}#toolbar=0&navpanes=0&scrollbar=0`} width="100%" height={window.innerHeight*0.65} /> */}
            <iframe
                src={objectURL}
                frameBorder={0}
                marginWidth={0}
                marginHeight={0}
                width="100%"
                height={window.innerHeight * 0.725}
                scrolling="no"
                seamless={true}
                style={{ display: "block", lineHeight: 0, overflow: "hidden" }}
                onLoad={onLoadedFrame}
            />
        </ModalForm>
    );
}

const defaultProps: IModalPdfViewerProps = {
    visible: false,
    title: "",
    onCancel: (_e: React.MouseEvent<HTMLElement>) => { }
}

export default ModalPdfViewer;