import Axios, { AxiosRequestConfig, Method } from "axios";
import { Common } from "mony-component";
import { GetAppConfig } from "../../config";
import { IApiResponse } from "../type-interface";
import agent from "superagent";
import _superagentNoCache from "superagent-no-cache";
// import AgentKeepAlive from "agentkeepalive";

const responseDefault: IApiResponse = {
    success: false,
    data: {},
    messages: []
};

const createInstance = (method: Method, config?: AxiosRequestConfig, header: { [key: string]: string } = {}, authToken?: string) => {
    let headers = {
        "Content-Type": "application/json",
        "5cf7b49e23fc1d1c347c37e3879cc2f7": Common.getCookie() || authToken,
        ...header
    };
    if (config && !config.withCredentials) {
        delete headers["5cf7b49e23fc1d1c347c37e3879cc2f7"];
    }
    return Axios.create({
        withCredentials: true,
        headers: headers,
        method: method,
        validateStatus: (status: number) => status === status, // Always True
        ...config
    });
}

export const ApiGet = async (apiPath: string, config?: AxiosRequestConfig, header: { [key: string]: string } = {}, authToken?: string) => {
    let response: IApiResponse = responseDefault;
    try {
        let api = await createInstance("GET", config, header, authToken).get(GetAppConfig().baseApiPath + apiPath);
        if (api.status.toString().startsWith("2")) {
            response = { ...response, success: true, data: api.data.payload || api.data };
        } else if (api.status === 404) {
            throw Error("Not found");
        } else {
            response = { ...response, success: false, messages: api.data.payload.errors };
        }
    } catch (error) {
        response = { ...response, success: false, messages: [{ message: "Error Network Connections...!", path: "" }] };
    }
    return response;
}

export const ApiPost = async (apiPath: string, data: any, config?: AxiosRequestConfig, header: { [key: string]: string } = {}, authToken?: string) => {
    let response = responseDefault;
    try {
        let api = await createInstance("POST", config, header, authToken).post(GetAppConfig().baseApiPath + apiPath, data);
        if (api.status.toString().startsWith("2")) {
            response = { ...response, success: true, data: api.data.payload || api.data };
        } else if (api.status === 404) {
            throw Error("Not found");
        } else {
            response = { ...response, success: false, messages: api.data.payload.errors };
        }
    } catch (error) {
        response = { ...response, success: false, messages: [{ message: "Please check your connection...!", path: "" }] };
    }
    return response;
}

export const ApiPut = async (apiPath: string, data: any, config?: AxiosRequestConfig, header: { [key: string]: string } = {}, authToken?: string) => {
    let response = responseDefault;
    try {
        let api = await createInstance("PUT", config, header, authToken).put(GetAppConfig().baseApiPath + apiPath, data);
        if (api.status.toString().startsWith("2")) {
            response = { ...response, success: true, data: api.data.payload || api.data };
        } else if (api.status === 404) {
            throw Error("Not found");
        } else {
            response = { ...response, success: false, messages: api.data.payload.errors };
        }
    } catch (error) {
        response = { ...response, success: false, messages: [{ message: "Please check your connection...!", path: "" }] };
    }
    return response;
}

export const ApiDelete = async (apiPath: string, config?: AxiosRequestConfig, header: { [key: string]: string } = {}, authToken?: string) => {
    let response = responseDefault;
    try {
        let api = await createInstance("DELETE", config, header, authToken).delete(GetAppConfig().baseApiPath + apiPath);
        if (api.status.toString().startsWith("2")) {
            response = { ...response, success: true, data: api.data.payload || api.data };
        } else if (api.status === 404) {
            throw Error("Not found");
        } else {
            response = { ...response, success: false, messages: api.data.payload.errors };
        }
    } catch (error) {
        response = { ...response, success: false, messages: [{ message: "Error Network Connections...!", path: "" }] };
    }
    return response;
}

export const ApiDownloadFile = (srcUrl: string, method: Method = "get", data: any = {}, header: { [key: string]: string } = {}) => {
    const protocol = srcUrl.split( '/' )[0];
    const fileUrl = protocol.includes("http") ? srcUrl : GetAppConfig().baseApiPath + srcUrl;
    let headers = {
        "5cf7b49e23fc1d1c347c37e3879cc2f7": Common.getCookie(),
        ...header
    };
    return new Promise<IApiResponse>((resolve, _reject) => {
        if (method.toLowerCase() === "get") {
            agent
                .get(fileUrl)
                .withCredentials()
                .responseType("blob")
                .buffer(true)
                .set(headers)
                .use(_superagentNoCache)
                // .timeout(60 * 1000 * 2)
                .end((error, res) => {
                    if (error && error.response && error.response.status !== 200) {
                        resolve({ ...responseDefault, success: false, messages: error.response.statusText });
                    } else {
                        resolve({ ...responseDefault, success: true, data: res.body });
                    }
                });
        } else if (method.toLowerCase() === "post") {
            agent
                .post(fileUrl)
                .send(data)
                .withCredentials()
                .responseType("blob")
                .buffer(true)
                .set("5cf7b49e23fc1d1c347c37e3879cc2f7", Common.getCookie())
                .use(_superagentNoCache)
                // .timeout(60 * 1000 * 2)
                .end((error, res) => {
                    if (error && error.response && error.response.status !== 200) {
                        resolve({ ...responseDefault, success: false, messages: error.response.statusText });
                    } else {
                        resolve({ ...responseDefault, success: true, data: res.body });
                    }
                });
        }
    });
}
export const ApiDownloadFileExcel = (srcUrl: string, data: any) => {
    return new Promise<IApiResponse>((resolve, _reject) => {
        agent
            .post(GetAppConfig().baseApiPath + srcUrl, data)
            .withCredentials()
            .responseType("blob")
            .buffer(true)
            .set("5cf7b49e23fc1d1c347c37e3879cc2f7", Common.getCookie())
            .use(_superagentNoCache)
            .timeout(60 * 1000 * 2)
            .end((error, res) => {
                if (error && error.response && error.response.status !== 200) {
                    resolve({ ...responseDefault, success: false, messages: error.response.statusText });
                } else {
                    resolve({ ...responseDefault, success: true, data: res.body });
                }
            });
    });
}