import React from "react";

export type TypeCheckListState = {
    checkList: Array<string | number>;
    actions: {
        IsChecked: (id: string | number) => boolean;
        AddChecked: (id: string | number | Array<string | number>) => void;
        RemoveChecked: (id: string | number) => void;
        ClearChecked: (listId?: Array<string | number>) => void;
        CheckedCount: () => number;
        IsCheckedAll: (listId: Array<string | number>) => boolean;
    };
}

interface ICheckListProps {
    defaultChecked?: Array<string | number>;
    multiple?: boolean;
}

const defaultProps: ICheckListProps = {
    defaultChecked: [],
    multiple: true
}

export const useCheckListState = (props?: ICheckListProps): TypeCheckListState => {

    props = { ...defaultProps, ...props };

    const [checkList, setCheckList] = React.useState<Array<string | number>>(props.defaultChecked);
    const IsChecked = React.useCallback((id: string | number): boolean => {
        const index = checkList.findIndex((x) => x.toString() === String(id));
        return index >= 0;
    }, [checkList]);

    const AddChecked = React.useCallback(async (id: string | number | Array<string | number>) => {
        if (props.multiple && Array.isArray(id)) {
            setCheckList(await checkList.concat(id)); // PR Harus di buat tidak boleh double
        } else if (!Array.isArray(id)) {
            if (props.multiple) {
                setCheckList(await checkList.concat([id]));
            } else {
                setCheckList([id]);
            }
        }
    }, [checkList]);

    const RemoveChecked = React.useCallback(async (id: string | number | Array<string | number>) => {
        if (Array.isArray(id)) {

        } else {
            const index = await checkList.indexOf(id);
            if (index !== -1) {
                await checkList.splice(index, 1);
                setCheckList(JSON.parse(JSON.stringify(checkList)));
            }
        }
    }, [checkList]);

    const ClearChecked = React.useCallback(async (listId?: Array<number | string>) => {
        if (Array.isArray(listId)) {
            const excludeRemove = await checkList.filter((x) => listId.indexOf(x) === -1);
            setCheckList(JSON.parse(JSON.stringify(excludeRemove)));
        } else {
            setCheckList([]);
        }
    }, [checkList]);

    const CheckedCount = React.useCallback(() => {
        return checkList.length;
    }, [checkList]);

    const IsCheckedAll = React.useCallback((listId: Array<number | string>): boolean => {
        let checkAll = false;
        if (checkList.length > 0 && listId.length > 0) {
            checkAll = true;
            for (const id of listId) {
                if (checkList.indexOf(id) === -1) {
                    checkAll = false;
                    break;
                }
            }
        }
        return checkAll;
    }, [checkList]);

    return {
        checkList,
        actions: {
            IsChecked,
            AddChecked,
            ClearChecked,
            RemoveChecked,
            CheckedCount,
            IsCheckedAll
        }
    };
}
