import { ApiGet, ApiDownloadFile} from "../helper";
import { IApiResponse } from "../type-interface";

export const GetInvoiceNumber = async (id: number): Promise<string> => {
    let invoiceNumber = "";

    const response = await ApiGet(`/ar-ap/invoice/number/${id || ""}`);
    if (response.success) {
        invoiceNumber = response.data.sales_number;
    }

    // invoiceNumber = `INV-20-06-000${id}`;

    return invoiceNumber;
}

export const FetchAll = async (queryString: string): Promise<IApiResponse> => {
    // let invoice: any[] = [];
    const response = await ApiGet(`/ar-ap/invoice${queryString}`);
    // if (response.success) {
    //     invoice = response.data.data;
    // }
    return response;
}

export const FetchTaxAll  = async (): Promise<IApiResponse> => {
    const response = await ApiGet(`/ar-ap/tax/all`);
    return response;
}

export const GetShareInvoiceLink = async (data: any): Promise<IApiResponse> => {
    let query = data.query ? data.query : ""
    const response = await ApiGet(`/ar-ap/sales-link/?token=${data.token}&type=${data.type}${query}`, {headers: { "company-id": data.company_id}});
    return response;
}

export const GetShareInvoicePdf = async (data: any): Promise<IApiResponse> => {
    let query = data.query ? data.query : ""
    const response = await ApiDownloadFile(`/ar-ap/sales-link/?token=${data.token}&type=${data.type}${query}`, "get", {headers: { "company-id": data.company_id}});
    return response;
}