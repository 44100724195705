import React from "react";
import { ArapApi } from "../api";
import { IPagingModel } from "../type-interface";
import { ArapInvoiceModel } from "../models";
import { IErrorModel, IFilter } from "../type-interface";
import { IInvoiceNumberPickerProps } from "./";
import { useGeneralFetchAllState, useCheckListState } from "../custom-state";

interface IInvoiceNumberPickerFilter extends IFilter {
    keyword: string;
    status: string;
}

export interface IInvoiceNumberPickerState {
    data: Array<ArapInvoiceModel>;
    filter: IInvoiceNumberPickerFilter;
    paging: IPagingModel;
    errorState: Array<IErrorModel>;
    loadingState: boolean;
    actions: {
        onOk: (callback: Function) => void;
        onCancel: () => void;
        isChecked: (id: string | number) => boolean;
        CheckedCount: () => number;
        onChangeChecked: (key: string, value: boolean, index: number) => void;
        onChangedFilter: (key: string, value: any) => void;
        onChangedPaging: (key: string, value: any) => void;
    }
}

export const useInvoiceNumberPickerPresenter = (props: IInvoiceNumberPickerProps): IInvoiceNumberPickerState => {

    const { checkList, actions: checkListActions } = useCheckListState({ multiple: props.multiple });
    const { state, actions } = useGeneralFetchAllState<ArapInvoiceModel, IInvoiceNumberPickerFilter>({
        apiFetch: ArapApi.FetchAll,
        initFilter: { keyword: "", status: "approved" }
    });

    const onOK = React.useCallback((callback: Function) => {
        if (checkList.length !== 0) {
            const selected = state.data.find(x => x.sales_id === checkList[0]);
            props.OnSelected(selected.sales_id, selected.sales_number, () => {
                onCancel();
                callback();
            });
        }
        if (callback) {
            callback();
        }
    }, [checkList, state.data]);

    const onCancel = React.useCallback(() => {
        checkListActions.ClearChecked();
        props.OnClose();
    }, [checkList]);

    const onChangeChecked = React.useCallback((_key: string, value: boolean, index: number) => {
        const dataId = state.data[index].sales_id;
        if (value) {
            checkListActions.AddChecked(dataId);
        } else {
            checkListActions.RemoveChecked(dataId);
        }
    }, [state.data, checkList]);

    return {
        data: state.data,
        filter: state.filter,
        paging: state.paging,
        loadingState: state.isLoading,
        errorState: state.error,
        actions: {
            onOk: onOK,
            onCancel: onCancel,
            isChecked: checkListActions.IsChecked,
            CheckedCount: checkListActions.CheckedCount,
            onChangeChecked: onChangeChecked,
            onChangedFilter: actions.ChangeFieldFilter,
            onChangedPaging: actions.ChangeFieldPaging
        }
    }
}
