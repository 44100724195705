import React from "react";
import { useDatasourceState } from "../custom-state";
import { MonyAutoComplete, GetMonyColor, TypeTranslate } from "mony-component";
import { TypeMonyOptions } from "mony-component/dist/component/MonyAutoComplete";
import { StateChageEvent } from "../type-interface";
import { AccountingApi } from "../api";
// import { PiletApi } from "piral";
// import { useLanguageState } from "../custom-state";

export interface ICoaFullAutoCompleteProps {
    value: number;
    stateKey?: string;
    stateRowIndex?: number;
    onStateChanged?: StateChageEvent;
    remoteFilter?: boolean;
    translate?: TypeTranslate;
    // piral?: PiletApi;
}

const CoaFullAutoComplete: React.FC<ICoaFullAutoCompleteProps> = (props) => {

    // const { translate } = useLanguageState(props.piral);
    const { state, actions } = useDatasourceState({ apiFetch: AccountingApi.FetchAll });
    const loaded = React.useRef(false);

    React.useEffect(() => {
        if (state.data.length === 0) {
            actions.LoadData(true);
            loaded.current = true;
        }
        return () => {
        }
    }, []);

    const GetDataSource = React.useCallback(() => {
        const datasource: TypeMonyOptions = [];
        state.data.forEach((header) => {
            datasource.push({
                label: RenderLabelGroup(header["coa_name"], header["ctype_position"]),
                options: header.coa_detail.map((detail) => {
                    return RenderDataSourceItem(detail["coadet_name"], detail["coadet_code"], detail["coadet_id"]);
                })
            });
        });
        return datasource;
    }, [state.data]);

    const RenderLabelGroup = React.useCallback((title: string, type: string) => {
        return (
            <div className="font-bold-14" style={{ display: "flex", justifyContent: "space-between", color: GetMonyColor("malibu") }}>
                {`${title} (${type.capitalizeFirstLetter()})`}
                <span>Code</span>
            </div>
        );
    }, []);

    const RenderDataSourceItem = React.useCallback((label: string, code: string, value: any) => {
        return {
            value: value,
            label: (
                <div className="font-bold-14" style={{ display: "flex", justifyContent: "space-between", color: GetMonyColor("oslo-gray") }}>
                    {label}
                    <span>{code}</span>
                </div>
            )
        };
    }, []);

    return (
        <MonyAutoComplete
            // language={props.language}
            data={GetDataSource()}
            value={props.value}
            placeholder={props.translate && props.translate("choose_coa", "placeholder")}
            stateKey={props.stateKey}
            stateRowIndex={props.stateRowIndex}
            onStateChanged={props.onStateChanged}
            remoteFilter={false}
            allowClear={true}
            dropdownMatchSelectWidth={400}
        />
    );
}

export default CoaFullAutoComplete;