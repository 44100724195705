import React, { PropsWithChildren } from "react";
import Modal from "antd/es/modal";
import ConfigProvider from "antd/es/config-provider";
import enUS from "antd/lib/locale-provider/en_US";

export interface IModalFormProps {
    visible?: boolean;
    closable?: boolean;
    top?: number;
    width?: number | string;
    title?: string | React.ReactNode;
    titlePosition?: "left" | "center" | "right";
    classSpanTitle?: string;
    className?: string;
    classBodyContent?: string;
    styleBodyContent?: React.CSSProperties;
    bottomLeft?: React.ReactNode | Array<React.ReactNode>;
    bottomRight?: React.ReactNode | Array<React.ReactNode>;
    style?: React.CSSProperties;
    onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
    footerLine?: boolean
    PDFBg?:boolean
}

const ModalForm: React.FC<PropsWithChildren<IModalFormProps>> = (props) => {
    props = { ...defaultProps, ...props };
    return (
        <ConfigProvider locale={enUS} >
            <Modal
                className={props.PDFBg === true ? `modal-pdf`: props.className}
                bodyStyle={{ padding: 0 }}
                closable={true}
                closeIcon={<span className="fa fa-remove" onClick={props.onCancel}></span>}
                onCancel={props.onCancel}
                wrapClassName="dis-animation"
                title={
                    typeof props.title === "string" ? (
                        <div className={props.PDFBg === true ? `modal-pdf`:`title--desc-form text-${props.titlePosition} p--0`}>
                            <div className={props.classSpanTitle}>{props.title}</div>
                        </div>
                    ) : props.title
                }
                footer={null}
                visible={props.visible}
                width={props.width}
                style={{
                    minWidth: props.width,
                    top: props.top,
                    ...props.style
                }}>
                <div className={props.classBodyContent} style={props.styleBodyContent}>
                    {props.children}
                </div>
                {props.bottomRight || props.bottomLeft ? (
                    <div className={`footer-full-form ${!props.footerLine ? "none-border" : ""}`}>
                        <div className="float-left dis-flex">
                            {props.bottomLeft}
                        </div>
                        <div className="float-right dis-flex">
                            {props.bottomRight}
                        </div>
                    </div>
                ) : null
                }
            </Modal>
        </ConfigProvider>
    );
}

const defaultProps: IModalFormProps = {
    visible: false,
    closable: true,
    top: null,
    width: 400,
    title: "",
    titlePosition: "left",
    classSpanTitle: "",
    className: "",
    classBodyContent: "content---form",
    bottomLeft: null,
    bottomRight: null,
    style: {},
    styleBodyContent: {},
    onCancel: (_e: React.MouseEvent<HTMLElement>) => { },
    footerLine: true,
    PDFBg:false
}

export default ModalForm;
