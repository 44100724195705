import React from "react";
import { IErrorModel } from "../../../libs/type-interface";
import { UserApi } from "../../../libs/api";
import { Common } from "mony-component";
import { CommonEnume } from "../../../libs/enum";
import { Validator } from "../../../libs/helper";
import { useHistory } from "react-router";

export interface ForgotPasswordPresenterType {
    state: {
        email: string;
        loading: boolean;
        error: Array<IErrorModel>
    };
    SetLoading: (loading: boolean) => void;
    SetError: (error: Array<IErrorModel>) => void;
    onChangedValue: (key: string, value: any) => void;
    onSubmit: (ajaxCallback: () => void) => void;
}

export const useForgotPasswordginPresenter = (): ForgotPasswordPresenterType => {

    const [email, setEmail] = React.useState("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<Array<IErrorModel>>([]);
    const history = useHistory();

    const onChangedValue = React.useCallback((key: string, value: any) => {
        setEmail(value);
        setError([]);
    }, [email, error]);

    const ValidateInput = React.useCallback(() => {
        const rule = [
            {filed: "email", display: "User Email", rule: CommonEnume.Rule.EMAIL},
            {filed: "email", display: "User Email", rule: CommonEnume.Rule.REQUIRED}
        ];

        const message = Validator.form({ email }, rule).filter((x) => x.message.length > 0);

        if (message.length > 0) {
            message.forEach((o) => o["type"] = "error");
            SetError(message);
            return false;
        }

        return true;
    }, [email, error]);

    const onSubmit = React.useCallback(async (ajaxCallback: () => void) => {

        if (!ValidateInput()) {
            return true;
        }

        const response = await UserApi.ForgotPassword({ email });
        if (response.success) {
            Common.setCookie(response.data.token);
            history.push(`/reset-sent?email=${email}`);
        } else {
            setError(response.messages);
        }
        if (ajaxCallback) {
            ajaxCallback();
        }
    }, [email]);

    const SetLoading = React.useCallback((loading: boolean) => {
        setLoading(loading);
    }, [loading]);

    const SetError = React.useCallback((error: Array<IErrorModel>) => {
        setError(error)
    }, [error]);

    return {
        state: {
            email,
            loading,
            error
        },
        SetLoading,
        SetError,
        onChangedValue,
        onSubmit
    }
}