import React, { PropsWithChildren } from "react";
import { PageHeaderProps } from "../interfaces/PageHeaderProps";
import { MonyInput, MonyButton } from ".";
import { GetLanguage } from "../helper/Language";
import { ILanguage } from "..";

const PageHeader: React.FC<PropsWithChildren<PageHeaderProps>> = (props) => {

    props = { ...defaultProps, ...props };

    const listdata = [];
    props.data.map((x) => { x.data.map((xx) => { listdata.push({ label: xx.value, value: xx.key }); }) });

    const [language, setLanguage] = React.useState<ILanguage>(!props.translate ? GetLanguage(props.language || "id") : null);
    React.useEffect(() => {
        if (!props.translate) {
            setLanguage(GetLanguage(props.language || "id"));
        }
    }, [props.language, props.translate]);
    
    const onSearchChanged = React.useCallback((e) => {
        props.OnSelected("keyword", e);
    }, []);

    const topRight = React.useMemo(() => {
        return props.buttonName !== undefined && props.topRight === undefined ? <div className="pr--10"><MonyButton value={props.buttonName} onClick={(e) => props.onClick(e)} className={props.buttonName ? "" : "hidden"} /></div> : props.topRight;
    }, [props.buttonName, props.className, props.topRight, props.language]);

    return (
        <div className={"content--header clearfix pageheader-shadow min-width-700 " + props.className}>
            <div className={"row " + props.classRow}>
                {props.children}
                <div className="side-top-search">
                    {
                        props.hideSearch ? null :
                            <div className="col-input-search">
                                <MonyInput
                                    inputType="search"
                                    placeholder={props.translate ? props.translate("search", "placeholder") : language && language.Placeholder.search + "..."}
                                    value={props.keyword}
                                    showClear={true}
                                    onChange={onSearchChanged} />
                            </div>
                    }
                </div>
                <div className={`side-top-button pt--30 ${props.topButtonClassName}`} style={{ paddingTop: props.paddingTop }}>
                    {topRight}
                </div>
                {props.bottomChildren}
                <div style={{ clear: "both" }} />
            </div>
        </div>
    );
}

const defaultProps: PageHeaderProps = {
    className: "",
    classRow: "",
    paddingTop: "",
    topButtonClassName: "",
    data: []
}

export default React.memo(PageHeader);
