import React, { PropsWithChildren } from "react";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import Loader from "./Loader";
// import Tooltip from "antd/es/tooltip";

export interface IMonyMenuButtonModel {
    key: string;
    label: string;
    disabled?: boolean;
    icon?: React.ReactNode;
    default?: boolean;
}

export interface IMonyMenuButtonProps {
    text?: string;
    menus?: Array<IMonyMenuButtonModel>;
    ajax?: boolean;
    className?: string;
    classNameLoadingIcon?: string;
    disabled?: boolean;
    hidden?: boolean;
    style?: React.CSSProperties;
    menuItemClassName?: string;
    onMenuClick?: (key: string, callback?: () => void) => void;
    placement?: "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | "bottomRight";
}

const MonyMenuButton: React.FC<PropsWithChildren<IMonyMenuButtonProps>> = (props) => {

    const defaultMenu = React.useRef<IMonyMenuButtonModel>(undefined);
    const [state /*, setState */] = React.useState({ disabled: false });

    React.useEffect(() => {
        const findDefault = props.menus.find(x => x.default);
        if (!defaultMenu.current || (defaultMenu.current && findDefault && findDefault.key !== defaultMenu.current.key)) {
            defaultMenu.current = findDefault;
        } else if (!findDefault) {
            defaultMenu.current = findDefault;
        }
    }, [props.menus]);

    // const handleMenuClick = React.useCallback((e) => {
    const handleMenuClick = (e: any) => {
        props.onMenuClick(e.key);
    };
    // }, []);

    // const onDefaultMenuClick = React.useCallback(() => {
    const onDefaultMenuClick = () => {
        if (defaultMenu.current) {
            if (props.onMenuClick) {
                props.onMenuClick(defaultMenu.current.key);
            }
        }
    };
    // }, [defaultMenu]);

    const menu = React.useMemo(() => (
        <Menu className={`${props.menuItemClassName || "list-add-row"}`}>
            {
                props.menus.map((menu) => {
                    return (
                        <Menu.Item key={menu.key} icon={menu.icon} onClick={handleMenuClick} className="cursor">
                            {menu.label}
                        </Menu.Item>
                    );
                })
            }
        </Menu>
    ), [props.menus]);

    return (
        props.disabled ?
            <button
                style={props.style}
                className={props.className}
                hidden={props.hidden}
                disabled={props.disabled || state.disabled}>
                <Loader color="#419aff" size={6} paddingBottom="0px" paddingTop="0px" className={props.classNameLoadingIcon} />
            </button> :
            <Dropdown.Button
                disabled={props.disabled}
                className={`${props.className ? props.className : "btn-dropdown"} ${props.disabled ? " btn-dropdown-disabled" : ""}`}
                overlay={menu}
                size="middle"
                type="primary"
                icon={
                    <span role="img" aria-label="down" className="anticon anticon-down">
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="black" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>
                    </span>
                }
                onClick={onDefaultMenuClick}
                // buttonsRender={([leftButton, rightButton]) => [
                //     // <Tooltip title="tooltip" key="leftButton">{
                //     leftButton as React.ReactElement
                //     // }</Tooltip>
                //     // <button type="button" className={`ant-btn ant-btn-default ${props.className || ""}`}><span>Test</span><span>&nbsp;</span></button>
                //     ,
                //     // <button type="button" className={`ant-btn ant-dropdown-trigger ant-btn-default`}><i className="anticon anticon-down"></i></button>
                //     React.cloneElement(rightButton as React.ReactElement, { loading: props.ajax }),
                // ]}
                placement={props.placement || "bottomLeft"}
                style={{ width: "100%", ...props.style || {} }}
                trigger={["click"]}
                arrow={true}
            >
                {defaultMenu.current ? defaultMenu.current.label : (props.children || props.text)}
            </Dropdown.Button>
    );
}

export default React.memo(MonyMenuButton);
