import React from "react";
import AutoComplete from "antd/es/auto-complete";
import { StateProps } from "../interfaces/StateProps";
import { GetLanguage } from "../helper/Language";
import { ILabelValueModel } from "../interfaces";
import { Default } from "../const/Default";
import { useDebounce } from "use-lodash-debounce";
import { Icons } from "./";
import { ILanguage, TypeTranslate } from "..";

export type TypeMonyOptions = Array<ILabelValueModel & { options?: Array<ILabelValueModel> }>;
const Spinner = "spinner1.svg";

export interface IMonyAutoCompleteProps extends StateProps {
    key?: string;
    id?: string;
    value?: string | number;
    data?: TypeMonyOptions;
    minLength?: number;
    language?: string;
    divClassName?: string;
    className?: string;
    classAddNew?: string;
    showAdds?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    notFound?: React.ReactNode;
    error?: boolean;
    placeholder?: string;
    allowClear?: boolean;
    flatBox?: boolean;
    border?: boolean;
    remoteFilter?: boolean;
    dropdownMatchSelectWidth?: number;
    stateChangedType?: "value" | "label-value";
    stateKeySearch?: string;
    onAdd?: (value: string | number, callback: (errorMessage?: any) => void, stateProps?: StateProps) => void;
    onBlur?: Function;
    onRemoteFilter?: (keyword: string, keyState?: string) => void;
    onRawInput?: (key: string, val: any | ILabelValueModel, index?: number) => void;
    translate?: TypeTranslate;
}

const MonyAutoComplete: React.FC<IMonyAutoCompleteProps> = (props) => {

    props = { ...MonyAutoCompletePropsDefaultProps, ...props };

    const [value, setValue] = React.useState<string | number>(props.value);
    const [searchValue, setSearchValue] = React.useState("");
    const [selected, setSelected] = React.useState(false);
    const [showAdd, setShowAdd] = React.useState(props.showAdds);
    const [focus, setFocus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const lastValue = React.useRef(value);
    const debouncedSearchValue = useDebounce(searchValue, Default.DELAY_TYPING);

    const [language, setLanguage] = React.useState<ILanguage>(!props.translate ? GetLanguage(props.language || "id") : null);

    React.useEffect(() => {
        if (props.value !== value) {
            const current = lastValue.current === props.value ? value : props.value;
            lastValue.current = current;
            setValue(current);
        }
        if (!props.translate) {
            setLanguage(GetLanguage(props.language || "id"));
        }
    }, [props]);

    const SyncPropsValue = React.useCallback(() => {
        if (props.onStateChanged && props.value !== value) {
            let changedValue: string | number | ILabelValueModel = value;
            if (props.stateChangedType === "label-value") {
                changedValue = { label: getValue(), value: value };
            }
            props.onStateChanged(props.stateKey, changedValue, props.stateRowIndex);
        }
    }, [props, value, lastValue]);

    React.useEffect(() => {
        SyncPropsValue();
    }, [value]);

    React.useEffect(() => {
        if (props.onRawInput) {
            props.onRawInput(props.stateKeySearch || "", value || "", props.stateRowIndex);
        }
        SyncPropsValue();
    }, [debouncedSearchValue]);

    const onSearch = React.useCallback((val) => {

        if (props.readonly) return;

        if (value) {
            setValue(null);
        }

        if (selected) {
            setSelected(false);
        }

        setSearchValue(val);

        if (focus && (props.remoteFilter && props.onRemoteFilter)) {
            props.onRemoteFilter(val, props.stateKey);
        }

    }, [focus, searchValue, value]);

    const onSelect = React.useCallback((val) => {
        setSelected(true);
        setValue(val);
        setSearchValue("");
    }, [selected]);

    const getValue = React.useCallback(() => {

        if (searchValue) {
            return searchValue;
        } else if (!props.data || (!searchValue && !value) || (Array.isArray(props.data) && props.data.length === 0)) {
            return "";
        }

        let findSelected: ILabelValueModel;

        const isGroup = Array.isArray(props.data[0].options);

        if (!isGroup) {
            findSelected = props.data.find((x) => String(x.value) === String(value));
        } else {
            for (const group of props.data) {
                findSelected = group["options"].find((x) => String(x.value) === String(value));
                if (findSelected) {
                    break;
                }
            }
        }
        return findSelected ? (typeof findSelected.label === "string" ? findSelected.label : findSelected.label.props.children[0].toString()) : searchValue;
    }, [props.data, value, searchValue]);

    const getOptions = React.useCallback(() => {

        let options = [];

        if (!focus) {
            return options;
        }

        if (!searchValue || (props.onRemoteFilter && props.remoteFilter)) {
            options = props.data;
        } else if (!props.remoteFilter) {
            props.data.forEach((opt, index) => {
                if (!opt.options) {
                    if (typeof opt.label === "string" && opt.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) {
                        options.push(opt);
                    } else if (typeof opt.label === "object" && opt.label.props.children[0].toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) {
                        options.push(opt);
                    }
                } else {
                    const detail = opt.options.filter((y) => {
                        if (typeof y.label === "string") {
                            return y.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
                        } else {
                            const title = y.label.props.children[0].toLowerCase();
                            const code = y.label.props.children[1].props.children.toLowerCase();
                            return title.indexOf(searchValue.toLowerCase()) >= 0 || code.indexOf(searchValue.toLowerCase()) >= 0;
                        }
                    });

                    if (detail.length > 0) {
                        options.push({
                            ...props.data[index],
                            options: detail
                        });
                    }
                }
            });
        }
        return options;
    }, [props.data, searchValue, focus]);

    const onFocus = React.useCallback(() => {
        setFocus(true);
        if (!selected) {
            setShowAdd(props.showAdds);
        }
    }, [focus, selected, showAdd, value]);

    const onBlur = React.useCallback(() => {
        setFocus(false);
        if (searchValue) {
            setSearchValue("");
        }
    }, [focus, value, searchValue]);

    const onClickAdd = React.useCallback(() => {
        // Next nya ditambah icon loading di kanan sampai proses selesai jika berhasil ada icon cheklist hijau jika gagal icon failed atau state error
        // this.mouse_inside_dropdown = false;
        if (props.onAdd) {
            setLoading(true);
            props.onAdd(searchValue, (errorMessage?: any) => {
                setLoading(false);
                if (!errorMessage || (Array.isArray(errorMessage) && errorMessage.length === 0)) {
                    // Jika Create Berhasil
                    setSelected(true);
                    setSearchValue("");
                    setShowAdd(props.showAdds);
                    setFocus(false);
                    // onFocus();
                    // setSelected({ selected: true }, () => {
                    //     this.showAdd(false);
                    // });
                } else {
                    // Jika Create Error
                }
            }, { stateKey: props.stateKey, stateRowIndex: props.stateRowIndex });
        }
    }, [loading, showAdd, selected, searchValue, focus]);

    const AddBox = React.useCallback(() => {
        const valueLen = String(searchValue || "").length;
        if (showAdd && valueLen >= props.minLength && !selected) {
            return (
                <div className={`long-text autocomplete-col-add ${props.classAddNew}`}>
                    <a className="wd---100" onClick={onClickAdd}>{"+ " + (props.translate ? props.translate("add", "button") : language && language.Button.add) + " " + searchValue}</a>
                </div>
            );
        } else {
            return null;
        }
    }, [showAdd, selected, props]);

    const getClassName = React.useCallback(() => {
        let adjustClass = "";
        if (props.flatBox) {
            adjustClass = `${adjustClass} ant-select-flat-box flat-box`;
        }
        if (!props.border) {
            adjustClass = `${adjustClass} ant-select-no-border no-border`;
        }
        return `${props.error ? "input-error-autocomplete-new" : ""} autocomplete-col ${props.className} ${adjustClass}`; // pos-absolute 
    }, [props])

    return (
        <div className={`pos-relativ ${props.divClassName}`}>
            <AutoComplete
                size="middle"
                style={{ width: "100%" }}
                className={getClassName()}
                options={!props.readonly ? getOptions() : []}
                value={getValue()}
                onSelect={onSelect}
                searchValue={searchValue}
                onFocus={onFocus}
                onBlur={onBlur}
                notFoundContent={AddBox()}
                placeholder={props.placeholder}
                onSearch={onSearch}
                allowClear={props.allowClear}
                disabled={props.disabled}
                dropdownMatchSelectWidth={props.dropdownMatchSelectWidth}>
            </AutoComplete>
            {
                loading ?
                    (
                        <div style={{ background: "transparent", position: "absolute", top: "4px", right: "0px" }}>
                            <Icons className="pb--2 pr--5" glyph={Spinner} />
                        </div>
                    ) : null
            }
        </div>
    );
}

export const MonyAutoCompletePropsDefaultProps: IMonyAutoCompleteProps = {
    value: "",
    data: [],
    placeholder: "Select item",
    allowClear: false,
    disabled: false,
    readonly: false,
    error: false,
    classAddNew: "",
    divClassName: "",
    notFound: null,
    showAdds: false,
    minLength: 3,
    flatBox: false,
    border: true,
    remoteFilter: false,
    language: "id",
    stateChangedType: "value",
    onAdd: () => { },
    onBlur: () => { },
    onRemoteFilter: undefined
}

export default MonyAutoComplete;