import moment from "moment";
import CommonEnume from "../enum/CommonEnume";
import "./extensions";

const ImgTitle = require("../assets/images/Favicon.png");

class Common {

    setCookie(token, cookieName = "5cf7b49e23fc1d1c347c37e3879cc2f7") {
        localStorage.setItem(cookieName, token);
    }

    getCookie(name = "5cf7b49e23fc1d1c347c37e3879cc2f7") {
        return localStorage[name];
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + "-" + s4() + "-" + s4() + "-" +
            s4() + "-" + s4() + s4() + s4();
    }

    timeSince(date) {

        var seconds = Math.floor((new Date().getDate() - new Date(date).getDate()) / 1000);

        var interval = Math.floor(seconds / 31536000);
        if (interval >= 1) {
            return interval + " years";
        }

        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            return interval + " months";
        }

        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
            return interval + " days ago";
        }

        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
            return interval + " hours ago";
        }

        interval = Math.floor(seconds / 60);
        if (interval >= 1) {
            return interval + " minutes ago";
        }

        return Math.floor(seconds) + " seconds ago";
    }

    // inActiveButtonWithID(className = "disabledbutton") {
    //     $(document).on("keyup", "." + className, function () {
    //         let value = $("." + className).val();
    //         if (typeof (value) !== "undefined") {
    //             if (String(value).length > 0) {
    //                 let target = $("." + className).attr("target");
    //                 $("#" + target).attr("disabled", "false");
    //             }
    //             else {

    //                 let target = $("." + className).attr("target");
    //                 $("#" + target).attr("disabled", "true");
    //             }
    //         }
    //     });
    // }

    iphoneScreen(width, height) {
        if (navigator.platform === "iPhone") {
            window.scrollTo(width, height)
        }
    }

    // inActiveButton(className = "disabledbutton", activeClass, inActiveClass) {
    //     $(document).on("keyup", "." + className, function () {
    //         let value = $("." + className).val();
    //         if (typeof (value) !== "undefined") {
    //             if (String(value).length > 0) {
    //                 //ant-select-enabled
    //                 let target = $("." + className).attr("target");
    //                 let targetelement = $("." + target).attr("class");
    //                 $("." + target).attr("class", targetelement.replace(inActiveClass, activeClass))
    //             }
    //             else {
    //                 //ant-select-disabled
    //                 let target = $("." + className).attr("target");
    //                 let targetelement = $("." + target).attr("class");
    //                 $("." + target).attr("class", targetelement.replace(activeClass, inActiveClass))
    //             }
    //         }
    //     });

    //     document.addEventListener("keyup", () => {
    //         const elements: HTMLCollectionOf<Element> = document.getElementsByClassName("." + className);
    //         for (let i = 0; i < elements.length; i++) {
    //         }
    //     });
    // }

    clearToken(_name = "5cf7b49e23fc1d1c347c37e3879cc2f7") {
        let objclear = Object.keys(localStorage)
        for (let i = 0; objclear.length > i; i++) {
            localStorage.removeItem(objclear[i]);
        }
    }

    setTitle(titleName = "Harmony", linkimg = ImgTitle) {
        let favicon = document.createElement("link");
        favicon.rel = "icon";
        favicon.href = linkimg;
        document.head.appendChild(favicon);
        document.title = titleName;
    }

}

let Common2 = new Common();

Array.prototype.contains = function (v) {
    for (let i = 0; i < this.length; i++) {
        if (this[i].value === v.value) {
            return true;
        }
    }
    return false;
};

String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

Object.defineProperty(Object.prototype, "queryStringBuilder", {
    get: function () {
        let querystring = "?";
        let key = Object.keys(this);
        if (key.length === 1 && key[0] === "") {
            return "";
        }
        for (let index = 0; index < key.length; index++) {
            if (querystring.length === 1) {
                querystring = querystring + key[index] + "=" + this[key[index]];
            } else {
                querystring = querystring + "&" + key[index] + "=" + this[key[index]];
            }
        }
        return querystring;
    },
    enumerable: false,
    configurable: true
});

Array.prototype.unique = function () {
    let arr = [];
    for (let i = 0; i < this.length; i++) {
        if (!arr.contains(this[i])) {
            arr.push(this[i]);
        }
    }
    return arr;
}

Array.prototype.concatAll = function () {
    let results = [];
    this.forEach(function (subArray) {
        results.push.apply(results, subArray);
    });

    return results;
};

String.prototype.format = function () {
    let args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof (args[number]) !== "undefined"
            ? args[number]
            : match
            ;
    });
};

String.prototype.shortDate = function () {
    let fullDate = this.split("-")
    if (fullDate.length > 1) {
        let getYear = fullDate[0]
        let getMonth = fullDate[1]
        let getDay = fullDate[2] ? fullDate[2].substring(0, 2) : ""
        return "{0} {1} {2}".format(getDay, CommonEnume.MONTHS[getMonth - 1].label.substring(0, 3), getYear);
    }
    return "";
};

String.prototype.shortDateMonth = function () {
    let fullDate = this.split("-");
    if (fullDate.length > 1) {
        let getYear = fullDate[0];
        let getMonth = fullDate[1];
        // let getDay = fullDate[2] ? fullDate[2].substring(0, 2) : "";
        return "{0} {1} {2}".format("", CommonEnume.MONTHS[getMonth - 1].label.substring(0, 3), getYear);
    }
    return ""
};

String.prototype.longDate = function () {
    if (!this) {
        return "";
    }
    return moment(this).locale(Common2.getCookie("timezone")).format(CommonEnume.DATE_FORMAT.LONG_DATE);
};

String.prototype.longDate = function () {
    let fullDate = this.toString().split("-");
    if (fullDate.length > 1) {
        let getYear = fullDate[0];
        let getMonth = fullDate[1];
        let getDay = fullDate[2] ? fullDate[2].substring(0, 2) : "";
        let newDate = new Date("{0} {1} {2}".format(getYear, getMonth, getDay));
        return "{0}, {1} {2} {3}".format(CommonEnume.FULLDAYS[newDate.getDay()].fullName, newDate.getDate(), CommonEnume.MONTHS[newDate.getMonth()].label, newDate.getFullYear());
    }
    return "";
};

String.prototype.apiDate = function () {
    if (!this) {
        return "";
    }
    return moment(this).locale(Common2.getCookie("timezone")).format(CommonEnume.DATE_FORMAT.API_DATE);
};

Number.prototype.noExponents = function () {
    let data = String(this).split(/[eE]/);
    if (data.length === 1) { return data[0]; }
    let clearNumber = data[0].replace(")", "");
    clearNumber = data[0].replace("(", "-");
    let z = "", sign = Number(clearNumber) < 0 ? "-" : "",
        str = clearNumber.replace(".", ""),
        mag = Number(data[1].replace(")", "")) + 1;

    if (mag < 0) {
        z = sign + "0.";
        while (mag++) { z += "0"; }
        return z + str.replace(/^\-/, "");
    }
    mag -= str.length;
    while (mag--) { z += "0"; }
    return str + z;
}

String.prototype.reverse_number = function () {
    let text = this;
    if (!text) {
        return text;
    }
    text = "" + text;
    let numb = text.match(/\d/g);
    if (numb !== null) {
        return Number(numb.join(""));
    } else {
        return 0;
    }
}

String.prototype.noExponents = function () {
    let data = String(this).split(/[eE]/);
    if (data.length === 1) {
        return data[0];
    }
    let clearNumber = data[0].replace(")", "");
    clearNumber = data[0].replace("(", "-");
    let z = "", sign = Number(clearNumber) < 0 ? "-" : "",
        str = clearNumber.replace(".", ""),
        mag = Number(data[1].replace(")", "")) + 1;

    if (mag < 0) {
        z = sign + "0.";
        while (mag++) {
            z += "0";
        }
        return z + str.replace(/^\-/, "");
    }
    mag -= str.length;
    while (mag--) {
        z += "0";
    }
    return str + z;
}

moment.prototype.longDate = function () {
    let fullDate = this.toString().split("-");
    if (fullDate.length > 1) {
        let getYear = fullDate[0];
        let getMonth = fullDate[1];
        let getDay = fullDate[2] ? fullDate[2].substring(0, 2) : "";
        let newDate = new Date("{0} {1} {2}".format(getYear, getMonth, getDay));
        return "{0}, {1} {2} {3}".format(CommonEnume.FULLDAYS[newDate.getDay()].fullName, newDate.getDate(), CommonEnume.MONTHS[newDate.getMonth()].label, newDate.getFullYear());
    }
    return "";
};

moment.prototype.shortDate = function () {
    let fullDate = this.toString().split("-")
    if (fullDate.length > 1) {
        let getYear = fullDate[0];
        let getMonth = fullDate[1];
        let getDay = fullDate[2] ? fullDate[2].substring(0, 2) : "";
        return "{0} {1} {2}".format(getDay, CommonEnume.MONTHS[getMonth - 1].label.substring(0, 3), getYear);
    }
    return "";
};

moment.prototype.apiDate = function () {
    if (!this) {
        return "";
    }
    return moment(this).locale(Common2.getCookie("timezone")).format(CommonEnume.DATE_FORMAT.API_DATE);
};

//region array Coa
Array.prototype.coa = function (useParent = false) {
    if (useParent) {
        return this.map((x) =>
            ({
                label: x.coa_name,
                options: x.coa_detail.map((detail) => ({
                    value: detail.coadet_id,
                    label: detail.coadet_code + " - " + detail.coadet_name,
                    disabled: typeof (detail.disabled) !== "undefined" ? true :
                        typeof (detail.is_child) !== "undefined" ? detail.is_child === 0 : false
                }))
            }));
    }
    else {
        return this.map((x) =>
            x.coa_detail.map((detail) => ({
                value: detail.coadet_id,
                label: detail.coadet_code + " - " + detail.coadet_name,
                disabled: detail.is_child === 0
            }))).concatAll();
    }
};

export default Common2;
