import React from "react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import CommonEnume from "../enum/CommonEnume";
import { DatePickerProps } from "../interfaces/DatePickerProps";

class MonyDatePickerCls extends React.Component<DatePickerProps> {

    static defaultProps: DatePickerProps;

    private focused: boolean;
    private textInput: any;
    private isRawInput: boolean;

    state = {
        value: undefined,
        rawValue: ""
    }

    constructor(props) {
        super(props);

        this.isRawInput = false;
        this.focused = false;

        this.convertToMoment = this.convertToMoment.bind(this);
        this.convertToString = this.convertToString.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeRaw = this.onChangeRaw.bind(this);
        this.setOpenPicker = this.setOpenPicker.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.isValid = this.isValid.bind(this);
        this.isIncluded = this.isIncluded.bind(this);
        this.isExclude = this.isExclude.bind(this);
    }

    componentWillReceiveProps(props) {
        if (this.convertToString(props.value) !== this.convertToString(this.state.value)) {
            let newValue = typeof props.value === "string" && this.isValid(props.value) ? new Date(props.value) : props.value
            if(newValue && (!this.isIncluded(newValue) || this.isExclude(newValue))) {
                newValue = undefined;
            }
            this.setState({ value: newValue });
        }
    }

    componentDidMount() {
        const value = this.state.value && this.isValid(this.state.value) ? moment(this.state.value) : this.props.defaultDate ? moment() : undefined;
        if (value) {
            const strDate = this.convertToString(value.toDate()).apiDate();
            this.setState({ rawValue: strDate }, () => {
                if (this.props.onStateChanged) {
                    this.props.onStateChanged(this.props.stateKey, strDate, this.props.stateRowIndex);
                } else {
                    this.props.onChange(strDate);
                }
            });
        }
    }

    convertToMoment(val: string | Date) {
        let momentValue: Date;
        if (typeof (val) === "string") {
            if (val === "" || !this.isValid(val)) {
                momentValue = undefined;
            } else {
                momentValue = moment(val).toDate();
            }
        } else {
            momentValue = val;
        }
        return momentValue;
    }

    convertToString(val: string | Date) {
        let toShow = "";
        if (this.isRawInput) {
            toShow = this.state.rawValue;
        } else if (val !== undefined && this.isValid(val)) {
            toShow = moment(val).format(this.props.dateFormat[0].toUpperCase());
        }
        return toShow;
    }

    onChange(e: Date, triggerBlur?: any) {

        if (this.isRawInput && !e) {
            return;
        }

        const targetValue = this.isValid(e || "") ? String(e).apiDate() : e;
        if (this.textInput && this.focused) {
            if ((typeof triggerBlur === "boolean" && triggerBlur === false) || typeof triggerBlur === "object")  {
                this.setFocus()
            }
            this.setOpenPicker(false);
        }

        this.setState({ rawValue: this.convertToString(targetValue), value: targetValue }, () => {
            if (this.props.onStateChanged) {
                this.props.onStateChanged(this.props.stateKey, targetValue, this.props.stateRowIndex);
            } else {
                this.props.onChange(targetValue);
            }
        });
    }

    onChangeRaw(e) {
        const currentEdit = e.target.value;
        this.isRawInput = true;
        if (currentEdit.length <= this.props.dateFormat[0].length) {
            this.setState({ rawValue: e.target.value }, () => {
                this.setOpenPicker(true);
            });
        }
        return false;
    }

    onFocus() {
        this.focused = true;
    }

    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.isRawInput = false;
            this.setFocus();
        } else if (e.keyCode === 46) {
            this.onChange(null);
        } else if (e.keyCode === 9) {
            this.setOpenPicker(false);
            this.onBlur();
        }
    }

    onBlur() {
        if (this.isRawInput) {
            this.isRawInput = false;
            const rawValue = this.convertToMoment(this.state.rawValue);
            if (!this.isIncluded(rawValue) || this.isExclude(rawValue)) {
                this.onChange(undefined, true);
            } else {
                this.setState({ rawValue: "" }, () => {
                    this.onChange(rawValue, true);
                });
            }
        }
    }

    isValid(date: string | Date) {
        return moment(date).isValid();
    }

    isIncluded(date: string | Date) {
        const value: Date = typeof date === "string" ? new Date(date) : date;
        if (this.props.includeDates && this.props.includeDates.length > 0) {
            return this.props.includeDates.findIndex((item) => String(item) === String(value)) !== -1;
        } else {
            return true;
        }
    }

    isExclude(date: string | Date) {
        const value: Date = typeof date === "string" ? new Date(date) : date;
        if (this.props.excludeDates && this.props.excludeDates.length > 0) {
            return this.props.excludeDates.findIndex((item) => String(item) === String(value)) !== -1;
        } else {
            return false;
        }
    }

    setOpenPicker(open: boolean) {
        if (this.textInput && this.textInput.hasOwnProperty("setOpen")) {
            this.textInput.setOpen(open, true);
        }
    }

    setFocus() {
        if (this.textInput && this.textInput.hasOwnProperty("setFocus")) {
            this.textInput.setFocus();
        }
    }

    render() {
        return (
            <ReactDatePicker
                selected={!this.state.value ? "" : (typeof this.state.value === "string" ? new Date(this.state.value) : this.state.value)}
                className={`picker-custome col-input-date-new ant-input pl--12 ${this.props.className}`}
                todayButton={"Today"}
                placeholderText={this.props.placeholder}
                dateFormat={this.props.dateFormat}
                selectsStart={this.props.selectsStart && !this.props.selectsEnd}
                startDate={this.props.selectsStart && !this.props.selectsEnd ? this.convertToMoment(this.props.startDate) : undefined}
                selectsEnd={this.props.selectsEnd && !this.props.selectsStart}
                endDate={this.props.selectsEnd && !this.props.selectsStart ? this.convertToMoment(this.props.endDate) : undefined}
                minDate={this.props.selectsEnd && !this.props.selectsStart && this.props.startDate ? this.convertToMoment(this.props.startDate) : undefined}
                maxDate={!this.props.selectsEnd && this.props.selectsStart && this.props.endDate ? this.convertToMoment(this.props.endDate) : undefined}
                disabled={this.props.disabled}
                readOnly={this.props.readOnly}
                excludeDates={this.props.excludeDates}
                includeDates={this.props.includeDates}
                onChange={this.onChange}
                onChangeRaw={this.onChangeRaw}
                previousMonthButtonLabel={this.props.previousMonthButtonLabel}
                nextMonthButtonLabel={this.props.nextMonthButtonLabel}
                previousYearButtonLabel={this.props.previousYearButtonLabel}
                nextYearButtonLabel={this.props.nextYearButtonLabel}
                allowSameDay={true}
                onFocus={this.onFocus}
                shouldCloseOnSelect={false}
                onKeyDown={this.onKeyDown}
                onBlur={this.onBlur}
                ref={(input) => { this.textInput = input; }}
            />
        );
    }
}

MonyDatePickerCls.defaultProps = {
    placeholder: "Select Date",
    selectsStart: false,
    selectsEnd: false,
    disabled: false,
    readOnly: false,
    showTime: false,
    defaultDate: false,
    dateFormat: [CommonEnume.DATE_FORMAT.SHORT_DATE, CommonEnume.DATE_FORMAT.API_DATE_DMY],
    valueDateFormat: CommonEnume.DATE_FORMAT.API_DATE,
    inputPrefixCls: "datepickerant",
    divClassName: "",
    previousMonthButtonLabel: "",
    nextMonthButtonLabel: "",
    previousYearButtonLabel: "",
    nextYearButtonLabel: "",
    onChange: function () { },
    disabledDate: function () { }
}

export default MonyDatePickerCls;