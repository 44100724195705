import React from "react";
import { MonyInput, MonyButton, Selected, TypeTranslate, SelectMultiple } from "mony-component";
import { useSignupPresenter } from "./SignupPresenter";

interface IProps {
    translate: TypeTranslate;
}
export const SignupStep2Page: React.FC<IProps> = (props) => {
    const { state, onChangedSignup, onNextStep } = useSignupPresenter(props);
    return (
        <div>
            <div>
                <div>
                    <label className="font-login">{props.translate('company_name', 'label')}<span className="required"> *</span></label>
                </div>
                <div className="pt--10">
                    <MonyInput
                        id="company_name"
                        maxLength={100}
                        error={state.error.filter((x) => x.path === "company_name").length > 0 ? true : false}
                        stateKey="company_name"
                        value={state.signup.company_name}
                        onStateChanged={onChangedSignup}
                    />
                </div>
            </div>
            <div className="pt--20">
                <div>
                    <label className="font-login">{props.translate('industry_type', 'label')}<span className="required"> *</span></label>
                </div>
                <div className="pt--10">
                    <Selected
                        id="company_type"
                        data={state.companyTypes}
                        placeholder={props.translate('select_industry_type', 'placeholder')}
                        error={state.error.filter((x) => x.path === "company_type").length > 0 ? true : false}
                        OnSelected={(select) => onChangedSignup("company_type", select.value)}
                        value={state.signup.company_type}
                    />
                    {/* <SelectMultiple
                        allable={false}
                        allowMultiple={false}
                        showAddBox={false}
                        stateKey="company_type"
                        className="default-tags wd---100"
                        onStateChanged={onChangedSignup}
                        data={[
                            { label: "Service Industry", value: 1 },
                            { label: "Retail Industry", value: 2 },
                            { label: "Home Industry", value: 3 }
                        ]}
                        placeholder={props.translate('select_industry_type', 'placeholder')}
                        value={state.signup.company_type}
                        error={state.error.filter((x) => x.path === "company_type").length > 0 ? true : false}
                        dropdownMatchSelectWidth={false}
                        translate={props.translate} /> */}
                </div>
            </div>
            <div className="pt--40 pb--20">
                <MonyButton
                    id="btn-step2"
                    className="button--save"
                    value={props.translate('continue', 'button')}
                    onClick={onNextStep}
                />
            </div>
        </div>
    );
}