import { ContainerActionType, CONTAINER_ENUM, IReferralRewardModel, IRegisterModel, ISnackBar } from '../types';
import { APP_ENUM, ErrorModel } from '../../../libs/redux/types';
import { UserCompanyApi, ReferralRewardApi } from '../../api';
import { SetError } from '../../../libs/redux/actions/AppAction';
import { Common } from 'mony-component';

export const OnChangedFieldUser = (key: string, value: any): ContainerActionType => {
    return { type: CONTAINER_ENUM.CHANGED_FIELD_USER, payload: { key, value } }
};

export const OnChangedFieldRegister = (key: string, value: any): ContainerActionType => {
    return { type: CONTAINER_ENUM.CHANGED_FIELD_REGISTER, payload: { key, value } }
};

export const SetStepSignup = (step: number): ContainerActionType => {
    return { type: CONTAINER_ENUM.SET_STEP_SIGNUP, payload: step }
};

export const FetchCompanyType = () => {
    return async (dispatch) => {
        const response = await UserCompanyApi.FetchCompanyType();
        if (response.success) {
            dispatch({ type: CONTAINER_ENUM.FETCH_COMPANY_TYPE, payload: response.data });
        }
    }
}

export const FetchCurrency = () => {
    return async (dispatch) => {
        const response = await UserCompanyApi.FetchCurrency();
        if (response.success) {
            dispatch({ type: CONTAINER_ENUM.FETCH_CURRENCY, payload: response.data });
        }
    }
}

export const FetchTimeZone = () => {
    return async (dispatch) => {
        const response = await UserCompanyApi.FetchTimeZone();
        if (response.success) {
            dispatch({ type: CONTAINER_ENUM.FETCH_TIMEZONE, payload: response.data });
        }
    }
}

export const FetchUser = (callback = null, callbackerror = null, callbackcompany = null, gtmCallback = null) => {
    return async (dispatch) => {
        if (!Common.getCookie()) {
            if (callbackerror) {
                callbackerror();
            } else {
                window.location.href = "/login";
            }
        } else {
            const response = await UserCompanyApi.FetchProfile();
            if (response.success) {
                dispatch({ type: CONTAINER_ENUM.FETCH_TIMEZONE, payload: response.data });
            }
        }
    }
}

export const RegisterUser = (data: IRegisterModel, onSuccess: (data: any) => void) => {
    return async (dispatch) => {
        dispatch({ type: APP_ENUM.SET_LOADING, payload: true });
        const response = await UserCompanyApi.RegisterUser(data);
        dispatch({ type: APP_ENUM.SET_LOADING, payload: false });
        if (response.success) {
            if (onSuccess) {
                onSuccess(response.data);
            }
        } else {
            response.messages.forEach((o) => o["type"] = "error");
            dispatch(SetError({ signup: response.messages as Array<ErrorModel> }));
        }
    }
}

export const FetchReferralReward = () => {
    return async (dispatch) => {
        const response = await ReferralRewardApi.FetchReferralReward();
        if (response.success) {
            let data: IReferralRewardModel = response.data.data;
            data.referralLink = `${location.origin}/sign-up?referral=${data.referral_code}`;
            dispatch({ type: CONTAINER_ENUM.FETCH_REFERRAL_REWARD, payload: data });
        }
    }
}

export const SetSnackBar = (data: ISnackBar): ContainerActionType => {
    return {
        type: CONTAINER_ENUM.SET_SNACKBAR,
        payload: {
            show: data.show,
            message: data.message || "",
            duration: data.duration || 3000
        }
    }
};

export const SetCookieReferralCode = (data: string): ContainerActionType => {
    return {
        type: CONTAINER_ENUM.SET_COOKIE_REFERRALCODE,
        payload: data
    }
};