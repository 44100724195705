import React from "react";

export interface TemplateForm3Props {
    key?: string;
    title?: string;
    className?: string;
    titleClassName?: string;
    topLeft?: React.ReactElement;
    topRight?: React.ReactElement;
    minWidthContainer?: number;
    rightWidth?: number;
};

const TemplateForm3: React.FC<React.PropsWithChildren<TemplateForm3Props>> = (props) => {
    return (
        <>
            <div className={`mb--10 header-content ${props.className || ""}`} style={{ minWidth: props.minWidthContainer }}>
                <div className={`pull-left title-header-col ${props.titleClassName || ""}`}>
                    {props.topLeft}
                    <label className="title-header">{props.title}</label>
                </div>
                <div className="title-right-header-col" style={{ width: props.rightWidth, maxWidth: props.rightWidth, float: "right" }}>
                    {props.topRight}
                </div>
            </div>
            {props.children}
        </>
    );
}

export default React.memo(TemplateForm3);
