import { Common } from "mony-component";
import { ApiGet, ApiPost } from "../helper";
import { IApiResponse, TaptalkTicketBody } from "../type-interface";
const moment = require("moment");

export const ValidateLogin = async (data: any): Promise<IApiResponse> => {
    const response = await ApiPost("/login", data);
    if (response.success) {
        Common.setCookie(response.data.token);
    }
    return response;
}

export const UserProfile = async (authToken?: string): Promise<IApiResponse> => {
    const response = await ApiGet("/user/profile", undefined, undefined, authToken);
    return response;
}

export const ForgotPassword = async (data: any): Promise<IApiResponse> => {
    const response = await ApiPost("/user/register/forgot-pwd", data);
    return response;
}

export const ResetPassword = async (data: any): Promise<IApiResponse> => {
    const response = await ApiPost("/user/register/reset-pwd", data);
    return response;
}

export const CheckResetToken = async (token: string): Promise<IApiResponse> => {
    const response = await ApiGet(`/user/register/reset-pwd?token=${token}`);
    return response;
}

export const Activation = async (token: string, isadmin?: number): Promise<IApiResponse> => {
    const response = await ApiGet(`/user/register/?token=${token}${isadmin !== undefined ? "&isadmin=" + isadmin : ""}`);
    return response;
}

export const RequestTaptalkTicket = async (user: TaptalkTicketBody): Promise<string> => {
    let ticket = "";
    const response = await ApiPost("/user/taptalk_ticket", user);
    if (response.success) {
        ticket = response.data.taptalkTicket;
    }
    return ticket;
}

export const GetUserCurrencyList = async (authToken?: string): Promise<IApiResponse> => {
    let todayDate = moment().format('YYYY-MM-DD');
    const response = await ApiGet(`/user/currency/settings?date=${todayDate}`, undefined, undefined, authToken);
    return response;
}

export const GetAllUserCurrency = async (authToken?: string): Promise<IApiResponse> => {
    const response = await ApiGet(`/user/currency/all`, undefined, undefined, authToken);
    return response;
}