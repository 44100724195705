import React from "react";

export interface IMonyCardSimpleProps {
    className?: string;
    cardTitle?: string;
    titleLink?: string;
    cardDescription?: string;
    others?: React.ReactNode;
    othersDetail?: React.ReactNode;
    logoContent?: React.ReactNode;
    logoPosition?: "left" | "top-left";
    logo?: {
        logoSize?: number;
        logoSrc?: string;
        logoClass?: string;
    };
}

const MonyCardSimple: React.FC<IMonyCardSimpleProps> = (props) => {

    props = { ...defaultProps, ...props };

    const Logo = React.useMemo(() => {
        if (props.logo) {
            return (
                <img height={props.logo.logoSize} width={props.logoPosition === "left" ? props.logo.logoSize : "auto"} src={props.logo.logoSrc} className={props.logo.logoClass} />
            );
        } else {
            return props.logoContent;
        }
    }, [props.logo, props.logoPosition]);

    return (
        <div className={`ant-card design-mini-panel ant-card-bordered ant-card-shadow ant-card-hoverable ${props.className}`}>
            <div className="ant-card-cover">
                <div className="ant-card-body dis-table">
                    {
                        props.logoPosition === "left" ? <div className="left-cell vtop">{Logo}</div> : ""
                    }
                    <div className={`ant-card-meta ${props.logoPosition === "left" ? "right-cell" : ""} wd---100 vtop text-left pl--15`}>
                        {
                            props.logoPosition !== "left" ? Logo : ""
                        }
                        <div className="ant-card-meta-detail">
                            <a href={props.titleLink} target="_blank" rel="noopener noreferrer" className={!props.titleLink ? "cursor-default" : ""}>
                                <div className="ant-card-meta-title">{props.cardTitle}</div>
                            </a>
                            <div>{props.cardDescription}</div>
                            {props.othersDetail}
                        </div>
                    </div>
                    {
                        props.others
                    }
                </div>
            </div>
        </div>
    );
}

const defaultProps: IMonyCardSimpleProps = {
    className: "",
    cardTitle: "",
    titleLink: "",
    cardDescription: "",
    logoContent: null,
    logoPosition: "left"
}

export default React.memo(MonyCardSimple);