import React from "react";
import { useDispatch } from "react-redux";
import { MonyButton, MonyCheckbox, Selected, TypeTranslate } from "mony-component";
import { useSignupPresenter } from "./SignupPresenter";
import { FetchCurrency, FetchTimeZone } from "../../redux/actions/ContainerAction";
import { RenderLabelInfo } from "../../lib/LabelInfo"
import { Link } from "react-router-dom";

interface IProps {
    translate: TypeTranslate;
}
export const SignupStep3Page: React.FC<IProps> = (props) => {

    const dispatch = useDispatch();
    const { state, onChangedSignup, onNextStep } = useSignupPresenter(props);

    React.useEffect(() => {
        if (state.listCurrency.length === 0) {
            dispatch(FetchCurrency());
        }
        if (state.timeZones.length === 0) {
            dispatch(FetchTimeZone());
        }
    }, []);

    return (
        <div>
            {state.signup.company_type == 1 ? null :
                <div>
                    <div>
                        {/* <label className="font-login">Inventory Method <span className="required"> *</span></label> */}
                        {RenderLabelInfo("InventoryMethod", false, props.translate)}
                    </div>
                    <div className="pt--10">
                        <Selected
                            id="inventory_method"
                            data={state.inventoryMethods}
                            placeholder={props.translate("select_method", "placeholder")}
                            error={state.error.filter((x) => x.path === "inventory_method").length > 0 ? true : false}
                            OnSelected={(select) => onChangedSignup("inventory_method", select.value)}
                            value={state.signup.inventory_method}
                        />
                    </div>
                </div>}
            <div className="clear"></div>
            <div className="pt--20">
                <div>
                    <label className="font-login">{props.translate("coa_language", "label")}<span className="required"> *</span></label>
                </div>
                <div className="pt--10">
                    <Selected
                        id="coalang"
                        data={state.coaLanguage}
                        placeholder={props.translate("select_language", "placeholder")}
                        error={state.error.filter((x) => x.path === "coalang").length > 0 ? true : false}
                        OnSelected={(select) => onChangedSignup("coalang", select.value)}
                        value={state.signup.coalang}
                    />
                </div>
            </div>
            <div className="pt--20">
                <div>
                    <label className="font-login">{props.translate("currency", "label")}<span className="required"> *</span></label>
                </div>
                <div className="pt--10">
                    <Selected
                        id="currency"
                        data={state.listCurrency}
                        placeholder={props.translate("select_currency", "placeholder")}
                        error={state.error.filter((x) => x.path === "currency").length > 0 ? true : false}
                        OnSelected={(select) => onChangedSignup("currency", select.value)}
                        value={state.signup.currency}
                    />
                </div>
            </div>
            <div className="pt--20">
                <div>
                    <label className="font-login">{props.translate("timezone_settings", "label")}<span className="required"> *</span></label>
                </div>
                <div className="pt--10">
                    <Selected
                        id="timezone"
                        data={state.timeZones}
                        placeholder={props.translate("select_timezone", "placeholder")}
                        error={state.error.filter((x) => x.path === "timezone").length > 0 ? true : false}
                        OnSelected={(select) => onChangedSignup("timezone", select.value)}
                        value={state.signup.timezone}
                    />
                </div>
            </div>
            <div className="pt--20">
                <label className="helper-text-modal">
                    <MonyCheckbox
                        // id="has_agreed"
                        className="pt--3"
                        checked={state.signup.has_agreed}
                        stateKey="has_agreed"
                        onStateChanged={onChangedSignup}
                        // onClick={(checked) => { onChangedSignup("has_agreed", checked) }}
                        
                    />
                    &emsp;{props.translate("by_clicking_finish", "label")} <Link to={{ pathname: "https://www.harmony.co.id/syarat" }} style={{ textDecoration: "none" }} target="_blank">{props.translate("agree_to_agreement", "label")}</Link> {props.translate("and", "label")} <Link to={{ pathname: "https://www.harmony.co.id/privasi" }} style={{ textDecoration: "none" }} target="_blank">{props.translate("privacy_policy", "label")}</Link>
                </label>
            </div>
            <div className="pt--40 pb--20">
                <MonyButton
                    id="btn-step3"
                    className="button--save"
                    disabled={!state.signup.has_agreed}
                    value={props.translate("finish", "button")}
                    onClick={onNextStep}
                    ajax={true}
                />
            </div>
        </div>
    );
}