import message from "antd/es/message";

const Success = (text: string) => {
    message.success(text);
}

const Info = (text: string) => {
    message.info(text);
}

const Warning = (text: string) => {
    message.warning(text);
}

const Error = (text: string) => {
    message.error(text);
}

export default {
    Success,
    Info,
    Warning,
    Error
}