import React from "react";
import { Chart as GoogleChart } from "react-google-charts";
import { Loader } from ".";
import { GetMonyColor } from "../helper";

const isEmpty = (data: Array<any>): boolean => {
  let empty = true;
  if (data) {
    for (let i = 1; i < data.length; i++) {
      for (let j = 1; j < data[i].length; j++) {
        if (data[i][j] > 0) {
          empty = false;
          break;
        }
      }
      if (!empty) {
        break;
      }
    }
  }
  return empty;
}

type viewWindowType = {} | { min: number; max: number; };

const MonyGroupBarChart: React.FC<{ height?: number, width?: number, data: any, vAxesTitle: string }> = (props) => {

  props = { ...defaultProps, ...props };
  const [viewWindow, setViewWindow] = React.useState<viewWindowType>({});

  React.useEffect(() => {
    let option: viewWindowType = { min: 0, max: 0.001 };
    if (!isEmpty(props.data)) {
      option = {};
    }
    setViewWindow(option);
  }, [props.data]);

  return (
    <GoogleChart
      width={props.width}
      height={props.height}
      chartType="ComboChart"
      loader={<Loader />}
      data={props.data}
      // errorElement={<div style={{ height: 500, width: 900, textAlign: "center", paddingTop: "10%" }}>No data</div>}
      options={{
        // Material design options
        hAxis: {
          textStyle: {
            fontSize: 12,
            fontName: "harmony-sans-bold"
          }
        },
        chartArea: {
          height: "80%",
          width: "100%",
          top: 50,
          right: 110,
          bottom: 100,
          left: 70
        },
        titlePosition: "out",
        // axisTitlesPosition: "in",
        // chart: {
        // title: "Sales Performance",
        //     subtitle: "Sales, Expenses, and Profit: 2014-2017",
        // },
        vAxes: {
          // 0: {
          //     title: "Gross Commission, Total Payout, Net Commission"
          // },
          1: {
            title: props.vAxesTitle,
            // ticks: [100, 200, 210, 1120, 1170]
            titleTextStyle: {
              fontSize: 16,
              fontName: "harmony-sans-bold"
            }
          }
        },
        seriesType: "bars",
        // isStacked: true,
        // curveType: "function",
        pointSize: 7.5,
        series: {
          // 0: {
          //     type: "bar",
          //     targetAxisIndex: 0
          // },
          // 1: {
          //     type: "bars",
          //     targetAxisIndex: 2
          // },
          3: {
            type: "line",
            targetAxisIndex: 1
          }
          // 0: {
          //   type: "line",
          //   targetAxisIndex: 1
          // }
        },
        colors: [GetMonyColor("dodger-blue"), GetMonyColor("light-grey"), GetMonyColor("emerald"), GetMonyColor("coral-red")],
        legend: {
          position: "bottom",
          alignment: "center",
          textStyle: {
            fontSize: 14,
            fontName: "harmony-sans-bold"
          }
        },
        // hAxis: { showTextEvery: "1", textPosition: "out" }
        vAxis: {
          viewWindow: viewWindow,
          // viewWindowMode: "explicit",
          // minValue: 0,
          textPosition: "out",
          gridlines: {
            color: "#CCCCCC"
          },
          format: "short",
          textStyle: {
            fontSize: 12,
            fontName: "harmony-sans-bold"
          }
          // baselineColor: "red"
        },
        animation: {
          duration: 500,
          easing: "linear",
          startup: true
        }
      }}
      rootProps={{ "data-testid": "1" }}
    />
  );
}

const defaultProps = {
  height: 200,
  width: 200,
  data: []
}

export default React.memo(MonyGroupBarChart);