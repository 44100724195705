class CommonEnume {
    static DATE_FORMAT: { LONG_DATE: string; SHORT_DATE: string; REPORT_DATE_DAILY: string; REPORT_DATE_MONTHLY: string; API_DATE: string; API_DATE_DMY: string; };
    static MONTHS: { index: number; value: string; label: string; days: number; }[];
    static FULLDAYS: { fullName: string; shortName: string; }[];
    static PERPAGE: { PERPAGE10: number; PERPAGE30: number; PERPAGE50: number; };
}

CommonEnume.DATE_FORMAT = {
    LONG_DATE: "dddd, DD MMMM YYYY",
    SHORT_DATE: "dd MMM yyyy",
    REPORT_DATE_DAILY: "DD MMMM YYYY",
    REPORT_DATE_MONTHLY: "MMMM YYYY",
    API_DATE: "YYYY-MM-DD",
    API_DATE_DMY: "dd-MM-YYYY"
}

CommonEnume.FULLDAYS = [
    {
        fullName: "Sunday",
        shortName: "Sun"
    },
    {
        fullName: "Monday",
        shortName: "Mon"
    },
    {
        fullName: "Tuesday",
        shortName: "Tue"
    },
    {
        fullName: "Wednesday",
        shortName: "Wed"
    },
    {
        fullName: "Thursday",
        shortName: "Thu"
    },
    {
        fullName: "Friday",
        shortName: "Fri"
    },
    {
        fullName: "Saturday",
        shortName: "Sat"
    }
];

CommonEnume.MONTHS = [
    {
        index: 0,
        value: "01",
        label: "January",
        days: 31
    },
    {
        index: 1,
        value: "02",
        label: "February",
        days: 29
    },
    {
        index: 2,
        value: "03",
        label: "March",
        days: 31
    },
    {
        index: 3,
        value: "04",
        label: "April",
        days: 30
    },
    {
        index: 4,
        value: "05",
        label: "May",
        days: 31
    },
    {
        index: 5,
        value: "06",
        label: "June",
        days: 30
    },
    {
        index: 6,
        value: "07",
        label: "July",
        days: 31
    },
    {
        index: 7,
        value: "08",
        label: "August",
        days: 31
    },
    {
        index: 8,
        value: "09",
        label: "September",
        days: 30
    },
    {
        index: 9,
        value: "10",
        label: "October",
        days: 31
    },
    {
        index: 10,
        value: "11",
        label: "November",
        days: 30
    },
    {
        index: 11,
        value: "12",
        label: "December",
        days: 31
    }
];

CommonEnume.PERPAGE =
{
    PERPAGE10: 10,
    PERPAGE30: 30,
    PERPAGE50: 50
};

export default CommonEnume;
