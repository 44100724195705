import { ApiDownloadFile } from "./ApiHelper";
import { Method } from "axios";

export interface TaskDownloaderProps {
    name: string;
    fileType: "pdf" | "xls" | "xlsx" | "csv" | "txt";
    fileName: string;
    link: string;
    method?: Method;
    postData?: any;
    otherInfo?: string;
    viewAble?: boolean;
    onLoad?: boolean;
    forcedownload?: boolean;
}

export const AddTaskDownloader = (option: TaskDownloaderProps) => {
    option.otherInfo = option.otherInfo || "";
    option.viewAble = option.fileType === "pdf";
    option.onLoad = option.onLoad === undefined ? false : option.onLoad;
    option.method = option.method === undefined ? "get" : option.method;
    option.forcedownload = option.forcedownload === undefined ? false : option.forcedownload;
    if (window.MonyHelper.addTaskDownloader) {
        window.MonyHelper.addTaskDownloader(option);
    } else {
        const downloader = async () => {
            const response = await ApiDownloadFile(option.link, option.method, option.postData);
            if (response.success) {
                const fileURL = window.URL.createObjectURL(response.data)
                const element = document.createElement("a");
                element.style.display = "none";
                element.download = option.fileName;
                element.href = fileURL;
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }
        }
        downloader();
    }
};