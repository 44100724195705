import React from "react";
import { PiralInstance } from "piral";
import { TestPage } from "./pages/TestPage";
import { LoginPage } from "./pages/login/LoginPage";
import { ShareInvoicePage } from "./pages/share-invoice/ShareInvoicePage";

import {
    ForgotPasswordPage,
    ForgotSuccessPage,
    ResetPasswordPage,
    ResetFailedPage,
    ResetSuccessPage
} from "./pages/forgot-password";
import { ActivatePage, ActivateSuccessPage, ActivateFailedPage } from "./pages/activate";
import { SignupPage } from "./pages/signup/SignupPage";
import { SignupSuccessPage } from "./pages/signup/SignupSuccessPage";
import { Route, Switch, useHistory } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { BindShellNavigate } from "../libs/helper";
import { containerStore } from "./redux/store";
import { useLanguageState } from "../libs/custom-state";
import { ExtensionNameEnum } from "../libs/component-extension";

export const IsUrlNoSession = () => {
    const url_session = window.location.pathname.split('/').length > 1 ? window.location.pathname.split('/')[1] : window.location.pathname.split('/');
    const urlNoSession = [
        "test",
        "login",
        "company-ownership",
        "reset-password",
        "invitation",
        "company-member-invitation",
        "activate",
        "forgot",
        "reset-error",
        "activate-error",
        "activate-success",
        "reset-sent",
        "sign-up-success",
        "reset-success",
        "sign-up",
        "permission-denied",
        "share-invoice",
        "share-quote",
        "share-po",
        "share-invoice"
    ]
    return urlNoSession.find((x) => x == url_session) !== undefined;
}

export const ContainerApp: React.FC<any> = ({ piral, _match, _changeLanguage }) => {

    const history = useHistory();
    BindShellNavigate(history);
    document.getElementById("loading-js").style.visibility = "hidden";
    const { currentLanguage, translate } = useLanguageState(piral);

    return (
        <BrowserRouter>
            <React.StrictMode>
                <Provider store={containerStore}>
                    <Switch>
                        <Route path="/dashboard2">
                            <div>Dashboard</div>
                            <button onClick={() => {
                                // Common.setCookie("");
                                // ShellNavigate("/login");
                                window.location.href = "/login";
                            }}>Logout</button>
                        </Route>
                        <Route path="/test">
                            <TestPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Test | Harmony"
                                CurrencyPicker={(props) => <piral.Extension name={ExtensionNameEnum.CurrencyPicker} params={props} />}
                            />
                        </Route>
                        <Route path="/login">
                            <LoginPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Login | Harmony" />
                        </Route>
                        <Route path="/sign-up">
                            <SignupPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Sign up | Harmony" />
                        </Route>
                        <Route path="/sign-up-success">
                            <SignupSuccessPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Sign up Success | Harmony" />
                        </Route>
                        <Route path="/forgot">
                            <ForgotPasswordPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Forgot Password | Harmony" />
                        </Route>
                        <Route path="/reset-sent">
                            <ForgotSuccessPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Reset Password | Harmony" />
                        </Route>
                        <Route path="/reset-password/:token">
                            <ResetPasswordPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Reset Password | Harmony" />
                        </Route>
                        <Route path="/reset-error">
                            <ResetFailedPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Invalid Token | Harmony" />
                        </Route>
                        <Route path="/reset-success">
                            <ResetSuccessPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Reset Password | Harmony" />
                        </Route>
                        <Route path="/activate/:token">
                            <ActivatePage piral={piral} translate={translate} language={currentLanguage} pageTitle="Activate | Harmony" />
                        </Route>
                        <Route path="/activate-error">
                            <ActivateFailedPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Invalid Token | Harmony" />
                        </Route>
                        <Route path="/activate-success">
                            <ActivateSuccessPage piral={piral} translate={translate} language={currentLanguage} pageTitle="Successfully Activated | Harmony" />
                        </Route>
                        <Route path="/share-invoice/:company_id/:token">
                            <ShareInvoicePage piral={piral} translate={translate} language={currentLanguage} pageTitle="Share-Invoice | Harmony" />
                        </Route>
                        {/* <Route path="/invitation/:token/:status">
                            <ActivatePage pageTitle="Activate | Harmony" />
                        </Route>
                        <Route path="/invitation/:token">
                            <ActivatePage pageTitle="Activate | Harmony" />
                        </Route> */}
                    </Switch>
                </Provider>
            </React.StrictMode>
        </BrowserRouter>
    );
}

// export const AppsRegisterPage = (app: PiletApi) => {
export const AppsRegisterPage = (instance: PiralInstance) => {
    // app.registerPage("/login", (props) => {
    //     BindShellNavigate(props.history);
    //     return <LoginPage {...props} />
    // });
    const app = instance.root;
    app.registerPage("/*", (props) => <ContainerApp {...props} changeLanguage={instance.context.selectLanguage}/>);
    document.getElementById("app").style.visibility = "visible";
}