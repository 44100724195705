import React from "react";
import { Common, ILabelValueModel } from "mony-component";
import { useDispatch, useSelector } from "react-redux";
import { SetStepSignup, OnChangedFieldRegister, RegisterUser, SetCookieReferralCode } from "../../redux/actions/ContainerAction";
import { ContainerState } from "../../redux/store";
import { ErrorModel } from "../../../libs/redux/types/AppTypes";
import { useAppPresenter } from "../../presenters/AppPresenter";
import { IRegisterModel } from "../../redux/types";
import { CommonEnume } from "../../../libs/enum";
import { Validator, pushGtm, queryParamsToObject } from "../../../libs/helper";
import { useHistory } from "react-router";
import { UserCompanyApi } from "../../api"
import { FetchCompanyType } from "../../redux/actions/ContainerAction";
import { useLanguageState } from "../../../libs/custom-state";
import { IPageProps } from "../../../libs/type-interface";
import { PiletApi } from "piral";

export interface SignupPresenterType {
    state: {
        companyTypes: Array<ILabelValueModel>;
        listCurrency: Array<ILabelValueModel>;
        timeZones: Array<ILabelValueModel>;
        signupStep: number;
        signup: IRegisterModel;
        inventoryMethods: Array<ILabelValueModel>;
        coaLanguage: Array<ILabelValueModel>;
        showPassword: boolean;
        showPasswordConfirm: boolean;
        loading: boolean;
        error: Array<ErrorModel>;
        cookieReferralCode: string;
    };

    onChangedSignup: (key: string, value: any) => void;
    onShowHidePassword: () => void;
    onShowHidePasswordConfirm: () => void;
    // onShowHideReferal: () => void;
    onNextStep: (callback?: () => void) => void;
    onPrevStep: () => void;
}

export const useSignupPresenter = (props : IPageProps): SignupPresenterType => {

    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState<boolean>(false);

    const { signup, signupStep, companyTypes, listCurrency, timeZones, cookieReferralCode } = useSelector((state: ContainerState) => state.container);
    const { GetError, SetError, IsLoading } = useAppPresenter();
    const history = useHistory();
    // const language = useLanguageState(props.piral);
    // const dataLayer = React.useRef(window.dataLayer).current;

    const dispatch = useDispatch();

    const getCookieReferralCode = React.useCallback((): string => {
        const params = queryParamsToObject(location.search);
        const paramReferralCode = params["referral"] || "";
        let cookieReferralCode = Common.getCookie("referral") || "";

        if (paramReferralCode && cookieReferralCode !== paramReferralCode) {
            cookieReferralCode = paramReferralCode;
            Common.setCookie(cookieReferralCode, "referral");
        }
        return cookieReferralCode;
    }, []);

    React.useEffect(() => {
        dispatch(FetchCompanyType());
        dispatch(SetCookieReferralCode(getCookieReferralCode()));
    }, []);

    const onPushGtm = React.useCallback((eventLabel: string = "0") => {
        pushGtm("signup", `step ${signupStep + 1}`, eventLabel);
    }, [signupStep]);

    const onShowHidePassword = React.useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);

    const onShowHidePasswordConfirm = React.useCallback(() => {
        setShowPasswordConfirm(!showPasswordConfirm);
    }, [showPasswordConfirm]);

    const onNextStep = React.useCallback(async (callback?: () => void) => {
        let rule = [];
        if (signupStep === 0) {
            rule = [
                { filed: "user_fullname", message: "sasd", display: props.translate("full_name", "label"), rule: CommonEnume.Rule.REQUIRED },
                // { filed: "user_phone", display: "Phone Number", rule: CommonEnume.Rule.REQUIRED },
                { filed: "user_email", display: props.translate("email", "label"), rule: CommonEnume.Rule.REQUIRED },
                { filed: "user_email", display: props.translate("email", "label"), rule: CommonEnume.Rule.EMAIL },
                { filed: "password", display: props.translate("password", "label"), rule: CommonEnume.Rule.REQUIRED },
                { filed: "confirm_password", display: props.translate("confirm_password", "label"), rule: CommonEnume.Rule.REQUIRED },
                { filed: "confirm_password", match: "password", matchDisplay: "Password", display: props.translate("confirm_password", "label"), rule: CommonEnume.Rule.MATCHES }
            ];
            if (signup.password !== "") {
                // rule.push({ filed: "password", display: "Password", rule: CommonEnume.Rule.MIN_LENGTH, lengthString: "8" });
                rule.push({ filed: "password", display: props.translate("password", "label"), rule: CommonEnume.Rule.PASSWORD })
            }
            if (signup.proref_code !== "") {
                const response = await UserCompanyApi.CheckValidasiReferral(signup.proref_code);

                if (response.success != true) {
                    rule.push({ filed: "proref_code", display: response.messages[0].message, rule: CommonEnume.Rule.AGREEMENT })
                }
            }

        } else if (signupStep === 1) {
            rule = [
                { filed: "company_name", display: props.translate("company_name", "label"), rule: CommonEnume.Rule.REQUIRED },
                { filed: "company_type", display: props.translate("industry_type", "label"), rule: CommonEnume.Rule.REQUIRED }
            ];
        } else if (signupStep === 2) {
            if (signup.company_type === 1) {
                signup.inventory_method = "AVG";
            }
            rule = [
                { filed: "inventory_method", display: props.translate("inventory_method", "label"), rule: CommonEnume.Rule.REQUIRED },
                { filed: "coalang", display: props.translate("coa_language", "label"), rule: CommonEnume.Rule.REQUIRED },
                { filed: "currency", display: props.translate("currency", "label"), rule: CommonEnume.Rule.REQUIRED },
                { filed: "timezone", display: props.translate("timezone_settings", "label"), rule: CommonEnume.Rule.REQUIRED },
                { filed: "has_agreed", display: props.translate("please_tick_agreement_checkbox", "label"), rule: CommonEnume.Rule.AGREEMENT }
            ];
        }

        const message = Validator.form(signup, rule, props.translate).filter((x) => x.message.length > 0);
        if (message.length > 0) {
            message.forEach((o) => o["type"] = "error");
            SetError({ signup: message as Array<ErrorModel> });
            return;
        }

        if (signupStep < 2) {
            onPushGtm();
            SetError({ signup: [] });
            dispatch(SetStepSignup(signupStep + 1));
        }

        if (callback instanceof Function) {
            callback();
        }

        if (signupStep === 2) {
            return dispatch(
                RegisterUser(signup, (data: any) => {
                    onPushGtm(`${data.company_id}-${data.user_id}`);
                    setTimeout(() => {
                        if (callback) {
                            callback();
                        }

                        if (data.verify === 'N') {
                            history.push("/sign-up-success?verify=" + data.verify);
                        }else{
                            history.push("/sign-up-success?email=" + signup.user_email);
                        }
                    }, 1000);
                })
            );
        }
    }, [signupStep, signup]);

    const onChangedSignup = React.useCallback((key: string, value: any) => {
        if (key === "user_phone") {
            value = Validator.CleanPhone(value);
        }
        dispatch(OnChangedFieldRegister(key, value))
    }, [signup]);

    return {
        state: {
            companyTypes: companyTypes.map((item) => ({ value: item.comtype_id, label: item.comtype_name })),
            listCurrency: listCurrency.map((x) => ({ value: x.curr_id, label: x.curr_code + " - " + x.curr_name })),
            timeZones: timeZones.map((item) => ({ value: item.tz_id, label: item.tz_name })),
            inventoryMethods: [{ label: "AVG", value: "AVG" }, { label: "FIFO", value: "FIFO" }],
            coaLanguage: [{ value: "en", label: "English" }, { value: "id", label: "Bahasa Indonesia" }],
            signupStep: signupStep,
            signup: signup,
            cookieReferralCode: getCookieReferralCode(),
            showPassword: showPassword,
            showPasswordConfirm: showPasswordConfirm,
            loading: IsLoading(),
            error: GetError("signup")
        },
        onNextStep: onNextStep,
        onPrevStep: () => dispatch(SetStepSignup(signupStep - 1)),
        onChangedSignup: onChangedSignup,
        onShowHidePassword: onShowHidePassword,
        onShowHidePasswordConfirm: onShowHidePasswordConfirm
    }
}