import { ApiGet } from "../helper";
import { IApiResponse } from "../type-interface";

export const DocumentNumberListAll = async (queryString: string): Promise<IApiResponse> => {
    const response = await ApiGet(`/saas/document-number/all${queryString}`);
    return response;
}

export const DocumentNumberGroupListAll = async (queryString: string): Promise<IApiResponse> => {
    const response = await ApiGet(`/saas/document-number/all-grouping${queryString}`);
    return response;
}
