import React from "react";
import { Icons, MonyInput, Warning, Common, MonyButton } from "mony-component";
import { useHistory } from "react-router-dom";
import { IPageProps } from "../../../libs/type-interface";
import { MonyFooter } from "mony-component";
import { useForgotPasswordginPresenter } from "./ForgotPasswordPresenter";

const Logoback = require("../../../assets/images/arrow-left.svg");

export const ForgotPasswordPage: React.FC<IPageProps> = (props) => {

    const history = useHistory();
    const { state, onChangedValue, onSubmit } = useForgotPasswordginPresenter();

    React.useEffect(() => {
        Common.setTitle(props.pageTitle);
        document.getElementById("loading-js").style.visibility = "hidden";
        document.body.onkeydown = (event) => {
            if (event.keyCode === 13) {
                document.getElementById("btn-submit").click();
            }
        }

        return () => {
            document.body.onkeydown = undefined;
        }
    }, []);

    return (
        <div className="login--container">
            <div className="main-content-login">
                <div className="top--70 content-login">
                    <div className="pt--20">
                        <div className="float-left">
                            <a className="pointer" onClick={() => history.push("/login")}><Icons glyph={Logoback} className="icon-signup-back" /></a>
                        </div>
                    </div>
                    <div className="clear">
                    </div>
                    <div className="pt--20">
                        <label className="lbl-forgot">{props.translate('forgot_password', 'label')}</label>
                        <p className="lbl-info-forgot">
                            {props.translate('enter_your_email', 'information')}
                        </p>
                    </div>
                    <div className="pt--10 pl--30 pr--30 text-left">
                        <div className="pb--20">
                            <Warning language="en" message={state.error} />
                        </div>
                        <div>
                            <div>
                                <label>{props.translate('your_email', 'label')}</label>
                            </div>
                            <div className="pt--10">
                                <MonyInput
                                    id="wmd-input"
                                    key="username"
                                    inputDelay={false}
                                    autoFocus={true}
                                    className={state.error.filter((x) => x.path === "username").length > 0 ? "input-type input-error" : "input-type "}
                                    onStateChanged={onChangedValue}
                                    stateKey="username"
                                    value={state.email} />
                            </div>
                        </div>
                        <div className="pt--20 pb--50">
                            <MonyButton disabled={state.email.length === 0} id="btn-submit" className="button--save" value={props.translate('reset_password', 'button')} onClick={onSubmit} />
                        </div>
                    </div>
                </div>
                <MonyFooter className="mt--72 footer-copyright" />
                {/* <Footer className="mt--72 footer-copyright" style={{ background: "transparent", color: "rgba(0, 0, 0, 0.65)" }}>
                    © <a href="http://www.harmony.co.id" className="">Harmony</a> 2021 All Rights Reserved <br /> Help | Privacy &amp; Terms
                </Footer> */}
            </div>
        </div>
    );
}