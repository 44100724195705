import { AppActionType, ErrorType, APP_ENUM } from '../types/AppTypes';

export const SetLoading = (loading: boolean): AppActionType => {
    return { type: APP_ENUM.SET_LOADING, payload: loading };
};

export const SetError = (error: ErrorType): AppActionType => {
    return { type: APP_ENUM.SET_ERROR, payload: error };
};

export const ResetAppState = (): AppActionType => {
    return { type: APP_ENUM.RESET_APP_STATE };
};