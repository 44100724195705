import React from "react";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import { GetLanguage } from "../helper/Language";
import {
    ILanguage,
    TypeTranslate
} from "..";

interface IAddRowsButtonProps {
    language?: string;
    disabled?: boolean;
    onAddRows: (count: number) => void;
    translate?: TypeTranslate;
}

const AddRowsButton: React.FC<IAddRowsButtonProps> = (props) => {

    const [language, setLanguage] = React.useState<ILanguage>(!props.translate ? GetLanguage(props.language || "id") : null);
    React.useEffect(() => {
        if (!props.translate) {
            setLanguage(GetLanguage(props.language || "id"));
        }
    }, [props.language, props.translate]);

    return (
        <div className="add-row">
            <Dropdown.Button
                disabled={props.disabled}
                className={"btn-add-row" + (props.disabled ? " btn-add-row-disabled" : "")}
                onClick={() => props.onAddRows(1)}
                overlay={
                    <Menu className="list-add-row">
                        {
                            [
                                { key: "5", label: props.translate ? props.translate("add_5", "dropdown") : language && language.Dropdown.add_5 },
                                { key: "10", label: props.translate ? props.translate("add_10", "dropdown") : language && language.Dropdown.add_10 },
                                { key: "15", label: props.translate ? props.translate("add_15", "dropdown") : language && language.Dropdown.add_15 }
                            ].map(menu => <Menu.Item key={menu.key} onClick={(item) => props.onAddRows(Number(item.key))}>{menu.label}</Menu.Item>)
                        }
                    </Menu>
                }
                trigger={["click"]}>
                {
                    props.translate ? props.translate("add_more_row", "button") : language && language.Button.add_more_row
                }&nbsp;
            </Dropdown.Button>
        </div>
    );
}

export default React.memo(AddRowsButton);