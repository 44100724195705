import React from "react";
import Tabs from "antd/es/tabs";

interface IMonyTabsContainerProps {
    tabKey: string;
    className?: string;
    enabledIndex?: number | Array<number>;
    header?: React.ReactNode;
    contents?: Array<{ title: string, content: React.ReactNode }>;
    tabPosition?: "left" | "right" | "top" | "bottom";
    activeIndex?: number;
    onTabChange?: (index: number) => void;
}

const MonyTabsContainer: React.FC<IMonyTabsContainerProps> = (props) => {

    props = { ...defaultProps, ...props };

    const getTabKeyByIndex = React.useCallback((index: number) => {
        let tabKey;
        if (props.contents.length > 0) {
            tabKey = `${props.tabKey}-${props.contents[index].title}`;
        }
        return tabKey;
    }, [props]);

    const [activeTab, setActiveTab] = React.useState(getTabKeyByIndex(props.activeIndex || 0));

    React.useEffect(() => {
    }, [props]);

    const tabBarStyle = React.useMemo(() => {
        let style: React.CSSProperties = {};
        if (props.tabPosition === "top") {
            style = {
                margin: 0,
                // backgroundColor: "#FFF",
                padding: "0px 0px 0px 20px"
            }
        }
        return style;
    }, [props.tabPosition]);

    const handleClick = React.useCallback((tabKey: string) => {
        setActiveTab(tabKey);
        const splitTitle = tabKey.split("-");
        const tabTitle = splitTitle[splitTitle.length - 1];
        const tabIndex = props.contents.findIndex((tab) => tab.title === tabTitle);
        props.onTabChange(tabIndex);
    }, [activeTab, props]);

    const isDisabled = React.useCallback((index: number): boolean => {

        if (props.enabledIndex === undefined || Array.isArray(props.enabledIndex) && props.enabledIndex.length === 0) {
            return false;
        }

        if (Array.isArray(props.enabledIndex)) {
            return props.enabledIndex.findIndex((i) => i === index) === -1;
        } else {
            return (index !== props.enabledIndex);
        }

    }, [props.enabledIndex]);

    return (
        <div className={`card-container ${props.className}`}>
            {
                props.header ? props.header : ""
            }
            <Tabs
                className="bg-white"
                tabBarStyle={tabBarStyle}
                onChange={handleClick}
                activeKey={activeTab}
                tabPosition={props.tabPosition}>
                {
                    props.contents.map((tab, index) => (
                        <Tabs.TabPane
                            key={`${props.tabKey}-${tab.title}`}
                            tab={<div className="text-left font-bold">{tab.title}</div>}
                            disabled={isDisabled(index)}>
                            <div className="p--20 bg-white">
                                {tab.content}
                            </div>
                        </Tabs.TabPane>
                    ))
                }
            </Tabs>
        </div>
    );
}

const defaultProps: IMonyTabsContainerProps = {
    tabKey: "",
    contents: [],
    tabPosition: "top",
    className: "",
    enabledIndex: undefined,
    onTabChange: () => { }
}

export default React.memo(MonyTabsContainer);
