import React from "react";
import { MonyButton, MonyInput, Selected, ModalForm, HorizontalSpace, TypeTranslate } from "mony-component";
import { MonyRadioGroup } from "mony-component";
import { ICurrencyValue, useCurrencyPickerPresenter } from "./CurrencyPickerPresenter";
import { StateProps } from "mony-component/dist/interfaces/StateProps";
// import { usepropsState } from "../../libs/custom-state";
import { IErrorModel, IFilter, IPageProps } from "../../libs/type-interface";

export interface ICurrencyPickerProps extends StateProps {
    isEdit?: boolean;
    value?: number;
    rate?: number;
    date?: string;
    translate?: TypeTranslate;
    onSelected?: (val: ICurrencyValue) => void;
}

const CurrencyPicker: React.FC<ICurrencyPickerProps & IPageProps> = (props) => {

    const {
        data,
        actions,
        currInfo,
        rateText,
        customRate,
        currSelected,
        isCustomRate,
        isRateVisible,
        isCustomRateBoxVisible
    } = useCurrencyPickerPresenter(props);

    return (
        <div>
            <div>
                <ModalForm
                    visible={isCustomRateBoxVisible}
                    top={150}
                    width={400}
                    title={props.translate('set_exchange_rate', 'label')}
                    titlePosition="center"
                    classSpanTitle="title-modal"
                    classBodyContent="content-coa pt--20"
                    style={{ maxWidth: "none" }}
                    footerLine={false}
                    closable={true}
                    onCancel={actions.hideCustomRateBox}
                    bottomRight={
                        <div className="text-right" >
                            <MonyButton className="wd-auto" buttonType="cancel" style={{ border: "none" }} value={props.translate("cancel", "button")} onClick={actions.hideCustomRateBox} />
                            <HorizontalSpace />
                            <MonyButton className="wd-auto" buttonType="save" value={props.translate("save", "button")} onClick={actions.onSaveCustomRate} />
                        </div>
                    }>
                    <div className="row-field">
                        <div className="label-rate">
                            {props.translate('exchange_rate_text', 'label')}<a href="/comp-currency"> {props.translate('goto_currency', 'label')}</a>
                        </div>
                    </div>
                    <div className="label-field">
                        {props.translate('select_method', 'label')}
                    </div>
                    <div className="row-field mb--70">
                        <MonyRadioGroup
                            value={isCustomRate}
                            displayType="vertical"
                            onChange={actions.onChangeRateOption}
                            options={[
                                {
                                    label: <label className="label-modal-rate pointer">{`${props.translate('apply_from_setting', 'label')} ${currInfo.currSourName}`}</label>,
                                    value: 0,
                                    optionDescription: <div>
                                        <label className="rate-fix-amount">1 {currInfo.currCode} = {currInfo.originalRate} {currInfo.baseCurrCode}</label>
                                        <br />
                                        <label className="rate-fix-date">{props.translate('rate_from', 'label')}{currInfo.currDateFormatted}</label>
                                    </div>
                                },
                                {
                                    label: <label className="label-modal-rate pointer">{props.translate('custom_rate', 'label')}</label>,
                                    value: 1,
                                    optionDescription: <div>
                                        <label className="rate-fix-amount float-left mt--6">1 {currInfo.currCode} = </label>
                                        <MonyInput inputType="rate-currency" value={customRate} onChange={actions.onInputCustomRate} onClick={actions.onClickCustomRateInput} className="wd--100 ml--10 float-left" />
                                        <label className="rate-fix-amount float-left mt--6">&nbsp;{currInfo.baseCurrCode}</label>
                                    </div>
                                }
                            ]}
                        />
                    </div>
                </ModalForm>
                <Selected
                    data={data}
                    defaultValue={currSelected.currId}
                    value={props.value}
                    placeholder={props.translate('select_currency', 'placeholder')}
                    OnSelected={actions.onSelected}
                />
            </div>
            {
                isRateVisible ? [
                    <div className="helper-text-modal" style={{ height: "auto" }}>
                        {rateText}
                    </div>,
                    <div className="helper-text-modal">
                        <a onClick={actions.onClickRateLink}>{props.translate('set_exchange', 'label')}</a>
                    </div>,
                ] : null
            }
        </div>
    );
}

export default CurrencyPicker;