import React from "react";
import { Warning, Flooter, ModalForm, MonyButton, MonyTable, MonyInput, HorizontalSpace, MonyRadioButton, TypeTranslate } from "mony-component";
import { useInvoiceNumberPickerPresenter } from "./InvoiceNumberPickerPresenter";
// import { useLanguageState } from "../custom-state";
// import { PiletApi } from "piral";

export interface IInvoiceNumberPickerProps {
    visible: boolean;
    keyword?: string;
    loadBasedOn?: "keyword" | "opened";
    multiple?: boolean;
    OnClose?: () => void;
    // OnSelected?: (id: number | string | Array<number | string>, callback: () => void) => void;
    OnSelected?: (invoiceRefId: number, invoiceRefNumber: string, callback: () => void) => void;
    translate?: TypeTranslate;
    // piral?: PiletApi;
}

const InvoiceNumberPicker: React.FC<IInvoiceNumberPickerProps> = (props) => {
    props = { ...defaultProps, ...props };
    // const { translate } = useLanguageState(props.piral);
    const {
        data,
        filter,
        paging,
        loadingState,
        actions,
        errorState
    } = useInvoiceNumberPickerPresenter(props);

    return (
        <ModalForm
            width={850}
            visible={props.visible}
            onCancel={actions.onCancel}
            title="Invoice Number Picker"
            className="p--0"
            // bottomLeft={loadingState ? null : <Flooter data={paging} updateFilter={actions.onChangedPaging} minWidth={0} />}
            bottomRight={
                <div className="text-right">
                    <MonyButton buttonType="cancel" className="wd--100" value="Cancel" onClick={actions.onCancel} />
                    <HorizontalSpace />
                    {actions.CheckedCount() > 0 ? <MonyButton buttonType="save" ajax={true} className="wd--100" value="Choose" onClick={actions.onOk} /> : null}
                </div>
            }>
            <div style={{ padding: "0px 10px 0px 10px" }}>
                {errorState.length > 0 ? <Warning language="id" type="warning" message={errorState} repeat={false} /> : null}
            </div>
            <div className="wd--300 mb--15 pl--8">
                <MonyInput inputType="search" value={filter.keyword} placeholder="Search" stateKey="keyword" onStateChanged={actions.onChangedFilter} />
            </div>
            <div className="table-view-no-scroll">
                <MonyTable
                    classDivTable="table-responsive-no-scroll"
                    className="table-valign-top"
                    rowClassName="content--table-body"
                    tableHover={false}
                    ajax={loadingState}
                    headers={[
                        {
                            content: "",
                            className: "text-left pl--15 wd--35",
                            classLabel: "font-weight-normal font-bold font-size--14"
                        },
                        {
                            content: props.translate && props.translate("number"),
                            className: "text-left pl--15 wd--175",
                            classLabel: "font-weight-normal font-bold font-size--14"
                        },
                        {
                            content: props.translate && props.translate("date", "label"),
                            className: "text-left pl--15 wd--130",
                            classLabel: "font-weight-normal font-bold font-size--14"
                        },
                        {
                            content: props.translate && props.translate("contact", "label"),
                            className: "text-left pl--15 wd--175",
                            classLabel: "font-weight-normal font-bold font-size--14"
                        },
                        {
                            content: props.translate && props.translate("reference", "label"),
                            className: "text-left pl--15 wd--200",
                            classLabel: "font-weight-normal font-bold font-size--14"
                        }
                    ]}
                    rows={
                        data ? data.map((x, index) => {
                            return [
                                {
                                    content:
                                        <MonyRadioButton
                                            stateKey="checked"
                                            stateRowIndex={index}
                                            value={actions.isChecked(x.sales_id)}
                                            onStateChanged={actions.onChangeChecked}
                                            // onClick={actions.onChangeChecked}
                                        />,
                                    className: "text-left"
                                },
                                {
                                    content: x.sales_number,
                                    className: "pl--15"
                                },
                                {
                                    content: x.sales_date.shortDate(),
                                    className: "pl--15"
                                },
                                {
                                    content: x.contact_name,
                                    className: "pl--15"
                                },
                                {
                                    content: x.sales_reference,
                                    className: "pl--15"
                                }
                            ]
                        }) : null
                    }
                />
            </div>
            {loadingState ? null : <Flooter data={paging} updateFilter={actions.onChangedPaging} minWidth={0} translate={props.translate} />}
        </ModalForm>
    );
}

const defaultProps: IInvoiceNumberPickerProps = {
    visible: false,
    multiple: false,
    loadBasedOn: "opened",
    keyword: undefined,
    OnClose: () => { },
    OnSelected: () => { }
}

export default InvoiceNumberPicker;