import React from "react";
import { ContactType, ContactFilterType, IErrorModel } from "../type-interface";
import { ILabelValueModel } from "mony-component";
import { ContactApi } from "../api";

export type TypeContactState = {
    state: ILabelValueModel[],
    actions: {
        Load: (keyword: string) => void;
        CreateOtg: (name: string, onSuccess: (contactId) => void, onError: (error: IErrorModel[]) => void) => void;
    }
}

export const useContactState = (type: ContactType, filterType: ContactFilterType): TypeContactState => {

    const [state, setState] = React.useState<ILabelValueModel[]>([]);

    const Load = React.useCallback(async (keyword: string) => {
        const data = await ContactApi.FetchAllWithoutPaging(keyword, filterType);
        setState(data);
    }, [state]);

    const CreateOtg = React.useCallback(async (name: string, onSuccess: (contactId) => void, onError: (error: IErrorModel[]) => void) => {
        const response = await ContactApi.CreateContactOtg(name, type);
        if (response.success) {
            // await Load(name);
            state.push({ label: name, value: response.data.contact_id });
            setState(state);
            await Load("");
            onSuccess(response.data.contact_id);
        } else {
            onError(response.messages);
        }
    }, [state]);

    return {
        state,
        actions: {
            Load,
            CreateOtg
        }
    };
}