import React from "react";

const Spacing: React.FC<{ spacingType: "vertical" | "horizontal", height?: string | number, width?: string | number }> = (props) => {

  props = { ...defaultProps(props), ...props };

  if (props.spacingType === "vertical") {
    props = { ...props, width: "100%" };
  } else {
    props = { ...props, height: props.width };
  }

  return (
    <div style={{
      width: typeof (props.width) === "number" ? `${props.width}px` : props.width,
      height: typeof (props.height) === "number" ? `${props.height}px` : props.height,
    }}></div>
  );
}

const defaultProps = (props) => {
  return {
    height: props.height || 50,
    width: props.width || 12
  };
}

export default React.memo(Spacing);