import React from "react";
import { GetMonyColor } from "../helper";
import { MonyDropdownMenu, IDropdownMenuOptions } from ".";

export interface IMonyCheckedInfoWithActionMenuProps {
    checkedCount?: number;
    className?: string;
    optionsMenu?: Array<IDropdownMenuOptions>;
    popoverClassName?: string;
    onOptionSelected?: (key: string, rowIndex?: number) => void;
}

const MonyCheckedInfoWithActionMenu: React.FC<IMonyCheckedInfoWithActionMenuProps> = (props) => {

    props = { ...defaultProps, ...props };

    return (
        <div className={props.className}>
            <span style={{ color: GetMonyColor("oslo-gray") }}>{props.checkedCount} Selected</span>&nbsp;
            {
                props.checkedCount > 0 ?
                    <MonyDropdownMenu
                        menu={props.optionsMenu}
                        onSelected={props.onOptionSelected}
                        iconType="caret-down"
                        placement="bottomRight"
                        className={props.popoverClassName}
                    /> : null
            }
        </div>
    );
}

const defaultProps: IMonyCheckedInfoWithActionMenuProps = {
    checkedCount: 0,
    optionsMenu: [],
    className: "font-bold-14 mv--8 ml--15 dis-flex",
    popoverClassName: "",
    onOptionSelected: () => { }
}

export default MonyCheckedInfoWithActionMenu;
