import { PiralInstance } from "piral";

const LangID = require("./assets/lang/id.json");
const LangEN = require("./assets/lang/en.json");

export const TranslationSetup = (instance: PiralInstance, langId?: string) => {

    instance.root.setTranslations({
        en: LangEN,
        id: LangID
    });

    window.MonyHelper = window.MonyHelper ?? {};
    window.MonyHelper.MicroFE = window.MonyHelper.MicroFE ?? {};
    window.MonyHelper.MicroFE.CurrentLanguage = langId;
    window.MonyHelper.MicroFE.changeLanguage = (language: string) => {
        instance.context.selectLanguage(language);
        instance.root.setData("language", language);
        if (window.MonyHelper.MicroFE.changeLangExt) {
            window.MonyHelper.MicroFE.changeLangExt(language);
        }
    };
    window.MonyHelper.MicroFE.translate = (key: string, prefix?: string) => {
        const combinedKey = prefix ? `${prefix}.${key}` : key;
        return instance.root.translate(combinedKey);
    }
    window.MonyHelper.MicroFE.changeLanguage(langId);

}