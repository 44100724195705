import { ContainerStateType, ContainerActionType, CONTAINER_ENUM } from '../types/ContainerTypes';
import { InitialStateContainer, InitialStateRegister } from './InitialState';

const initialState: ContainerStateType = {
    language: "id",
    signupStep: 0,
    user: InitialStateContainer(),
    signup: InitialStateRegister(),
    cookieReferralCode: "",
    companyTypes: [],
    listCurrency: [],
    timeZones: [],
    referralReward: {
        user_id: 0,
        default_company_claim: 0,
        referral_code: '',
        referred_count: 0,
        total_pending: 0,
        total_used: 0,
        total_cancel: 0,
        list_reward: [],
        referralLink: ""
    },
    snackbar: {
        show: false,
        duration: 3000,
        message: ""
    }
}

export const getContainerReducer = (
    state = initialState,
    action: ContainerActionType
): ContainerStateType => {
    if (action.type === CONTAINER_ENUM.CHANGED_FIELD_USER) {
        const { key, value } = action.payload;
        const user = { ...state.user, [key]: value };
        return { ...state, user };
    } else if (action.type === CONTAINER_ENUM.CHANGED_FIELD_REGISTER) {
        const { key, value } = action.payload;
        const signup = { ...state.signup, [key]: value };
        return { ...state, signup };
    } else if (action.type === CONTAINER_ENUM.SET_STEP_SIGNUP) {
        return { ...state, signupStep: action.payload };
    } else if (action.type === CONTAINER_ENUM.FETCH_COMPANY_TYPE) {
        return { ...state, companyTypes: action.payload };
    } else if (action.type === CONTAINER_ENUM.FETCH_CURRENCY) {
        return { ...state, listCurrency: action.payload };
    } else if (action.type === CONTAINER_ENUM.FETCH_TIMEZONE) {
        return { ...state, timeZones: action.payload };
    } else if (action.type === CONTAINER_ENUM.FETCH_REFERRAL_REWARD) {
        return { ...state, referralReward: action.payload };
    } else if (action.type === CONTAINER_ENUM.SET_SNACKBAR) {
        return { ...state, snackbar: action.payload };
    } else if (action.type === CONTAINER_ENUM.SET_COOKIE_REFERRALCODE) {
        return { ...state, cookieReferralCode: action.payload };
    } else {
        return state;
    }
};