import LanguageID from "../language/id";
import LanguageEN from "../language/en";

export const GetLanguage = (paramLanguage: string, callback?: () => void) => {
    if (paramLanguage === "id") {
        return LanguageID;
    }
    if (callback) {
        callback();
    }
    return LanguageEN;
}