import React from "react";
import { MonyLabel, IMonyLabelProps } from "mony-component";

export const RenderLabelInfo = (keyLabel: string, isViewPage: boolean = false, translate) => {
    const labelInfo: { [key: string]: IMonyLabelProps } = {
        InventoryMethod: {
            label: translate ? translate('inventory_method', 'label') : "Inventory Method",
            markRequired: !isViewPage,
            labelInfo: {
                contentInfo: translate ? translate('avg_and_fifo', 'information') : "Metode Persediaan yang akan digunakan untuk menghitung Harga Pokok Penjualan dari Invoice. AVG adalah cara hitung harga pokok penjualan dengan Metode Rata-Rata. FIFO adalah cara hitung harga pokok penjualan dengan Metode FIFO (First-In-First-Out).",
                width: 250,
                position: isViewPage ? "float-left" : "float-right"
            }
        }
    };
    return (<MonyLabel {...labelInfo[keyLabel]} />);
}
