import { FetchReferralReward } from "src/apps/api/ReferralRewardApi";

export enum CONTAINER_ENUM {
    CHANGED_FIELD_USER = "CHANGED_FIELD_USER",
    CHANGED_FIELD_REGISTER = "CHANGED_FIELD_REGISTER",
    SET_STEP_SIGNUP = "SET_STEP_SIGNUP",
    FETCH_COMPANY_TYPE = "FETCH_COMPANY_TYPE",
    FETCH_CURRENCY = "FETCH_CURRENCY",
    FETCH_TIMEZONE = "FETCH_TIMEZONE",
    FETCH_USER = "FETCH_USER",
    FETCH_REFERRAL_REWARD = "FETCH_REFERRAL_REWARD",
    SET_SNACKBAR = "SET_SNACKBAR",
    SET_COOKIE_REFERRALCODE = "SET_COOKIE_REFERRALCODE"
}

export interface ICurencyModel {
    curr_id: number;
    curr_code: string;
    curr_name: string;
}

export interface ITimeZoneModel {
    tz_id: number;
    tz_code: string;
    tz_name: string;
}

export interface ICompanyTypeModel {
    comtype_id: number;
    comtype_name: string;
}

export interface IUserModel {
    username: string;
    password: string;
    oldpassword: string;
    newpassword: string;
    confirmnewpassword: string;
    confirm_password: string;
    user_fullname: string;
    user_email: string;
    user_email_tmp: string;
    user_email_old: string;
    user_phone: string;
    has_agreed: boolean;
    company_name: string;
    company_type?: number;
    inventory_method?: null | "AVG" | "FIFO",
    files: {
        filename: string;
        file: string;
        id: number;
        url: string;
        uploaded: boolean;
    },
    // is_show: boolean;
    user_permission: Array<any>;
    // coalang: string;
    // currency: string;
    // proref_code: string;
    // timezone: string;
    // show_referral: boolean;
}

export interface IRegisterModel {
    coalang: string;
    company_name: string;
    company_type?: number;
    confirm_password: string;
    currency: string;
    has_agreed: boolean;
    inventory_method?: null | "AVG" | "FIFO",
    password: string;
    proref_code: string;
    timezone: string;
    user_email: string;
    user_fullname: string;
    user_phone: string;
    year_end: string;
    year_end_day: string;
    year_end_month: string;
    // is_show: boolean;
    show_referral: boolean;
}

export interface IReferralRewardModel {
    user_id: number;
    default_company_claim: number;
    referral_code: string;
    referred_count: number;
    total_pending: number;
    total_used: number;
    total_cancel: number;
    list_reward: Array<any>;
    referralLink: string;
}

export interface ISnackBar {
    show: boolean;
    message: string;
    duration?: number;
}

export interface ContainerStateType {
    language: string;
    signupStep: number;
    user: IUserModel;
    cookieReferralCode: string;
    signup: IRegisterModel;
    companyTypes: Array<ICompanyTypeModel>;
    listCurrency: Array<ICurencyModel>;
    timeZones: Array<ITimeZoneModel>;
    referralReward: IReferralRewardModel; //{data: IReferralRewardModel, status? : string} ;
    snackbar: ISnackBar;
}

export interface OnChangedUserActionType {
    type: typeof CONTAINER_ENUM.CHANGED_FIELD_USER;
    payload: { key: string; value: any; };
}

export interface OnChangedSignupActionType {
    type: typeof CONTAINER_ENUM.CHANGED_FIELD_REGISTER;
    payload: { key: string; value: any; };
}

export interface NextStepdSignupActionType {
    type: typeof CONTAINER_ENUM.SET_STEP_SIGNUP;
    payload: number;
}

export interface FetchCompanyTypeActionType {
    type: typeof CONTAINER_ENUM.FETCH_COMPANY_TYPE;
    payload: Array<ICompanyTypeModel>;
}

export interface FetchCurrencyActionType {
    type: typeof CONTAINER_ENUM.FETCH_CURRENCY;
    payload: Array<ICurencyModel>;
}

export interface FetchReferralRewardType {
    type: typeof CONTAINER_ENUM.FETCH_REFERRAL_REWARD;
    payload: IReferralRewardModel;
}

export interface FetchTimeZoneActionType {
    type: typeof CONTAINER_ENUM.FETCH_TIMEZONE;
    payload: Array<ITimeZoneModel>;
}

export interface FetchUserActionType {
    type: typeof CONTAINER_ENUM.FETCH_USER;
    payload: IUserModel;
}

export interface SetSnackbarType {
    type: typeof CONTAINER_ENUM.SET_SNACKBAR;
    payload: ISnackBar;
}

export interface SetCookieReferralCode {
    type: typeof CONTAINER_ENUM.SET_COOKIE_REFERRALCODE;
    payload: string;
}

export type ContainerActionType = OnChangedUserActionType
    | NextStepdSignupActionType
    | FetchCompanyTypeActionType
    | FetchCurrencyActionType
    | FetchTimeZoneActionType
    | FetchUserActionType
    | OnChangedSignupActionType
    | FetchReferralRewardType
    | SetSnackbarType
    | SetCookieReferralCode;