import React from "react";
import { Selected } from "mony-component";
import { SelectProps } from "mony-component/dist/interfaces/SelectProps";

const DashboardPeriodSelect: React.FC<SelectProps> = (props) => {
    return (
        <Selected
            OnSelected={props.OnSelected}
            className={props.className}
            data={[
                { label: "2019", value: "2019" },
                { label: "2020", value: "2020" },
                { label: "2021", value: "2021" }
            ]}
            value={props.value}
        />
    );
}

export default DashboardPeriodSelect;