import React from "react";
import { TemplateFormDashboardProps } from "../interfaces/TemplateFormProps";

const TemplateFormDashboard: React.FC<TemplateFormDashboardProps> = (props) => {

  const className = "content-form-dashboard " + props.className;

  return (
    <div
      className={props.classNames}
      key={props.key}
      style={{ width: props.width, minWidth: "500px", paddingTop: props.padding }}>
      <div className={className} style={{ height: props.height }}>
        <div className={"header-full-form " + props.classHead}>
          <div style={{ textAlign: "left" }} className={props.classPadding ? "pl--15 " : "pl--20 "}>
            <label className="lbl-title-form-3 ">{props.title_1}<span>{props.labelForm}</span></label>
            <div className="float-left">{props.topLeft}</div>
            <div className="float-right pr--12">{props.topRight}</div>
          </div>
          {props.lastUpdated}
        </div>

        {
          props.children ?
            <div className={"content-form3 " + props.classChild}>
              {props.children}
            </div> : null
        }
        {
          props.bottomLeft || props.bottomRight ?
            <div className={"footer-full-form " + props.footerClass}>
              <div className="float-left">{props.bottomLeft}</div>
              <div className="float-right">{props.bottomRight}</div>
            </div> : null
        }
      </div>
    </div>
  );
}

export default TemplateFormDashboard;
