import React, { PropsWithChildren } from "react";
import { StateProps } from "../interfaces/StateProps";

export interface IMonyToggleProps extends StateProps {
    key?: string;
    name?: string;
    switchOn?: boolean;
    classNameCheckBox?: string;
    className?: string;
    disabled?: boolean;
    readonly?: boolean;
    classDisable?: string;
    hidden?: boolean;
    labels?: { on: string, off: string }
    onChange?: (toggled: boolean) => void;
    onSwitchToggle?: (toggled: boolean) => void;
}

const MonyToggle: React.FC<PropsWithChildren<IMonyToggleProps>> = (props) => {

    props = { ...defaultProps, ...props };

    const [toggled, setToggled] = React.useState(props.switchOn);

    React.useEffect(() => {
        if (props.switchOn !== toggled) {
            setToggled(props.switchOn);
        }
    }, [props.switchOn]);

    const handleToggle = React.useCallback(() => {
        const newValue = !toggled;
        if (props.onStateChanged) {
            props.onStateChanged(props.stateKey, newValue, props.stateRowIndex);
        } else {
            props.onSwitchToggle(newValue);
        }
    }, [toggled, props]);

    return (
        <div className="model-status text-left">
            <div key={props.key} className="crud--options" hidden={props.hidden}>
                <br />
                <div className="sidebar--options-icon" >
                    <label className="sidebar--switch-container cursor-point">
                        <input type="checkbox" name={props.name} onChange={handleToggle} checked={props.switchOn} disabled={props.disabled || props.readonly} />
                        <div className={props.disabled || props.readonly ? `sidebar--switch ${props.readonly ? '' : 'diable-toggle'} ${props.classDisable}` : "sidebar--switch "} style={{ cursor: props.readonly ? "not-allowed" : "inherit" }}></div>
                    </label>
                </div>
            </div>
            <label className="helper-text-modal-toggle">{toggled ? props.labels.on : props.labels.off}</label>
        </div>
    );
}

const defaultProps: IMonyToggleProps = {
    name: "",
    switchOn: false,
    classNameCheckBox: "",
    className: "",
    disabled: false,
    readonly: false,
    classDisable: "",
    hidden: false,
    labels: {
        on: "On",
        off: "Off"
    },
    onChange: () => { },
    onSwitchToggle: () => { }
}

export default React.memo(MonyToggle);