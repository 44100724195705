import * as H from "history";

const isCallSameExtension = (extension: string) => {
    return extension === window.location.pathname.split("/")[2];
}

export const MonyNavigate = (to: string) => {
    if (window.MonyHelper.MicroFE.oldFeNavigate) {
        window.MonyHelper.MicroFE.oldFeNavigate(to);
    }
};

export const ShellNavigate = (to: string, from=window.location.pathname) => {
    const extension = to.split("/")[2];
    const isShellDev = (window.MonyHelper.MicroFE.ShellMode ?? "develop") === "develop";
    const isAvailExt = window.MonyHelper.MicroFE.AvailExtension[extension];
    if (isShellDev || window.MonyHelper.MicroFE.shellNavigate && isAvailExt && isCallSameExtension(extension)) {
        window.MonyHelper.MicroFE.shellNavigate(to, from);
    } else {
        MonyNavigate(to);
    }
};

export const BindShellNavigate = (history: H.History<any>) => {
    window.MonyHelper.MicroFE.shellNavigate = (link: string, from: string = window.location.pathname) => {
        const pathname = link.split("?")[0];
        const search = link.split("?")[1];
        history.push({ pathname: pathname, search: search, state: { from: from } });
    }
    window.MonyHelper.MicroFE.addVisitedExtension(history.location.pathname.split("/")[2]);
};
