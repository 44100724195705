import React from "react";
import { useDatasourceState } from "../custom-state";
import { MonyAutoComplete } from "mony-component";
import { MasterDataApi } from "../api";
import {
    IFilter,
    StateChageEvent,
} from "../type-interface";

export interface IBankAutoCompleteProps {
    value: string | number;
    stateKey?: string;
    placeholder?: string;
    stateRowIndex?: number;
    onStateChanged?: StateChageEvent;
    remoteFilter?: boolean;
    error?:boolean

}

interface IBankFilter extends IFilter {
    keyword: string;
}

const BankAutoComplete: React.FC<IBankAutoCompleteProps> = (props) => {

    const defaultFilter: IBankFilter = {
        keyword: ""
    }

    const { state, actions } = useDatasourceState({
        apiFetch: MasterDataApi.FecthAllheader,
        defaultFilter: defaultFilter,
        dataMapper: [
            { from: "bank_name", to: "label" },
            { from: "bank_id", to: "value" }
        ]
    });

    React.useEffect(() => {
        if (state.data.length === 0) {
            actions.LoadData();
        }
    }, []);

    const onReloadData = React.useCallback(async (value: string) => {
        actions.ChangeFieldFilter("keyword", value);
    }, [state]);

    return (
        <MonyAutoComplete
            language={"id"}
            data={state.data}
            value={props.value}
            placeholder={props.placeholder || "Select Bank Name"}
            stateKey={props.stateKey}
            stateRowIndex={props.stateRowIndex}
            onStateChanged={props.onStateChanged}
            remoteFilter={props.remoteFilter}
            onRemoteFilter={onReloadData}
            allowClear={true}
            error={props.error}
        />
    );
}

export default BankAutoComplete;