import React, { PropsWithChildren } from "react";
import Popover from "antd/es/popover";
import { TypeMonyColors, GetMonyColor } from "../helper";

export interface IMonyLabelProps {
    label: string;
    className?: string;
    markRequired?: boolean;
    labelInfo?: {
        contentInfo?: string | JSX.Element | Array<JSX.Element>;
        textColor?: TypeMonyColors;
        width?: number | string | "auto";
        className?: string;
        position?: "float-left" | "float-right";
    }
}

const MonyLabel: React.FC<PropsWithChildren<IMonyLabelProps>> = (props) => {

    props = { ...defaultProps, ...props };

    const LabelContent = React.useCallback(() => {
        return (
            <span className="fa fa-info-circle color-text-blue pointer" style={{ paddingTop: 3.5, marginLeft: props.labelInfo.position === "float-right" ? 5 : 0, marginRight: props.labelInfo.position === "float-left" ? 5 : 0, color: GetMonyColor(props.labelInfo.textColor) }}></span>
        );
    }, [props.labelInfo]);

    const PopContent = React.useCallback(() => {
        let content: string | JSX.Element | Array<JSX.Element> = props.labelInfo.contentInfo;
        if (typeof props.labelInfo.contentInfo === "string") {
            content = <div style={{ width: props.labelInfo.width, textAlign: "justify" }}>{props.labelInfo.contentInfo}</div>;
        }
        return content;
    }, [props.labelInfo]);

    const RenderInfo = React.useCallback(() => {
        return (
            <Popover
                className={(props.labelInfo.className ?? "") + (props.labelInfo.position ?? " float-right")}
                title={props.label ? <strong>{props.label}</strong> : null}
                content={PopContent()}
                trigger="click"
                placement="right">{LabelContent()}
            </Popover>
        );
    }, [props.labelInfo]);

    return (
        <div className={props.className}>
            {props.label}
            {props.markRequired ? <span className="required" style={{ marginLeft: 5 }}>*</span> : null}
            {props.labelInfo ? RenderInfo() : null}
        </div>
    );
}

const defaultProps: IMonyLabelProps = {
    label: "",
    className: "font-bold pb--8",
    markRequired: false,
    labelInfo: undefined
}

export default React.memo(MonyLabel);