import React from "react";
import ReactDOM from "react-dom";
import { ModalForm, MonyButton, MonyTable, MonyInput, Icons } from "mony-component";
import { PiletApi } from "piral";
import { useDispatch, useSelector } from "react-redux";
import { ContainerState } from "../../redux/store";
import { FetchReferralReward, SetSnackBar } from "../../redux/actions/ContainerAction";
import { containerStore } from "../../redux/store";
import { Provider } from "react-redux";
import { SnackBar } from "./"
import { OnBoarding, onBoardingStages } from "../../pages/OnBoarding";
// import { useLanguageState } from "../../../libs/custom-state";

const LogoSuccess = "handshake.svg";
const LogoWait = "awaiting-ico-24.svg";
const LogoCancel = "no-handshake.svg";
const LogoCopy = "copy.svg";

export interface IModalReferralRewardProps {
    visible: boolean;
    OnClose?: () => void;
    piral?: PiletApi;
}

export const ModalReferralRewardPage: React.FC<IModalReferralRewardProps> = (props) => {

    props = { ...defaultProps, ...props };

    // const { translate } = useLanguageState(props.piral);
    const dispatch = useDispatch();
    const { referralReward } = useSelector((state: ContainerState) => state.container);
    const [copied, setCopied] = React.useState(false);


    React.useEffect(() => {
        if (props.visible) {
            dispatch(FetchReferralReward());
        }
    }, [props.visible]);

    const onCopyLink = React.useCallback((e: any) => {
        navigator.clipboard.writeText(referralReward.referralLink);

        setCopied(true);

        dispatch(SetSnackBar({
            message: "URL Copied",
            duration: 3000,
            show: true
        }));

    }, [referralReward, copied]);

    return (
        <ModalForm
            width={750}
            classBodyContent="m--0"
            visible={props.visible}
            title="Referral Rewards"
            onCancel={() => props.OnClose()}>
            {/* <div className="table-view-no-scroll mb--20" style={{ minWidth: "650px" }}> */}
            <div className="wd---100 p--25" style={{ backgroundColor: "whitesmoke" }}>

                <div className="dis-flex wd---100">
                    <MonyInput
                        divClassName="wd---100"
                        className="input-share wd---100"
                        readonly={true}
                        value={referralReward.referralLink}
                    />
                    <MonyButton
                        className="button--save btn-copy-link wd--153"
                        style={{ padding: 0 }}
                        onClick={onCopyLink}
                        value={"Copy Link"} />
                </div>

                <div className="wd---100">
                    <div className="wd--153 pull-right mb--50">
                        {copied === true ? <label className="label-url"><img src="/assets/images/green-check.svg" /><span className="url-copied">URL Copied</span></label> : null}
                    </div>
                </div>

                <div className="wd---100 dis-flex">

                    <div className="wd--230 mr--25 ml--10">
                        <div className="card-referral"
                        // style={{ backgroundColor: "yellow" }}
                        >
                            <div style={{ display: "flex" }}>
                                <div className="col wd--100 ml--10">
                                    <span className="font-bold-50-blue" >{referralReward.total_pending}</span>
                                </div>
                                <div className="">
                                    <div className="text-right" >
                                        <Icons glyph={LogoWait} className="icon-logo-referral"></Icons>
                                    </div>
                                </div>
                            </div>
                            <span className="font-16-bold color-wait-payment">Waiting Payment</span>
                        </div>
                    </div>

                    <div className="wd--230 mr--25">
                        <div className="card-referral"
                        // style={{ backgroundColor: "salmon" }}
                        >
                            <div className="card-body">
                                <div style={{ display: "flex" }}>
                                    <div className="col wd--100 ml--10">
                                        <span className="font-bold-50-blue" >{referralReward.total_cancel}</span>
                                    </div>
                                    <div className="">
                                        <div className="text-right">
                                            <Icons glyph={LogoCancel} className="icon-logo-referral"></Icons>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <span className="font-16-bold color-canceled">Canceled</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wd--230">
                        <div className="card-referral"
                        // style={{ backgroundColor: "yellowgreen" }}
                        >
                            <div className="card-body">
                                <div style={{ display: "flex" }}>
                                    <div className="col wd--100 ml--10">
                                        <span className="font-bold-50-blue" >{referralReward.total_used}</span>
                                    </div>
                                    <div className="">
                                        <div className="text-right">
                                            <Icons glyph={LogoSuccess} className="icon-logo-referral"></Icons>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <span className="font-16-bold color-used">Already Used</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {
                    referralReward.list_reward.length > 0 ?
                        <div className="table-view-no-scroll wd---100 m--0">
                            <MonyTable
                                id="rewards_list"
                                classDivTable="table-responsive-no-scroll"
                                className="table-valign-top"
                                rowClassName="content--table-body"
                                tableHover={false}
                                headers={[
                                    {
                                        content: "Description",
                                        className: "text-left pl--15",
                                        classLabel: "font-weight-normal font-bold font-size--14"
                                    },
                                    {
                                        content: "Status",
                                        className: "text-center pl--15 wd--150",
                                        classLabel: "font-weight-normal font-bold font-size--14"
                                    }
                                ]}
                                rows={
                                    referralReward.list_reward ? referralReward.list_reward.map((x, index) => {
                                        let color = ""
                                        if (x.status.toLowerCase() == "already used") {
                                            color = "color-used";
                                        } else if (x.status.toLowerCase() == "waiting payment") {
                                            color = "color-wait-payment";
                                        } else if (x.status.toLowerCase() == "canceled") {
                                            color = "color-canceled";
                                        } else if (x.status.toLowerCase() == "pending") {
                                            color = "color-pending";
                                        }

                                        return [
                                            {
                                                content: x.description,
                                                className: "text-left"
                                            },
                                            {
                                                content: x.status,
                                                className: `pl--15 text-center font-bold ${color}`
                                            }
                                        ]
                                    }) : null
                                }
                            />
                        </div> : null
                }
                <SnackBar />
            </div>
        </ModalForm>
    );
}

const defaultProps: IModalReferralRewardProps = {
    visible: false,
    OnClose: () => { },
}

export const ModalReferralReward: React.FC<{ piral?: PiletApi }> = (props) => {

    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        window.ShowHideReferralRewards = (show: boolean) => setVisible(show);
    }, []);

    return (
        <Provider store={containerStore}>
            <ModalReferralRewardPage visible={visible} piral={props.piral} OnClose={() => setVisible(false)} />
        </Provider>
    );
}

export const RenderPopupReferral = (piral: PiletApi) => {
    if (!document.getElementById("referral-rewards")) {
        const element = document.createElement("DIV");
        element.id = "referral-rewards";
        ReactDOM.render(<ModalReferralReward piral={piral} />, document.body.appendChild(element));
    }
}