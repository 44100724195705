import React, { PropsWithChildren } from "react";
import { TemplateForm1Props } from "../interfaces/TemplateFormProps";

const TemplateForm1: React.FC<PropsWithChildren<TemplateForm1Props>> = (props) => {
  return (
    <div style={{display: "grid" }}>
      <div style={{ width: "100%" }}>
        <div className={"content-full-form " + props.minWidthContainer} style={{ minWidth: props.minWidth }}>
          <div className="header-full-form">
            <div style={{ textAlign: "center" }}>
              <label className={"h-34 title-form pt--2 " + props.classTitle}>{props.title_1}</label>
              <div className="float-left">{props.topLeft}</div>
              <div className="float-right pr--12">{props.topRight}</div>
            </div>
          </div>

          <div className="content-full-form content---form" >
            <div className="header-full-form text-left title--desc-form">
              <div className="float-left">{props.title_2}</div>
              <div className="float-right pr--20">{props.title_2_right}</div>
              <div className="clear" />
            </div>
            <div className={"content--form--input " + props.classNameBody} >
              {props.children}
            </div>
          </div>
          {props.history}
          {
            props.bottomLeft || props.bottomRight ?
              <div className="footer-full-form" >
                <div className="float-left">{props.bottomLeft}</div>
                <div className="float-right">{props.bottomRight}</div>
              </div> : null
          }
        </div>
        {
          props.spacing ?
            <div className="content-full-form--spacing" style={{ height: props.heightspacing }} /> : null
        }
      </div>
    </div>
  );
}

export default React.memo(TemplateForm1);
