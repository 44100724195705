import React, { PropsWithChildren } from "react";
import Collapse from "antd/es/collapse";

export interface IMonyExpandableProps {
    title?: string;
    className?: string;
    controllPosition?: "left" | "right";
    default?: "expanded" | "collapsed";
    titleClassName?: string;
    onChange?: (val) => void;
}

const MonyExpandable: React.FC<PropsWithChildren<IMonyExpandableProps>> = (props) => {

    props = { ...defaultProps, ...props };

    return (
        <div className={props.className}>
            <Collapse
                defaultActiveKey={(props.default === "expanded" ? "1" : "0")}
                onChange={props.onChange}
                expandIconPosition={props.controllPosition}>
                <Collapse.Panel header={props.title} key="1" className={props.titleClassName}>
                    {props.children}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
}

const defaultProps: IMonyExpandableProps = {
    title: "",
    className: "",
    controllPosition: "right",
    default: "collapsed",
    titleClassName: "",
    onChange: () => { }
}

export default React.memo(MonyExpandable);