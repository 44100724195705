import React from "react";
import ReactDOM, { render } from "react-dom";
import { Piral, renderInstance, LoadingIndicatorProps, PiralInstance, SetComponent, ErrorInfoProps, PiralRenderOptions } from "piral";
import { extensionRenderer } from "./dom-finder";
import * as Helper from "./libs/helper";
import * as ComponentExtension from "./libs/component-extension";
import { Common, Loading, NotFound } from "mony-component";
import { injectTaptalkScript, taptalkLogout } from "./libs/helper";

import "./mony-style/style.scss";
import "antd/dist/antd.css";
import "./sass/style.scss";

import config from "./config.json";
import { AppsRegisterPage, IsUrlNoSession } from "./apps/PiralRegister";
import { UserProfile } from "./libs/api/UserApi";
import { TranslationSetup } from "./translation";
import { injectGtmScript, pushGtmPageView, pushGtmSignin } from "./libs/helper";
import { RenderPopupReferral } from "./apps/layout/modal";
import { RenderOnBoarding } from "./apps/pages/OnBoarding";
import { RenderHelpGuide } from "./apps/pages/HelpGuide";

const setAppVariable = () => {
  global.TAPTALK_SECRET_KEY = config.TAPTALK_SECRET_KEY;
  global.BASE_API_URL = config.baseApiPath;
  global.GTM_AUTH_APP = config.GTM_AUTH_APP;
  global.GTM_AUTH_WEBSITE = config.GTM_AUTH_WEBSITE;
  global.GTM_PREVIEW_APP = config.GTM_PREVIEW_APP;
  global.GTM_PREVIEW_WEBSITE = config.GTM_PREVIEW_WEBSITE;
  global.GTM_ID_APP = config.GTM_ID_APP;
  global.GTM_ID_WEBSITE = config.GTM_ID_WEBSITE;
}

export const RewritedApp = () => {
  const url_session = window.location.pathname.split('/').length > 1 ? window.location.pathname.split('/')[1] : window.location.pathname.split('/');
  const urlNoSession = [
    "test",
    "login",
    "sign-up",
    "sign-up-success",
    "forgot",
    "reset-sent",
    "reset-password",
    "reset-error",
    "reset-success",
    "activate",
    "activate-success",
    "activate-error",
    "share-invoice"
  ]
  return urlNoSession.find((x) => x == url_session) !== undefined;
}

const mainApp = async () => {

  setAppVariable();

  const mustRedirectIfHasLogged = ["/", "/login", "/sign-up"].indexOf(window.location.pathname) >= 0;
  let loadOldApps = false;
  if (!Common.getCookie() && !IsUrlNoSession()) {
    window.location.href = "/login";
  } else if (Common.getCookie() && (!IsUrlNoSession() || mustRedirectIfHasLogged)) {
    const response = await UserProfile();
    if (response.success) {
      loadOldApps = true;
      if (mustRedirectIfHasLogged) {
        window.location.href = "/dashboard";
      }
      injectTaptalkScript({
        customerUserID: `${response.data.user_id}-${response.data.current_company.company_id}`,
        fullName: response.data.user_fullname,
        alias: `${response.data.current_company.company_id} - ${response.data.current_company.company_name}`,
        email: response.data.user_email
        // phone: response.data.user_phone
      });
    } else {
      Common.clearToken();
      window.location.href = "/login";
    }
  }

  const piletServiceUrl = "/piral-pilet/pilet";
  const monyContainer = "#app";
  const piletContainer = "#main-content";

  const PageNotFound: React.FC<ErrorInfoProps> = (props) => {
    return props["location"]["pathname"].split("/")[1] === "apps" ? <NotFound onGoHomeClick={() => Helper.MonyNavigate("/")} /> : null;
  }

  const PiralLoadingIndicator: React.FC<LoadingIndicatorProps> = (props) => {
    return location.pathname.split("/")[1] === "apps" ? <Loading showLoading={true} /> : null;
  }

  let renderOption: PiralRenderOptions = {
    state: {
      language: {
        available: ["id", "en"],
        loading: false,
        selected: "id"
      }
    }
  };

  let extRenderOption: PiralRenderOptions = { ...renderOption };

  if (!RewritedApp() && (loadOldApps || IsUrlNoSession())) {
    renderOption.requestPilets = () => {
      return Helper.ApiGet(`${piletServiceUrl}/?org=harmony.co.id&name=harmony-app&type=container`, { withCredentials: false }).then((res) => res.data["items"]);
    };
  }

  let piralInstance: PiralInstance = renderInstance(renderOption);

  const StyleScriptInject = () => {
    const style = document.createElement("link");
    style.rel = "stylesheet";
    style.href = `${window.location.origin}/assets/css/bootstrap.css`;
    document.getElementsByTagName("head")[0].appendChild(style);

    const script = document.createElement("script");
    script.src = `${window.location.origin}/assets/js/jquery.min.js`;
    script.async = true;
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  StyleScriptInject();

  const renderer = (reactApp: React.ReactElement, el: HTMLDivElement) => {
    ReactDOM.render(reactApp, el);
  }

  const createPiralMainApp = (piralInstance: PiralInstance): React.ReactElement => {
    window.MonyHelper.ThirdParty.injectGtmScript();
    const app = piralInstance.root;
    TranslationSetup(piralInstance, "id");

    ComponentExtension.RegisterPiralExtension(app);
    AppsRegisterPage(piralInstance);

    return (
      <Piral instance={piralInstance}>
        <SetComponent name="LoadingIndicator" component={() => null} />
        <SetComponent name="ErrorInfo" component={() => null} />
      </Piral>
    );
  }

  const createPiralExtApp = (piralInstance: PiralInstance, landId?: string): React.ReactElement => {
    const app = piralInstance.root;
    TranslationSetup(piralInstance, landId);
    ComponentExtension.RegisterPiralExtension(app);

    return (
      <Piral instance={piralInstance}>
        <SetComponent name="LoadingIndicator" component={(info: LoadingIndicatorProps) => <PiralLoadingIndicator {...info} />} />
        <SetComponent name="ErrorInfo" component={(err: ErrorInfoProps) => <PageNotFound {...err} />} />
      </Piral>
    );
  }

  window.MonyHelper = {
    MicroFE: {
      AvailExtension: {},
      VisitedExtension: [],
      hasVisitedExtension: (extension: string): boolean => {
        return window.MonyHelper.MicroFE.VisitedExtension.findIndex((o) => o === extension) !== -1;
      },
      addVisitedExtension: (extension: string) => {
        if (!window.MonyHelper.MicroFE.hasVisitedExtension(extension)) {
          window.MonyHelper.MicroFE.VisitedExtension.push(extension);
        }
      },
      renderExt: async (params: any = {}) => {
        const { extensionName, ext_module: extModule, lang_id: langId, user_id: userId } = params;
        const filterName = extensionName ? `&name=${extensionName}` : "";

        if (!window.MonyHelper.MicroFE.AvailExtension[filterName]) {
          const res = await Helper.ApiGet(`${piletServiceUrl}/?org=harmony.co.id&type=pilet${filterName}`, { withCredentials: false });
          if (res.success) {
            const pilets: Array<any> = res.data["items"];
            for (const pilet of pilets) {
              window.MonyHelper.MicroFE.AvailExtension[pilet.name] = pilet.description;
            }
            extRenderOption.state.language.selected = langId;
            extRenderOption.requestPilets = () => new Promise((resolve) => resolve(pilets));
          }
        }

        const piralMfe = renderInstance(extRenderOption);
        extensionRenderer((el: HTMLDivElement) => renderer(createPiralExtApp(piralMfe, langId), el), piletContainer, 1000);
      }

    }
  };

  window.MonyHelper = window.MonyHelper ?? {};
  window.MonyHelper.ThirdParty = window.MonyHelper.ThirdParty ?? {};
  window.MonyHelper.ThirdParty.injectGtmScript = injectGtmScript;
  window.MonyHelper.ThirdParty.gtmPageTracking = pushGtmPageView;
  window.MonyHelper.ThirdParty.gtmSigninTracking = pushGtmSignin;
  window.MonyHelper.ThirdParty.taptalkLogout = taptalkLogout;

  // Render Harmony App as Main Container
  if (!Common.getCookie() || IsUrlNoSession()) {
    renderer(createPiralMainApp(piralInstance), document.querySelector(monyContainer));
  }

  TranslationSetup(piralInstance, "id");
  RenderPopupReferral(piralInstance.root);
  RenderOnBoarding();
  RenderHelpGuide();

  let setVisibleApp = setInterval(() => {
    if (document.getElementById("app").children.length > 0) {
      if (document.getElementById("app").children[0].children.length > 0) {
        document.getElementById("app").style.visibility = "visible";
        clearInterval(setVisibleApp);
      }
    }
  }, 500);

}

mainApp();