import React from "react";
import { Icons, MonyButton, Common } from "mony-component";
import { MonyFooter } from "mony-component";
import { IPageProps } from "../../../libs/type-interface";
import { NavLink } from "react-router-dom";

// const Logo = require("../../../assets/images/logomark.svg");
// const Logo2 = require("../../../assets/images/error-outline-ico-24.svg");
const Logo = "logomark.svg";
const Logo2 = "error-outline-ico-24.svg";

export const ResetFailedPage: React.FC<IPageProps> = (props) => {

    React.useEffect(() => Common.setTitle(props.pageTitle), []);

    return (
        <div className="login--container">
            <div className="main-content-login ">
                <div className="activate--container content-login">
                    <div className="pt--10 text-left pl--15">
                        <Icons glyph={Logo} className="icon-logo-activate" />
                    </div>
                    <div className="pt--40 pl--30 pr--30 text-center">
                        <Icons glyph={Logo2} className="icon-logo-activate-content" />
                        <label className=" lbl-title-activate pt--15">{props.translate("sorry_invalid_token", "label")}</label>
                        <p className="lbl-title-content pt--15">
                            {props.translate("reset_password_expired", "information")}
                        </p>
                        <hr className="line-activate" />
                        <label className="lbl-title-content pt--60 font-weight-normal pb--80">{props.translate("please_click", "label")} <NavLink className="link-reset-password" to="/forgot">{props.translate("here", "label")}</NavLink> {props.translate("get_new_link", "label")}</label>
                    </div>
                </div>

                <MonyFooter className="footer-activate" />
            </div>
        </div>
    );
}