import React, { Component } from "react";
import Modal from "antd/es/modal";
import { MonyButton } from ".";
import { MonyButtonType } from "./MonyButton";
import enUS from "antd/lib/locale-provider/en_US";
import ConfigProvider from "antd/es/config-provider";

export interface IConfirmationProps {
    // buttonOk?: typeof MonyButton;
    visible?: boolean;
    top?: number;
    width?: number;
    title?: string;
    classSpanTitle?: string;
    className?: string;
    classNameOk?: string;
    classNameCancel?: string;
    classNameChild?: string;
    classNameTitle?: string;
    showCancel?: boolean;
    cancelText?: string;
    cancelLeft?: string;
    classButton?: string;
    closable?: boolean;
    ajax?: boolean;
    okText?: string;
    okButtonType?: MonyButtonType;
    urlImg?: string;
    onCancel?: () => void;
    onOk?: (callback?: Function) => void;
}

class Confirmation extends Component<IConfirmationProps> {

    static defaultProps = {};

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ConfigProvider locale={enUS} >
                <Modal
                    className={this.props.className}
                    closable={this.props.closable}
                    closeIcon={<span className="fa fa-remove"></span>}
                    wrapClassName="dis-animation"
                    title={<div className={this.props.classNameTitle}><img className="float-left mr--10" src={this.props.urlImg} /><div className={this.props.classSpanTitle}>{this.props.title}</div><div className="clear" /></div>}
                    footer={
                        <div className={this.props.classButton}>
                            {this.props.onOk ? <MonyButton buttonType={this.props.okButtonType} className={`float-right ${this.props.classNameOk}`} ajax={this.props.ajax} value={this.props.okText} onClick={this.props.onOk} /> : null}
                            {this.props.showCancel ? <MonyButton buttonType="cancel" className={this.props.cancelLeft ? `float-left ${this.props.classNameCancel}` : `float-right ${this.props.classNameCancel}`} value={this.props.cancelText} onClick={this.props.onCancel} /> : null}
                            <div className="clear" />
                        </div>
                    }
                    visible={this.props.visible}
                    // onOk={this.props.onOk}
                    onCancel={this.props.onCancel}
                    width={this.props.width}
                    style={{ minWidth: this.props.width, top: this.props.top }}>
                    <div className="conf-div">
                        {typeof this.props.children == "string" ?
                            <div className={"conf-text " + this.props.classNameChild}>{this.props.children}</div>
                            :
                            this.props.children}
                        {/* <div className={this.props.classButton}>
                        {this.props.onOk ? <MonyButton buttonType={this.props.okButtonType} className={`float-right ${this.props.classNameOk}`} ajax={this.props.ajax} value={this.props.okText} onClick={this.props.onOk} /> : null}
                        {this.props.showCancel ? <MonyButton buttonType="cancel" className={this.props.cancelLeft ? `float-left ${this.props.classNameCancel}` : `float-right ${this.props.classNameCancel}`} value={this.props.cancelText} onClick={this.props.onCancel} /> : null}
                        <div className="clear" />
                    </div> */}
                    </div>
                </Modal>
            </ConfigProvider>
        );
    }
}

Confirmation.defaultProps = {
    buttonOk: null,
    okButtonType: "default",
    title: null,
    className: "",
    classNameOk: "wd--132",
    classNameTitle: "conf-title",
    classNameCancel: "modal-delete-cancel mr--50",
    classNameChild: "",
    onCancel: () => { },
    showCancel: true,
    closable: true,
    cancelText: "No",
    ajax: false,
    okText: "Yes",
    urlImg: "/assets/images/question-24.svg"
}

export default Confirmation;
