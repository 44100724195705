import React from "react";
import {
    Icons,
    MonyButton,
    MonyInput,
    Warning,
    Common,
    MonyFooter,
    MonyTagLabel,
    Converter,
    Loader
} from "mony-component";
import { Link } from "react-router-dom";
import { IPageProps } from "../../../libs/type-interface";
import { ShareModalPreviewPage } from "../../layout/modal/ShareModalPreview"
import { ArapApi } from "../../../libs/api";
import { useHistory, useParams, useLocation } from "react-router";
import { IErrorModel } from "../../../libs/type-interface";

export const ShareInvoicePage: React.FC<IPageProps> = (props) => {
    const params = useParams();
    const [showShare, setShowShare] = React.useState(false);
    const [copyLink, setCopyLink] = React.useState(false);
    const [loadingState, setLoadingState] = React.useState<boolean>(false);
    const [errorState, setErrorState] = React.useState<Array<IErrorModel>>([]);
    const [data, setData] = React.useState(Object);

    const loadData = React.useCallback(async () => {
        setLoadingState(true);
        const response = await ArapApi.GetShareInvoiceLink({ "token": params["token"], "type": "html", "company_id": params["company_id"] });
        if (response.success) {
            setData(response.data);
            setErrorState([]);
        } else {
            setErrorState(response.messages);
        }
        setLoadingState(false);
    }, [loadingState, data, errorState]);

    const getLinkInvoicePdf = React.useCallback(async (ajaxCallback: () => void) => {
        const response = await ArapApi.GetShareInvoicePdf({ "token": params["token"], "type": "pdf", "query": "&isDownload=1", "invNumber": 0, "company_id": params["company_id"] })
        if (response.success) {
            const newBlob = new Blob([response.data], { type: "application/pdf" });
            const src = window.URL.createObjectURL(newBlob);
            const element = document.createElement("a");
            element.style.display = "none";
            element.download = data.json_data.number;
            element.href = src;
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        } else {
            setErrorState(response.messages);
        }
        if (ajaxCallback) {
            ajaxCallback();
        }
    }, [errorState, data]);

    React.useEffect(() => {
        Common.setTitle(props.pageTitle);
        loadData();
        return () => {
        };
    }, []);

    const showShareModal = React.useCallback(async () => {
        setShowShare(true)
    }, [showShare, copyLink]);

    const frameShare = (html_data: any) => {
        let x = document.getElementById("shareonlines");
        let y = x["contentDocument"];
        y.open();
        y.write(html_data);
        y.close();
    }


    const renderShare = React.useMemo(() => {
        return (
            <ShareModalPreviewPage
                ajax={true}
                copylink={copyLink}
                link={params["token"]}
                company_id={params["company_id"]}
                type="invoice"
                token={params["token"]}
                title='Share Invoice'
                visible={showShare}
                onChange={() => { setCopyLink(true) }}
                onCancel={() => { setShowShare(false), setCopyLink(false) }} />
        );
    }, [showShare, copyLink]);

    return (
        <>
            <div id="coate" className="share-container">
                <div className="share-inv-tab">
                    {renderShare}
                    <table className="wd-100"><tbody>
                        <tr>
                            <td className="share-tab">
                                <label className="text--empty--report">Remaining to pay</label><br />
                                <label className="share-amount">{data.html_data != undefined ? data.json_data.status != "paid" ? data.json_data != undefined ? data.json_data.currency_code + " " + data.json_data.remaining : "" : "-" : "-"}</label>
                            </td>
                            <td className="share-tab text-center">
                                <label className="share-title">{data.json_data != undefined ? data.json_data.number : ""}</label>
                            </td>
                            {data.html_data != undefined ? <td className="share-tab">
                                {/* <a href={Harmony.ArAp.baseUrl + "/sales-link/?token=" + this.props.params.token + "&type=pdf&isDownload=1&" + Common.setCookie(this.props.params.company_id, "company-id")} className="default cur-pointer button--primary btn-share ml--10 btn-download"> */}
                                <MonyButton
                                    id="btn-download"
                                    ajax={true}
                                    classIcon="button-loading-icon"
                                    className="default cur-pointer button--primary btn-share ml--10 btn-download"
                                    onClick={getLinkInvoicePdf}
                                    value={<label className="color-white font-bold cur-pointer">
                                        <img src={"/assets/images/download-white.svg"} className="cur-pointer icon-download" />
                                        <span className="pl--10 middle">Download</span>
                                    </label>} />
                                <MonyButton
                                    buttonType="secondary"
                                    className="btn-share ml--20"
                                    onClick={showShareModal}
                                    value={<label className="pos-relativ cur-pointer">
                                        <img src={"/assets/images/user.svg"} />
                                        <span className="pl--10 middle">Share Now</span></label>} />
                            </td> : null}
                        </tr>
                    </tbody></table>
                </div>
                <div className="share-inv">
                    {data.html_data != undefined ?
                        <div className="share-content">
                            <iframe className="frame-share" id="shareonlines" ref={(f) => { frameShare(data.html_data) }} />
                            {/* <iframe className="frame-share" id="shareonline" src={Harmony.ArAp.baseUrl + "/sales-link/?token=" + this.props.params.token + "&type=pdf" + "&5cf7b49e23fc1d1c347c37e3879cc2f7=" + Common.getCookie()}></iframe> */}
                            {/* <span className="share-span" dangerouslySetInnerHTML={this.rawMarkup(html.html_data)} /> */}
                        </div>
                        : <div className="share-content load-share">
                            <Loader className="loader-share" color="#419aff" size={16} margin={4} />
                        </div>
                    }
                    <MonyFooter className="footer-activate" />
                </div>
                <div className="clear" />
            </div></>
    );
}

