export const handleErrorMultiLanguage = (error, language) => {
    if (error.filter((x) => x.message.match(/is required/g))) {
        error.filter((x) => x.message.match(/is required/g)).map((x) => x.message = x.message.replace(/is required/, language.Validasi.required))
    }
    if (error.filter((x) => x.message.match(/is invalid/g))) {
        error.filter((x) => x.message.match(/is invalid/g)).map((x) => x.message = x.message.replace(/is invalid/, language.Validasi.invalid))
    }
    if (error.filter((x) => x.message.match(/cannot be less than zero/g))) {
        error.filter((x) => x.message.match(/cannot be less than zero/g)).map((x) => x.message = x.message.replace(/cannot be less than zero/, language.Validasi.gte_zero))
    }
    if (error.filter((x) => x.message.match(/has to be minimum/g))) {
        error.filter((x) => x.message.match(/has to be minimum/g)).map((x) => x.message = x.message.replace(/has to be minimum/, language.Validasi.minimum_char))
        error.filter((x) => x.message.match(/characters/g)).map((x) => x.message = x.message.replace(/characters/, language.Validasi.char))
    }
    return error;
}