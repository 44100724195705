import React, { PropsWithChildren } from "react";
import { TemplateForm2Props } from "../interfaces/TemplateFormProps";

const TemplateForm2: React.FC<PropsWithChildren<TemplateForm2Props>> = (props) => {
  return (
    <div>
      <div className={"content-full-form " + props.minWidthContainer || ""}>
        {
          props.title_1 || props.topLeft || props.topRight ? (
            <div className={`header-full-form ${props.classHead}`}>
              <div style={{ textAlign: "center" }}>
                <label className={"h-34 title-form pt--2 " + props.classTitle || ""}>{props.title_1}</label>
                <div className="float-left">
                  {props.topLeft}
                </div>
                <div className="float-right pr--12">
                  {props.topRight}
                </div>
                <div className="clear" />
              </div>
            </div>
          ) : null
        }
        {props.children}
        {
          props.bottomLeft || props.bottomRight ?
            <div className="footer-full-form">
              <div className="float-left">{props.bottomLeft}</div>
              <div className="float-right">{props.bottomRight}</div>
            </div> : null
        }
      </div>
      {
        props.spacing ? <div className="content-full-form--spacing" /> : null
      }
    </div>
  );
}

export default React.memo(TemplateForm2);
