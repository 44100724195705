import React from "react";
import { MonyAutoComplete } from "mony-component";

const InvoiceAutoComplete: React.FC = (_props) => {

    return (
        <MonyAutoComplete data={[{ label: "xxx", value: "AAA" }]} value="AAA"/>
    );
}

export default InvoiceAutoComplete;