import React from "react";
import Popover from "antd/es/popover";
import { GetMonyColor } from "../helper";
import { StateProps } from "../interfaces/StateProps";
import InfoCircleTwoTone from "@ant-design/icons/InfoCircleTwoTone"
import MonyRadioButton from "./MonyRadioButton";

type TypeOptions = { label: string | React.ReactNode, value: any, optionDescription?: React.ReactNode | string, othersContent?: React.ReactNode };

interface IMonyRadioGroupProps extends StateProps {
    id?: string;
    value: string | number;
    options: Array<TypeOptions>,
    displayType?: "vertical" | "horizontal",
    className?: string;
    onChange?: (value: string | number) => void;
}

const MonyRadioGroup: React.FC<IMonyRadioGroupProps> = (props) => {

    props = { ...defaultProps, ...props };

    const [value, setValue] = React.useState(props.value);

    React.useEffect(() => {
        if (props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);

    const onChange = React.useCallback((e) => {
        let val = e;
        if (val.hasOwnProperty("target")) {
            val = e.target.value;
        }
        setValue(val);
        if (props.onStateChanged) {
            props.onStateChanged(props.stateKey, val, props.stateRowIndex);
        } else if (props.onChange) {
            props.onChange(val);
        }
    }, [value, props]);

    const RenderVertical = React.useMemo(() => props.options.map((option, index) => (
        <div className="">
            <div>
                <MonyRadioButton
                    id={`${props.id}-${index}`}
                    displayType="horizontal"
                    value={value === option.value}
                    stateKey={`${index}`}
                    className="ml--8 pointer"
                    onClick={() => onChange(option.value)}
                />
                {
                    typeof option.label === "string" ? <span>{option.label}</span> : option.label
                }
            </div>
            {
                option.othersContent && option.value === value ?
                    (
                        <div className="mr--8">{option.othersContent}</div>
                    ) : null
            }
            {option.optionDescription ? <div className="ml--25 pb--8 font-bold-14" style={{ color: GetMonyColor("oslo-gray") }}>{option.optionDescription}<br /></div> : null}
        </div>
    )), [value, props.options]);

    const RenderHorizontal = React.useMemo(() => (
        <div className={`dis-flex ${props.className}`}>
            {
                props.options.map((option, index) => (
                    <div className="pointer mb--8">
                        <div className="mr--15" onClick={() => onChange(option.value)}>
                            <MonyRadioButton
                                id={`${props.id}-${index}`}
                                displayType="horizontal"
                                value={value === option.value}
                                stateKey={`${index}`}
                                className="ml--8"
                            />
                            <span>{option.label}</span>
                            {
                                option.optionDescription ?
                                    <Popover content={option.optionDescription} trigger="hover">
                                        {<InfoCircleTwoTone className="ml--8" twoToneColor={[GetMonyColor("geraldine"), GetMonyColor("catskill-white")]} />}
                                    </Popover>
                                    : null
                            }
                        </div>
                        {
                            option.othersContent && option.value === value ? <div className="ml--25 mr--8">{option.othersContent}</div> : null
                        }
                    </div>
                ))
            }
        </div>
    ), [value, props.options]);

    return (<>{props.displayType === "vertical" ? RenderVertical : RenderHorizontal}</>);
}

const defaultProps: IMonyRadioGroupProps = {
    value: null,
    displayType: "vertical",
    className: "",
    options: [],
    onChange: () => { }
}

export default React.memo(MonyRadioGroup);