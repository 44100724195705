import React, { PropsWithChildren } from "react";
import { InputProps } from "../interfaces";
import { Default } from "../const/Default";
import { useDebounce } from "use-lodash-debounce";
import { Converter } from "../helper";
import { Icons, MonyButton } from ".";

// const LogoUp = require("../assets/images/close_ico_25.svg");
// const SearchIcon = require("../assets/images/search-ico-24.svg");
const LogoUp = "close_ico_25.svg";
const SearchIcon = "search-ico-24(1).svg";

const MonyInput: React.FC<PropsWithChildren<InputProps>> = (props) => {

    props = { ...defaultProps, ...props };

    const [state, setState] = React.useState({ focus: false, paste: false, keyDown: "", reff: undefined });
    const [value, setValue] = React.useState(props.value || props.defaultValue);
    const debouncedValue = useDebounce(value, props.inputDelay ? Default.DELAY_TYPING : 0);
    const hasSearch = React.useRef(false);
    const lastValue = React.useRef(value);
    const variable = React.useRef({ count: 0, textInput: undefined, requestClear: false });
    const hasValue = (typeof (props.defaultValue) === "string" && String(value).length > 0) || (typeof (props.defaultValue) === "number" && Number(value) !== 0);
    const hasSynced = React.useRef(false);

    React.useEffect(() => {
        if (variable.current.count === 0 || hasSynced.current) {
            if (props.inputType === "search" && String(lastValue.current).length > 0 && !hasSearch.current) {
                return;
            }
            lastValue.current = props.value;
            let newValue = props.value;
            if (variable.current.requestClear) {
                newValue = value;
                variable.current.requestClear = false;
            }
            setValue(newValue);
        }
    }, [props.value !== value, props.value !== lastValue.current]);

    React.useEffect(() => {
        if (props.inputType === "search" && props.value !== lastValue.current && !hasSearch.current) {
            setValue(props.value);
        }
    }, [location.href]);

    const SyncPropsValue = React.useCallback(() => {
        if (lastValue.current !== value) {
            lastValue.current = value;
            if (props.inputType !== "search") { //|| (props.inputType === "search" && props.autoCommitSearch)) {
                if (props.onStateChanged) {
                    props.onStateChanged(props.stateKey || "", value || "", props.stateRowIndex);
                } else {
                    props.onChange(value);
                }
                hasSynced.current = true;
            } else if (props.inputType === "search" && props.onFilterTempChanged) {
                props.onFilterTempChanged(props.stateKey || "", value || "", props.stateRowIndex);
                hasSynced.current = true;
            }
        }
    }, [props, value, lastValue, hasSynced]);

    React.useEffect(() => {
        SyncPropsValue();
    }, [debouncedValue]);

    const onRef = React.useCallback((node) => {
        variable.current.textInput = node;
        if (props.reff) {
            props.reff(variable.current.textInput);
        }
    }, [variable]);

    const onChange = React.useCallback((e) => {
        hasSynced.current = false;
        hasSearch.current = false;
        e.preventDefault();
        if (props.inputType === "rupiah" && variable.current.textInput) {
            setState({
                ...state,
                reff: {
                    selectionStart: variable.current.textInput.selectionStart,
                    selectionEnd: variable.current.textInput.selectionEnd,
                    value: variable.current.textInput.value
                }
            });
        }
        onChangeValue(e.target.value);
    }, [value, state, hasSynced]);

    const onClearClick = React.useCallback((e) => {
        e.preventDefault();

        if (props.inputType !== "search") {
            variable.current.requestClear = true;
            onChangeValue(props.defaultValue || "");
            hasSearch.current = true;
        } else {
            const val = props.defaultValue || "";
            variable.current.textInput.value = val;
            setValue(val);
            onSearch(null);
        }
    }, [props, variable, value]);

    const onSearch = React.useCallback((e) => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }
        const searchValue = e ? debouncedValue : variable.current.textInput.value; // Jika Klik dari Tombol ambil value debouncedValue jika dari enter atau trigger lain maka ambil state value
        hasSearch.current = true;
        if (props.onFilterTempChanged) {
            props.onFilterTempChanged(props.stateKey || "", searchValue || "", props.stateRowIndex);
        }
        if (props.onStateChanged) {
            props.onStateChanged(props.stateKey || "", searchValue || "", props.stateRowIndex);
        } else {
            props.onChange(searchValue);
        }
    }, [props, value, debouncedValue]);

    const onFocus = React.useCallback((e) => {
        setState({ ...state, focus: true });
        variable.current.count = variable.current.count + 1;
        props.onFocus(e);
    }, [props, variable, state]);

    const onBlur = React.useCallback((e) => {
        setState({ ...state, focus: false, reff: null });
        const valStr = String(value);
        if (props.inputType === "rupiah") {
            setValue(Converter.rupiahToNumber(valStr, 2, true));
        }
        variable.current.count = 0;
        if (value !== debouncedValue) { // Untuk handle onBlur sebelum delay debounce selesai
            if (props.inputType !== "search") { // || (props.inputType === "search" && props.autoCommitSearch)) { // Hanya Sync selain search yang tidak autocommit
                SyncPropsValue();
            }
        }
        props.onBlur(e);
    }, [variable, value, debouncedValue]);

    const handleKeyPress = React.useCallback((e) => {
        e = e || window.event;
        const key = e.which || e.keyCode;
        const ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false;

        if (props.inputType === "card-expired") {
            setState({ ...state, keyDown: key });
        } else if (props.inputType === "price") {
            const paste = (key === 86 && ctrl);
            setState({ ...state, paste });
        } else if (props.inputType === "search" && key === 13) {
            onSearch(null);
        }
    }, [props, state, value, debouncedValue]);

    const onChangeValue = React.useCallback((val: string | number) => {
        const valStr = String(val);
        const splitPoint = valStr.split(".");

        variable.current.count = variable.current.count + 1;

        if (props.inputType === "rate" || props.inputType === "rate-currency") {
            if (splitPoint.length > 2) {
                return false;
            }
        } else if (props.inputType === "price") {
            if (state.paste) {
                val = valStr.match(" ") ? valStr.replace(/ /g, "") : val;
                val = valStr.replace(/\,/g, "");
            }
            if (splitPoint.length === 4) {
                val = splitPoint[0] + "." + splitPoint[1].substring(0, 4);
            } else if (splitPoint.length > 4) {
                return false;
            }
        } else if (props.inputType === "card-expired") {
            if (valStr.length === 3) {
                val = valStr.substring(0, 2) + "/" + valStr.substring(2);
            }
            if (String(props.value).match(/^([0-9]\d{0,2})(\/\d{0,1})?$/) && state.keyDown === "Backspace") {
                val = valStr.substring(0, 2);
            }
        } else if (props.inputType === "disc-rate") {
            if (splitPoint.length === 2) {
                val = splitPoint[0] + "." + splitPoint[1].substring(0, 2);
            } else if (splitPoint.length > 2) {
                return false;
            }
        } else if (props.inputType === "rupiah") {
            if (variable.current.count !== 0 && valStr !== "") {
                if (splitPoint.length === 1) {
                    val = Converter.rupiahToNumber(valStr, 0, false);
                } else if (splitPoint.length > 1 && splitPoint[1].length > 0) {
                    val = Converter.rupiahToNumber(valStr, 2, variable.current.count === 0);
                }
            }
        }

        if (validate(val)) {
            setValue(val);
        }

        return true;
    }, [props, variable, value]);

    const validate = React.useCallback((value: string | number) => {

        let valStr = typeof (value) === "number" ? String(value) : value;
        const splitPoint = valStr.split(".");

        if (!value) {
            return true;
        }

        let regex: RegExp = undefined;

        if (props.inputType === "basic" || props.inputType === "search") {
            if (props.useValidate) {
                regex = RegExp(props.regex);
            } else {
                return true;
            }
        } else if (props.inputType === "no-fa" || props.inputType === "npwp") {
            regex = RegExp(/^([0-9])+([0-9])*$/);
        } else if (["number", "integer", "price-int", "price", "password-number", "price-minus", "rate", "disc-rate", "qty", "positive"].indexOf(props.inputType) !== -1) {
            if (props.inputType === "number" || props.inputType === "password-number") {
                if (props.inputType === "number" && props.Special === true) {
                    if (props.tax === true) {
                        regex = RegExp(/^([0-9,.-])*?([0-9,.-])*$/);
                    } else {
                        regex = RegExp(/^([0-9])*?([0-9])*$/);
                    }
                } else {
                    regex = RegExp(/^-?([0-9])*[.]?([0-9])*$/);
                }
            } else if (props.inputType === "positive") {
                regex = RegExp(/^([0-9])*[.]?([0-9])*$/);
            } else if (props.inputType === "price") {
                if (props.payment) {
                    regex = RegExp(/^(0|[1-9]\d{0,19})(\.\d{0,4})?$/);
                } else {
                    regex = RegExp(/^(\d+(?:[\,]\d{2})?)(\.\d{0,4})?$/);
                }
            } else if (props.inputType === "price-minus") {
                if (props.payment) {
                    regex = RegExp(/^-?(0|[1-9]\d*(\.\d+)\d{0,19})(\.\d{0,4})?$/);
                } else {
                    regex = RegExp(/^([-]|\d)(|\d+(?:[\,]\d*(\.\d+)\d{2})?)(\.\d{0,4})?$/);
                }
            } else if (props.inputType === "rate" || props.inputType === "rate-currency") {
                if (props.payment) {
                    regex = RegExp(/^(0|[1-9]\d{0,19})(\.\d{0,10})?$/);
                } else {
                    regex = RegExp(/^(\d+(?:[\,]\d{10})?)(\.\d{0,10})?$/);
                }
            } else if (props.inputType === "disc-rate") {
                if (props.payment) {
                    regex = RegExp(/^(0|[1-9]\d{0,19})(\.\d{0,2})?$/);
                } else {
                    regex = RegExp(/^(\d+(?:[\,]\d{2})?)(\.\d{0,2})?$/);
                }
            } else if (props.inputType === "integer" || props.inputType === "price-int") {
                regex = RegExp(/^([1-9])+([0-9])*$/);
            } else if (props.inputType === "qty") {
                regex = RegExp(/^(0|[1-9]\d{0,5})(\.\d{0,4})?$/);
            }
        } else if (props.inputType === "card-expired") {
            if ((valStr.length > 2 && Number(valStr.substring(0, 2)) === 0) || Number(valStr.substring(0, 2)) > 12) {
                return false;
            }
            regex = RegExp(/^([0-9]\d{0,3})(\/\d{0,2})?$/);
        } else if (props.inputType === "rupiah" && valStr !== "") {
            const valNum = Converter.rupiahToNumber(valStr, 2, true);
            const bgtMax = props.maxValue ? value > props.maxValue : false;
            if (bgtMax || splitPoint.length > 1 && splitPoint[1].length > 2 ||
                Number(valNum) === 0 && valStr.length > 1 && splitPoint.length === 1) {
                return false;
            }
        }

        return valStr.match(regex);

    }, [value]);

    const setSelectionControll = (latestVal: string | number) => {

        if (variable.current.count === 1 && props.autoSelectDel && variable.current.textInput) {
            if (variable.current.textInput.value !== latestVal) {
                variable.current.textInput.value = latestVal;
            }
            variable.current.textInput.setSelectionRange(0, String(latestVal).length);
        } else if (props.inputType === "rupiah" && variable.current.count > 1 && variable.current.textInput && state.reff) {
            const diff = String(latestVal).length - variable.current.textInput.value.length;
            if (diff !== 0) {
                setState({
                    ...state,
                    ...{
                        reff: {
                            selectionStart: state.reff.selectionStart + diff,
                            selectionEnd: state.reff.selectionEnd + diff,
                            value: latestVal
                        }
                    }
                });
            }

            if (variable.current.textInput.value !== latestVal) {
                variable.current.textInput.value = latestVal;
            }
            variable.current.textInput.setSelectionRange(state.reff.selectionStart, state.reff.selectionEnd);
        }
    }

    const getValue = React.useCallback((val?: any) => {
        let retVal = val || value;
        const valStr = String(retVal);
        if (props.inputType === "no-fa") {
            if (!state.focus && value !== undefined) {
                retVal = valStr !== "" ? valStr.substr(0, 3) + "." + valStr.substr(3, 3) + "-" + valStr.substr(6, 2) + "." + valStr.substr(8) : valStr;
            }
        } else if (props.inputType === "npwp") {
            if (!state.focus && value !== undefined) {
                retVal = valStr !== "" ? valStr.substr(0, 2) + "." + valStr.substr(2, 3) + "." + valStr.substr(5, 3) + "." + valStr.substr(8, 1) + "-" + valStr.substr(9, 3) + "." + valStr.substr(12) : valStr;
            }
        } else if (["price", "price-minus", "price-int", "rate", "rate-currency", "disc-rate"].indexOf(props.inputType) !== -1) {
            if (state.focus) {
                if (props.inputType !== "price-int") {
                    retVal = valStr.split(".").length === 1 && parseFloat(valStr) === 0 ? 0 : value;
                }
            } else {
                if (props.inputType === "rate-currency") {
                    retVal = valStr.split(".").length !== 1 && parseFloat(valStr.split(".")[0]) === 0 ? Math.floor(parseFloat(valStr) * 1000000) / 1000000 : Converter.moneyConverter(value);
                } else {
                    retVal = Converter.moneyConverter(value);
                }
            }
        } else if (props.inputType === "rupiah") {
            const decimal = variable.current.count !== 0 ? 0 : 2;
            const allowEmpty = variable.current.count !== 0;
            retVal = Converter.formatRupiah(value, decimal, allowEmpty);
        }
        if (!val) {
            setSelectionControll(retVal);
        }
        return retVal || props.defaultValue;
    }, [props, variable, value]);

    const setDefaultProps = () => {
        if (["price", "price-minus", "rate", "disc-rate"].indexOf(props.inputType) !== -1) {
            props = { ...props, alignment: props.alignment || "right" };
        } else if (props.inputType === "card-expired") {
            props = { ...props, maxLength: props.maxLength || 5, placeholder: props.placeholder || "MM/YY" };
        } else if (props.inputType === "rate-currency") {
            props = { ...props, type: props.type || "text" };
        } else if (props.inputType === "password" || props.inputType === "password-number") {
            props = { ...props, type: !props.showPassword ? "password" : "text" };
        } else if (props.inputType === "no-fa") {
            props = { ...props, maxLength: props.maxLength || 17, placeholder: "010.xxx-xx.xxxxxxxx" };
        } else if (["number", "qty", "npwp"].indexOf(props.inputType) !== -1) {
            props = { ...props, maxLength: props.maxLength || 15 };
        }
    }

    setDefaultProps();

    const getClassName = React.useCallback(() => {
        let inputTypeClass = "input-type-search";
        let adjustClass = "";
        if (["basic", "search", "password", "no-fa", "npwp"].indexOf(props.inputType) === -1) {
            inputTypeClass = "input-type-number";
        }
        if (props.flatBox) {
            adjustClass = `${adjustClass} flat-box`;
        }
        if (!props.border) {
            adjustClass = `${adjustClass} no-border`;
        }
        return `${props.error ? "input-error" : ""} ${inputTypeClass} ${alignmentClass} ${props.className} ${props.showClear ? "pr--20" : ""} ${adjustClass}`;
    }, [props]);

    const alignmentClass = props.alignment === "left" ? "text-left" : props.alignment === "right" ? "text-right" : "";

    return (
        <div className={`col-input-type ${props.divClassName}`}>
            <input
                id={props.id}
                value={getValue()}
                autoFocus={props.autoFocus}
                readOnly={props.readonly}
                style={props.style}
                hidden={props.hidden}
                ref={onRef}
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={props.placeholder}
                type={props.type}
                onKeyPress={props.onKeyPress}
                onKeyDown={handleKeyPress}
                onChange={onChange}
                className={getClassName()}
                onClick={props.onClick}
                disabled={props.disabled}
                maxLength={Number(props.maxLength)}
            />
            {
                props.inputType === "search" ?
                    <MonyButton
                        disabled={props.disabled}
                        className="btn-find"
                        onClick={onSearch}>
                        <Icons glyph={SearchIcon} className="icon-find-text" />
                    </MonyButton>
                    : null
            }
            {
                props.showClear && hasValue ?
                    <a onClick={onClearClick}>
                        <Icons glyph={LogoUp} className={props.inputType !== "search" ? "icon-clear-text" : "icon-clear-findtext"} />
                    </a>
                    : null
            }
            {
                props.icon ? <a onClick={props.onClickIcon}>{props.icon}</a> : null
            }
        </div>
    );
}

const defaultProps: InputProps = {
    value: "",
    type: "text",
    defaultValue: "",
    inputType: "basic",
    useValidate: false,
    readonly: false,
    regex: "^[a-zA-Z0-9_]*$",
    autoFocus: false,
    className: "input-type",
    style: {},
    hidden: false,
    showClear: false,
    Special: false,
    tax: false,
    autoSelectDel: false,
    payment: false,
    showPassword: false,
    autoCommitSearch: false,
    border: true,
    flatBox: false,
    maxLength: 50,
    inputDelay: true,
    icon: null,
    placeholder: "",
    onClickIcon: () => {},
    reff: () => { },
    onChange: () => { },
    onBlur: () => { },
    onFocus: () => { },
    getValue: () => { },
    validate: () => { },
    onKeyPress: () => { }
}

export default MonyInput;
// export default React.memo(MonyInput);
