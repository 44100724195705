import React, { PropsWithChildren, MouseEventHandler } from "react";
import Tag from "antd/es/tag";
import Popover from "antd/es/popover";
import { TypeMonyColors, GetMonyColor } from "../helper";
import { NavLink } from "react-router-dom";

export interface IMonyTagLabelProps {
    value?: string | number;
    width?: string | number;
    tagColor?: TypeMonyColors;
    textColor?: TypeMonyColors;
    tagClassName?: string;
    textClassName?: string;
    closable?: boolean;
    hyperlink?: string;
    onClose?: (value: string | number) => void;
    popover?: boolean;
    onClick?: MouseEventHandler;
}

const MonyTagLabel: React.FC<PropsWithChildren<IMonyTagLabelProps>> = (props) => {

    props = { ...defaultProps, ...props };

    const onClose = React.useCallback((e) => {
        e.preventDefault();
        if (props.closable && props.value) {
            props.onClose(props.value);
        }
    }, []);

    const genStyle = React.useCallback(() => {
        let style: React.CSSProperties = {};
        if (props.width) {
            style = { ...style, width: props.width };
        }
        return style;
    }, [props]);

    const TagElement = React.useCallback(() => {
        return (
            <Tag
                color={GetMonyColor(props.tagColor)}
                className={`mr--0 ${props.tagClassName}`}
                closable={props.closable}
                onClose={onClose}
                onClick={props.onClick}
                style={genStyle()}>
                {
                    props.hyperlink ?
                        <NavLink to={{ pathname: props.hyperlink, state: { from: location.pathname } }} className={props.textClassName} style={{ color: GetMonyColor(props.textColor), width: "100%" }}>{props.value || props.children}</NavLink> :
                        <span className={props.textClassName} style={{ color: GetMonyColor(props.textColor), width: "100%" }}>{props.value || props.children}</span>
                }
            </Tag>
        );
    }, [props]);

    return (
        <>
            {
                props.popover ? <Popover content={props.value || props.children} trigger="hover">{TagElement()}</Popover> : TagElement()
            }
        </>
    );
}

const defaultProps: IMonyTagLabelProps = {
    tagClassName: "",
    textClassName: "font-14-bold",
    closable: false,
    popover: false,
    hyperlink: "",
    onClose: () => { },
    onClick: () => { }
}

export default React.memo(MonyTagLabel);