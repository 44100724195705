import React from "react";
import { SelectProps } from "../interfaces/SelectProps";
import { Default } from "../const/Default";
import Select from "antd/es/select";

const Selected: React.FC<SelectProps> = (props) => {

    props = { ...defaultProps, ...props };

    const [value, setValue] = React.useState(props.value || props.defaultValue);

    React.useEffect(() => {
        if (String(props.value) !== String(value)) {
            setValue(props.value);
            onChange(props.value);
        }
    }, [props]);

    const onChange = React.useCallback((valueSelected) => {
        if (props.readonly) return;
        if (valueSelected !== props.placeholder) { // && String(valueSelected) !== String(value)
            props.data.forEach((x) => {
                if (x.option) {
                    x.option.forEach(element => {
                        let click = getEvent(element, valueSelected);
                        if (typeof (click) === "function") {
                            click();
                        }
                    });
                } else {
                    let click = getEvent(x, valueSelected);
                    if (typeof (click) === "function") {
                        click();
                    }
                }
            });

            setValue(valueSelected);

            if (props.OnSelected) {
                props.OnSelected(
                    {
                        value: valueSelected,
                        // label: e.target.selectedOptions[0].label,
                        // option: e.target.selectedOptions,
                        stateKey: props.stateKey || "",
                        stateRowIndex: props.stateRowIndex
                    }
                );
            } else if (props.onStateChanged) {
                props.onStateChanged(props.stateKey || "", valueSelected || "", props.stateRowIndex);
            }
        }
    }, [props, value]);

    const getEvent = (option, value) => {
        return option.value === value ? option.onClick : () => { };
    }

    const getClassName = React.useCallback(() => {
        let classname = `${props.className || ""}`; // selected
        if (props.error) {
            classname = `${classname} selected-error`;
        }
        if (props.flatBox) {
            classname = `${classname} flat-box`;
        }
        if (!props.border) {
            classname = `${classname} no-border`;
        }
        if (!value) {
            classname = `${classname} disabled-placeholder`;
        }
        return classname;
    }, [props]);

    const renderOptionGroup = (optionGroup) => {
        return (
            <Select.OptGroup
                label={optionGroup.label}
                key={optionGroup.value}
                disabled={optionGroup.disabled} >
                {optionGroup && Array.isArray(optionGroup.option) && optionGroup.option.map((x) => renderOption(x))}
            </Select.OptGroup >
        );
    }

    const renderOption = (option) => {
        return (
            <Select.Option key={option.value} value={option.value} disabled={option.disabled} label={option.label} >
                {option.label}
            </Select.Option>
        );
    }

    const getData = () => {
        return props.data && Array.isArray(props.data) && props.data.map((x) => {
            let value = [];
            if (x.option) {
                value.push(renderOptionGroup(x));
            } else {
                value.push(renderOption(x));
            }
            return value;
        });
    }

    return (
        <div className={props.divClassName}>
            <Select
                id={props.id}
                style={{ backgroundImage: `url("${Default.BASE_URL}/assets/images/group.svg")`, backgroundRepeat: "no-repeat", backgroundPosition: "right 5px center", cursor: props.readonly ? "not-allowed" : "inherit" }}
                className={getClassName()}
                // dropdownStyle={{ cursor: props.readonly ? "not-allowed" : "inherit" }}
                value={value}
                placeholder={props.placeholder}
                disabled={props.disabled}
                dropdownMatchSelectWidth={props.dropdownMatchSelectWidth}
                onChange={onChange}>
                {
                    String(props.placeholder).length > 0
                        ? <Select.Option value={""} className="ant-select-item-option-disabled" disabled={true}>{props.placeholder}</Select.Option>
                        : null
                }
                {getData()}
            </Select>

        </div>
    );
}

const defaultProps: SelectProps = {
    data: [],
    flatBox: false,
    border: true,
    disabled: false,
    readonly: false,
    placeholder: "Select",
    dropdownMatchSelectWidth: false,
    className: "wd---100"
}

export default Selected;
