import React from "react";
import { TabsProps } from "../interfaces/TabsProps";

const Tabs: React.FC<TabsProps> = (props) => {

    props = { ...defaultProps(props), ...props };

    const [activeTab, setActiveTab] = React.useState(props.initial);

    React.useEffect(() => {
        if (props.value !== activeTab) {
            setActiveTab(props.value);
        }
    }, [props]);

    const handleClick = (value: string | number) => {
        setActiveTab(value);
        props.onTabChange(String(value));
    }

    const content = props.data.map((row, index) => {
        let status = "";
        if (typeof (row) === "string") {
            status = props.value === row || activeTab === row ? "active-tab" : "";
            return (
                <li key={index}>
                    <a onClick={() => handleClick(row)} className={status}>{row}</a>
                </li>
            );
        } else if (typeof (row) === "object") {
            status = props.value === row.value || activeTab === row.value ? "active-tab" : "";
            return (
                <li key={index}>
                    <a onClick={() => handleClick(row.value)} className={status}>{row.label}</a>
                </li>
            );
        }
        return null;
    });

    return (
        <div className={"header-content "} style={{ minWidth: props.minWidthContainer }}>
            <div className={`title-header-col ${props.className || ""}`} style={{ width: props.leftWidth, maxWidth: props.leftWidth, float: "left" }}>
                <label className="title-header">{props.topLeft || props.headerName}</label>
            </div>
            <div className="list-tab text-center" style={{ overflowX: "auto", width: props.minWidth, maxWidth: props.minWidth, top: 4, position: "relative" }}>
                {
                    props.children ? props.children : <ul className={`tab-content ${props.classNameList || ""}`}>
                        {content}
                    </ul>
                }
            </div>
            <div className="title-right-header-col" style={{ width: props.rightWidth, maxWidth: props.rightWidth, float: "right" }}>
                {props.topRight}
            </div>
        </div>
    );
}

const defaultProps = (props: TabsProps) => {
    return {
        onTabChange: props.onTabChange || function () { },
        leftWidth: props.leftWidth || "30%",
        data: props.data || [],
        minWidth: props.minWidth || "50%",
        rightWidth: props.rightWidth || "20%",
        minWidthContainer: props.minWidthContainer || "1000px",
        className: props.className || ""
    };
}

export default React.memo(Tabs);
