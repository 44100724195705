export type TypeMonyColors = "white" | "dodger-blue" | "dodger-blue-2" | "malibu" | "hawkes-blue" | "black" | "grey" | "light-grey" | "catskill-white" | "emerald" | "algae-green" | "magic-mint" | "midnight" | "big-stone" | "dark-ebony-clay" | "oslo-gray" | "heather" | "coral-red" | "geraldine" | "ripe-lemon" | "gray" | "catskill-white-2";

export const MonyColors = {
    "white": "#ffffff",
    "dodger-blue": "#419aff",
    "dodger-blue-2": "#2088ff",
    "malibu": "#7ab8ff",
    "hawkes-blue": "#e3f3fd",
    "black": "#000000",
    "gray": "#eeeeee",
    "grey": "#b2b3b4",
    "light-grey": "#646464",
    "catskill-white": "#f5f7fa",
    // "red": "#ff414a",
    "emerald": "#4ddb7c",
    "algae-green": "#82e6a3",
    "magic-mint": "#adf0c3",
    "midnight": "#012040",
    "big-stone": "#192b46",
    "dark-ebony-clay": "#242b33",
    "oslo-gray": "#7e8893",
    "heather": "#b8c1cc",
    "coral-red": "#ff414a",
    "geraldine": "#ff7a80",
    "ripe-lemon": "#f8e71c",
    "catskill-white-2": "#f5f7fa"
};

export const GetMonyColor = (color: TypeMonyColors) => {
    return MonyColors[color];
};
