import React, { PropsWithChildren, CSSProperties } from "react";
import { StateProps } from "../interfaces/StateProps";
// import { CaretDownOutlined } from "@ant-design/icons";
// import { GetMonyColor } from "../helper";

export interface IMonyCheckboxProps extends StateProps {
    checked?: boolean;
    classNameCheckBox?: string;
    className?: string;
    disabled?: boolean;
    style?: CSSProperties;
    label?: string;
    classLabel?: string;
    // menuOption?: Array<{ menuKey: string; menuDesc: string; }>;
    onClick?: (checked: boolean) => void;
    onChange?: (checked: boolean) => void;
}

const MonyCheckbox: React.FC<PropsWithChildren<IMonyCheckboxProps>> = (props) => {

    props = { ...defaultProps, ...props };

    const [checked, setChecked] = React.useState(props.checked);

    const onChange = React.useCallback(() => {
        if (!props.disabled) {
            if (props.onStateChanged) {
                props.onStateChanged(props.stateKey || "", !checked, props.stateRowIndex);
            } else {
                props.onChange(!checked);
            }
        }
    }, [props, checked]);

    React.useEffect(() => {
        if (props.checked !== checked) {
            setChecked(props.checked);
        }
    }, [props.checked]);

    // React.useEffect(() => {
    //     if (props.onStateChanged) {
    //         props.onStateChanged(props.stateKey || "", checked, props.stateRowIndex);
    //     } else if (props.onChange) {
    //         props.onChange(checked);
    //     }
    // }, [checked]);

    const onClick = React.useCallback(() => {
        if (!props.disabled) {
            setChecked(!checked);
            props.onClick(!checked);
        }
    }, [checked, props]);

    const Checkbox = React.useMemo(() => (
        // <div style={props.style} className={`${props.menuOption ? "dis-grid wd--50" : "inline-block"} ${props.className}`}>
        <div style={props.style} className={`inline-block ${props.className}`}>
            <div className={"onecheckbox--row " + props.classNameCheckBox}>
                <input
                    type="checkbox"
                    checked={props.checked}
                    onChange={onChange}
                    // onChange={() => {}}
                    onClick={onClick}
                    disabled={props.disabled} />
                <div id="buffer" />
            </div>
            {/* {props.menuOption ? <CaretDownOutlined width="0.8em" style={{ color: GetMonyColor("oslo-gray") }} /> : null} */}
        </div>
    ), [props]);

    return (
        <label className={props.classLabel}>{Checkbox} {props.label}</label>
    );
}

const defaultProps: IMonyCheckboxProps = {
    checked: false,
    disabled: false,
    classNameCheckBox: "",
    className: "",
    label: "",
    classLabel: "font-bold-14",
    style: { marginRight: "5px", paddingTop: "1px" },
    onClick: () => {}
}

export default React.memo(MonyCheckbox);