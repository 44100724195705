import React from "react";

export interface MonyFooterProps {
    disabled?: boolean;
    className?: string;
}

const MonyFooter: React.FC<MonyFooterProps> = (props) => {
    props = { ...defaultProps, ...props };

    const onClick = React.useCallback((e) => {
        if (props.disabled) {
            e.preventDefault();
        }
    }, [props]);

    return (
        <div
            id="copyright"
            className={props.className + " footer-copyright"}>
            &copy; <a href={props.disabled ? "" : "http://www.harmony.co.id"} className={props.disabled ? "normal-text" : ""} onClick={onClick}>Harmony</a> 2021 All Rights Reserved <br /><a href="http://www.harmony.co.id/bantuan">Help | Privacy &amp; Terms</a>
        </div>
    )
}

const defaultProps: MonyFooterProps = {
    disabled: false,
    className: "pt--10 pr--40 pl--10 pb--10 footer-copyright-app"
}

export default React.memo(MonyFooter);
