import React from "react";

import {
    IFilter,
    IErrorModel,
    TypeApiFetchAll,
    TypeApiCreateOtg,
    IDataSourceStateModel,
    TypeDataSourceObjectMapper,
    TypeQueryStringObjectMapper
} from "../type-interface";

export type TypeDataSourceState = {
    state: IDataSourceStateModel;
    actions: {
        LoadData: (fromInit?: boolean) => Promise<void>;
        CreateOtg: (data: object, onCreatedOtg: (data: object) => void) => Promise<void>;
        ChangeFieldFilter: (key: string, value: any) => void;
    }
}

interface DataSourceStateProps {
    apiFetch: TypeApiFetchAll;
    dataMapper?: TypeDataSourceObjectMapper;
    apiCreateOtg?: TypeApiCreateOtg;
    defaultFilter?: IFilter;
    queryStringMapper?: TypeQueryStringObjectMapper;
}

export const useDatasourceState = (props: DataSourceStateProps): TypeDataSourceState => {

    const initState: IDataSourceStateModel = {
        isLoading: false,
        data: [],
        error: [],
        filter: props.defaultFilter
    };

    const changedFilterRef = React.useRef(false);
    const [state, setState] = React.useState<IDataSourceStateModel>(initState);

    React.useEffect(() => {
        if (changedFilterRef.current) {
            changedFilterRef.current = false;
            LoadData();
        }
    }, [state.filter]);

    const GetQueryString = React.useCallback((): string => {
        if (props.queryStringMapper) {
            const queryStringObject: Object = {};
            Object.keys(state.filter).forEach((keyFrom) => {
                const mapper = props.queryStringMapper.find((m) => m.from === keyFrom);
                if (mapper) {
                    queryStringObject[mapper.to] = state.filter[mapper.from] || mapper.default;
                } else {
                    queryStringObject[keyFrom] = state.filter[keyFrom];
                }
            });
            return Object({ ...queryStringObject }).queryStringBuilder;
        } else {
            return Object({ ...state.filter }).queryStringBuilder;
        }
    }, [state.filter]);

    const onLoading = React.useCallback(() => {
        setState({
            isLoading: true,
            data: [],
            error: []
        });
    }, [state]);

    const onError = React.useCallback((message: Array<IErrorModel>) => {
        setState({
            isLoading: false,
            data: [],
            error: message
        });
    }, [state]);

    const onSuccess = React.useCallback((data: Array<any>) => {
        let newData;
        if (props.dataMapper) {
            newData = data.map((dataRow) => {
                let newObj = {};
                props.dataMapper.forEach((mapp) => {
                    newObj[mapp.to] = dataRow[mapp.from];
                });
                return newObj;
            });
        } else {
            newData = data;
        }
        setState({ ...state, data: newData });
    }, [state]);

    const LoadData = React.useCallback(async (fromInit?: boolean) => {

        if (fromInit && state.data.length > 0) {
            return;
        }

        onLoading();
        const response = await props.apiFetch(GetQueryString());
        if (response.success) {
            onSuccess(response.data);
        } else {
            onError(response.messages)
        }
    }, [state]);

    const CreateOtg = React.useCallback(async (data: object, onCreatedOtg: (data: object) => void) => {
        onLoading();
        const response = await props.apiCreateOtg(data);
        if (response.success) {
            onCreatedOtg(response.data);
        } else {
            onError(response.messages)
        }
    }, []);

    const ChangeFieldFilter = React.useCallback((key: string, value: any) => {
        changedFilterRef.current = true;
        setState({ ...state, filter: { ...state.filter, [key]: value } });
    }, [state.filter]);

    return {
        state,
        actions: {
            LoadData,
            CreateOtg,
            ChangeFieldFilter
        }
    };

}
