import { RequestTaptalkTicket } from "../api/UserApi";
import { TaptalkTicketBody } from "../type-interface";

export const addStyle = (styles, id) => {
    let css: any = document.createElement("style");
    if (id) css.id = id;
    css.type = "text/css";
    if (css.styleSheet) {
        css.styleSheet.cssText = styles;
    } else {
        css.appendChild(document.createTextNode(styles));
    }
    document.head.appendChild(css);
}

export const injectTaptalkScript = (user: TaptalkTicketBody) => {
    const scriptId = "taptalk.io-script";
    if (!document.getElementById(scriptId)) {
        const scriptInclude = document.createElement("script");
        scriptInclude.id = "taptalk.io-script-main";
        scriptInclude.src = "https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v1.0/main.js";
        scriptInclude.async = true;
        scriptInclude.defer = true

        const onLoad = async () => {
            addStyle(
                `.taplive-chat-widget-opener-button { bottom: 60px; } .taplive-outer-container { bottom: 140px; height: calc(100% - 160px); max-height: calc(100% - 160px); }`,
                "taptalk.io-script-style"
            );
            if (window.TapTalkLive) {
                TapTalkLive.init(global.TAPTALK_SECRET_KEY);
                if (!TapTalkLive.isAuthenticated()) {
                    const taptalkTicket = await RequestTaptalkTicket(user);
                    TapTalkLive.authenticateWithAuthTicket(taptalkTicket, (e) => { });
                }
            }
        };

        try {
            scriptInclude.onload = onLoad;
        } catch (e) {
            scriptInclude.addEventListener("load", onLoad);
        }

        document.head.appendChild(scriptInclude);
    }
}

export const taptalkLogout = () => {
    if (window.TapTalkLive) {
        if (window.TapTalkLive.isAuthenticated()) {
            TapTalkLive.launcherLogout(() => { });
        }
    }
}