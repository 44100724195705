import { IFilter, IPagingModel } from "../../type-interface";

export const SET_FILTER = "SET_FILTER";
export const RESET_FILTER = "RESET_FILTER";

export const CLEAR_LIST = "CLEAR_LIST";
export const SET_LIST = "SET_LIST";
export const UPDATE_FIELD_LIST = "UPDATE_FIELD_LIST";
export const UPDATE_BULK_FIELD_LIST = "UPDATE_BULK_FIELD_LIST";

export const RESET_DETAIL = "RESET_DETAIL";
export const REPLACE_DETAIL = "REPLACE_DETAIL";
export const UPDATE_FIELD_DETAIL = "UPDATE_FIELD_DETAIL";
export const UPDATE_BULK_FIELD_DETAIL = "UPDATE_BULK_FIELD_DETAIL";

export type FetchFilterType = IFilter & IPagingModel;

export interface StateType<T> {
    filter: FetchFilterType;
    list: Array<T>;
    detail?: T;
}

export interface ISetFilterAction {
    name: string;
    type: typeof SET_FILTER;
    payload: FetchFilterType;
}

export interface IResetFilterAction {
    name: string;
    type: typeof RESET_FILTER;
}

export interface IClearListAction {
    name: string;
    type: typeof CLEAR_LIST;
}

export interface ISetListAction<T> {
    name: string;
    type: typeof SET_LIST;
    payload: Array<T>;
}

export interface IUpdateFieldListAction<T> {
    name: string;
    type: typeof UPDATE_FIELD_LIST;
    payload: {
        key: keyof T;
        value: any;
        index: number;
    };
}

export interface IUpdateBulkFieldListAction {
    name: string;
    type: typeof UPDATE_BULK_FIELD_LIST;
    payload: {
        value: { [key: string]: any };
        index: number;
    };
}

export interface IReplaceAction<T> {
    name: string;
    type: typeof REPLACE_DETAIL;
    payload: T;
}

export interface IUpdateFieldAction<T> {
    name: string;
    type: typeof UPDATE_FIELD_DETAIL;
    payload: {
        key: keyof T;
        value: any;
    };
}

export interface IUpdateBulkFieldAction {
    name: string;
    type: typeof UPDATE_BULK_FIELD_DETAIL;
    payload: { [key: string]: any };
}

export interface IResetAction {
    name: string;
    type: typeof RESET_DETAIL;
}

export type ListActionType<T> = IClearListAction | ISetListAction<T> | IUpdateFieldListAction<T> | IUpdateBulkFieldListAction;
export type DetailActionType<T> = IReplaceAction<T> | IUpdateFieldAction<T> | IUpdateBulkFieldAction | IResetAction;
export type ActionType<T> = ISetFilterAction | IResetFilterAction | ListActionType<T> | DetailActionType<T>;