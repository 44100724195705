import React from "react";
import { PulseLoader } from "react-spinners";

export interface LoaderProps {
    key?: string;
    labelText?: string;
    paddingBottom?: string | number;
    minWidth?: number;
    paddingTop?: string | number;
    className?: string;
    showText?: boolean;
    labelClassName?: string;
    color?: string;
    size?: number;
    margin?: number
};

const Loader: React.FC<LoaderProps> = (props) => {

    props = { ...defaultProps, ...props };

    return (
        <div
            key={props.key}
            className={props.className}
            style={{
                paddingTop: typeof (props.paddingTop) === "number" ? `${props.paddingTop}px` : props.paddingTop,
                paddingBottom: typeof (props.paddingBottom) === "number" ? `${props.paddingBottom}px` : props.paddingBottom,
                textAlign: "center",
                display: "block",
                margin: "0 auto",
                borderColor: "red",
                minWidth: props.minWidth
            }}>
            <PulseLoader color={props.color || "#419aff"} size={props.size || 25} margin={props.margin || 4} />
            {
                props.showText ? <label className={`${props.labelClassName} "pt--40 lbl-info-loading"`}>{props.labelText || "Loading..."}</label> : null
            }
        </div>
    );
}

const defaultProps: LoaderProps = {
    showText: false,
    paddingTop: "20%",
    paddingBottom: "20%"
}

export default React.memo(Loader);